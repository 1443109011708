import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import Grid from '@material-ui/core/Grid';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApproveForm from './ApproveForm';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup, Toolbar } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FilterVendor from '../Residential/FilterVendor';
import { size } from 'lodash';
// import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import { getProjectVendorList } from '../../../../api/PE/task';
import { approveRejectApi } from '../../../../api/PE/vendor';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import CircularIndeterminate from '../../../Loader'

import {
	getMilestoneList,
	RejectApi,
	getPurchaseOrderApi,
	getListOfPerformaInvoiceFile,
	getPrListForMilestone,
	getVendorMilestoneList
} from '../../../../api/PE/vendor';
import Moment from 'moment';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { Height } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'bootstrap/dist/css/bootstrap.min.css'



const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '4px 10px 16px 10px'
	},
  modalStyle: {
    position: "absolute",
    inset: "40px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    width:"85%",
    margin:"7%",
    marginBottom:"3%"
  },
  cancleIcon: {
    position:"fixed",
    right:"6%"
  },
	root: {
		minWidth: 300,
		textAlign: "center",
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},

	// paperform: {
	//     backgroundColor: theme.palette.background.paper,
	//     border: '2px solid #000',
	//     boxShadow: theme.shadows[5],
	//     padding: theme.spacing(2, 4, 3),
	// },
	secondaryTail: {
		backgroundColor: theme.palette.green,
	},
	btnPadding: {
		padding: "6px 14px !important"
	},
	modalBox: {
		justifyContent: "center",
		maxWidth: "50% !important",

	},
	dialog: {
		boxShadow: "0 8px 6px -6px black",
		position: "static",
		// left: "10%",
		top: "1%",
		right:"10%"
	},
	image: {
		width: "100%",
		height:"100%"
	}
	// modal: {
	//     display: 'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center'
	// },
	// formControl: {

	// },
	// bootstrapbtn: {
	//     backgroundColor: "#3F51B5",
	//     color: "#ffffff",
	//     fontSize: "14px",
	//     padding: "7px 16px",
	//     fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	//     fontWeight: "500",
	//     lineHeight: "1.75",
	//     borderRadius: "4px",
	//     border: "none",
	//     letterSpacing: "0.02857em",
	//     textTransform: "uppercase",
	//     cursor: "pointer"
	// }
}));

export default function CustomizedTimeline(props) {
	const auth = useSelector(state => state.auth)
	const { project, vendorData } = props;
	console.log("props value is ******", props)
	const [milestone, setMilestone] = useState([]);
	const [open, setOpen] = useState(false);
	const [openHistory, setOpenHistory] = useState(false);
	const [approveFormData, setApproveFormData] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [rejectForm, setRejectForm] = useState();
	const [invoiceListData, setInvoiceData] = useState([]);
	const [prList, setPrList] = useState();
	const [approveButton, setApproveButton] = useState();
	const [rolesOption, setRoleOption] = useState([]);
  const userValue = JSON.parse(localStorage.getItem("current_user"))
  // var filterVendor
  // if(userValue.office_role_id = 13){
  //    filterVendor = vendorData?.filter(value => value.name == userValue.name) || []
  // }else{
	 const filterVendor = (userValue.office_role_id != 13 ? vendorData : vendorData?.filter(value => value.name == userValue.name)) || []
  // }
	const [personName, setPersonName] = useState(filterVendor && filterVendor[0] || {});
	const [userRoleID, setUserRoleID] = useState();
	const [largeImage, setLargeImage] = useState(false);
	const [dialogUrl, setDialogUrl] = useState("");
	const [vendorState, setVendorState] = useState('');
  const [singleUrl, setSingleUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
	console.log("filterVendorvvvvv",filterVendor, props.vendorData)


	console.log("props.authenticate", props.authenticate)
	// const [showModal, setModal] = useState(true);


	const classes = useStyles();
	// const approveForm = () => {
	//     this.props.history.push('/ApproveForm/');
	// }
	const bull = <span className={classes.bullet}>•</span>;
	useEffect(() => {
		console.log("current user", localStorage.getItem("current_user"))
		var userId = JSON.parse(localStorage.getItem("current_user"))
		setUserRoleID(userId.office_role_id)
		console.log(userId.office_role_id, "99999999999")
		if (userId.office_role_id != 13) {
			getProjectVendorList(props.authenticate, project.id, userId.id)
				.then(res => {
					if (res.status === 200) {
						console.log("vendor list ................1122345", res.data);
						// setFilterVendor(res.data, userId.id);
						handleVendorList(res.data[0]);
						// console.log(filterVendor, "000000000000000")
					}
				});
		}
		else {
			getVendorMilestoneList(auth, project.id, userId.id)
				.then(res => {
					if (res.status === 200) {
						console.log("milestone list ................12345", res.data);
						// setPersonName(userId.id);
						setMilestone(res.data);
						// console.log("milestonedata is in milestone", milestone);
					}
				});
			// setPersonName(userId.id);
		}
		console.log(userRoleID, "0")
	}, []);

	const poidVariable = []
	const handleprojectId =
		(rowData) => {
			getPurchaseOrderApi(rowData.id).
				then((res) => {
					if (res.status == 200) {
						console.log("project id milja", res.data)
						setApproveFormData(res && res.data)
						console.log("123456789098765432112345678", res.data)
						localStorage.setItem("milestone_id", rowData.id);
						localStorage.setItem("task_po_id", rowData.task_po_list[0].purchase_order_id);
						// console.log("reference_no", rowData.reference_no)
						setOpen(true);
					}
				})
				.catch((err) => {
					// Swal.fire('Error', err && err.data && err.data.message, "error");
				})
		}

	const handleModal = (status) => {
		console.log(status)
	}
	const handleApprove = (formData) => {
		console.log(formData)
	}

	const handleReject = (event) => {
		var status = "pm_rejected";
		console.log("reject ", event)
		RejectApi(event.id, props.authenticate, status)
			.then((res) => {
				if (res.status == 200) {
					setRejectForm(res)
					console.log(res, rejectForm, "reject the form")
					Swal.fire('Success', `successfully`, "success");
					onRejectClick();
				}
			})
			.catch((err) => {

				Swal.fire('Error', err.response.data.message, "error");

			})
	}

	const handleChange = (event) => {
		if(event?.target?.value?.id){
			getMilestoneList(props.authenticate, project.id, event.target.value.id)
				.then(res => {
					if (res.status === 200) {
						console.log("milestone list ................12345", res.data);
						setPersonName(event.target.value);
						setMilestone(res.data);
						// console.log("milestonedata is in milestone", newListOfMilestone);
					}
				});
		}else {
			setPersonName(event.target.value);
			setVendorState(event.target.value);
		}
		console.log("==================>", event.target.value)
	};

	function handleVendorList(data) {
		console.log("check for data---------->", data)
		if(data && data?.id) {
		getMilestoneList(props.authenticate, project.id, data.id)
			.then(res => {
				if (res.status === 200) {
					// setPersonName(data);
					setMilestone(res.data);
				}
			});
		}
	};

	const handleInvoiceList = (event) => {
		getListOfPerformaInvoiceFile(event.id, props.authenticate)
			.then(res => {
				if (res.status == 200) {
					setInvoiceData(res.data)
					console.log(res.data, invoiceListData, "check for invoice data")
				}
			});
		setIsOpen(true)
	}

	const handlePrList = (eventchange) => {
		getPrListForMilestone(eventchange.id, props.authenticate)
			.then(res => {
				if (res.status == 200) {
					setPrList(res.data)
					console.log(res, prList, "pr list status")
				}
			})
		setOpenHistory(true);
	}
	const handleOpen = (showValue) => {
		const status = "pm_approved";
		const data = ''
    setIsLoading(true)
		approveRejectApi(showValue.id, status, data, auth)
			.then((res) => {
				if (res.status == 200) {
          setIsLoading(false)
					Swal.fire('Success', res && res.data, 'success')
					onRejectClick();
				}
			})
			.catch((err) => {
        setIsLoading(false)
				Swal.fire('Error', err.response.data.message, "error");
				closeModal();
			})
	};

	const handleClose = () => {
		setOpen(false);
	};

	const setCloseHistory = () => {
		setOpenHistory(false);
		closeModal();
	}

	

	

	const handleBoxOpen = () => {
		setOpen(true);
	};

	const handleBoxClose = () => {
		setOpen(false);
	};

	function openModal() {
		setIsOpen(true);
	}
	function afterOpenModal() {
		console.log("after the modal is open")
	}

	function closeModal() {
		console.log("after the modal is closed")
		setIsOpen(false);
	}


	function capitalizeFirstLetter(string) {
		return string?.charAt(0)?.toUpperCase() + string?.slice(1);
	}

	const onRejectClick = () => {
		getMilestoneList(props.authenticate, project.id, personName?.id)
			.then(res => {
				if (res.status === 200) {
					console.log("milestone list ................12345", res.data);
					setMilestone(res.data);
					// console.log("milestonedata is in milestone", newListOfMilestone);
				}
			});
	}

	const handleClick = (data) => {
		console.log('The link was clicked.', data);
		var status = "pm_rejected";
		console.log("reject ", data)
		RejectApi(data.id, props.authenticate, status)
			.then((res) => {
				if (res.status == 200) {
					setRejectForm(res)
					Swal.fire('Success', `successfully`, "success");
					onRejectClick();
				}

			})
			.catch((err) => {
				console.log(err)
				Swal.fire('Error', err.response.data.message, "error");

			})
	}
	const onClickImage = (url) => {
		// if(url && url.length > 0) {
		// 	setDialogUrl(url)
		// 	setLargeImage(!largeImage)
		// }else {
      if (url?.length > 0){
        setSingleUrl(url)
      }
      
			setLargeImage(!largeImage)
		// }
	}

	const removeUnderscore = (str) => {
		var i, milestone = str.split('_');
		console.log(milestone, "milestone &&&&&&&&&&&&******")
		for (i = 0; i < milestone.length; i++) {
			if (milestone[i] == 'pm') {
				milestone[i] = milestone[i].toUpperCase()
			} else {
				milestone[i] = milestone[i].charAt(0).toUpperCase() + milestone[i].slice(1);
			}
		}
		return milestone.join(' ');
	}


	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				contentLabel="Example Modal"
				className={classes.modalStyle}
				style={{backgroundColor:"rgba(19,19,19,0.8) !important"}}
			>
				<button onClick={setCloseHistory} className={classes.cancleIcon}>x</button>
				{invoiceListData && invoiceListData.length > 0 ?
					invoiceListData.map((showData) => (
						<div>
							<Grid>
								{/* <Paper elevation={0}> */}
									<Typography className="text-center" style={{ backgroundColor: "#dad4d4", margin:"15px", marginBottom:"0px" }}><b>PO Number : {showData.po_number}</b></Typography>
									<Card className={classes.root} elevation={0}>
										<CardContent>
											<Typography>{bull} <b>Invoice Number : {showData.invoice_number}</b></Typography>
											<Typography>{bull} <b>Invoice Amount : <span>&#8377;</span> {Math.round(showData.invoice_amount)}</b></Typography>
											<Typography>{bull} <b>Invoice Date :{showData.invoice_date != null ? Moment(showData.invoice_date).format("Do MMM YYYY") : ''}</b></Typography>
											{showData.attachment_file_content_type == 'application/pdf' ? 
											 <Typography>File : <a href={showData.url} target="_blank" alt="download"><GetAppRoundedIcon /></a></Typography>
											: <Typography>{bull} <b>Image :  <img height={65} width={65} src={showData.url} onClick={() => onClickImage(showData.url)}></img></b></Typography> }
										</CardContent>
									</Card>
								{/* </Paper> */}
							</Grid>
						</div>

					)) : 
					<Grid item xs alignItems="center" style={{height:"100%", display:"flex", justifyContent:"center"}}>
						<Typography className="text-center">No Data Found</Typography>
					</Grid>
				}
        
        {largeImage && singleUrl && <dialog
          className={classes.dialog}
          style={{ position: "fixed", top:"26%", marginLeft:"27%", marginRight:"20%", height:"58vh" }}
          open
          onClick={onClickImage}
        >
          <img
            className={classes.image}
            src={singleUrl}
            onClick={onClickImage}
            alt="no image"
          />
        </dialog>}
			</Modal>
      {/* {largeImage && singleUrl && <iframe src={singleUrl} style={{height:"200px", width:"300px"}} title="description"></iframe>} */}


			<Modal
				isOpen={openHistory}
				onAfterOpen={afterOpenModal}
				onRequestClose={setCloseHistory}
				contentLabel="Example Modal"
				className={classes.modalStyle}
				style={{backgroundColor:"rgba(19,19,19,0.8) !important"}}
			>
				<button onClick={setCloseHistory} className={classes.cancleIcon}>x</button>
				{prList && prList.length > 0 ?
					prList.map((showHistory) => (
						<div>
							<Grid>
								{/* <Paper elevation={0}> */}
									<Typography className="text-center" style={{ backgroundColor: "#dad4d4", margin:"15px", marginBottom:"0px" }}>{`PO Number : ${showHistory.po_number}`}</Typography>
									<Card className={classes.root} elevation={0}>
										<CardContent>
											<Typography>{bull} <b>Description : {capitalizeFirstLetter(showHistory.description)}</b></Typography>
											<Typography>{bull} <b>Remarks : {capitalizeFirstLetter(showHistory.remarks) || 'Null'}</b></Typography>
											<Typography><b>Payment Status :{capitalizeFirstLetter(showHistory.payment_status)}</b></Typography>
											<Typography>{bull} <b>Amount : <span>&#8377;</span> {Math.round(showHistory.amount)}</b></Typography>
											<Typography><b>Payment Type :{capitalizeFirstLetter(showHistory.payment_type)}</b></Typography>
											<Typography><b>Payment Stage :{capitalizeFirstLetter(showHistory.payment_stage)}</b></Typography>
										</CardContent>
									</Card>
								{/* </Paper> */}
							</Grid>
						</div>

					))
					:
					<Grid item xs alignItems="center" style={{height:"100%", display:"flex", justifyContent:"center"}}>
						<Typography className="text-center">No Data Found</Typography>
					</Grid>
				}
			</Modal>
			<div className="d-flex justify-content-center " >
				{filterVendor && filterVendor?.length > 0 ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="demo-simple-select-placeholder-label-label" style={{ width: "144px", fontSize: "x-large", fontWeight: "600" }}>Vendor List</InputLabel>
						<Select
							labelId="demo-simple-select-placeholder-label-label"
							id="demo-simple-select-placeholder-label"
							style={{ minWidth: "100px" }}
							value={personName || filterVendor[0]}
							displayEmpty
							onChange={handleChange}
						>
							{filterVendor?.map((vendorname, index) => (
								<MenuItem key={vendorname.id} selected={vendorname.id == 231} value={vendorname}>
									{/* <ListItemText primary={vendorname.name} /> */}
									{vendorname.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				  ) : 
					<FormControl className={classes.formControl}>
						<InputLabel shrink id="demo-simple-select-placeholder-label-label" style={{ width: "144px", fontSize: "x-large", fontWeight: "600" }}>Vendor List</InputLabel>
						<Select
							labelId="demo-simple-select-placeholder-label-label"
							id="demo-simple-select-placeholder-label"
							value={vendorState}
							onChange={handleChange}
							displayEmpty
							className={classes.selectEmpty}
						>
							<MenuItem value="">
								<em>No Vendor Assigned</em>
							</MenuItem>
						</Select>
					</FormControl>
				}
			</div>
			{/* <FilterVendor project={project} /> */}
			{milestone && milestone.length == 0 &&
				<div>
					<h4 className="mt-5 text-center">Data Not Found</h4>
				</div>
			}
			{
				milestone && milestone.map((showValue) => (
					<Timeline >
						{showValue.milestone_type == 'advance' && showValue.milestone_type !== 'percent_50_completion' && showValue.milestone_type !== 'percent_75_completion' && showValue.milestone_type !== 'handover' && showValue.milestone_type !== 'months_3_post_handover' ?
							(<TimelineItem >
								<TimelineOppositeContent>
									<Typography variant="h6" color="textSecondary">
										1st Milestone
                            </Typography>
								</TimelineOppositeContent>

								<TimelineSeparator>
									<div>
										{showValue.status == 'finance_pending' || showValue.status == 'pm_pending' ?
											<TimelineDot style={{ color: "#ffd34d", backgroundColor: "#ffd34d" }} /> :
											<TimelineDot style={{ color: "green", backgroundColor: "green" }} />
										}
									</div>
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className={classes.contentStyle}>
									<Paper elevation={3} className={classes.paper}>
										<Typography variant="h6" component="h1">
                      Advance [Status : {removeUnderscore(showValue.status)}]
                                </Typography>
										<Typography>Finance Review Date :{showValue?.finance_reviewed_at?.length > 0 ? Moment(showValue.finance_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
										<Typography>Approved Amount : <span>&#8377;</span> {Math.round(showValue.amount)}</Typography>

									</Paper>
								</TimelineContent>
							</TimelineItem>
							) :
							showValue.milestone_type == 'percent_50_completion' && showValue.milestone_type !== 'percent_75_completion' && showValue.milestone_type !== 'advance' && showValue.milestone_type !== 'handover' && showValue.milestone_type !== 'months_3_post_handover' ?

								<TimelineItem style={{ flexDirection: "row-reverse" }}>
									<TimelineOppositeContent>
										<Typography variant="h6" color="textSecondary" style={{ float: "left" }}>
											2nd Milestone
                                </Typography>
									</TimelineOppositeContent>
									<TimelineSeparator>
										<div>
											{showValue.status == 'finance_pending' || showValue.status == 'pm_pending' ?
												<TimelineDot style={{ color: "#ffd34d", backgroundColor: "#ffd34d" }} /> :
												<TimelineDot style={{ color: "green", backgroundColor: "green" }} />
											}
										</div>
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent className="text-left">
										<Paper elevation={3} className={classes.paper}>
											<Typography variant="h6" component="h1">
                        50% Completion [Status : {removeUnderscore(showValue.status)}]
                                        </Typography>
											<Typography>Approved Date :{showValue.pm_reviewed_at != null ? Moment(showValue.pm_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
											<Typography>Finance Review Date :{showValue?.finance_reviewed_at?.length > 0 ? Moment(showValue.finance_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
											<Typography>Approved Amount : <span>&#8377;</span> {Math.round(showValue.amount)}</Typography>
											<div className="d-flex justify-content-start">

												{console.log('show value', showValue.milestone_type, showValue.status)}

												{/* {
                                                showValue.milestone_type == 'percent_50_completion' && showValue.status === "pm_pending" || showValue.status === "finance_rejected" ?
                                                    <ApproveForm  approveFormData={approveFormData} />
                                                    :
                                                    <Button variant="contained" color="primary" className="mr-1"  approveFormData={approveFormData}>APPROVE{showValue.task_po_list.purchase_order_id}</Button>
                                                 <Button variant="contained" color="primary" className="mr-1">Just APPROVE{showValue.task_po_list.purchase_order_id}</Button> 

                                            } */}
												{userRoleID != 13 &&
														showValue.milestone_type === 'percent_50_completion' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") &&
														<div className="d-flex justify-content-between" style={{width:"23%"}}>		
															<ApproveForm onClick={handleOpen} onApproveClick={onRejectClick} showValue={showValue} approveFormData={approveFormData} />
														</div>
												}
												{showValue.milestone_type === 'percent_50_completion' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") && userRoleID != 13 &&
													<div className="d-flex justify-content-between" style={{width:"22%"}}>	
														<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleClick(showValue)}>
															REJECT
														</Button>
													</div>
												}

												<div className="d-flex justify-content-between" style={{width:"22%"}}>	
													<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleInvoiceList(showValue)} >INVOICE</Button>
												</div>
												<div className="d-flex justify-content-between" style={{width:"33%"}}>	
													<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handlePrList(showValue)} >PAYMENT HISTORY</Button>
												</div>
											</div>
										</Paper>
									</TimelineContent>
								</TimelineItem>
								:
								showValue.milestone_type == 'percent_75_completion' && showValue.milestone_type !== 'percent_50_completion' && showValue.milestone_type !== 'advance' && showValue.milestone_type !== 'handover' && showValue.milestone_type !== 'months_3_post_handover' ?
									<TimelineItem>
										<TimelineOppositeContent>
											<Typography variant="h6" color="textSecondary">
												3rd Milestone
                                        </Typography>
										</TimelineOppositeContent>
										<TimelineSeparator>
											<div>
												{showValue.status == 'finance_pending' || showValue.status == 'pm_pending' ?
													<TimelineDot style={{ color: "#ffd34d", backgroundColor: "#ffd34d" }} /> :
													<TimelineDot style={{ color: "green", backgroundColor: "green" }} />
												}
											</div>
											<TimelineConnector />
										</TimelineSeparator>
										<TimelineContent>
											<Paper elevation={3} className={classes.paper}>
												<Typography variant="h6" component="h1">
                          75% Completion [Status : {removeUnderscore(showValue.status)}]
                                               </Typography>
												<Typography>Approved Date : {showValue.pm_reviewed_at != null ? Moment(showValue.pm_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
												<Typography>Finance Review Date :{showValue?.finance_reviewed_at?.length > 0 ? Moment(showValue.finance_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
												<Typography>Approved Amount : <span>&#8377;</span> {Math.round(showValue.amount)}</Typography>
												<div className="d-flex justify-content-start">

													{/* <Button variant="contained" color="primary" data-target={"thirdMilestone" + showValue.id} >APPROVE{showValue.task_po_list.purchase_order_id}</Button> */}
													{console.log('show value', showValue.milestone_type, showValue.status)}
													{userRoleID != 13 &&
															showValue.milestone_type === 'percent_75_completion' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") &&
															<div className="d-flex justify-content-between" style={{width:"23%"}}>
                                { isLoading ?
                                  <CircularIndeterminate/> :		
																  <Button variant="contained" color="primary" onClick={() => handleOpen(showValue)} approveFormData={approveFormData} >APPROVE </Button>
                                }
															</div>
													}

													{showValue.milestone_type === 'percent_75_completion' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") && userRoleID != 13 && 
														<div className="d-flex justify-content-between" style={{width:"22%"}}>	
															<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleClick(showValue)}>
																REJECT
															</Button>
														</div>
													}
													{/* <div className="d-flex justify-content-between" style={{width:"22%"}}>	
														<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleInvoiceList(showValue)} >INVOICE</Button>
													</div> */}
													<div className="d-flex justify-content-between" style={{width:"33%"}}>	
														<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handlePrList(showValue)} >PAYMENT HISTORY</Button>
													</div>
												</div>
											</Paper>
										</TimelineContent>
									</TimelineItem>
									:
									showValue.milestone_type == 'handover' && showValue.milestone_type !== 'percent_50_completion' && showValue.milestone_type !== 'advance' && showValue.milestone_type !== 'percent_75_completion' && showValue.milestone_type !== 'months_3_post_handover' ?
										<TimelineItem style={{ flexDirection: "row-reverse" }}>
											<TimelineOppositeContent>
												<Typography variant="h6" color="textSecondary" style={{ float: "left" }}>
													4th Milestone
                                            </Typography>
											</TimelineOppositeContent>
											<TimelineSeparator>
												<div>
													{showValue.status == 'finance_pending' || showValue.status == 'pm_pending' ?
														<TimelineDot style={{ color: "#ffd34d", backgroundColor: "#ffd34d" }} /> :
														<TimelineDot style={{ color: "green", backgroundColor: "green" }} />
													}
												</div>
												<TimelineConnector />
											</TimelineSeparator>
											<TimelineContent className="text-left">
												<Paper elevation={3} className={classes.paper}>
													<Typography variant="h6" component="h1">
                            Handover [Status : {removeUnderscore(showValue.status)}]
                                                </Typography>
													<Typography>Approved Date : {showValue.pm_reviewed_at != null ? Moment(showValue.pm_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
													<Typography>Finance Review Date :{showValue?.finance_reviewed_at?.length > 0 ? Moment(showValue.finance_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
													<Typography>Approved Amount : <span>&#8377;</span> {Math.round(showValue.amount)}</Typography>
													<div className="d-flex justify-content-start">

														{console.log('show value', showValue.milestone_type, showValue.status)}
														{userRoleID != 13 &&
																showValue.milestone_type === 'handover' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") &&
																<div className="d-flex justify-content-between" style={{width:"23%"}}>		
																	<ApproveForm onClick={handleOpen} onApproveClick={onRejectClick} showValue={showValue} approveFormData={approveFormData} />
																</div>
														}
														{showValue.milestone_type === 'handover' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") && userRoleID != 13 &&
															<div className="d-flex justify-content-between" style={{width:"22%"}}>	
																<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleClick(showValue)}>
																	REJECT
																</Button>
															</div>
														}
														<div className="d-flex justify-content-between" style={{width:"22%"}}>	
															<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleInvoiceList(showValue)} >INVOICE</Button>
														</div>
														<div className="d-flex justify-content-between" style={{width:"33%"}}>	
															<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handlePrList(showValue)} >PAYMENT HISTORY</Button>
														</div>
													</div>
												</Paper>
											</TimelineContent>
										</TimelineItem>
										:
										showValue.milestone_type == 'months_3_post_handover' && showValue.milestone_type !== 'percent_50_completion' && showValue.milestone_type !== 'advance' && showValue.milestone_type !== 'percent_75_completion' && showValue.milestone_type !== 'handover' ?
											<TimelineItem>
												<TimelineOppositeContent>
													<Typography variant="h6" color="textSecondary">
														5th Milestone
                                                </Typography>
												</TimelineOppositeContent>
												<TimelineSeparator>
													<div>
														{showValue.status == 'finance_pending' || showValue.status == 'pm_pending' ?
															<TimelineDot style={{ color: "#ffd34d", backgroundColor: "#ffd34d" }} /> :
															<TimelineDot style={{ color: "green", backgroundColor: "green" }} />
														}
													</div>
													<TimelineConnector />
												</TimelineSeparator>
												<TimelineContent>
													<Paper elevation={3} className={classes.paper}>
														<Typography variant="h6" component="h1">
                              3 months Post Handover [Status : {removeUnderscore(showValue.status)}]
                                                    </Typography>
												<Typography>Approved Date : {showValue.pm_reviewed_at != null ? Moment(showValue.pm_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
												<Typography>Finance Review Date :{showValue?.finance_reviewed_at?.length > 0 ? Moment(showValue.finance_reviewed_at).format("Do MMM YYYY") : ''}</Typography>
												<Typography>Approved Amount : <span>&#8377;</span> {Math.round(showValue.amount)}</Typography>
														<div className="d-flex justify-content-start">
															{console.log('show value', showValue.milestone_type, showValue.status)}
															{userRoleID != 13 && showValue.milestone_type === 'months_3_post_handover' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") &&
																<div className="d-flex justify-content-between" style={{width:"23%"}}>
                                  { isLoading ?
                                    <CircularIndeterminate/> :		
                                    <Button variant="contained" color="primary" onClick={() => handleOpen(showValue)}>APPROVE</Button>
                                  }	
																</div>
															}
															{/* {userRoleID != 13 &&
                                                                <Button>
                                                                    {showValue.milestone_type === 'months_3_post_handover' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") &&
                                                                        <ApproveForm onClick={handleOpen} showValue={showValue} approveFormData={approveFormData} />}
                                                                </Button>
                                                            } */}
															{showValue.milestone_type === 'months_3_post_handover' && (showValue.status === "pm_pending" || showValue.status === "finance_rejected") && userRoleID != 13 && <div className="d-flex justify-content-between" style={{width:"22%"}}>	
																<Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handleReject(showValue)}>REJECT </Button>
															</div>}
                              <div className="d-flex justify-content-between" style={{width:"33%"}}>	
                                <Button variant="contained" color="primary" className="mr-1 align-self-md-center" onClick={() => handlePrList(showValue)} >PAYMENT HISTORY</Button>
                              </div>
														</div>
													</Paper>
												</TimelineContent>
											</TimelineItem>
											: null
						}

					</Timeline>
				))
			}
		</div>

	);
}
