import axios from '../base';

export function getDeltaUserByRole(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_payments/get_delta_user`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function makePayment(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_payments`,
    method: 'POST',
    headers: accessToken,
    params: data
  })
};

export function getPaymentHistory(accessToken) {
  return axios.request({
    url: `/api/v1/office_payments/get_all_paytm_payments`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getTxnStatus(accessToken, id) {
  return axios.request({
    url: `/api/v1/office_payments/check_txn_status/${id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getFinanceDashboardStats(accessToken) {
  return axios.request({
    url: `/api/v1/office_stats/finance_dashboard_stats`,
    method: 'GET',
    headers: accessToken,
  })
};