import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import 'react-dates/lib/css/_datepicker.css';
import Button from "@material-ui/core/Button";
import { Grid, InputLabel, makeStyles, Typography, FormHelperText } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import Input from '@material-ui/core/Input'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from "@material-ui/core/Fab";
import { Label } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { approveRejectApi, getPurchaseOrderApi } from '../../../../api/PE/vendor';
import Modal from 'react-modal';
import Brands from '../Residential/Brands.json';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import CircularIndeterminate from '../../../Loader'


// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { handleInputChange } from 'react-select/src/utils';

const styles = makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',

    }
  },
  modalStyle: {
    position: "absolute",
    inset: "40px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    width:"85%",
    margin:"7%",
    marginBottom:"3%"
  },
  invoiceStyle: {
    textAlign:"center",
    marginTop:"10px",
  },
  cancleIcon: {
    position:"fixed",
    right:"6%"
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: "40px",
    paddingTop:"0px",
  },
  dateContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    marginLeft: '9px',
    border: '1px solid #a9a9a9b0',
    padding: '14px 14px',
    width: '100%',
    borderRadius: '4px'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  cusError: {
    color:'red',
    marginTop:"-10px",
    marginBottom:"10px"
  },
  cusErrorImg: {
    color:'red',
    marginTop:"10px",
  },
}));

export default function ApproveForm({ approveFormData, showValue, onApproveClick }) {
  const auth = useSelector(state => state.auth)
  console.log("auth value is&&&&&&&&", auth)

  const [formDaaata, setFormDaaata] = useState([]);
  const [open, setOpen] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null)
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const [attachmentFile, setAttachmentFile] = useState();
  const [attachmentFileName, setAttachmentFileName] = useState();
  const [notes, setNotes] = useState();
  const [baseImage, setBaseImage] = useState('');
  const [values, setValues] = React.useState({
    amount: ''
  });

  const [dateError, setDateError] = useState('This is required');
  const [dateEmpty, setDateEmpty] = useState(true);
  const [noteError, setNoteError] = useState('This is required');
  const [noteEmpty, setNoteEmpty] = useState(true);
  const [numberError, setNumberError] = useState('This is required');
  const [numberEmpty, setNumberEmpty] = useState(true);
  const [imageError, setImageError] = useState('This is required');
  const [imageEmpty, setImageEmpty] = useState(true);
  const [errorsValue, setErrorsValue] = useState([]);
  const [formIsValid, setFormIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false)


  const apiarray = []
  useEffect(() => {
    if (showValue?.id) {
      getPurchaseOrderApi(showValue?.id).
        then((res) => {
          if (res.status == 200) {
            console.log("get the project id", res.data)
            // res && res.data.map((value) => {
            //   apiarray.push({
            //     po_id: value.id,
            //     invoice_number: Number(1234),
            //     attachment_file: "data:image/gif;base64,R0lGODlhUAAPAKIAAAsLav///88PD9WqsYmApmZmZtZfYmdakyH5BAQUAP8ALAAAAABQAA8AAAPbWLrc/jDKSVe4OOvNu/9gqARDSRBHegyGMahqO4R0bQcjIQ8E4BMCQc930JluyGRmdAAcdiigMLVrApTYWy5FKM1IQe+Mp+L4rphz+qIOBAUYeCY4p2tGrJZeH9y79mZsawFoaIRxF3JyiYxuHiMGb5KTkpFvZj4ZbYeCiXaOiKBwnxh4fnt9e3ktgZyHhrChinONs3cFAShFF2JhvCZlG5uchYNun5eedRxMAF15XEFRXgZWWdciuM8GCmdSQ84lLQfY5R14wDB5Lyon4ubwS7jx9NcV9/j5+g4JADs=",
            //     attachment_file_file_name: null,
            //     invoice_date: "",
            //     notes: "",
            //     invoice_amount: Number(1234),
            //   })
            // })
            // const newArray = [{type:"notes", errMessage:noteError}, {type:"invoice_date", errMessage:dateError}, 
            //   {type:"invoice_number", errMessage:numberError}, {type:"file_name", errMessage:imageError}
            // ]
            // setErrorsValue(newArray)
            // if(newArray && newArray.length > 0) {
            //   const newFormData = res.data.map(value => value = {...value, error:newArray})
            //   setFormDaaata(newFormData)
            //   console.log("newarray*****", newFormData)
            // }else{
              setFormDaaata(res.data)
            // }
            console.log("get the formdata", formDaaata, formDaaata.length)
            console.log("thie is the response data", res.data)
            localStorage.setItem("milestone_id", showValue.id);
            localStorage.setItem("task_po_id", showValue.task_po_list[0].purchase_order_id);

            // console.log("reference_no", rowData.reference_no)

          }
          setFormDaaata(res.data)
          console.log("get the formdata", formDaaata)        
        })
        .catch((err) => {
          // Swal.fire('Error', err && err.data && err.data.message, "error");
        })
    }
  }, [showValue?.id]
  )

  const handleValidation = (i) => {
    var errors = errorsValue;
    var valid = formIsValid;
    
    if (!formDaaata[i]?.notes) {
      // var invnotesError = {notes:"This is required"}
      errors[i] = {...errors[i], notes:"This is required"}
      const erMsgArray = [...errorsValue, errors[i]]
      // setErrorsValue(erMsgArray)
      valid = false;
    }
    if (!formDaaata[i]?.invoice_date) {
      // var invdateError = {invoice_date:"This is required"}
      errors[i] = {...errors[i], invoice_date:"This is required"}
      const erMsgArray = [...errorsValue, errors[i]]
      // setErrorsValue(erMsgArray)
      valid = false;
    }
    if (!formDaaata[i]?.invoice_number) {
      // var invnumError = {invoice_number:"This is required"}
      errors[i] = {...errors[i], invoice_number:"This is required"}
      const erMsgArray = [...errorsValue, errors[i]]
      // setErrorsValue(erMsgArray)
      valid = false;
    }
    if (!formDaaata[i]?.attachment_file_file_name) {
      // var filenameError = {file_name:"This is required"}
      errors[i] = {...errors[i], file_name:"This is required"}
      const erMsgArray = [...errorsValue, errors[i]]
      // setErrorsValue(erMsgArray)
      valid = false;
    }
    if(formDaaata[i]?.attachment_file_file_name && formDaaata[i]?.invoice_number && formDaaata[i]?.invoice_date && formDaaata[i]?.notes) {
      errors[i] = {};
      valid = true;
    }
      setErrorsValue(errors)
      setFormIsValid(valid)
    // }
    
    return valid;
  }


  function handleSubmitForm(e) {
    e.preventDefault();
    console.log("handle the onclick approve handlApiForm")
    var newArray = []
    // var errorMessage = {po_id: "this is required", invoice_number: "this is required", attachment_file_file_name: "this is required", invoice_date: "this is required",
    // notes: "this is required", invoice_amount: "this is required", attachment_file: "this is required",
    // }
    formDaaata.map((value) => {
      newArray.push({
        po_id: value.id,
        invoice_number: value.invoice_number,
        attachment_file: value.attachment_file,
        attachment_file_file_name: value.attachment_file_file_name,
        invoice_date: value.invoice_date,
        notes: value.notes,
        invoice_amount: value.po_vendor_amount
      })
    })
    const data = { performa_invoice_files: [...newArray] }
    console.log("data******", data, "form**********", formDaaata)

    const validation = data?.performa_invoice_files.map((v, i) => handleValidation(i))
    const validationFilter = validation?.filter(v => v == false)

    console.log("valid*******", validation, "er*****", errorsValue)


    // const errorData = data?.performa_invoice_files?.filter((value) => {
    //   return (value?.po_id?.length == 0 || value?.invoice_number?.length == 0 || value?.attachment_file?.length == 0 || value?.attachment_file_file_name?.length == 0 ||
    //   value?.invoice_date?.length == 0 || value?.notes?.length == 0 || value?.invoice_amount?.length == 0) 
    // })

    console.log("newArray value is****", newArray)
    if(validationFilter.length == 0) {
      const status = "pm_approved"
      setIsLoading(true)
      approveRejectApi(showValue.id, status, data, auth)
      .then((res) => {
        if (res.status == 200) {
          console.log("check for approval", res.data)
          setIsLoading(false)
          Swal.fire('Success', res && res?.data?.message, 'success')
          onApproveClick()
          // this.props.setOpen(false)
          closeModal();
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
        Swal.fire('Error', err?.response?.data?.message, "error");
        closeModal();
      })
    }

  }

  // function handleUploadClick(event, po_id) {
  //   var file = event.target.files[0];
  //   setUploadedFile(file.name);
  //   const reader = new FileReader();
  //   var url = reader.readAsDataURL(file);
  //   console.log(event.target.files, file);
  //   formDaaata && formDaaata.map((value) => {
  //     if (po_id == value.po_id) {
  //       value['attachment_file_file_name'] = file.name;
  //     }
  //   })
  //   console.log(po_id, formDaaata, 'uploadfile');
  // }
  // const fileOnChangeHandler = (e) => {
  //   var file = e.target.files[0];
  //   setUploadedFile(file)
  //   setUploadedFilePre(URL.createObjectURL(file))
  // }


  function onChange(event, po_id) {
    var file_name = event.target.files[0].name
    var basefile = event.target.files[0] || event.srcElement.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(basefile);
    var base64;
    reader.onload = function () {
      base64 = reader.result;
      setBaseImage(base64);
      console.log('RESULT', baseImage)
      formDaaata && formDaaata.map((value) => {
        if (po_id == value.id) {
          value['attachment_file'] = base64;
          value['attachment_file_file_name'] = file_name;
          setUploadedFile(basefile)
        }
      })
    }

    // reader.readAsDataURL(basefile);
    // var fileReader = new FileReader();
    // fileReader.onload = (fileLoadedEvent) => {
    //   base64 = fileLoadedEvent.target;
    //   basefile = base64.result;
    //document.getElementById('floorplanImg').setAttribute('src',event.srcElement.files[0].name);
    // };
    // console.log("basefile", basefile)
    // fileReader.readAsDataURL(basefile);

    console.log(formDaaata, "---------forformDaaata")
  }

  // const handleFormApiMsg = (message) => {
  //   const data = []
  //   approveRejectApi(showValue.id, data, "pm_approved", auth)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setIsOpen(true)
  //       }
  //     })
  //     .catch((err) => {
  //       Swal.fire('Cannot approved milestone as previous milestone payments are not approved my finance', err && err.data && err.data.message, "error");
  //     })
  // }

  var subtitle;
  function openModal() {
    // handleFormApiMsg();
    console.log("modal check ")
    if(formDaaata && formDaaata.length > 0) {
      setIsOpen(true);
    } else {
      Swal.fire('Error', "No Data To Show", "error");
    }
  }
  function afterOpenModal() {
    console.log("after the modal is open")
  }

  function closeModal() {
    console.log("after the modal is closed")
    setIsOpen(false);
  }

  function test() {
    console.log("test the console")
  }

  const handleChanges = (e, index) => {
    console.log(e, index, 'a and index check');
    let temporaryarray = formDaaata.slice();
    temporaryarray[index][e.target.name] = e.target.value;
    // const type = e.target.name;
    // const value = e.target.value;
    // if (type == 'invoice_date' && value != null) {
    //   const errIndex = temporaryarray[index].error.findIndex(data => data.type == type)
    //   if(errIndex > -1) {
    //     temporaryarray[index].error.splice(errIndex,1)
    //     setFormDaaata(temporaryarray)
    //   }else {
    //     setFormDaaata(temporaryarray)
    //   }
    //   console.log("removeErrorData*******",formDaaata)
    // } else if (type == 'invoice_date' && value == null) {
    //   const filterType = temporaryarray[index].error.filter(data => data.type == type)
    //   if(!filterType && filterType.length == 0) {
    //     const errorMsg = {type:type, errMessage:dateError}
    //     temporaryarray = [...temporaryarray[index].error, errorMsg]
    //     setFormDaaata(temporaryarray)
    //   }else {
    //     setFormDaaata(temporaryarray)
    //   }
    // }else if (type == 'notes' && value != null) {
    //   setNoteError("")
    //   setNoteEmpty(false)
    // }else if (type == 'notes' && value == null) {
    //   setNoteError("This is required")
    //   setNoteEmpty(true)
    // } else if (type == 'invoice_number' && value != null) {
    //   setNumberError("")
    //   setNumberEmpty(false)
    // } else if (type == 'invoice_number' && value == null) {
    //   setNumberError("This is required")
    //   setNumberEmpty(true)
    // }
    setFormDaaata(temporaryarray);
    console.log("dattaa=", formDaaata)
  };

  //   console.log("value=", e)
  // setFields({ ...fields, [e.target.name]: [e.target.value] })
  // setFormDaaata({ ...formDaaata[index], [e.target.name]: e.target.value })

  //formDaaata[index][type] = value;
  //setFormDaaata({ ...formDaaata })  

  const classes = styles();
    return (
      <div>
        <Button variant="contained" color="primary" className="mr-1" onClick={openModal} >APPROVE</Button>
        <div style={{display:"flex", justifyContent:"center"}}> 
        
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={makeStyles}
            contentLabel="Example Modal"
            className={classes.modalStyle}
            style={{backgroundColor:"rgba(19,19,19,0.8) !important"}}
          >
             { isLoading ?
              <CircularIndeterminate/> :
            <div>
              <button onClick={closeModal} className={classes.cancleIcon}>x</button>
              <form>
                <Paper className={classes.pageContent}>
                  {formDaaata && formDaaata.length > 0 && formDaaata.map((approveMap, index) => (
                    <Grid >
                      {console.log("index value ---------", "attachment_file" + approveMap.id)}
                      <Typography ref={_subtitle => (subtitle = _subtitle)} className={classes.invoiceStyle}>{`Invoice : ${approveMap.reference_no + "(" + "Amount: " + approveMap.po_vendor_amount + ")"}`}</Typography>
                      {console.log("reference no.", formDaaata.reference_no)}
                      <form className={classes.root}>
                        <div className="row justify-content-center pt-5 pb-5" style={{ backgroundColor: "#f2f1eb" }}>
                          <div className="col-md-6">
                            <Typography className="form-control cursorPointer" name={"attachment_file" + approveMap.id} onClick={() => document.getElementById('userImgUpload' + approveMap.id).click()}>
                              <i className="fa fa-upload" aria-hidden="true"></i>
                              <span className="ml-2">{approveMap.attachment_file_file_name ? approveMap.attachment_file_file_name : 'Upload File'}</span>
                            </Typography>

                            <Grid className="position-relative">
                              <input type="file" accept="image/jpeg,image/png,application/pdf" name={"attachment_file" + approveMap.id} className="d-none" id={'userImgUpload' + approveMap.id}
                                onChange={(e) => onChange(e, approveMap.id)} />
                              {!approveMap?.attachment_file_file_name && errorsValue && <FormHelperText id="email-text" className={classes.cusErrorImg}>{errorsValue[index]?.file_name}</FormHelperText>}
                            </Grid>
                            <Grid className="position-relative mt-4">
                              {/* <input type="text" className="form-control mb-3" placeholder="Document Name" name="attachment_file_file_name" value={formDaaata.attachment_file_file_name} onChange={(e) => handleChanges(e, index)} /> */}
                              <input type="text" className="form-control mb-3" placeholder="Enter Notes.." name="notes" value={approveMap.notes} onChange={(e) => handleChanges(e, index)} />
                              {!approveMap?.notes && errorsValue && <FormHelperText id="email-text" className={classes.cusError}>{errorsValue[index]?.notes}</FormHelperText>}
                              <input type="date" className="form-control mb-3" placeholder="Date" name="invoice_date" value={approveMap.invoice_date} onChange={(e) => handleChanges(e, index)} />
                              {!approveMap?.invoice_date && errorsValue && <FormHelperText id="email-text" className={classes.cusError}>{errorsValue[index]?.invoice_date}</FormHelperText>}
                              <input type="text" className="form-control mb-3" placeholder="Enter Invoice Number" name="invoice_number" value={approveMap.invoice_number} onChange={(e) => handleChanges(e, index)} />
                              {!approveMap?.invoice_number && errorsValue && <FormHelperText id="email-text" className={classes.cusError}>{errorsValue[index]?.invoice_number}</FormHelperText>}
                              <input type="number" disabled={true}  className="form-control mb-3" placeholder="Enter Invoice Amount" name="invoice_amount" value={approveMap.po_vendor_amount || approveMap.invoice_amount} onChange={(e) => handleChanges(e, index)} />
                            </Grid>

                          </div>
                        </div>
                      </form>
                    </Grid>
                  ))}
                </Paper>
                {formDaaata && formDaaata.length > 0 ?
                  <Button variant="contained" onClick={handleSubmitForm} color="primary" type="submit" className="myButton ml-auto mr-auto mt-4 d-block w-25">APPROVE FORM</Button>
                  :
                  null
                }
              </form>
            </div>
            }
          </Modal>
        </div>

      </div>
    );
}
