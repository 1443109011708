import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table'
import { getSnagList, getProjectSiteSupervisorList,getOfficeNatureOfIssues, getOfficeMistakeHead, updateSnags, createSnags } from '../../../api';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Badge } from '@material-ui/core';
import ReactDropzone from "react-dropzone";
import FileIcon from '@material-ui/icons/AttachFile';

const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror : {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};


const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
  width: '50vh',
  height: '50vh'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

class Snags extends Component {
  state = {
    Snags: [],
    SiteSupervisorList: [],
    SnagStatus: {
      0: 'Not Resolved',
      1: 'In Progress',
      2: 'Resolved'
    },
    SnagIsUrgent: {
      true: 'Yes',
      false: 'No'
    },
    openDialog: false,
    newSnag: {
      project_id: this.props.project.id,
      file: null
    },
    files: [],
    filesDialog: false,
    filesList: []
  };

  async componentDidMount() {
    if (this.props.project) {
      try {
        
        const SiteSupervisorListRes = await getProjectSiteSupervisorList(this.props.auth.token, this.props.project.id)
        const SnagsRes = await getSnagList(this.props.auth.token, this.props.project.id)
        const OfficeNatureOfIssuesRes = await getOfficeNatureOfIssues(this.props.auth.token, this.props.project.id)
        const OfficeMistakeHeadRes = await getOfficeMistakeHead(this.props.auth.token, this.props.project.id)


        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

        let OfficeNatureOfIssuesOptions = {}
        OfficeNatureOfIssuesRes.data.map(i => OfficeNatureOfIssuesOptions[i.id] = i.name)

        let OfficeMistakeHeadOptions = {}
        OfficeMistakeHeadRes.data.map(i => OfficeMistakeHeadOptions[i.id] = i.name)

        this.setState({
          Snags: SnagsRes.data,
          SiteSupervisorList: SiteSupervisorListRes.data,
          SiteSupervisorOption,
          OfficeNatureOfIssuesOptions,
          OfficeMistakeHeadOptions
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  SnagValidation = (data) => {
    if (
      (data.user_id !== "" && data.user_id !== null && data.user_id !== undefined) &&
      (data.product !== "" && data.product !== null && data.product !== undefined) &&
      (data.description !== "" && data.description !== null && data.description !== undefined) &&
      (data.item_required !== "" && data.item_required !== null && data.item_required !== undefined) &&
      (data.size !== "" && data.size !== null && data.size !== undefined ) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.office_snag_nature_of_issue_id !== "" && data.office_snag_nature_of_issue_id !== null && data.office_snag_nature_of_issue_id !== undefined) &&
      (data.office_mistake_head_id !== "" && data.office_mistake_head_id !== null && data.office_mistake_head_id !== undefined) &&
      (data.status !== "" && data.status !== null && data.status !== undefined) &&
      (data.is_urgent !== "" && data.is_urgent !== null && data.is_urgent !== undefined)
    ) {
      if ((data.size >= 0) && ( data.quantity >= 0)) {
        return true
      }else if(data.size < 0){
        alert('size is negative')
        return false
      }else if(data.quantity < 0){
        alert('quantity is negative')
        return false
      }
    } else {
      return false
    }
  }

  onDrop = (files) => {
    let newSnag = {...this.state.newSnag};
    newSnag.files = files 
    this.setState({newSnag,
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
  }
  
  onSubmit = (e) => {
    e.preventDefault();
    let newSnag = {...this.state.newSnag};
    const fd = new FormData();
    fd.append('office_snag[project_id]', this.props.project.id)
    fd.append('office_snag[file_length]', newSnag.files.length)
    for (let i in newSnag.files) {
      if (newSnag.files.hasOwnProperty(i)) {
        fd.append(`office_snag[file][${parseInt(i) + 1}]`, newSnag.files[i])
      }
    }
    createSnags(this.props.auth.token, fd)
    .then(res => {
      if (res.status === 200) {
        alert(res.data)
      }
    })
    .catch(error => console.log(error))

  }

  render() {
    const {classes} = this.props;
    const { Snags, SiteSupervisorOption, OfficeNatureOfIssuesOptions,
      OfficeMistakeHeadOptions, SnagStatus, SnagIsUrgent, openDialog, files, filesDialog, filesList } = this.state;
      const thumbs = files.map(file => (
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              style={img}
              alt="Arrivae"
            />
          </div>
        </div>
      ));   
    return(
      <div>
          <MaterialTable
          title="Snags List"     
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{margin: '10px'}}>
                {/* <Button onClick={() => this.setState({ openDialog: true })}>Create Snag</Button> */}
                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Files',
              field: 'files',
              render: rowData => {
                return (
                  <Tooltip title="Files" aria-label="add">
                    <Badge
                      className={classes.button}
                      color="secondary"
                      badgeContent={rowData.files.length}
                      invisible={rowData.files.length > 0 ? false : true}
                      >
                      <FileIcon
                        color={rowData.files.length > 0 ? "primary" : "disabled"} />
                    </Badge>
                  </Tooltip>
                )
              },
              grouping: false,
              filtering: false,
              editable: 'never'
            },
            {
              title: 'Snag ID',
              field: 'unique_id',
              editable: 'never'
            },
            {
              title: 'Raised By',
              field: 'user_id',
              lookup: SiteSupervisorOption
            },
            { 
              title: 'Raised On', 
              field: 'created_at',
              render: rowData=> moment(rowData.created_at).format("ll"),
              type: 'date',
              editable: 'never'
            },
            {
              title: 'Product', field: 'product',
              lookup: {
                'hardware': 'hardware',
                'panel': 'panel'
              }
            },
            { title: 'Issue Description', field: 'description' },
            { title: 'Item Required', field: 'item_required' },
            { title: 'Size', field: 'size' },
            { title: 'Quantity', field: 'quantity'},
            {
              title: 'Nature of Issue',
              field: 'office_snag_nature_of_issue_id',
              lookup: OfficeNatureOfIssuesOptions
              
            },
            {
              title: 'Mistake Head',
              field: 'office_mistake_head_id',
              lookup: OfficeMistakeHeadOptions
            },
            {
              title: 'Status',
              field: 'status',
              lookup: SnagStatus
            },
            {
              title: 'Is Urgent',
              field: 'is_urgent',
              lookup: SnagIsUrgent
            }
          ]}
          onRowClick={(event, rowData, togglePanel) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
          data={Snags}        
          options={{
            filtering: true,
            exportButton: true
          }}
          // editable={{
          //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          //     if (this.SnagValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           updateSnags(this.props.auth.token, newData)
          //           .then(res => {
          //             if (res.status === 200) {
          //               getSnagList(this.props.auth.token, this.props.project.id)
          //               .then(res => {
          //                 if (res.status === 200) {
          //                   this.setState({Snags: res.data})
          //                 }
          //               })
          //               .catch(error => console.log(error))
          //             }  
          //           })
          //         }
          //         return resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         {
          //           console.log('rejected', newData)
          //           alert("Fill all the fields")
          //         }
          //         return reject()
          //       }, 1000);
          //     }
          //   })
          // }}
        />
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <ReactDropzone onDrop={this.onDrop}>
              Drop your file here!!
            </ReactDropzone>
              <aside style={thumbsContainer}>
                {thumbs}
              </aside>

              <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={() => this.setState({ openDialog: false })}
              >
                Close
              </Button>
              <Button
                type="submit"
                onClick={this.onSubmit}
                variant="contained"
                color="primary">
                Upload Files
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={filesDialog}
          onClose={() => this.setState({ filesDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="task-updates">Files</DialogTitle>
          <DialogContent dividers={true}>
              <aside style={thumbsContainer}>
              {
                filesList.map((file,index) => (
                  <div key={index} style={thumbInner}>
                    <img
                      src={file.file}
                      style={img}
                      alt="Arrivae"
                    />
                  </div>
                ))
                }
              </aside>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(Snags);
