import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { 
  createPLI, 
  getAllPLI, 
  deletePLItem, 
  changePLIStatus,
  completeAllPLI,
  updateSpecification,
  getClientList,
  getBoqList,
  getBoqLineItemList,
  getSliList
 } from '../../../api'

const PLIStatus = [
  { label: 'Compelete', value: true },
  { label: 'Incompelete', value: false },
].map(suggestion => ({
  value: suggestion.value,
  label: suggestion.label,
}));
const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    // overflowX: 'auto',
    // height: '-webkit-fill-available',
  },
  table: {
    minWidth: 700,
  },
  select: {
    minWidth: 120,
  }
});



class ProductionItemList extends Component {
  
  state = {
    open: false,
    openDialog2: false,
    name: "",
    newPLIForm: {
      operation_id: this.props.operationId,
      pli_name: '',
      client_id: '',
      client_name: '',
      quotation_id: '',
      boq_no: '',
      ownerable_id: '',
      boq_line_item: '',
      sli: '',
      specification: '',
    },
    productionItemList: [],
    boqList: [],
    boqLineItem: [],
    sliList: [],
  }

  componentDidMount() {
    getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
    .then(response => {
      if(response.status){
        this.setState({ productionItemList: response.data })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }
  handleClickOpen = (RemarkPLIId, specification) => {
    this.setState({ open: true, specification, RemarkPLIId });
  };
  
  handleClickOpenDialog2 = () => {
    this.setState({ openDialog2: true });
  };
  
  handleClose = () => {
    this.setState({ open: false, openDialog2: false });
  };
  
  handleStatusChange = (e) => {
    this.setState({ status: e.target.value});
  }
  
  onSubmit = (e) => {
    e.preventDefault();
    updateSpecification(this.props.auth.token, this.state.RemarkPLIId, {
      specification: this.state.specification
    })
    .then(response => {
      this.setState({ open: false });
      if(response.status){
        getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
        .then(response => {
          if(response.status){
            this.setState({ productionItemList: response.data })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
  }

  onSubmitPLIForm = (e) => {
    e.preventDefault();
    createPLI(this.props.auth.token, this.state.newPLIForm)
    .then(response => {
      if(response.status){
        this.setState({openDialog2: false})
        getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
        .then(response => {
          if(response.status){
            this.setState({ productionItemList: response.data })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  setClientName = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.client_name = e.value
    this.setState({ newPLIForm })
  }

  setBoqName = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.boq_no = e.label
    newPLIForm.quotation_id = e.value
    this.setState({ newPLIForm })
    getBoqLineItemList(this.props.auth.token, { quotation_id: e.value })
    .then(response => {
      if(response.status){
        let boqLineItem = response.data.map(suggestion => ({
          value: suggestion.ownerable_id,
          label: suggestion.name,
        }))
        this.setState({ boqLineItem })
      }
    })
  }

  setBoqLineItem = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.ownerable_id = e.value
    newPLIForm.boq_line_item = e.label
    this.setState({ newPLIForm })
    getSliList(this.props.auth.token, { ownerable_id: e.value })
    .then(response => {
      if(response.status){
        let sliList = response.data.map(suggestion => ({
          value: suggestion.ownerable_id,
          label: suggestion.name,
        }))
        this.setState({ sliList })
      }
    })
  }

  setSpecification = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.specification = e.target.value
    this.setState({ newPLIForm })
  }

  setSli = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.sli = e.label
    this.setState({ newPLIForm })
  }

  compeletePlI = () => {
    completeAllPLI(this.props.auth.token, { operation_id: this.props.operationId})
    .then(response => {
      if(response.status){
        getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
        .then(response => {
          if(response.status){
            this.setState({ productionItemList: response.data })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
  }

  changePLIStatus = (PLIId, e) => {
    changePLIStatus(this.props.auth.token, PLIId, {
      status: e.value
    })
    .then(response => {
      if(response.status){
        getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
        .then(response => {
          if(response.status){
            this.setState({ productionItemList: response.data })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
  }

  deletePLItem = (PLIId) => {
    if (window.confirm('Are you sure you want to delete PLI?')) {
      deletePLItem(this.props.auth.token, PLIId)
      .then(response => {
        if(response.status){
          getAllPLI(this.props.auth.token,{ operation_id: this.props.operationId})
          .then(response => {
            if(response.status){
              this.setState({ productionItemList: response.data })
            }
          })
          .catch(error => {
            console.log(error)
          })
        }
      })
    }
  }

  loadClientNames = (inputValue, callback) => {
    if(inputValue.length > 2){
      getClientList(this.props.auth.token, {
        client_name: inputValue
      })
      .then(response => {
        if (response.data) {
          let clientList = response.data.map(suggestion => ({
            value: suggestion.id,
            label: suggestion.name,
          }))
          this.setState({clientList})
          callback(clientList)
        }
      })
      .catch(error => console.log(error))
    }
  };

  selectClient = (e) => {
    let newPLIForm = {...this.state.newPLIForm}
    newPLIForm.client_name = e.label;
    newPLIForm.client_id = e.value
    
    this.setState({ newPLIForm });
    getBoqList(this.props.auth.token, {
      lead_id: e.value
    })
    .then(response => {
      if (response.status) {
        let boqList = response.data.map(suggestion => ({
          value: suggestion.id,
          label: suggestion.reference_number,
        }))

        this.setState({boqList})
      }
    })
  };
  
  render() {
    const { classes } = this.props;
    const { productionItemList } = this.state;
    return (
      <div>
        <Button 
          style={{ textAlign: 'right' }}
          variant="contained" 
          color="primary" 
          className={classes.button}
          onClick={() => this.handleClickOpenDialog2()}>
          Add PLI
        </Button>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell numeric style={{textAlign: 'left'}} >PLI</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >Client Name</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >BOQ Number</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >BOQ Line Item</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >SLI</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >Remark</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >Status</TableCell>
                <TableCell numeric style={{textAlign: 'left'}} >Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productionItemList && productionItemList.map(row => {
                let PLIStatusValue = null;
                if(row.status === true){
                  PLIStatusValue = {value: true, label: "Compelete"}
                }else if(row.status === false){
                  PLIStatusValue = {value: false, label: "Incompelete"}
                }
                return (
                  <TableRow key={row.id}>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.pli_name}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.client_name}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.boq_num}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.boq_line_item}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.sli}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >{row.specification}</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >
                      <Select
                        options={PLIStatus}
                        value={PLIStatusValue}
                        onChange={(e) => this.changePLIStatus(row.id, e)}
                        placeholder={row.status || ""}
                        style={classes.select}
                      />
                    </TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >
                      <Tooltip title="Remark">
                        <IconButton aria-label="Remark" 
                          onClick={() => this.handleClickOpen(row.id, row.specification)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton 
                          variant="fab" 
                          color="secondary" 
                          aria-label="Delete"
                          onClick={() => this.deletePLItem(row.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <Button 
          style={{ textAlign: 'right' }}
          variant="contained" 
          color="primary" 
          className={classes.button}
          onClick={this.compeletePlI}>
          Compelete all PLIs
        </Button>
        
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
        <ValidatorForm 
          className={classes.form} 
          onSubmit={this.onSubmit} >
            <DialogTitle id="form-dialog-title">Add Remark</DialogTitle>
            <DialogContent>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  id="multiline-static"
                  label="Add Remark"
                  multiline
                  rows="6"
                  className={classes.textField}
                  margin="normal"
                  name="specification"
                  value={this.state.specification}
                  onChange={(e) => this.setState({specification: e.target.value})}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                  type="submit"
                  variant="contained"
                  color="primary">
                  Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>


        <Dialog
          open={this.state.openDialog2}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
        <ValidatorForm 
          className={classes.form} 
          onSubmit={this.onSubmitPLIForm} >
            <DialogTitle id="form-dialog-title">Create New PLI</DialogTitle>
            <DialogContent>
              <FormControl required fullWidth>
                <TextValidator
                  label="PLI Number (Auto generated on form submition)"
                  name="pli_name"
                  disabled
                  defaultValue={this.state.newPLIForm.pli_name}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <AsyncSelect
                  cacheOptions
                  loadOptions={this.loadClientNames}
                  defaultOptions
                  onChange={(e) => this.selectClient(e)}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Select
                  classes={classes}
                  options={this.state.boqList}
                  value={this.state.newPLIForm.boq_no}
                  onChange={(e) => this.setBoqName(e)}
                  placeholder={this.state.newPLIForm.boq_no || "Select BOQ Number"}
                />

              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Select
                  classes={classes}
                  options={this.state.boqLineItem}
                  value={this.state.newPLIForm.boq_line_item}
                  onChange={(e) => this.setBoqLineItem(e)}
                  placeholder={this.state.newPLIForm.boq_line_item || "Select BOQ Line Item"}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Select
                  classes={classes}
                  options={this.state.sliList}
                  value={this.state.newPLIForm.sli}
                  onChange={(e) => this.setSli(e)}
                  placeholder={this.state.newPLIForm.sli || "Select SLI"}
                />
              </FormControl>
              <FormControl required fullWidth>
                <TextValidator
                  id="multiline-static"
                  label="Specification"
                  multiline
                  rows="4"
                  className={classes.textField}
                  name="specification"
                  onChange={(e) => this.setSpecification(e)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                  type="submit"
                  variant="contained"
                  color="primary">
                  Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    );
  }
} 

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),connect(mapStateToProps))(ProductionItemList);