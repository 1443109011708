import axios from '../base';


// const DELTA_URL = "http://192.168.9.198:3000";
// const DELTA_URL = "http://uatapi.arrivae.com";
const DELTA_URL = "https://api.arrivae.com";


export function downloadBOQ(projectID,boqID){
  return axios.request({
    url: `${DELTA_URL}/v1/projects/${projectID}/quotations/${boqID}/download_v2_pdf_office`,
    method: 'GET',
    headers: {"Content-Type": "application/json", "X-WWW-API-KEY": "RR8xqcXh199vzW5ad3uF2xBT"},
    params: {download_type:'boq'}
  })
}