import axios from '../base';

export function getOfficeUserListByRole(accessToken, roleId) {
  return axios.request({
    url: `/api/v1/office_users/office_users_list_by_role/${roleId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getAssignedCMToOMList(accessToken, OMId) {
  return axios.request({
    url: `/api/v1/office_users/assigned_cm_to_om_list/${OMId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function mapCMToOM(accessToken, userData) {
  return axios.request({
    url: '/api/v1/office_users/map_cm_to_om',
    method: 'POST',
    headers: accessToken,
    data: userData,
  })
};

export function getAssignedPMToOMList(accessToken, OMId) {
  return axios.request({
    url: `/api/v1/office_users/assigned_pm_to_om_list/${OMId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function mapPMToOM(accessToken, userData) {
  return axios.request({
    url: '/api/v1/office_users/map_pm_to_om',
    method: 'POST',
    headers: accessToken,
    data: userData,
  })
};

export function getAssignedSSToPMList(accessToken, PEId) {
  return axios.request({
    url: `/api/v1/office_users/assigned_ss_to_pm_list/${PEId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function mapSSToPM(accessToken, userData) {
  return axios.request({
    url: '/api/v1/office_users/map_ss_to_pm',
    method: 'POST',
    headers: accessToken,
    data: userData,
  })
};