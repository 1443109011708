import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ReactDropzone from "react-dropzone";
import TextField from '@material-ui/core/TextField';
import { getFilterMeasurementRequests, getMeasurementRequests, getDynamicFilterDropdowns, site_supervisor_list_by_role, assignSupervisor, createMeasurementFiles, getExportFilterReport } from '../../../api';
import CircularIndeterminate from '../../Loader';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Collections, CloudDownload, Ballot, FlareSharp, Gif } from '@material-ui/icons';
import { Grid, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Button, Chip, Tooltip, Avatar, InputLabel } from '@material-ui/core'
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../../Shared/TablePaginationWrapper';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  button: {
    margin: theme.spacing(),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: '500',
    textAlign: 'center',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textCenter: {
    textAlign: 'center',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 50,
    height: 50,
    padding: 2,
    boxSizing: 'border-box',
    borderWidth: 1,
    borderColor: 'black',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: 50,
  },
  dialogBlock: {
    width: '100%',
    height: 500,
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

class SiteMeasurement extends Component {
  state = {
    open: false,
    totalEnteries: 0,
    totalPages: 0,
    measurementRequests: [],
    measurementPendingRequests: [],
    measurementCompleteRequests: [],
    siteSupervisors: [],
    selectedSupervisor: null,
    selectErr: false,
    newFile: {
      projectId: null,
      files: '',
    },
    fileList: [],
    files: [],
    openPreview: false,
    previewUrl: '',
    remark: '',
    isLoading: true,
    selectedRequestId: null,
    updatable_type:'OfficeUserSiteMeasurementRequest',
    arrowIconID: null,
    siteimages: [],
    siteGalaryDialog: false,
    siteupdates: [],
    siteUpdateDialog: false,
    site_measurement_list_status: 'pending',
    btnTab: false,
    page: 0,
    rowsPerPage: 15,
    searchString:"",
    fromDate:"",
    toDate:"",
    designerList: [],
    designerFilterId: [],
    designerFilterArray: [],
    cmList: [],
    cmFilterId: [],
    cmFilterArray: [],
    requestStatusList: [],
    requestStatusFilters: [],
    requestStatusFilterArray: [],
    siteMeasurementTypeList: [],
    siteMeasurementTypeFilters: [],
    siteMeasurementTypeFilterArray: [],
    siteSupervisorsList: [],
    siteSupervisorsFilterId: [],
    siteSupervisorsFilterArray: [],
    requestDoneByList: [],
    requestDoneByFilterId: [],
    requestDoneByFilterArray: []
  };

  _getMeasurementRequest = () => {
    const { token, project } = this.props;
    let projectId = project != null ? project.id : null;
    const { site_measurement_list_status } = this.state;
    getMeasurementRequests(token, projectId, site_measurement_list_status)
      .then(response => {
        if (response.status === 200) {
          // this.setState({ measurementRequests: response.data })
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementRequests: response.data,
              measurementPendingRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementRequests: response.data,
              measurementCompleteRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
        else {
          Swal.fire({ title: response.data.message })
        }
      })
      .catch(error => { console.log(error) })
  }

  async componentDidMount() {
    await this.getInitalData()
    // await this.getOtherSiteMeasurementRequest()
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location.search !== this.props.location.search) {
  //     this.getInitalData()
  //   }
  // }

  getInitalData = async () => {
    const { token, project } = this.props;
    const { site_measurement_list_status, page } = this.state;
    let projectId = project != null ? project.id : null;
    let url = new URL(window.location.href)
    let request_status = projectId ? 'pending' : (url.searchParams.get('request_status') || 'pending')
    let page_number = project?.id ? (page + 1) : (parseInt(url.pathname.split('/').reverse()[0]) || (page + 1))
    let SiteSupervisorListRes = await site_supervisor_list_by_role(this.props.token)
    let MeasurementRequestsRes = await getMeasurementRequests(token, projectId, request_status, page_number)
    let SiteSupervisorOption = {}
    let dynamicDropdownFilter = await getDynamicFilterDropdowns(token, request_status)
    SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

    if (MeasurementRequestsRes.status == 200) {
      this.setState({
        measurementPendingRequests: MeasurementRequestsRes.data,
        siteSupervisors: SiteSupervisorOption,
        site_measurement_list_status: request_status,
        isLoading: false,
        totalEnteries: MeasurementRequestsRes.headers['total-entries'],
        totalPages: MeasurementRequestsRes.headers['total-pages']
      })

    }
    if (dynamicDropdownFilter.status == 200) {
      this.setState({
        isLoading: false,
        designerList: dynamicDropdownFilter.data.designer_id,
        cmList: dynamicDropdownFilter.data.cm,
        site_measurement_list_status: request_status,
        requestStatusList: dynamicDropdownFilter.data.request_status,
        siteMeasurementTypeList: dynamicDropdownFilter.data.site_measurement_type,
        siteSupervisorsList: dynamicDropdownFilter.data.site_supervisor,
        requestDoneByList: dynamicDropdownFilter.data.request_done_by
      })
    }
  }

  getOtherSiteMeasurementRequest = async () => {
    const { token, project } = this.props;
    const { site_measurement_list_status, totalPages } = this.state;
    let projectId = project != null ? project.id : null;
    for (let i = 1; i < totalPages; i++) {
      let NewMeasurementRequestsRes = await getMeasurementRequests(token, projectId, site_measurement_list_status, i + 1)
      if (NewMeasurementRequestsRes.status == 200) {
        if (site_measurement_list_status == 'pending') {
          this.setState({ measurementPendingRequests: this.state.measurementPendingRequests.concat(NewMeasurementRequestsRes.data) })
        } else {
          this.setState({ measurementCompleteRequests: this.state.measurementCompleteRequests.concat(NewMeasurementRequestsRes.data) })
        }
      }
    }
  }

  openDialog = (requestId) => {
    this.setState({
      open: true, 
      selectedRequestId: requestId, 
    });
  }

  handleClose = () => {
    this.setState({ open: false, remark: '', files: [] })
  }

  onDrop = (files) => {
    let newFile = { ...this.state.newFile };
    console.log(files);
    newFile.files = files
    this.setState({
      newFile,
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
  }

  onSubmit = (e) => {
    const remarkValue = document.getElementById("outlined-multiline-static").value
    e.preventDefault();
    this.setState({ isLoading: true, open: false, remark: remarkValue })
    const { newFile, selectedRequestId, updatable_type } = this.state;
    const fd = new FormData();
    fd.append('requestId', parseInt(selectedRequestId, 10))
    fd.append('file_length', newFile.files.length)
    for (const i in newFile.files) {
      if (newFile.files.hasOwnProperty(i)) {
        fd.append(`file_${i}`, newFile.files[i])
        fd.append(`remark`, remarkValue)
        fd.append(`is_client_mom`, false)
        fd.append(`office_visit_id`, null)
        fd.append(`status`, true)
        fd.append(`updateable_type`, updatable_type)
      }
    }
    createMeasurementFiles(this.props.token, selectedRequestId, fd)
      .then(res => {
        if (res.status === 200) {
          Swal.fire('Success', res.data.message );
          this._getMeasurementRequest();
          this.setState({files: []})
        } else {
          Swal.fire({ title: res.data.message })
        }
        this.setState({
          isLoading: false
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoading: false
        })
      })
  }

  selectSupervisor = (e, requestId) => {
    this.setState({ selectedSupervisor: e.value, arrowIconID: requestId })
  }

  // handleChange = (e) => {
  //   let key = e.target.name;
  //   this.setState({ [key]: e.target.value })
  // }

  setSupervisor = (requestId) => {
    this.setState({ isLoading: true })
    const { token } = this.props;
    assignSupervisor(token, { office_user_id: this.state.selectedSupervisor, site_measurement_request_id: requestId })
      .then(response => {
        Swal.fire({ title: response.data.message })
        this._getMeasurementRequest()
        this.setState({ isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false })
        console.log(error)
      })
  }

  getUpdatedMeasurementRequests = async (site_measurement_list_status) => {
    this.setState({
      searchString: "",
      fromDate: "",
      toDate: "",
      designerFilterId: [],
      designerFilterArray: [],
      cmFilterId: [],
      cmFilterArray: [],
      requestStatusFilters: [],
      requestStatusFilterArray: [],
      siteMeasurementTypeFilters: [],
      siteMeasurementTypeFilterArray: [],
      siteSupervisorsFilterId: [],
      siteSupervisorsFilterArray: [],
      requestDoneByFilterId: [],
      requestDoneByFilterArray: []
    })
    this.setState({ isLoading: true, searchString:"" })
    const { token, project } = this.props;
    let projectId = project != null ? project.id : null;
    let dynamicDropdownFilter = await getDynamicFilterDropdowns(token, site_measurement_list_status)

    if(!projectId){
      if(site_measurement_list_status == 'pending') {
        this.props.history.push({
          pathname: '/om/site-measurements',
          search: '?request_status=pending'
        })
      }else {
        this.props.history.push({
          pathname: '/om/site-measurements',
          search: '?request_status=completed'
        })
      }
    }

    await getMeasurementRequests(token, projectId, site_measurement_list_status)
      .then(response => {
        if (response.status === 200) {
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementPendingRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: false,
              page:0,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementCompleteRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: true,
              page:0,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
        else {
          Swal.fire({ title: response.data.message })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
    // await this.getOtherSiteMeasurementRequest()

    if (dynamicDropdownFilter.status == 200) {
      this.setState({
        isLoading: false,
        designerList: dynamicDropdownFilter.data.designer_id,
        cmList: dynamicDropdownFilter.data.cm,
        requestStatusList: dynamicDropdownFilter.data.request_status,
        siteMeasurementTypeList: dynamicDropdownFilter.data.site_measurement_type,
        siteSupervisorsList: dynamicDropdownFilter.data.site_supervisor,
        requestDoneByList: dynamicDropdownFilter.data.request_done_by
      })
    }
  }
  handleChangePage = async(event, page) => {
    this.setState({ page, isLoading: true });
    const { token, project } = this.props;
    let projectId = project != null ? project.id : null;
    const { site_measurement_list_status,
      searchString,
      fromDate,
      toDate,
      designerFilterId,
      cmFilterId,
      requestStatusFilters,
      siteMeasurementTypeFilters,
      siteSupervisorsFilterId,
      requestDoneByFilterId } = this.state;
    let url = new URL(window.location.href)
    let request_status = url.searchParams.get('request_status') || 'pending'
    let page_num = page + 1
    let fromFilterDate = fromDate || ""
    let toFilterDate = toDate || ""
    let search = searchString
    let designer_id = designerFilterId || ""
    let cm = cmFilterId || ""
    let request_status_filter = requestStatusFilters || ""
    let site_measurement_type = siteMeasurementTypeFilters || ""
    let site_supervisor = siteSupervisorsFilterId || ""
    let request_done_by = requestDoneByFilterId || ""
    
    if((searchString && searchString.length > 0) || fromDate || toDate || designerFilterId || cmFilterId || requestStatusFilters || siteMeasurementTypeFilters || siteSupervisorsFilterId || requestDoneByFilterId) {
      !project && this.props.history.push(`/om/site-measurements/${page_num}?request_status=${request_status}`)
      await getFilterMeasurementRequests(token, projectId, request_status, page_num, search, request_status_filter, fromFilterDate, toFilterDate, cm, designer_id, site_measurement_type, site_supervisor, request_done_by)
      .then(response => {
        if (response.status === 200) {
          if (request_status == 'pending') {
            this.setState({
              measurementPendingRequests: response.data,
              site_measurement_list_status:request_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementCompleteRequests: response.data,
              site_measurement_list_status:request_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    }else {
      this.props.history.push(`/om/site-measurements/${page + 1}?request_status=${request_status}`)
      await getMeasurementRequests(token, projectId, request_status, page + 1)
      .then(response => {
        if (response.status === 200) {
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementPendingRequests: response.data,
              site_measurement_list_status:request_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementCompleteRequests: response.data,
              site_measurement_list_status:request_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
        else {
          Swal.fire({ title: response.data.message })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
    }
  };

  setSearchValHandler=(val)=>{
    this.setState({searchString: val})
  }

  handleFilter = (e) => {
    this.setState({isLoading: true});
    const { token, project } = this.props;
    const { site_measurement_list_status, page,
      searchString,
      fromDate,
      toDate,
      designerFilterId,
      cmFilterId,
      requestStatusFilters,
      siteMeasurementTypeFilters,
      siteSupervisorsFilterId,
      requestDoneByFilterId } = this.state;
    let url = new URL(window.location.href)
    let request_status = url.searchParams.get('request_status') || site_measurement_list_status
    let page_num = 1
    let projectId = project != null ? project.id : null;
    let fromFilterDate = fromDate || ""
    let toFilterDate = toDate || ""
    let search = searchString
    let designer_id = designerFilterId || ""
    let cm = cmFilterId || ""
    let request_status_filter = requestStatusFilters || ""
    let site_measurement_type = siteMeasurementTypeFilters || ""
    let site_supervisor = siteSupervisorsFilterId || ""
    let request_done_by = requestDoneByFilterId || ""

      !project && this.props.history.push(`/om/site-measurements/${page_num}?request_status=${request_status}`)
      getFilterMeasurementRequests(token, projectId, request_status, page_num, search, request_status_filter, fromFilterDate, toFilterDate, cm, designer_id, site_measurement_type, site_supervisor, request_done_by)
        .then(response => {
          if (response.status === 200) {
            if (site_measurement_list_status == 'pending') {
              this.setState({
                measurementPendingRequests: response.data,
                site_measurement_list_status,
                isLoading: false,
                btnTab: false,
                totalEnteries: response.headers['total-entries'],
                totalPages: response.headers['total-pages']
              })
            } else {
              this.setState({
                measurementCompleteRequests: response.data,
                site_measurement_list_status,
                isLoading: false,
                btnTab: true,
                totalEnteries: response.headers['total-entries'],
                totalPages: response.headers['total-pages']
              })
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
  }

  resetAllFilter = () => {
    const { token, project } = this.props;
    const { site_measurement_list_status, page } = this.state;
    let url = new URL(window.location.href)
    let request_status = url.searchParams.get('request_status') || site_measurement_list_status
    let page_num = 1
    let projectId = project != null ? project.id : null;
    this.setState({
      isLoading: true,
      searchString: "",
      fromDate:"",
      toDate:"",
      designerFilterId: [],
      designerFilterArray: [],
      cmFilterId: [],
      cmFilterArray: [],
      requestStatusFilters: [],
      requestStatusFilterArray: [],
      siteMeasurementTypeFilters: [],
      siteMeasurementTypeFilterArray: [],
      siteSupervisorsFilterId: [],
      siteSupervisorsFilterArray: [],
      requestDoneByFilterId: [],
      requestDoneByFilterArray: []
    })
    
    this.props.history.push(`/om/site-measurements/${page_num}?request_status=${request_status}`)
    getMeasurementRequests(token, projectId, request_status, page_num)
      .then(response => {
        if (response.status === 200) {
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementPendingRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementCompleteRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
      })
      .catch(error => {
        Swal.fire("Error", "Error while loading Data", "error");
      })
  }

  handleKeyPress = async(e) => {
    const { token, project } = this.props;
    const { site_measurement_list_status, page,
      searchString,
      fromDate,
      toDate,
      designerFilterId,
      cmFilterId,
      requestStatusFilters,
      siteMeasurementTypeFilters,
      siteSupervisorsFilterId,
      requestDoneByFilterId } = this.state;
    let url = new URL(window.location.href)
    const search = searchString
    let request_status = url.searchParams.get('request_status') || site_measurement_list_status
    let page_num = 1
    let projectId = project != null ? project.id : null;
    if(e.key == "Enter") {
      this.setState({isLoading: true});
      !project && this.props.history.push(`/om/site-measurements/${page_num}?request_status=${request_status}`)
      await getFilterMeasurementRequests(token, projectId, request_status, page_num, search, requestStatusFilters, fromDate, toDate, cmFilterId, designerFilterId, siteMeasurementTypeFilters, siteSupervisorsFilterId, requestDoneByFilterId)
        .then(response => {
          if (response.status === 200) {
            if (site_measurement_list_status == 'pending') {
              this.setState({
                measurementPendingRequests: response.data,
                site_measurement_list_status,
                isLoading: false,
                btnTab: false,
                totalEnteries: response.headers['total-entries'],
                totalPages: response.headers['total-pages']
              })
            } else {
              this.setState({
                measurementCompleteRequests: response.data,
                site_measurement_list_status,
                isLoading: false,
                btnTab: true,
                totalEnteries: response.headers['total-entries'],
                totalPages: response.headers['total-pages']
              })
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  handleExportFilter = () => {
    const { token, project } = this.props;
    const { site_measurement_list_status, page,
      searchString,
      fromDate,
      toDate,
      designerFilterId,
      cmFilterId,
      requestStatusFilters,
      siteMeasurementTypeFilters,
      siteSupervisorsFilterId,
      requestDoneByFilterId } = this.state;
    let url = new URL(window.location.href)
    let projectId = project != null ? project.id : null;
    const search = searchString
    let request_status = url.searchParams.get('request_status') || site_measurement_list_status
    getExportFilterReport(token, projectId, request_status, search, requestStatusFilters, fromDate, toDate, cmFilterId, designerFilterId, siteMeasurementTypeFilters, siteSupervisorsFilterId, requestDoneByFilterId)
      .then(res => {
        if (res.data.status == 200) {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.file}`;
          const downloadLink = document.createElement("a");
          const fileName = res.data.file_name;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          Swal.fire('Success', 'Downloaded successfully.', 'success');
        } else {
          Swal.fire('Error', res?.data?.message, 'success');
        }
      })
      .catch(err => {
        Swal.fire('Error', 'Something went wrong!', 'error');
      })
  }

  handleDesignerAutoComplete = (event, newValue) => {
    this.setState({
      designerFilterId: newValue.map(v => v.id) || [],
      designerFilterArray: newValue || []
    })
  }

  handleCMAutoComplete = (event, newValue) => {
    this.setState({
      cmFilterId:newValue.map(v => v.id) || [],
      cmFilterArray:newValue || []
    })
  }

  handleRequestStatusAutoComplete = (event, newValue) => {
    this.setState({
      requestStatusFilters:newValue.map(v => v.value) || [],
      requestStatusFilterArray:newValue || []
    })
  }

  handleSiteMeasurementTypeAutoComplete = (event, newValue) => {
    this.setState({
      siteMeasurementTypeFilters:newValue.map(v => v.value) || [],
      siteMeasurementTypeFilterArray:newValue || []
    })
  }

  handleSiteSupervisorsAutoComplete = (event, newValue) => {
    this.setState({
      siteSupervisorsFilterId:newValue.map(v => v.id) || [],
      siteSupervisorsFilterArray:newValue || []
    })
  }

  handleRequestDoneByAutoComplete = (event, newValue) => {
    this.setState({
      requestDoneByFilterId:newValue.map(v => v.id) || [],
      requestDoneByFilterArray:newValue || []
    })
  }

  onDateFromChange = (e) => {
    this.setState({
      fromDate: e.target.value
    })
  }

  onDateToChange = (e) => {
    this.setState({
      toDate: e.target.value
    })
  }

  removeUnderScore = (str) => {
    var i, roleName = str.split('_');
    for (i=0; i<roleName.length; i++) {
      roleName[i] = roleName[i].charAt(0).toUpperCase() + roleName[i].slice(1);
    }
    return roleName.join(' ');
  }

  render() {
    const { siteSupervisors,
      measurementRequests,
      open,
      isLoading,
      btnTab,
      measurementPendingRequests,
      measurementCompleteRequests,
      files,
      totalEnteries,
      rowsPerPage,
      page,
      arrowIconID, siteimages, siteGalaryDialog, siteupdates, siteUpdateDialog, site_measurement_list_status,
      designerList, designerFilterArray, cmList, cmFilterArray, requestStatusList, requestStatusFilterArray, siteMeasurementTypeList, siteMeasurementTypeFilterArray,
      siteSupervisorsList, siteSupervisorsFilterArray, requestDoneByList, requestDoneByFilterArray } = this.state;
      let url = new URL(window.location.href)
      let request_status = this.props?.project?.id ? site_measurement_list_status : (url.searchParams.get('request_status') || 'pending')
      let page_number = this.props?.project?.id ? (page + 1) : (parseInt(url.pathname.split('/').reverse()[0]) || (page + 1))
    const { classes } = this.props;
    const thumbs = files.map(file => (
      <div key={file.preview} style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginTop: 8,
        marginBottom: 8,
        marginRight: 8,
        width: 50,
        height: 50,
        padding: 2,
        boxSizing: 'border-box',
      }}>
        <div style={styles.thumbInner}>
          <img
            src={file.preview}
            style={{
              display: 'block',
              width: 'auto',
              height: 50,
            }}
            alt="Arrivae"
          />
        </div>
      </div>
    ));
    return (
      <div className={classes.root}>
        
          <div>
            <MaterialTable
              title="Site Measurement List"
              // onSearchChange={this.handleSearchChange}
              isLoading={isLoading}
              columns={[
                {
                  title: 'Date of Measurement', field: 'scheduled_at',
                  render: rowData =>( rowData && rowData.scheduled_at !== null   ? moment(rowData.scheduled_at.replace("T", " ")).format("ll") : ''),
                  type: 'date',
                  editable: 'never'
                },
                {
                  title: 'Lead Id',
                  field: 'project.lead_id',
                  editable: 'never'
                },
                {
                  title: 'Project Name',
                  field: 'project.name',
                  editable: 'never'
                },
                {
                  title: 'Community Manager',
                  field: 'assigned_cm.name',
                  editable: 'never'
                },
                {
                  title: 'Designer',
                  field: 'assigned_designer.name',
                  editable: 'never'
                },
                {
                  title: 'Type of Measurement',
                  field: 'request_type',
                  editable: 'never'
                },
                {
                  title: 'Address',
                  field: 'address',
                  editable: 'never'
                },
                {
                  title: 'Request Status',
                  field: 'office_user_site_measurement_requests.completed',
                  render: rowData => (rowData?.request_status),
                  editable: 'never'
                },
                {
                  title: 'Assigned Site Supervisor/PM',
                  field: 'office_user_site_measurement_requests.office_user_id',
                  render: rowData => rowData && rowData.office_user_site_measurement_requests !== null ? siteSupervisors[rowData.office_user_site_measurement_requests && rowData.office_user_site_measurement_requests.length > 0 && rowData.office_user_site_measurement_requests[0].office_user_id] : null,
                  lookup: siteSupervisors,
                  editable: 'onUpdate'
                },
                {
                  title: request_status == "completed" && 'Request Done By',
                  field: request_status == "completed" && 'office_user_site_measurement_request_done_by.office_user_id',
                  render: rowData => rowData?.completed_by_for_serializer?.name ? rowData?.completed_by_for_serializer?.name + "(" + this.removeUnderScore(rowData?.completed_by_for_serializer?.role) + ")" : null,
                  
                  editable: 'never'
                },
                {
                  title: 'Site Images',
                  field: '',
                  render: rowData => {
                    return (
                      <Badge
                        className={classes.margin}
                        badgeContent={rowData.site_images.length || 0}
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        showZero
                        onClick={() => rowData.site_images.length === 0 ? Swal.fire({ icon: 'info', title: "No files to view" }) : this.setState({ siteimages: rowData.site_images, siteGalaryDialog: true })}
                      >
                        <Collections />
                      </Badge>
                    )
                  },
                  editable: 'never'
                },
                {
                  title: 'Site Updates',
                  field: '',
                  render: rowData => {
                    return (
                      <Badge
                        className={classes.margin}
                        badgeContent={(rowData.office_updates && rowData.office_updates.length) || 0}
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        showZero
                        onClick={() => rowData.office_updates.length === 0 ? Swal.fire({ icon: 'info', title: "No Updates" }) : this.setState({ siteupdates: rowData.office_updates, siteUpdateDialog: true })}
                      >
                        <Ballot />
                      </Badge>
                    )
                  },
                  editable: 'never'
                },
              ]}
              data={btnTab ? measurementCompleteRequests : measurementPendingRequests}
              actions={[
                rowData => ({
                  icon: (rowData.office_user_site_measurement_requests !== null && rowData.office_user_site_measurement_requests.completed) ? 'check_box' : 'check_box_outline_blank',
                  tooltip: 'Mark Complete',
                  onClick: (event, rowData) => this.openDialog(rowData.id),
                  disabled: (rowData.office_user_site_measurement_requests !== null && rowData.office_user_site_measurement_requests.completed) ? true : false
                })
              ]}
              editable={request_status == "pending" && {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        // if (oldData.office_user_site_measurement_requests && oldData.office_user_site_measurement_requests.length <= 0) {
                        this.setState({ isLoading: true })
                        assignSupervisor(this.props.token, {
                          office_user_id: parseInt(newData.office_user_site_measurement_requests.office_user_id),
                          site_measurement_request_id: newData.id
                        })
                          .then(response => {
                            Swal.fire({ icon: 'success', title: response.data.message })
                            this._getMeasurementRequest()
                            this.setState({ isLoading: false })
                            this.handleFilter();
                          })
                          .catch(error => {
                            this.setState({ isLoading: false })
                            console.log(error.response)
                            Swal.fire('Error', error.response.data.message, 'error')
                            console.log(error)
                          })
                        // } else {
                        //   alert('Site Supervisor already assigned!')
                        //   return reject()
                        // }
                      }
                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                grouping: true,
                paging: false,
                search:this.props?.project ? true : false,
              }}
              components={{
                Toolbar: props => (
                  <div>
                    {/* <MTableToolbar {...props} /> */}
                    <Grid container style={{alignItems: "center", paddingRight: '8px', paddingLeft: '1rem'}}>
                      <Grid item xs={8}>
                        <h5>Site Measurement List</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item style={{padding:"10px", display: 'flex', justifyContent: 'flex-end'}}>
                          <Button variant="contained" color="primary" onClick={() => this.handleExportFilter()}>Export</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container style={{alignItems: "center"}}>
                      <Grid item xs={9}>
                        <div style={{ padding: '0px 10px' }}>
                          <Chip label="Pending/Assigned/Discarded"
                            avatar={request_status === 'pending' ? <Avatar>{totalEnteries}</Avatar> : null}
                            color={request_status === 'pending' ? "secondary" : 'default'}
                            onClick={() => this.getUpdatedMeasurementRequests('pending')}
                            style={{ margin: 5 }} 
                          />
                          <Chip label="Completed"
                            avatar={request_status === 'completed' ? <Avatar>{totalEnteries}</Avatar> : null}
                            color={request_status === 'completed' ? "secondary" : 'default'}
                            onClick={() => this.getUpdatedMeasurementRequests('completed')}
                            style={{ margin: 5 }} 
                          />
                        </div>
                      </Grid>
                      {!this.props?.project && <Grid item xs={3}>
                        <div style={{ paddingRight: "10px", textAlign:"right", paddingBottom:"20px"}}>
                            <TextField
                              label="Search for Lead Id, Name and Address"
                              value={this.state?.searchString}
                              onChange={(e)=>this.setSearchValHandler(e.target.value)}
                              onKeyPress={this.handleKeyPress}
                              autoFocus={true}
                              style={{width: "100%"}}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                      </Grid>}
                    </Grid>
                    <Grid container spacing={3} className="w-auto m-2">
                      <Grid item xs={3} >
                          <TextField
                            id="date"
                            label="From"
                            type="date"
                            defaultValue=""
                            className="w-100"
                            value={this.state.fromDate}
                            onChange={(e) => this.onDateFromChange(e)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                      </Grid>
                      <Grid item xs={3}>
                          <TextField
                            id="date"
                            label="To"
                            type="date"
                            defaultValue=""
                            className="w-100"
                            value={this.state.toDate}
                            onChange={(e) => this.onDateToChange(e)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={designerFilterArray}
                          options={designerList.map((item) => {
                            return { "id": item[0], "name": item[1]}
                          })}
                          getOptionSelected={(option, value) => option.id === value.id}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Designer" />
                          )}
                          onChange={(event, newValue) => this.handleDesignerAutoComplete(event, newValue)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={cmFilterArray}
                          options={cmList.map((item) => {
                            return { "id": item[0], "name": item[1]}
                          })}
                          disableCloseOnSelect
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField style={{padding:"16px 0px"}} className="textField" {...params} placeholder="CM" />
                          )}
                          onChange={(event, newValue) => this.handleCMAutoComplete(event, newValue)}
                        />
                      </Grid>
                      {site_measurement_list_status == 'pending' ? 
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={requestStatusFilterArray}
                          options={requestStatusList.map((item, index) => {
                            return { "id": index, "value": item}
                          })}
                          disableCloseOnSelect
                          getOptionSelected={(option, value) => option.value === value.value}
                          getOptionLabel={(option) => option.value}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.value}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField style={{padding:"16px 0px"}} className="textField" {...params} placeholder="Request Status" />
                          )}
                          onChange={(event, newValue) => this.handleRequestStatusAutoComplete(event, newValue)}
                        />
                      </Grid> : null }
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={siteMeasurementTypeFilterArray}
                          options={siteMeasurementTypeList.map((item, index) => {
                            return { "id": index, "value": item, "name": this.removeUnderScore(item)}
                          })}
                          disableCloseOnSelect
                          getOptionSelected={(option, value) => option.value === value.value}
                          getOptionLabel={(option) => option.value}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField style={{padding:"16px 0px"}} className="textField" {...params} placeholder="Site measurement type" />
                          )}
                          onChange={(event, newValue) => this.handleSiteMeasurementTypeAutoComplete(event, newValue)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={siteSupervisorsFilterArray}
                          options={siteSupervisorsList.map((item) => {
                            return { "id": item[0], "name": item[1]}
                          })}
                          disableCloseOnSelect
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField style={{padding:"16px 0px"}} className="textField" {...params} placeholder="Site supervisor" />
                          )}
                          onChange={(event, newValue) => this.handleSiteSupervisorsAutoComplete(event, newValue)}
                        />
                      </Grid>
                      {site_measurement_list_status !== 'pending' ?
                      <Grid item xs={3}>
                        <Autocomplete
                          multiple
                          value={requestDoneByFilterArray}
                          options={requestDoneByList.map((item) => {
                            return { "id": item[0], "name": item[1]}
                          })}
                          disableCloseOnSelect
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField style={{padding:"16px 0px"}} className="textField" {...params} placeholder="Request Done By" />
                          )}
                          onChange={(event, newValue) => this.handleRequestDoneByAutoComplete(event, newValue)}
                        />
                      </Grid> : null}
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="flex-end">
                          <Grid item style={{padding:"10px"}}>
                            <Button variant="contained" color="primary" onClick={() => this.handleFilter()}>Filter Task List</Button>
                          </Grid>
                          <Grid item style={{padding:"10px"}}>
                            <Button variant="contained" color="primary" onClick={() => this.resetAllFilter()}>Reset All Filters</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                ),
              }}
            />

            <div style={{float:"right"}}>
              <TablePagination
                colSpan={8}
                count={totalEnteries}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page_number - 1}
                onChangePage={this.handleChangePage}
                // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationWrapper}
              />
            </div>
          </div>


        {/* Add files dailog */}
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ height: '100vh' }}
          maxWidth={'md'}
        >
          <DialogTitle id="form-dialog-title">Add Files</DialogTitle>
          <ValidatorForm
            className={classes.form}
            onSubmit={this.onSubmit}
            onError={errors => console.log(errors)}
            instantValidate={false} >
            <DialogContent style={{ minWidth: '400px' }}>

              <ReactDropzone onDrop={this.onDrop}>
                Drop your file here!!
              </ReactDropzone>
              <aside style={styles.thumbsContainer}>
                {thumbs}
              </aside>
              <TextField
                required
                id="outlined-multiline-static"
                label="Remark"
                name="remark"
                multiline
                rows="4"
                // value={this.state.remark}
                // onChange={(e) => this.handleChange(e)}
                className={styles.textField}
                margin="normal"
                variant="outlined"
              />
              <br />
              <br />
              <Button
                className="pull-right"
                type="button"
                variant="contained"
                style={{ marginLeft: '20px' }}
                onClick={this.handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Upload Files
            </Button>
            </DialogContent>
          </ValidatorForm>
        </Dialog>

        <Dialog onClose={() => this.setState({ siteimages: [], siteGalaryDialog: false })} aria-labelledby="customized-dialog-title" open={siteGalaryDialog}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ siteimages: [], siteGalaryDialog: false })}>
            Site Galary
        </DialogTitle>
          <DialogContent dividers>
            <MaterialTable
              title=""
              columns={[
                { title: 'File Name', field: 'site_image_file_name' },
                {
                  title: 'Uploaded Date',
                  field: 'site_image_updated_at',
                  render: rowData => moment(rowData.site_image_updated_at).format("ll"),
                  type: 'date'
                },
                {
                  title: 'File',
                  field: 'site_image',
                  render: rowData => {
                    return (
                      <CloudDownload onClick={() => window.open(rowData.site_image)} />
                    )
                  },
                  grouping: false
                },

              ]}
              data={siteimages}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                grouping: true
              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ siteimages: [], siteGalaryDialog: false })} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={() => this.setState({ siteupdates: [], siteUpdateDialog: false })} aria-labelledby="customized-dialog-title" open={siteUpdateDialog}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ siteupdates: [], siteUpdateDialog: false })}>
            Site Updates
						</DialogTitle>
          <DialogContent dividers>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => rowData.created_at !== null ? moment(rowData.created_at).format("ll") : null,
                  type: 'date'
                },
              ]}
              detailPanel={[
                {
                  icon: 'attachment',
                  tooltip: 'Files',
                  render: rowData => {
                    return (
                      <>
                      {console.log('rowData',rowData)}
                        <Grid container spacing={1} style={{ margin: '15px' }}>
                          {
                            rowData.files.length !== 0 ?
                              rowData.files.map(file =>
                                (
                                  <React.Fragment key={file.id}>
                                    <Grid item xs={8}>{file.file_file_name}</Grid>
                                    <Grid item className={classes.alignCenter} xs={3}>
                                      <a href={file.file} target="_blank" download={file.file_file_name}>
                                        <CloudDownload color="primary" />
                                      </a>
                                    </Grid>
                                  </React.Fragment>
                                )
                              )
                              :
                              <div> No files found. </div>
                          }
                        </Grid>
                      </>
                    )
                  },
                }]}
              data={siteupdates}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                grouping: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ siteupdates: [], siteUpdateDialog: false })} color="primary">
              Close
							</Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteMeasurement);
