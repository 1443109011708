import React ,{ Component }from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getProjectFinalBoqs, getMeasurementRequests, getSiteSupervisorList, getQCStatus } from '../../../api';
import CircularIndeterminate from '../../Loader';
import MaterialTable from 'material-table';
import { Grid, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core'
import { Collections, CloudDownload, Ballot } from '@material-ui/icons';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: '100%',
		padding:10,
	},
	rootTabPanel: {
		backgroundColor: theme.palette.background.paper,
		width: '100%',
		overflowX: 'auto',
	},
	button: {
		margin: theme.spacing(),
	},
	alignCenter:{
		textAlign: 'center',
	},
});

class ProjectBOQJourney extends Component {

	state = {
		AllBoqs:[],
		expanded: false,
		setExpanded: false,
		measurementRequests: [],
		isLoading: true,
		siteimages: [],
		siteUpdates: [],
		siteUpdateDialog: false,
		siteGalaryDialog: false,
		siteSupervisors: [],
		QCStatus: {}
	}

	componentDidMount(){
    const { token, project} = this.props;
    getProjectFinalBoqs(token, project.id)
    .then(response => {
			if (response.status === 200){
        this.setState({AllBoqs:response.data})
      }
      else{
        alert(response.message);
      }
    })
		.catch(error => {
			this.setState({AllBoqs:[]});
			console.log(error);
		});
		this._getSiteSupervisor();
		this._getQCStatus();
		this._getMeasurementRequest();
  }

  _getMeasurementRequest = () => {
    const { token, project} = this.props;
    let projectId = project != null ? project.id : null; 
    getMeasurementRequests(token, projectId)
    .then(response => {
      if (response.status === 200){
        this.setState({measurementRequests: response.data})
      }
      else{
        alert(response.data.message)
			}
			this.setState({isLoading: false})
    })
    .catch(error => { 
			console.log(error)
			this.setState({isLoading: false})
		})
	}
	
	_getSiteSupervisor = () => {
		getSiteSupervisorList(this.props.token, 'site_supervisor' )
      .then(response => {
        if (response.data) {
          let siteSupervisors = response.data.map(user => ({
            value: user.id,
            label: user.name,
          }))
          this.setState({siteSupervisors, isLoading: false})
        }
      })
      .catch(error => console.log(error))
	}

	_getQCStatus = () => {
		getQCStatus(this.props.token, this.props.project.id)
		.then(response => {
			if (response.data){
				this.setState({ QCStatus: response.data })
			}
		})
		.catch(error => { console.log(error) })
	}

	render(){
		const { classes, project } = this.props;
		const { AllBoqs, isLoading, siteimages, siteUpdateDialog, siteUpdates, siteGalaryDialog, siteSupervisors, measurementRequests, QCStatus } = this.state;
	
    var finalBoqs = AllBoqs.filter(function(boq){
      return(boq.wip_status === "10_50_percent")
		});
		
		return(
			<React.Fragment>
			{
				isLoading ? 
					<CircularIndeterminate/>
				:
				<div className={classes.root}>
					<Table className={classes.table}>
						<TableBody>
							<TableRow>
								<TableCell align="left">
									<strong style={{color:'gray'}}>BOQ Journey:</strong><br/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">
									<strong>Lead ID:</strong> <br/>
									{project.lead_id}</TableCell>
								<TableCell align="left">
									<strong>Client Name:</strong> <br/>
									{project.lead.name}</TableCell>
								<TableCell align="left">
									<strong>Community Manager:</strong> <br/>
									{project.assigned_cm.name}</TableCell>
								<TableCell align="left">
									<strong>Designer:</strong> <br/>
									{project.assigned_designer.name}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Typography className={classes.heading}>Final BOQs:</Typography>
					{   
						finalBoqs.length !== 0 ?
							finalBoqs.map((b, index)=>{
								return(
									<Table key={index} className={classes.table} style={{backgroundColor:'#F5F5F5'}}>
										<TableBody>
											<TableRow>
												<TableCell align="left">
													<strong style={{color:'gray'}}>BOQ Journey:</strong><br/>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">
													<strong>BOQ No.</strong> <br/>
													{b.reference_number}</TableCell>
												<TableCell align="left">
													<strong>10% Payment On</strong> <br/>
													{b.boq_journey.date_10_percent_payment && moment(b.boq_journey.date_10_percent_payment).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>Category Handover On</strong> <br/>
													{b.boq_journey.category_handover_date && moment(b.boq_journey.category_handover_date).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>Category Acceptance On</strong> <br/>
													{b.boq_journey.category_acceptance_date && moment(b.boq_journey.category_acceptance_date).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>Client Sharing On</strong> <br/>
													{b.boq_journey.client_share_date && moment(b.boq_journey.client_share_date).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>Payment Addition On</strong> <br/>
													{b.boq_journey.payment_addition_date && moment(b.boq_journey.payment_addition_date).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>Finance Verification On</strong> <br/>
													{b.boq_journey.finance_verification_date && moment(b.boq_journey.finance_verification_date).format('ll') || 'NA'}</TableCell>
												<TableCell align="left">
													<strong>CM approval date for less than 50%</strong> <br/>
													{b.boq_journey.approval_for_less_than_50_percent_pay && moment(b.boq_journey.approval_for_less_than_50_percent_pay).format('ll') || 'NA'}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								);
							})
						:
						<div className={classes.heading}> No Final BOQs found!</div>
					}
					<br/>
					<Table className={classes.table}>
						<TableBody>
							<TableRow>
								<TableCell align="left">
									<strong style={{color:'gray'}}>Site Validation / Line Marking / Site Measurement:</strong><br/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="center">
									<strong>Site Validation Request Date</strong>
								</TableCell>
								<TableCell align="center">
									<strong>Assigned Site Supervisor</strong>
								</TableCell>
								<TableCell align="center">
									<strong>Site Validation Outputs</strong>
								</TableCell>
								<TableCell align="center">
									<strong>Site Updates</strong>
								</TableCell>
								<TableCell align="center">
									<strong>Completion Date for Site Validation</strong>
								</TableCell>
							</TableRow>
							
								{	measurementRequests.length !== 0 &&
										measurementRequests.map((m) => {
											let ss = siteSupervisors.filter((i) => {
												if(m.ss_assigned_details !== undefined && m.ss_assigned_details !== null && i.value === m.ss_assigned_details.office_user_id) {
													return i
												}})
											return(	
												<TableRow>
													<TableCell align="center">
														{ moment(m.created_at).format("ll") }
													</TableCell>
													<TableCell align="center">
														{
															m.request_status === 'complete' ?
																""
															:
															`${ ss.length !== 0 ? ss[0].label : "NA" }`
														}
													</TableCell>
													<TableCell align="center">
														<Badge
															className={classes.margin}
															badgeContent={m.site_images.length || 0}
															color="primary"
															style={{ cursor: 'pointer' }}
															showZero
															onClick={() => m.site_images.length === 0 ? alert("No files to view") :this.setState({siteimages: m.site_images , siteGalaryDialog: true})}
														>
															<Collections />
														</Badge>
													</TableCell>
													<TableCell align="center">
														<Badge
															className={classes.margin}
															badgeContent={m.office_updates.length || 0}
															color="primary"
															style={{ cursor: 'pointer' }}
															showZero
															onClick={() => m.office_updates.length === 0 ? alert("No updates") :this.setState({siteUpdates: m.office_updates , siteUpdateDialog: true})}
														>
															<Ballot />
														</Badge>
													</TableCell>
													<TableCell align="center">
														{ (m.ss_assigned_details != null && m.ss_assigned_details.completed_at) ? moment(m.ss_assigned_details.completed_at).format("ll") : 'NA' }
													</TableCell>
												</TableRow>
											)
										})
									|| 
										<div className={classes.alignCenter}> No measurement request found!</div>
								}
						</TableBody>
					</Table>
					<br/>
							
					<Table className={classes.table}>
						<TableBody>	
							<TableRow>
								<TableCell>Design QC Status:</TableCell>
								<TableCell>{ QCStatus.design_qc !== null ? (QCStatus.design_qc === true ? 'Pass' : 'Fail') : 'NA' }</TableCell>                                
							</TableRow>
							<TableRow>
								<TableCell>Cost QC Status:</TableCell>
								<TableCell>{ QCStatus.cost_qc !== null ? (QCStatus.cost_qc === true ? 'Pass' : 'Fail') : 'NA' }</TableCell>                                
							</TableRow>
							<TableRow>
								<TableCell>Technician QC Status:</TableCell>
								<TableCell>{ QCStatus.tech_qc !== null ? (QCStatus.tech_qc === true ? 'Pass' : 'Fail') : 'NA' }</TableCell>                                
							</TableRow>
						</TableBody>
					</Table>
					<br/>
					<Dialog onClose={() => this.setState({siteimages: [] , siteGalaryDialog: false})} aria-labelledby="customized-dialog-title" open={siteGalaryDialog}>
						<DialogTitle id="customized-dialog-title" onClose={() => this.setState({siteimages: [] , siteGalaryDialog: false})}>
							Site Galary
						</DialogTitle>
						<DialogContent dividers>
							<MaterialTable
								title=""
								columns={[
									{ title: 'File Name', field: 'site_image_file_name' },
									{
										title: 'Uploaded Date',
										field: 'site_image_updated_at',
										render: rowData => moment(rowData.site_image_updated_at).format("ll"),
										type: 'date'
									},
									{
										title: 'File',
										field: 'site_image',
										render: rowData => {
											return (
												<CloudDownload onClick={() => window.open(rowData.site_image)}/>
											)
										},
										grouping: false
									},
									
								]}
								data={siteimages}
								options={{
									actionsColumnIndex: -1,
									filtering: true,
									exportButton: true,
									grouping: true
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({siteimages: [] , siteGalaryDialog: false})} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>
							
					<Dialog onClose={() => this.setState({siteUpdates: [] , siteUpdateDialog: false})} aria-labelledby="customized-dialog-title" open={siteUpdateDialog}>
						<DialogTitle id="customized-dialog-title" onClose={() => this.setState({siteUpdates: [] , siteUpdateDialog: false})}>
							Site Updates
						</DialogTitle>
						<DialogContent dividers>
							<MaterialTable
								title=""
								columns={[
									{ title: 'Remark', field: 'remark' },
									{
										title: 'Created on',
										field: 'created_at',
										render: rowData => rowData.created_at !== null ? moment(rowData.created_at).format("ll") : null,
										type: 'date'
									},
								]}
								detailPanel={[
									{
										icon: 'attachment',
										tooltip: 'Files',
										render: rowData => {
										return (
											<>
												<Grid container spacing={1} style={{ margin: '15px' }}>
													{
														rowData.files.length !== 0 ?
															rowData.files.map(file =>
																(
																	<React.Fragment key={file.id}>
																		<Grid item xs={8}>{file.file_file_name}</Grid>
																		<Grid item className={classes.alignCenter} xs={3}>
																			<a href={file.url} target="_blank" download={file.file_file_name}>
																				<CloudDownload color="primary"/>
																			</a>
																		</Grid>
																	</React.Fragment>
																)
															)
														:
														<div> No files found. </div>
													}
												</Grid>
											</>
										)
										},
									}]}
								data={siteUpdates}
								options={{
									actionsColumnIndex: -1,
									filtering: true,
									exportButton: true,
									grouping: true
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({siteUpdates: [] , siteUpdateDialog: false})} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>

				</div>

			}											
		</React.Fragment>
		);
	}

}

const mapStateToProps = state => {
  return {
		token: state.auth.token,
		current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(ProjectBOQJourney);