import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getProjectList } from '../../api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import moment from 'moment';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
})

class ProjectsList extends Component {
  state = {
    ProjectList: [],
    page: 0,
    rowsPerPage: 5,
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    getProjectList(this.props.token)
      .then(response => {
        if (response.status) {
          this.setState({
            ProjectList: response.data,
          });
        }
      })
      .catch(error => console.log(error))
  }


  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { ProjectList } = this.state;
    return (
      <div>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
          Projects List
        </Typography>
        <div className="table-container">
          {ProjectList.length !== 0 &&
            <Paper className="user-list-root">
              <Table className="user-list-table">
                <TableHead>
                  <TableRow>
                    <TableCell numeric style={{ textAlign: 'left' }}>Name</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>City</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Address</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Pin Code</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Client Name</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Client Email</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Client Contact</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Area(Sq.ft)</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ProjectList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(n => {
                    return (
                      <TableRow key={n.id}>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.name}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.city}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.address}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.pin_code}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.client_name}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.client_email}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.client_contact}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{n.area}</TableCell>
                        <TableCell numeric style={{ textAlign: 'left', cursor: 'pointer' }} >{moment(n.end_date).format('ll')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={10}
                      count={ProjectList.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationWrapper}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          }
        </div>




      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(ProjectsList);