import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './ducks';
import './index.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import JssProvider from "react-jss/lib/JssProvider";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// create the store
let store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <JssProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </JssProvider>,
  document.getElementById('root')
);

registerServiceWorker();
