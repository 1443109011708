import React, { useState, useEffect } from 'react';
import {
  getNocDelayedProjects, createNocNotifcation
} from '../../../api';
import { useSelector } from 'react-redux'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, IconButton,
  Checkbox, Tooltip, Chip
} from '@material-ui/core'; 
import CircularIndeterminate from '../../Loader';
import {
  StarBorder, Star,
  AddAlert
} from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
  },
}));

export default function DelayProjectDashboard({ NocNotifications }) {
  const auth = useSelector(state => state.auth)
  const classes = useStyles();
  const [Projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  console.log(NocNotifications)

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      const NocDelayedProjectsRes = await getNocDelayedProjects(auth.token)
      setProjects(NocDelayedProjectsRes.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

  }

  const handleImportantChange = (project_id) => {
    let data = {
      project_id: project_id,
      notification_type: 'important'
    }
    createNocNotifcation(auth.token, data)
    .then(res => {
      if (res.data.is_active) {
        alert("The Project has been marked Stared!")
      } else {
        alert("The Project has been unmarked Stared!")
      }
    })
    .catch(error => console.log(error))
  }

  const handleAddAlert = (project_id) => {

  }

  return (
    <>
    {
      isLoading ?
        <CircularIndeterminate />
        :
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sr No.</TableCell>
                  <TableCell align="left">Customer</TableCell>
                  <TableCell>
                    <TableRow>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>BOQ No</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>10% Payment</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>Ageing</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>Site Measurement</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>BOQ QC</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>Design Qc</TableCell>
                      {/* <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>Customer Walkthrough</TableCell> */}
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>Site Validation</TableCell>
                      <TableCell align="left" style={{ minWidth: '150px', maxWidth: '150px', border: 'none' }}>40% Payment</TableCell>
                    </TableRow>
                  </TableCell>  
                  <TableCell align="left">Comunity Manager</TableCell>
                  <TableCell align="left">Designer</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
                </TableHead>
                  <TableBody>
                  {
                    Projects && Projects.length !== 0 &&
                    Projects.map((project,index) => {
                      return (
                        <TableRow key={project.id}>
                          <TableCell align="left" style={{ verticalAlign: 'top' }}>{index + 1}</TableCell>
                          <TableCell align="left" style={{ verticalAlign: 'top' }}>{project.lead.name} <br /> {project.lead_id} <br /> {project.lead.city}</TableCell>
                          <TableCell>
                            { project.delay_boqs_list && project.delay_boqs_list.length !== 0 &&
                              project.delay_boqs_list.map(boq => {
                                return(
                                  <TableRow key={boq.id}>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>{boq.boq_name}</TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>{boq.finance_approved_at && moment(boq.finance_approved_at).format('lll')}</TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>{boq.finance_approved_at && moment(boq.finance_approved_at).startOf('day').fromNow()}</TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                      {
                                        (project.noc_site_measurement && project.noc_site_measurement.length !== 0) ?
                                          <>
                                            { 
                                              (project.noc_site_measurement.office_user_site_measurement_request && project.noc_site_measurement.office_user_site_measurement_request) ?
                                                <>
                                                  {
                                                    project.noc_site_measurement.office_user_site_measurement_request.completed ?
                                                      `${
                                                        project.noc_site_measurement.office_user_site_measurement_request.completed_at &&
                                                        moment(project.noc_site_measurement.office_user_site_measurement_request.completed_at).format('lll')
                                                      }`
                                                    :
                                                      <Chip label="Pending" variant="outlined" color="secondary" size="small" />
                                                  }
                                                </>  
                                              :
                                                <Chip label="Pending" variant="outlined" color="secondary" size="small" />
                                            }
                                          </>
                                          :
                                          <Chip label="Not scheduled" variant="outlined" color="primary" size="small"/>
                                      }
                                    </TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>BOQ QC</TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                      {
                                        (project.noc_design_qc && project.noc_design_qc.length !== 0) ?
                                          <>
                                            {
                                              project.noc_design_qc.status ?
                                              `${
                                                project.noc_design_qc.updated_at &&
                                                moment(project.noc_design_qc.updated_at).format('lll')
                                              }`
                                                :
                                                <Chip label="Pending" variant="outlined" color="secondary" size="small" />
                                            }
                                          </>
                                          :
                                          <Chip label="Not scheduled" variant="outlined" color="primary" size="small"/>
                                      }
                                    </TableCell>
                                    {/* <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>Customer Walkthrough</TableCell> */}
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                      {
                                        (project.noc_site_validation && project.noc_site_validation.length !== 0) ?
                                          <>
                                            {
                                              project.noc_site_validation.status == "accepted" ?
                                              `${
                                                project.noc_site_validation.status_changed_on &&
                                                moment(project.noc_site_validation.status_changed_on).format('lll')
                                              }`
                                                :
                                                <Chip label="Pending" variant="outlined" color="secondary" size="small" />
                                            }
                                          </>
                                          :
                                          <Chip label="Not scheduled" variant="outlined" color="primary" size="small"/>
                                      }
                                    </TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                      {
                                        boq.finance_approved_at && moment(boq.finance_approved_at).add(30, 'days').calendar()
                                      }
                                    </TableCell>
                                  </TableRow>
                                )
                              }) 
                            }
                          </TableCell>
                          
                          <TableCell align="left" style={{ verticalAlign: 'top' }}>{project.assigned_cm && project.assigned_cm.name}</TableCell>
                          <TableCell align="left" style={{ verticalAlign: 'top' }}>{project.assigned_designer && project.assigned_designer.name}</TableCell>
                          <TableCell align="left" style={{ verticalAlign: 'top' }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Tooltip title="Mark Started">
                                  <Checkbox
                                    checked={false}
                                    onChange={() => handleImportantChange(project.id)}
                                    icon={<StarBorder />} checkedIcon={<Star />}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12}>
                                <Tooltip title="Add Remider">
                                  <IconButton 
                                    aria-label="Add Alert"
                                    onClick={handleAddAlert(project.id)}>
                                    <AddAlert />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
              </TableBody>
            </Table>
          </TableContainer>
        </>
    }
    </>
  );
}
