import axios from '../base';

export function getSiteUpdateListOm(accessToken, selectedPms) {
  return axios.request({
    url: `/api/v1/office_updates/site_update_list_for_om?assigned_pms=${selectedPms}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function assignedPmToOmList(accessToken, id) {
  return axios.request({
    url: `/api/v1/office_users/assigned_pm_to_om_list/${id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getDynamicSiteUpdateFilterDropdowns(accessToken) {
  return axios.request({
    url: `/api/v1/office_updates/site_update_dynamic_filter_om`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getSiteUpdateFilter(accessToken, selectedPms, page_num, search, fromFilterDate, toFilterDate, type, task, vender, siteSupervisor, isClientMom) {
  return axios.request({
    url: `/api/v1/office_updates/site_update_list_for_om?assigned_pms=${selectedPms}&page=${page_num}&search=${search}&from_date=${fromFilterDate}&to_date=${toFilterDate}&type=${type}&task_type=${task}&vendors=${vender}&site_supervisor=${siteSupervisor}&is_client_mom=${isClientMom}`,
    method: 'GET',
    headers: accessToken,
  })
};
