import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  getSiteSupervisorsOfPm, getSupervisorSignInList, getAddress
} from '../../api';
import MaterialTable from 'material-table';
import moment from 'moment'

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  }
})

class SiteSupervisorSignIn extends Component {
  state = {
    SiteSupervisor: [],
    isLoading: true,
    addressLoader: {}
  }

  async componentDidMount() {
    getSupervisorSignInList(this.props.token)
      .then(response => {
        if (response.status) {
          console.log('ssresp', response.data)
          this.getGeoAddress(response.data);
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
    // getSiteSupervisorsOfPm(this.props.token, { id: this.props.current_user.id } )
    // .then(response => {
    //   if (response.status) {
    //     this.setState({
    //       SiteSupervisor: response.data,
    //     });
    //   }
    // })
  }

  async getGeoAddress(supervisorList) {
    const SiteSupervisors = supervisorList.map(async supervisor => {
      if (supervisor.checkin_lat) {
        getAddress(supervisor.checkin_lat, supervisor.checkin_lng)
          .then(response => {
            supervisor.address = response.data.results[0] ? response.data.results[0].formatted_address : null;
            this.setState({ addressLoader: supervisor.address })
          })
          .catch(error => {
            supervisor.address = null;
            console.log(error);
          })
      } else {
        supervisor.address = null;
      }
    })
    const sups = await Promise.all(SiteSupervisors);
    this.setState({ SiteSupervisor: supervisorList, isLoading: false });
    return sups;
  }

  render() {
    const { SiteSupervisor, isLoading } = this.state;
    return (
      <div>
        <MaterialTable
          title="Site Supervisors Sign In/Out"
          isLoading={isLoading}
          columns={[
            { title: 'Site Supervisor', field: 'office_user_name' },
            { title: 'Project', field: 'project_name' },
            {
              title: 'CheckIn',
              field: 'checkin_time',
              render: rowData => rowData.checkin_time !== null ? moment(rowData.checkin_time).format("lll") : null,
              type: 'date'
            },
            {
              title: 'Address', field: 'address',
              render: rowData => rowData && rowData.address !== null ? rowData.address : "Address not available"
            },

          ]}
          data={SiteSupervisor}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            exportButton: true,
            grouping: true
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteSupervisorSignIn);
