import axios from 'axios';

// const HOST_URL = "http://localhost:3001";
// const HOST_URL = "https://officeapi.arrivae.com/";
// const HOST_URL = "http://qa-officeapi.arrivae.com/";
// const HOST_URL = "https://7929567e1519.ngrok.io/"



// configure an axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.reload(true);
  }
  return Promise.reject(error);
});

export default instance;
