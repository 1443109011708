import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { 
  MoodboardPptList
} from '../../api';

import Typography from '@material-ui/core/Typography';
import { 
  loadState
} from '../../utils/storage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import moment from 'moment';

class Moodboard extends Component {
  state = {
    token: loadState(),
    Moodboard: null,
    open: false,
    selectopen: false,
    selectValue: "",
    page: 0,
    rowsPerPage: 25,
  }


  componentDidMount() {
    MoodboardPptList(this.state.token)
    .then((response) => {
      if(response.status) {
        this.setState({Moodboard: response.data});
      }
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  
  render() {
    
    const { Moodboard } = this.state;
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          List of Moodboard PPT shared
        </Typography>
        <div className="table-container">
          { Moodboard !== null &&   <Paper className="user-list-root">
          <Table className="user-list-table">
              <TableHead>
                <TableRow>
                  <TableCell numeric style={{textAlign: 'left'}}>Id</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Client Id</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Client Name</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Designer Id</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Designer Name</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Contact</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Send Date Time</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>PPT URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Moodboard.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(n => {
                  return (
                    <TableRow key={n.id}>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.id}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.client_id}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.client_name || ''}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.designer_id}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.designer_name || ''}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.contact}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{moment(n.created_at).format('llll')}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.url}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={8}
                  count={Moodboard.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationWrapper}
                />
              </TableRow>
            </TableFooter>
            </Table>
          </Paper>
          }
        </div>
      </div>
    );
  }
}

export default Moodboard;