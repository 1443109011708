import axios from "../base";

export function getAllEscalation(accessToken, page, per_page) {
  return axios.request({
    url: "api/v1/manual_escalations?page=" + page + "&per_page=" + per_page,
    method: "GET",
    headers: accessToken,
  });
}

export function getAllEscalationFilters(accessToken) {
  return axios.request({
    url: "api/v1/manual_escalations/get_all_filters",
    method: "GET",
    headers: accessToken,
  });
}

export function updateEscalation(accessToken, escalationId, comment, status) {
  return axios.request({
    url:
      "api/v1/manual_escalations/" + escalationId + "/update_escalation_status",
    method: "PUT",
    headers: accessToken,
    data: {
      comments: comment,
      status: status,
    },
  });
}

export function getEscalationFilter(
  accessToken,
  page,
  per_page,
  sort_column,
  sort_order,
  status,
  store,
  escalation_stage,
  from_date,
  to_date,
  escalation_source
) {
  return axios.request({
    url:
      "api/v1/manual_escalations?page=" +
      page +
      "&per_page=" +
      per_page +
      "&sort_column=" +
      sort_column +
      "&sort_order=" +
      sort_order +
      "&status=" +
      status +
      "&store=" +
      store +
      "&escalation_stage=" +
      escalation_stage +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&escalation_source=" +
      escalation_source,
    method: "GET",
    headers: accessToken,
  });
}

export function getEscalationLogsOnId(
  accessToken,
  id,
  page = 1,
  per_page = ""
) {
  return axios.request({
    url:
      "api/v1/manual_escalation_logs/get_all_manual_escalation_logs_on_escalation_id?escalation_id=" +
      id +
      "&page=" +
      page +
      "&per_page=" +
      per_page,
    method: "GET",
    headers: accessToken,
  });
}
