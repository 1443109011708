import React, { Component } from 'react';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../Loader';
import {
  getResidentialProjectPEList, assignResidentialProjectPE,
  changeProjectEngineerType
} from '../../../api'
import { connect } from 'react-redux';
import {
  Button, Select, MenuItem
} from '@material-ui/core';

class AssignProjectManager extends Component {
  state = {
    isLoading: false,
    PEList: [],
    isMapLoading: false,
    showDialog: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    
    await getResidentialProjectPEList(this.props.token, this.props.project.id)
    .then(res => {
      if (res.status === 200) {
        this.setState({
          PEList: res.data,
          isLoading: false
        })
      }
    })
    .catch(error => {
      console.log(error);
      this.setState({isLoading: false})
    } )
  }

  assignPEtoProject = (office_user_id) => {
    this.setState({ isLoading: true })
    const data = {
      office_user_id: office_user_id,
      project_id: this.props.project.id
    }
    assignResidentialProjectPE(this.props.token, data)
    .then(res => {
      if (res.status === 200) {
        getResidentialProjectPEList(this.props.token, this.props.project.id)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              PEList: res.data,
              isLoading: false
            })
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({isLoading: false})
        } )
      } 
    })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        })
    })
  }

  handleChangePEType = (e, pe) => {
    this.setState({ isLoading: true })
    let data = {
      id: pe.id,
      pe_type: e.target.value
    }
    changeProjectEngineerType(this.props.token, data)
    .then(res => {
    if (res.status === 200) {
      getResidentialProjectPEList(this.props.token, this.props.project.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            PEList: res.data,
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({isLoading: false})
      } )
    }
    })
    .catch(error => {
      console.log(error)
      this.setState({isLoading: false})
    })
  }

  render() {
    const { PEList, isLoading} = this.state;
    return (
      <React.Fragment>
        { isLoading ?
          <CircularIndeterminate/>
          :
          <MaterialTable
            title=""
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects' },
              { title: 'No. 10 - 40% Projects', field: 'pre_40_percent_project_count' },
              { title: 'No. Post 40% Projects', field: 'post_40_percent_project_count' },
              {
                title: 'Type',
                field: 'pe_type',
                render: rowData => <Select
                    value={rowData.pe_type}
                    onChange={(e) => this.handleChangePEType(e,rowData)}
                  >
                    <MenuItem value={'Services'}>Services</MenuItem>
                    <MenuItem value={'Installation'}>Installation</MenuItem>
                  </Select>
              },
            ]}
            data={PEList}
            actions={[
              {
                icon: 'save',
                tooltip: 'Save User',
                onClick: (event, rowData) => this.assignPEtoProject(rowData.id) 
              }
            ]}
            components={{
              Action: props => {
                return (
                  props.data.is_ss_assigned ? 
                  <Button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  Unassign
                </Button>
                  :
                <Button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Assign
                </Button>
              )
              }
            }}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true
            }}
          />
        }
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default connect(mapStateToProps)(AssignProjectManager);