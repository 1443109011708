import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminDashboard from './admin/AdminDashboard';
import RegDashboard from './Registered/RegDashboard';
import SSDashboard from './SiteSupervisor/SSDashboard';
import SMDashboard from './SiteManager/SMDashboard';
import PPMDashboard from './PPManager/PPMDashboard';
import PMDashboard from './ProjectManager/PMDashboard';
import PEDashboard from './ProjectEngineer/PEDashboard';
import DesignerDashboard from './Designer/DesignerDashboard';
import FinDashboard from './Finance/FinDashboard';
import OperationalManagerDashboard from './OperationalManager/OperationalManagerDashboard';
import GeneralManagerDashboard from './GeneralManager/GeneralManagerDashboard';
import NocLeadDashboard from './NocLead/NocLeadDashboard';
import NocExecutiveDashboard from './NocExecutive/NocExecutiveDashboard';
import VendorDashboard from './Vendor/VendorDashboard';

import NotFound from '../components/NotFound';

import {
  Route,
  Switch,
} from 'react-router-dom';

class Dashboard extends Component {
  componentDidMount() {
    const role_id = this.props.auth.current_user.office_role_id
    const path_name = this.props.location.pathname
    console.log("pathName:", path_name, "RoleId:", role_id);
    if (path_name === "/") {
      switch (role_id) {
        case 1:
          if (path_name === "/" || path_name === "/admin") {
            this.props.history.push('/admin/users')
          }
          break;
        case 2:
          this.props.history.push('sm')
          break;
        case 3:
          this.props.history.push('ss')
          break;
        case 4:
          this.props.history.push('reg')
          break;
        case 5:
          this.props.history.push('ppm')
          break;
        case 6:
          this.props.history.push('pm')
          break;
        case 7:
          if (path_name === "/" || path_name === "/pe") {
            this.props.history.push('/pe/projects-list/1')
          }
          break;
        case 8:
          this.props.history.push('designer')
          break;
        case 9:
          this.props.history.push('designer')
          break;
        case 10:
          this.props.history.push('fin')
          break;
        case 11:
          this.props.history.push('/om/projects-list/1')
          break;
        case 12:
          this.props.history.push('/gm/projects')
          break;
        case 13:
          if (path_name === "/" || path_name === "/ve") {
            this.props.history.push('/ve/projects-list/1')
          }
          break;
        case 14:
          this.props.history.push('/noc_exe/dashbaord')
          break;
        default:
          this.props.history.push('not-found')
          break;
      }
    }
  }

  render() {
    const role_id = this.props.auth.current_user.office_role_id
    var path_role_name
    var component_name

    switch (role_id) {
      case 1:
        path_role_name = 'admin'
        component_name = AdminDashboard
        break;
      case 2:
        path_role_name = 'sm'
        component_name = SMDashboard
        break;
      case 3:
        path_role_name = 'ss'
        component_name = SSDashboard
        break;
      case 4:
        path_role_name = 'reg'
        component_name = RegDashboard
        break;
      case 5:
        path_role_name = 'ppm'
        component_name = PPMDashboard
        break;
      case 6:
        path_role_name = 'pm'
        component_name = PMDashboard
        break;
      case 7:
        path_role_name = 'pe'
        component_name = PEDashboard
        break;
      case 8:
        path_role_name = 'designer'
        component_name = DesignerDashboard
        break;
      case 9:
        path_role_name = 'designer'
        component_name = DesignerDashboard
        break;
      case 10:
        path_role_name = 'fin'
        component_name = FinDashboard
        break;
      case 11:
        path_role_name = 'om'
        component_name = OperationalManagerDashboard
        break;
      case 12:
        path_role_name = 'gm'
        component_name = GeneralManagerDashboard
        break;
      case 13:
        path_role_name = 've'
        component_name = VendorDashboard
        break;
      case 14:
        path_role_name = 'noc_exe'
        component_name = NocLeadDashboard
        break;
      default:
        this.props.history.push('not-found')
        break;
    }

    return (
      <Route
        path="/"
        render={({ match: { url } }) => {
          return (
            <Switch>
              <Route path={url + path_role_name} component={component_name} />
              <Route component={NotFound} />
            </Switch>
          )
        }
        }
      />
    )

  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(Dashboard);
