import axios from '../base';

export function getTaskList(accessToken, params) {
  return axios.request({
    url: '/api/v1/office_tasks',
    method: 'GET',
    headers: accessToken,
    params: params
  })
};

export function getOfficeSubTaskList(accessToken, params, id) {
  return axios.request({
    url: `/api/v1/office_tasks/${id}/sub_tasks`,
    method: 'GET',
    headers: accessToken,
    params: params
  })
};

export function createTask(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_tasks',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function createOfficeSubTask(accessToken, data, id) {
  return axios.request({
    url: `/api/v1/office_tasks/${id}/sub_task_create`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function updateTask(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_tasks/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function deleteTask(accessToken, id) {
  return axios.request({
    url: `/api/v1/office_tasks/${id}`,
    method: 'DELETE',
    headers: accessToken
  })
};

export function getProjectTask(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_tasks/get_project_tasks/${projectId}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getTaskDetails(accessToken, taskId) {
  return axios.request({
    url: `/api/v1/office_tasks/${taskId}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function uploadPertFile(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_tasks/upload_pert_file',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function filterTaskByDate(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_tasks/get_project_tasks/${data.office_project_id}`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function getSubTaskList(accessToken, office_task_id) {
  return axios.request({
    url: `/api/v1/office_sub_tasks/${office_task_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function createNewSubTask(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_sub_tasks',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};


export function disableOfficeSubTask(accessToken, id) {
  return axios.request({
    url: `/api/v1/office_sub_tasks/${id}`,
    method: 'DELETE',
    headers: accessToken
  })
};

export function updateSubTask(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_sub_tasks/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function getOfficeSpaceList(accessToken) {
  return axios.request({
    url: `/api/v1/office_tasks/office_space_list/${1}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getProjectVendorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/vendors_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getProjectSiteSupervisorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/site_supervisor_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getProjectSiteSupervisorAndPMList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/pm_site_supervisor_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};
export function getProjectSSVendorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/vendor_site_supervisor_list//${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getTaskUpdatesList(accessToken, taskId) {
  return axios.request({
    url: `/api/v1/office_tasks/task_updates/${taskId}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function taskBulkUpload(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_tasks/import_from_excel`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function serviceTaskReport(accessToken, taskId) {
  return axios.request({
    url: `/api/v1/office_tasks/get_services_installation_tasks_list_report/${taskId}`,
    method: 'GET',
    headers: accessToken,
  })
}

export function shareWithCustomer(accessToken, taskId) {
  return axios.request({
    url: `/api/v1/office_tasks/send_services_installation_tasks_list_report/${taskId}`,
    method: 'GET',
    headers: accessToken,
  })
}

export function getExportAllTasksBulkUploadReport(accessToken, projectId) {
  return axios.request({
    url: `api/v1/office_tasks/get_all_tasks_bulk_upload_report/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function serviceTaskReportFilter(accessToken, search, projectId,spaceId,superVisorId,vendorFiltId,dependFiltId,statusFiltId,scheduleFiltName,fromFiltDate,toFiltDate,service_type,lineFiltitem) {

  return axios.request({
    url: `api/v1/office_tasks/filter_tasks_list?project_id=${projectId}&page=1&per_page=1000&type=${service_type}&search=${search}&space=${spaceId}&site_supervisor=${superVisorId}&vendor=${vendorFiltId}&dependent_on=${dependFiltId}&schedule_type=${scheduleFiltName}&line_item=${lineFiltitem}&from_date=${fromFiltDate}&to_date=${toFiltDate}&status=${statusFiltId}&sort_column=&sort_order=`,
    method: 'GET',
    headers: accessToken,
  })
}

export function shareEmailWithAny(accessToken, projectId, shareToEmails) {
  return axios.request({
    url: `api/v1/office_tasks/send_services_installation_tasks_list_report_before_sharing?project_id=${projectId}&emails=${shareToEmails}`,
    method: 'POST',
    headers: accessToken,
  })
}
