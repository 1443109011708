
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';                                    
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProjectsIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import ClientIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { loadAdmin, switchToAdmin } from '../../utils/storage';
import ProjectList from './ProjectList';
import ProjectDetails from './Residential/ResidentialProjectDetails';
import SiteSupervisorList from './SiteSupervisorList';
import VendorList from './VendorList';
import Tooltip from '@material-ui/core/Tooltip';  
import SiteMeasurementList from './Residential/SiteMeasurement';
import ProjectManagerList from './ProjectManagerList'; 
import Profile from '../Shared/Profile';    
import compose from 'recompose/compose';
import { connect } from 'react-redux'; 
import Avatar from '@material-ui/core/Avatar';
import NotFound from '../../components/NotFound';
import CommunityManagerList from './CommunityManagerList';
import {
  Switch,
  Route,
} from 'react-router-dom'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
});

class GeneralManagerDashboard extends React.Component {
  state = {
    open: false,
    isAdmin: loadAdmin(),
  };

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    localStorage.clear();
    document.location.reload(true);
  }

  switchBackToAdmin = () => {
    switchToAdmin();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
              { this.activeRoute('profile') && 'Profile' }
              { this.activeRoute('projects') && 'Projects List' }
              { this.activeRoute('site-supervisor') && 'Site Supervisors List' }
              { this.activeRoute('project-managers') && 'Project Managers' }
              { this.activeRoute('community-managers') && 'Community Managers' }
              { this.activeRoute('vendors') && 'Vendors List' }
              { this.activeRoute('site-measurements') && 'Site Measurement / Line Marking Requests' }
              </Typography>
              
              <Typography color="inherit">
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    { this.props.auth.current_user.name || "User" }
                  </Typography> <br />
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    General Manager
                  </Typography>
                </React.Fragment>
              </Typography>
              <Typography color="inherit">
                {this.state.isAdmin? <Button color="inherit" onClick={this.switchBackToAdmin} className={classes.button}>Switch back to admin</Button>:
                  <Button color="inherit" onClick={this.logout} className={classes.button}>
                    <Tooltip title="Logout">
                      <PowerIcon style={{ color: 'white', '&:hover': { background: 'black' } }} />
                    </Tooltip>
                  </Button>}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem selected={this.activeRoute('projects')} button onClick={() => this.props.history.push('/gm/projects')}>
                <Tooltip title="Projects">
                  <ListItemIcon>
                    <ProjectsIcon style={{ color: this.activeRoute('projects') ? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Projects" />
              </ListItem> 
              <ListItem selected={this.activeRoute('project-managers')} button onClick={() => this.props.history.push('/gm/project-managers')}>
                <Tooltip title="Project Managers">
                  <ListItemIcon>
                    <ClientIcon style={{ color: this.activeRoute('project-managers')? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Project Managers" />
              </ListItem>
              
              <ListItem selected={this.activeRoute('site-supervisors')} button onClick={() => this.props.history.push('/gm/site-supervisors')}>
                <Tooltip title="SiteSupervisor">
                  <ListItemIcon>
                    <SupervisorIcon style={{ color: this.activeRoute('site-supervisors')? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="SiteSupervisor" />
              </ListItem>
              
              <ListItem selected={this.activeRoute('vendors')} button onClick={() => this.props.history.push('/gm/vendors')}>
                <Tooltip title="Vendor">
                  <ListItemIcon>
                    <PersonIcon style={{ color: this.activeRoute('vendors')? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Vendor" />
              </ListItem> 

              <ListItem selected={this.activeRoute('site-measurements')} button onClick={() => this.props.history.push('/gm/site-measurements')}>
                <Tooltip title="Site Measurements">
                  <ListItemIcon>
                    <ControlCameraIcon style={{ color: this.activeRoute('site-measurements')? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Site Measurements" />
              </ListItem>
             
            </List>
            <List style={{ position: 'absolute', bottom: '0' }}>
            <Divider/>
              <ListItem selected={this.activeRoute('profile')} button onClick={() => this.props.history.push('/gm/profile')}>
                <Tooltip title="Profile">
                  <ListItemIcon>
                    {this.props.auth.current_user.name ? <Avatar className={classes.purpleAvatar}>{this.props.auth.current_user.name[0].toUpperCase()}</Avatar> : <PersonIcon style={{ color: this.activeRoute('profile')? '#007bff': '#868e95'}}/>}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={ this.props.auth.current_user.name || "User Profile" }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ display: 'inline' }}
                        color="textPrimary"
                      >
                        General Manager
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>

          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Route
              path={"/gm"}
              render={({ match: { url } }) => {
                return (
                  <Switch>
                    <Route path={url + '/profile'} component={Profile}/>
                    <Route path={url + '/projects/:project_id'} component={ProjectDetails}/>
                    <Route path={url + '/projects'} component={ProjectList} />
                    <Route path={url + '/project-managers'} component={ProjectManagerList} />
                    <Route path={url + '/site-supervisors'} component={SiteSupervisorList} />
                    <Route path={url + '/vendors'} component={VendorList} />
                    <Route path={url + '/site-measurements'} component={SiteMeasurementList}/>
                    <Route component={NotFound} />
                  </Switch>
                )}
              }
            />
          </main>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(GeneralManagerDashboard);