import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProjectFiles from "./ProjectFiles";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import {
  getResidentialProjectDetails,
  getProjectVendorList,
} from "../../../../api/VENDOR/project";
import CircularIndeterminate from "../../../Loader";
import LaborReport from "./LaborReport";
import ClientMeetingMoM from "./ClientMeetingMoM";
import SiteUpdate from "./SiteUpdate";
import TaskList from "./TaskList";
import ProjectHandover from "./ProjectHandover";
import PaymentMilestone from "../../../ProjectEngineer/Projects/Residential/PaymentMilestone";
import AssignVendor from "../../../ProjectEngineer/Projects/Residential/AssignVendor";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "5px",
  },
});

class Project extends Component {
  state = {
    value: parseInt(window.location.hash.split("#")[1]) || 0,
    isLoading: true,
    project: [],
    projectStatus: null,
    VendorOption: [],
    vendorData: [],
  };
  componentDidMount() {
    let url = new URL(window.location.href);
    // let HasLocation = window.location && window.location.hash && window.location.hash.split('#')[1];
    // if(HasLocation) {
    //   console.log("HasLocation value is",HasLocation)
    //   this.setState({
    //     value:HasLocation || 0
    //   });
    // }
    let project_status = url.searchParams.get("project_status") || null;
    const index = url?.href?.indexOf("project_status=50_percent");
    if (index > -1) {
      project_status = "50_percent";
    }
    if (project_status !== null) {
      this.setState({ projectStatus: project_status });
    }
    this.getProjectDetails();
    this.getVendorOptionList();
  }
  getProjectDetails = () => {
    let projectId = this.props.match.params.project_id || undefined;
    getResidentialProjectDetails(this.props.token, projectId)
      .then((res) => {
        console.log("SomeeeeeeData", res.data);
        if (res.status === 200) {
          this.setState({
            isLoading: false,
            project: res.data,
            projectId,
          });
        }
      })
      .catch((error) => console.log(error));
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  getVendorOptionList = () => {
    let projectId = this.props.match.params.project_id || undefined;
    getProjectVendorList(this.props.token, projectId)
      .then((res) => {
        if (res.status) {
          let VendorOption = {};
          res.data.map((i) => (VendorOption[i.id] = i.name));
          this.setState({ VendorOption, vendorData: res.data });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeTab = (tabName, proj) => {
    this.props.history.push({
      // pathname: `/pe/projects/${this.state.projectId || this.props?.location?.state?.project?.id}?project_status=${this.props?.location?.state?.ProjectStatus}#${tabName}`,
      pathname: `/ve/projects/${
        this.state.projectId || this.props?.location?.state?.project?.id
      }`,
      search: `?project_status=${this.props?.location?.state?.ProjectStatus}#${tabName}`,
      state: {
        offset: this.props?.location?.state.offset,
        tabNumber: this.props?.location?.state.tabNumber,
        project: this.props?.location?.state?.project?.id,
        ProjectStatus: this.props?.location?.state?.ProjectStatus,
      },
    });
    if (proj == "projectSchedule") {
      this.getVendorOptionList();
      this.getProjectDetails();
    }
  };

  render() {
    const {
      isLoading,
      projectId,
      project,
      projectStatus,
      VendorOption,
      vendorData,
    } = this.state;
    const { offset, tabNumber } = this.props.location.state;
    console.log(
      "checking for props :",
      this.props,
      "vendorOpion value",
      VendorOption,
      "Vendordata value",
      vendorData
    );
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "5px" }}>
          <Link
            color="inherit"
            href={`/ve/projects-list/${offset}#${tabNumber}-${projectStatus}`}
          >
            Projects List
          </Link>
          <Typography color="textPrimary">
            {(project.lead && project.lead.name) || "Project"}
          </Typography>
        </Breadcrumbs>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          // <React.Fragment>
          //   {((project.length !== 0 && project.payment_stage.length === 1) &&
          //     <React.Fragment>
          //       <AppBar position="static" color="default">
          //         <Tabs
          //           value={this.state.value}
          //           onChange={this.handleChange}
          //           indicatorColor="primary"
          //           textColor="primary"
          //           variant="scrollable"
          //           scrollButtons="on"
          //         >
          //           <Tab label="Files" />
          //           <Tab label="Assign Vendor" />
          //         </Tabs>
          //       </AppBar>
          //       <SwipeableViews
          //         index={this.state.value}
          //         onChangeIndex={this.handleChangeIndex}
          //       >
          //         {/* <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()} /></TabContainer> */}
          //       </SwipeableViews>
          //     </React.Fragment>
          //   )
          //     ||
          <React.Fragment>
            {(project.length !== 0 && (project.closure == true || project.pushed_to_production == true) && (
              <div>
                {project.office_project_handover_details !== undefined &&
                project.office_project_handover_details.length !== 0 ? (
                  <>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          label="Project Handover"
                          onClick={() => this.onChangeTab(0)}
                        />
                        <Tab
                          label="Final Files"
                          onClick={() => this.onChangeTab(1)}
                        />
                        <Tab
                          label="Project Schedule"
                          onClick={() => this.onChangeTab(2, "projectSchedule")}
                        />
                        <Tab
                          label="Daily Labour Report"
                          onClick={() => this.onChangeTab(3)}
                        />
                        <Tab
                          label="Client Meeting MOM"
                          onClick={() => this.onChangeTab(4)}
                        />
                        <Tab
                          label="Site Update"
                          onClick={() => this.onChangeTab(5)}
                        />
                        <Tab
                          label="Payment Milestones"
                          onClick={() => this.onChangeTab(6)}
                        />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer>
                        <ProjectHandover project={project} />
                      </TabContainer>

                      <TabContainer>
                        <ProjectFiles project={project} />
                      </TabContainer>
                      <TabContainer>
                        <TaskList
                          project={project}
                          VendorOption={VendorOption}
                        />
                      </TabContainer>
                      <TabContainer>
                        <LaborReport
                          project={project}
                          VendorOption={VendorOption}
                        />
                      </TabContainer>
                      <TabContainer>
                        <ClientMeetingMoM project={project} />
                      </TabContainer>
                      <TabContainer>
                        <SiteUpdate project={project} />
                      </TabContainer>
                      <TabContainer>
                        <PaymentMilestone
                          vendorData={vendorData}
                          project={project}
                        />
                      </TabContainer>
                      {/* <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()} /></TabContainer> */}
                    </SwipeableViews>
                  </>
                ) : (
                  <>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          label="Final Files"
                          onClick={() => this.onChangeTab(0)}
                        />
                        <Tab
                          label="Project Schedule"
                          onClick={() => this.onChangeTab(1)}
                        />
                        <Tab
                          label="Daily Labour Report"
                          onClick={() => this.onChangeTab(2)}
                        />
                        <Tab
                          label="Client Meeting MOM"
                          onClick={() => this.onChangeTab(3)}
                        />
                        <Tab
                          label="Site Update"
                          onClick={() => this.onChangeTab(4)}
                        />
                        <Tab
                          label="Payment Milestones"
                          onClick={() => this.onChangeTab(5)}
                        />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer>
                        {this.state.value === 0 && (
                          <ProjectFiles project={project} />
                        )}
                      </TabContainer>
                      <TabContainer>
                        {this.state.value === 1 && (
                          <TaskList
                            project={project}
                            VendorOption={VendorOption}
                          />
                        )}
                      </TabContainer>
                      <TabContainer>
                        {this.state.value === 2 && (
                          <LaborReport
                            project={project}
                            VendorOption={VendorOption}
                          />
                        )}
                      </TabContainer>
                      <TabContainer>
                        {this.state.value === 3 && (
                          <ClientMeetingMoM project={project} />
                        )}
                      </TabContainer>
                      <TabContainer>
                        {this.state.value === 4 && (
                          <SiteUpdate project={project} />
                        )}
                      </TabContainer>
                      <TabContainer>
                        {this.state.value === 5 && (
                          <PaymentMilestone
                            vendorData={vendorData}
                            project={project}
                          />
                        )}
                      </TabContainer>
                      {/* <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()} /></TabContainer> */}
                    </SwipeableViews>
                  </>
                )}
              </div>
            )) || <div>No Project Found</div>}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(Project);
