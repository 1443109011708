import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  createTask,
  getTaskList,
  getOfficeSpaceList,
  getProjectSiteSupervisorList,
  updateTask,
  deleteTask,
  getTaskUpdatesList
} from '../../../api';
import { AppBar, Tabs, Tab, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class TaskList extends Component {
  state = {
    value: 0,
    TaskList: [],
    selectedTask: '',
    dependentOnOption: [],
    VendorOption: this.props.VendorOption,
    SiteSupervisorOption: [],
    openDialog: false,
    TaskUpdates: []
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ VendorOption: nextProps.VendorOption });
  }

  async componentDidMount() {
    if (this.props.project) {
      try {
        const SpaceListRes = await getOfficeSpaceList(this.props.auth.token)
        const TaskListRes = await getTaskList(this.props.auth.token, { project_id: this.props.project.id })
        const SiteSupervisorListRes = await getProjectSiteSupervisorList(this.props.auth.token, this.props.project.id)

        let SpaceOptions = {}
        SpaceListRes.data.map(i => SpaceOptions[i.id] = i.name)
        let dependentOnOption = {}
        TaskListRes.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

        this.setState({
          TaskList: TaskListRes.data,
          SpaceList: SpaceListRes.data,
          SpaceOptions,
          dependentOnOption,
          SiteSupervisorOption
        })
      } catch (error) {
        console.log(error)
      }

    }
  }

  status = (id) => {
    switch (id) {
      case 0:
        return "Not Initiated";
      case 1:
        return "Initiated";
      case 2:
        return "Completed";
      default:
        break;
    }
  }

  materialDeliveryStatus = (id) => {
    switch (id) {
      case 0:
        return "Not Delivered";
      case 1:
        return "Partial Delivery";
      case 2:
        return "Delivered";
      default:
        break;
    }
  }

  servicesValidation = (data) => {
    if (
      (data.name !== "" && data.name !== null && data.name !== undefined) &&
      (data.description !== "" && data.description !== null && data.description !== undefined) &&
      (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
      (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
      (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined) &&
      (data.plan_start_date_time !== "" && data.plan_start_date_time !== null && data.plan_start_date_time !== undefined) &&
      (data.plan_end_date_time !== "" && data.plan_end_date_time !== null && data.plan_end_date_time !== undefined) &&
      (data.start_date_time !== "" && data.start_date_time !== null && data.start_date_time !== undefined) &&
      (data.end_date_time !== "" && data.end_date_time !== null && data.end_date_time !== undefined) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) &&
      (data.status !== "" && data.status !== null && data.status !== undefined)
    ) {
      let plan_start_date = moment(data.plan_start_date_time)
      let plan_end_date = moment(data.plan_end_date_time)
      let start_date = moment(data.start_date_time)
      let end_date = moment(data.end_date_time)
      if (
        (plan_end_date.diff(plan_start_date, 'days') >= 0) &&
        (end_date.diff(start_date, 'days') >= 0)
      ) {
        if (!isNaN(data.quantity) && data.quantity > 0) {
          return true
        } else {
          alert('Invalid Quantity')
          return false
        }
      } else {
        alert('End date cannot be greater than Start Date')
        return false
      }
    } else {
      return false
    }
  }

  installationValidation = (data) => {
    if (
      (data.line_item !== "" && data.line_item !== null && data.line_item !== undefined) &&
      (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
      (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
      (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined) &&
      (data.installation_date !== "" && data.installation_date !== null && data.installation_date !== undefined) &&
      (data.material_delivery_status !== "" && data.material_delivery_status !== null && data.material_delivery_status !== undefined) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) &&
      (data.status !== "" && data.status !== null && data.status !== undefined)
    ) {
      if (!isNaN(data.quantity) && data.quantity > 0) {
        if (parseInt(data.material_delivery_status) === 2) {
          if (data.material_delivery_date !== "" && data.material_delivery_date !== null && data.material_delivery_date !== undefined) {
            return true
          } else {
            alert("Need Material Delivery Date")
            return false
          }
        } else {
          data.material_delivery_date = null
          return true
        }
      } else {
        alert('Invalid Quantity')
        return false
      }
    } else {
      return false
    }
  }

  openDialog = (task_id) => {
    getTaskUpdatesList(this.props.auth.token, task_id)
      .then(res => {
        this.setState({
          openDialog: true,
          TaskUpdates: res.data
        })
      })
      .catch(error => console.log(error))
  }

  render() {
    const { TaskList, SpaceOptions, dependentOnOption, SiteSupervisorOption, VendorOption, value, openDialog, TaskUpdates } = this.state;
    const completed_services = TaskList.filter(task => task.office_task_type === "services" &&  task.status === 2 ).map(item => item.value).reduce((prev, next) => { return prev + next}, 0);
    const total_services = TaskList.filter(task => task.office_task_type === "services").map(item => item.value).reduce((prev, next) => { return prev + next}, 0);
    const completed_installations = TaskList.filter(task => task.office_task_type === "installation" &&  task.status === 2 ).map(item => item.value).reduce((prev, next) => { return prev + next}, 0); 
    const total_installations = TaskList.filter(task => task.office_task_type === "installation" ).map(item => item.value).reduce((prev, next) => { return prev + next}, 0);
    let services_status = total_services == 0 ? 0 : ((completed_services/total_services)*100).toFixed(2);
    let installations_status = total_installations == 0 ? 0 : ((completed_installations/total_installations)*100).toFixed(2);

    
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={(e, i) => this.setState({ value: i })}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Services" />
            <Tab label="Installation" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MaterialTable
            title= {`Services Tasks List (${services_status}% Completed)`}
            columns={[
              {
                title: 'Task ID',
                field: 'id',
                render: rawData => rawData !== undefined ? `P${rawData.project_id}T${rawData.id}` : null,
                editable: false,
                filtering: false
              },
              {
                title: 'Name',
                field: 'name'
              },
              {
                title: 'Description',
                field: 'description'
              },
              {
                title: 'Space',
                field: 'office_space_id',
                lookup: SpaceOptions
              },
              {
                title: 'Site Supervisor',
                field: 'office_user_id',
                lookup: SiteSupervisorOption
              },
              {
                title: 'Vendor',
                field: 'office_vendor_id',
                lookup: VendorOption
              },
              {
                title: 'Dependent on',
                field: 'dependent_on',
                lookup: dependentOnOption
              },
              {
                title: 'Planned Start Date',
                field: 'plan_start_date_time',
                render: rowData => rowData.plan_start_date_time !== null ? moment(rowData.plan_start_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Planned End Date',
                field: 'plan_end_date_time',
                render: rowData => rowData.plan_end_date_time !== null ? moment(rowData.plan_end_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Actual Start Date',
                field: 'start_date_time',
                render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Actual End Date',
                field: 'end_date_time',
                render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Quantity',
                field: 'quantity'
              },
              {
                title: 'Unit of Measurement',
                field: 'unit_of_measurement'
              },
              {
                title: 'Status',
                field: 'status',
                render: rowData => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                }
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
            ]}
            data={TaskList.filter(task => task.office_task_type === "services")}
            options={{
              filtering: true,
              exportButton: true
            }}
            // editable={{
            //   onRowAdd: newData => new Promise((resolve, reject) => {
            //     if (newData.length !== 0 && this.servicesValidation(newData)) {
            //       setTimeout(() => {
            //         {
            //           let data = {
            //             ...newData,
            //             project_id: this.props.project.id,
            //             office_task_type: 'services'
            //           }
            //           createTask(this.props.auth.token, data)
            //           .then(res => {
            //             if (res.status === 200) {
            //               getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //               .then(res => {
            //                 if (res.status === 200) {
            //                   let dependentOnOption = {}
            //                   res.data.map(i => dependentOnOption[i.id] = i.name)
            //                   this.setState({TaskList: res.data, dependentOnOption})
            //                 }
            //               })
            //               .catch(error => console.log(error))
            //             }    
            //           })
            //         }
            //         resolve();
            //       }, 1000);
            //     } else {
            //       return setTimeout(() => {
            //         {
            //           console.log('rejected', newData)
            //           alert("Fill all the fields")
            //         }
            //         return reject()
            //       }, 1000);
            //     }
            //   }),
            //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
            //     if (newData.length !== 0 && this.servicesValidation(newData)) {
            //       setTimeout(() => {
            //         {
            //           updateTask(this.props.auth.token, newData)
            //           .then(res => {
            //             if (res.status === 200) {
            //               getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //               .then(res => {
            //                 if (res.status === 200) {
            //                   let dependentOnOption = {}
            //                   res.data.map(i => dependentOnOption[i.id] = i.name)
            //                   this.setState({TaskList: res.data, dependentOnOption})
            //                 }
            //               })
            //               .catch(error => console.log(error))
            //             }  
            //           })
            //         }
            //         return resolve();
            //       }, 1000);
            //     } else {
            //       return setTimeout(() => {
            //         {
            //           console.log('rejected', newData)
            //           alert("Fill all the fields")
            //         }
            //         return reject()
            //       }, 1000);
            //     }
            //   }),
            //   onRowDelete: oldData => new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       {
            //         deleteTask(this.props.auth.token, oldData.id)
            //         .then(res=> {
            //           if(res.status === 200){
            //             getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //               .then(res => {
            //                 if (res.status === 200) {
            //                   let dependentOnOption = {}
            //                   res.data.map(i => dependentOnOption[i.id] = i.name)
            //                   this.setState({TaskList: res.data, dependentOnOption})
            //                 }
            //               })
            //               .catch(error => console.log(error))
            //           }
            //         })
            //       }
            //       resolve();
            //     }, 1000);
            //   })
            // }}
            onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MaterialTable
            title ={`Installation Tasks List (${installations_status}% Completed)`}
            columns={[
              {
                title: 'Task ID',
                field: 'id',
                render: rawData => rawData !== undefined ? `P${rawData.project_id}T${rawData.id}` : null,
                editable: false,
                filtering: false
              },
              {
                title: 'Space',
                field: 'office_space_id',
                lookup: SpaceOptions
              },
              {
                title: 'Line Item',
                field: 'line_item'
              },
              {
                title: 'Site Supervisor',
                field: 'office_user_id',
                lookup: SiteSupervisorOption
              },
              {
                title: 'Vendor',
                field: 'office_vendor_id',
                lookup: VendorOption
              },
              {
                title: 'Installation Start Date',
                field: 'start_date_time',
                render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Installation End Date',
                field: 'end_date_time',
                render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Quantity',
                field: 'quantity'
              },
              {
                title: 'Unit of Measurement',
                field: 'unit_of_measurement'
              },
              {
                title: 'Current Status',
                field: 'status',
                render: rowData => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                }
              },
              {
                title: 'Installation Date',
                field: 'installation_date',
                render: rowData => rowData.installation_date !== null ? moment(rowData.installation_date).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Material Delivery Status',
                field: 'material_delivery_status',
                render: rowData => <p>{this.materialDeliveryStatus(rowData.material_delivery_status)}</p>,
                lookup: {
                  0: "Not Delivered",
                  1: "Partial Delivery",
                  2: "Delivered",
                }
              },
              {
                title: 'Material Delivery Date',
                field: 'material_delivery_date',
                render: rowData => rowData.material_delivery_status === 2 ? moment(rowData.material_delivery_date).format("ll") : null,
                type: 'date'
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
            ]}
            data={TaskList.filter(task => task.office_task_type === "installation")}
            options={{
              filtering: true,
              exportButton: true
            }}
            // editable={{
            //   onRowAdd: newData => new Promise((resolve, reject) => {
            //     if (newData.length !== 0 && this.installationValidation(newData)) {
            //       setTimeout(() => {
            //         {
            //           let data = {
            //             ...newData,
            //             project_id: this.props.project.id,
            //             office_task_type: 'installation'
            //           }
            //           createTask(this.props.auth.token, data)
            //           .then(res => {
            //             if (res.status === 200) {
            //               getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //               .then(res => {
            //                 if (res.status === 200) {
            //                   let dependentOnOption = {}
            //                   res.data.map(i => dependentOnOption[i.id] = i.name)
            //                   this.setState({TaskList: res.data, dependentOnOption})
            //                 }
            //               })
            //               .catch(error => console.log(error))
            //             }    
            //           })
            //         }
            //         resolve();
            //       }, 1000);
            //      } else {
            //       return setTimeout(() => {
            //         {
            //           console.log('rejected', newData)
            //           alert("Fill all the fields")
            //         }
            //         return reject()
            //       }, 1000);
            //      }
            //   }),
            //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
            //     if (newData.length !== 0 && this.installationValidation(newData)) {
            //       setTimeout(() => {
            //         {
            //           updateTask(this.props.auth.token, newData)
            //           .then(res => {
            //             if (res.status === 200) {
            //               getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //               .then(res => {
            //                 if (res.status === 200) {
            //                   let dependentOnOption = {}
            //                   res.data.map(i => dependentOnOption[i.id] = i.name)
            //                   this.setState({TaskList: res.data, dependentOnOption})
            //                 }
            //               })
            //               .catch(error => console.log(error))
            //             }  
            //           })
            //         }
            //         return resolve();
            //       }, 1000);
            //     } else {
            //       return setTimeout(() => {
            //         {
            //           console.log('rejected', newData)
            //           alert("Fill all the fields")
            //         }
            //         return reject()
            //       }, 1000);
            //     }
            //   }),
            //   onRowDelete: oldData => new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       {
            //         deleteTask(this.props.auth.token, oldData.id)
            //         .then(res=> {
            //           if(res.status === 200){
            //             getTaskList(this.props.auth.token, {project_id: this.props.project.id})
            //             .then(res => {
            //               if (res.status === 200) {
            //                 let dependentOnOption = {}
            //                 res.data.map(i => dependentOnOption[i.id] = i.name)
            //                 this.setState({TaskList: res.data, dependentOnOption})
            //               }
            //             })
            //             .catch(error => console.log(error))
            //           }
            //         })
            //       }
            //       resolve();
            //     }, 1000);
            //   })
            // }}
            onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false, TaskUpdates: [] })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => moment(rowData.created_at).format("ll"),
                  type: 'date'
                },
              ]}
              data={TaskUpdates}
              options={{
                filtering: true,
                exportButton: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false, TaskUpdates: [] })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(TaskList);
