import axios from '../base';

export function getCustomerReportList(accessToken, projectId){
  return axios.request({
    url: `/api/v1/office_customer_reports`,
    params:{
      project_id: projectId
    },
    method: 'GET',
    headers: accessToken,
  })
}

export function shareCustomerReport(accessToken, reportId){
  return axios.request({
    url: `/api/v1/office_customer_reports/shared_with_client/${reportId}`,
    method: 'PUT',
    headers: accessToken,
  })
}

export function createCustomerReport(accessToken, data){
  return axios.request({
    url: `/api/v1/office_customer_reports`,
    method: 'POST',
    headers: {
      ...accessToken, 
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

