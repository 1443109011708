import axios from '../base';

export function getSnagList(accessToken, projectId){
  return axios.request({
    url: `/api/v1/office_snags`,
    params: {
      project_id: projectId
    },
    method: 'GET',
    headers: accessToken,
  })
}


export function getSnagQuotationList(accessToken, projectId , scope_of_work){
  return axios.request({
    url: `/api/v3/office_snags/quotation_for_snag?project_id=${projectId}&scope_of_work=${scope_of_work}`,
    method: 'GET',
    headers: accessToken,
  })
}

export function getOfficeNatureOfIssues(accessToken, projectId){
  return axios.request({
    url: `/api/v1/office_snags/office_nature_of_issues/1`,
    method: 'GET',
    headers: accessToken,
  })
}

export function getOfficeMistakeHead(accessToken, projectId){
  return axios.request({
    url: `/api/v1/office_snags/office_mistake_head/1`,
    method: 'GET',
    headers: accessToken,
  })
}

export function updateSnags(accessToken, data){
  return axios.request({
    url: `/api/v1/office_snags/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
}

export function deleteSnag(accessToken, id){
  return axios.request({
    url: `/api/v3/office_snags/${id}`,
    method: 'DELETE',
    headers: accessToken
  })
}

export function SnagImgDelete( accessToken,id){
  return axios.request({
    url : `/api/v1/office_files/${id}`,
    method : 'DELETE',
    headers : accessToken
  })
}

export function addSnags(accessToken, data){
  return axios.request({
    url: `api/v3/office_snags`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
}


export function createSnags(accessToken, data){
  return axios.request({
    url: `/api/v1/office_snags`,
    method: 'POST',
    headers: {
      ...accessToken, 
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

export function snagClose(accessToken, snagId){
  return axios.request({
    url: `/api/v3/office_snags/mark_snag_closed/${snagId}`,
    method: 'POST',
    headers: accessToken,
  })
}

