import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as AuthActions from '../ducks/auth';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ArrivaeLogo from '../arrivae.png'
import ArrivaeBackgroundImage from '../images/arrivae_bg_black.png'
import Eye from '../images/eye.png'
import EyeOff from '../images/eye-off.png'
import { InputAdornment } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { forgotPassword } from '../api';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { size } from 'lodash';


const styles = theme => ({
  paperFullScreen: {
    width: '100%',
    margin: 0,
    height: '100%',
    maxWidth: '100%',
    maxHeight: 'none',
    borderRadius: 0,
    backgroundColor: '#3857d4',
    backgroundImage: `url(${ArrivaeBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
  overlayDialog: {
    backgroundColor: 'black',
    opacity: 0.5
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class PasswordForgot extends Component {

  state = {
    email: '',
    showDanger: true,
    showError: false,
    message: '',
  }

  handleValidation = () => {
    var formIsValid = true;
    // eslint-disable-next-line
    if ((this.state.email && this.state.email.indexOf("@") === -1) || (this.state.email && !this.state.email.match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/))) {
      formIsValid = false;
    }


    return formIsValid;
  }

  goBack = () => {
    window.history.back();
    console.log("Function Back Work");
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      forgotPassword(this.state.email && this.state.email.trim())
        .then(response => {
          if (response.status === 200) {
            this.setState({ showDanger: false, showError: true, message: response.data.message })
          } else {
            this.setState({ showDanger: true, showError: true, message: response.data.message })
          }
        })
        .catch((error) => {
          console.log('error:', error.response.data.error)
          this.setState({ showError: true, showDanger: true, message: error.response.data.error })
        });
    }
  }

  handleBlur = (event) => {
    this.refs[event.target.name].validate(event.target.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.message) {
      this.setState({
        open: true,
        errorMessages: nextProps.auth.message,
        showLoader: false,
      })
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const token = this.props.auth && this.props.auth.token;
    const { classes } = this.props;
    const { showPassword, showLoader, errorMessages } = this.state;

    return (
      token ?
        <Redirect to='/' /> :
        <Dialog
          fullScreen={true}
          open={true}
          classes={{ paperFullScreen: classes.paperFullScreen }}>
          {showLoader && <LinearProgress />}
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <img src={ArrivaeLogo} alt="Arrivae" width="200" />
              <br />
              <Typography variant="h4">Forgot Password</Typography>
              <p className="text-center" style={{ fontSize: 'small' }}>(We will send you instructions for your password recovery to the email provided)</p>
              <ValidatorForm
                className={classes.form}
                onSubmit={(e) => this.onSubmit(e)}
                onError={errors => console.log(errors)}
                instantValidate={false} >
                <FormControl margin="normal" required fullWidth>
                  <TextValidator
                    ref="email"
                    label="Email"
                    onBlur={(e) => this.handleBlur(e)}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                    fullWidth={true}
                  />
                </FormControl>
                <div className="auth-box">
                  {this.state.showError &&
                    <div style={{ color: this.state.showDanger ? 'red' : 'green' }}>
                      <p className="text-center" style={{ fontSize: 'small' }}>{this.state.message}</p>
                    </div>
                  }
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <Link to="/sign_in" className="d-flex align-items-center"><ArrowBackIcon className="w18px" /> Back</Link>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="px-5"
                  >
                    Send
                  </Button>
                </div>
              </ValidatorForm>
            </Paper>
          </main>
        </Dialog>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(AuthActions, dispatch)
  }
}

export default compose(withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps))(PasswordForgot);