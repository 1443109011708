import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    padding: '15px'
  },
  button: {
    margin: theme.spacing(),
  },
  textCenter: {
    textAlign: 'center',
  },
});

class ProjectDetails extends Component {

  render() {
    const { classes, project } = this.props;

    return (
      <Paper className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}><strong> Lead ID: </strong></Grid>
          <Grid item xs={12} sm={8}>{project.lead_id}</Grid>

          <Grid item xs={12} sm={4}><strong> Client Name: </strong></Grid>
          <Grid item xs={12} sm={8}> {project.lead.name}</Grid>

          <Grid item xs={12} sm={4}><strong> Client Email:  </strong></Grid>
          <Grid item xs={12} sm={8}> {project.lead.email}</Grid>

          <Grid item xs={12} sm={4}><strong>Designer:</strong></Grid>
          <Grid item xs={12} sm={8}>
            {project.assigned_designer.name}<br />
            {project.assigned_designer.email}<br />
            {project.assigned_designer.contact}
          </Grid>

          <Grid item xs={12} sm={4}><strong>Community Manager:</strong></Grid>
          <Grid style={{ marginBottom: '15px' }} item xs={12} sm={8}>
            {project.assigned_cm.name}<br />
            {project.assigned_cm.email}<br />
            {project.assigned_cm.contact}
          </Grid>

          {(project.project_booking_form !== undefined && project.project_booking_form.length !== 0) ?
            <React.Fragment>

              <Grid item xs={12} sm={4}><strong> Primary mobile </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.primary_mobile || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Secondary mobile </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.secondary_mobile || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Landline </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.landline || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Primary email </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.primary_email || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Secondary email </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.secondary_email || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Flat no </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.flat_no || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Floor no </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.floor_no || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Building name </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.building_name || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Current address </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.current_address || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Location </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.location || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> City </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.city || 'Not available'} </Grid>
              <Grid item xs={12} sm={4}><strong> Pincode </strong></Grid>
              <Grid item xs={12} sm={8}>{project.project_booking_form.pincode || 'Not available'} </Grid>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid item xs={12} sm={4}><strong> Client Contact </strong></Grid>
              <Grid item xs={12} sm={8}>{project.lead.contact}</Grid>
            </React.Fragment>
          }

        </Grid>
      </Paper>
    )
  }
}

export default compose(withStyles(styles))(ProjectDetails);
