import axios from '../base';

export function createSiteSupervisor(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_users/register_new_user',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function getSiteSupervisorList(accessToken, roleName) {
  return axios.request({
    url: `/api/v1/office_users/office_users_list_by_role/${roleName}`,
    method: 'GET',
    headers: accessToken
  })
};

export function site_supervisor_list_by_role(accessToken) {
  return axios.request({
    url: `/api/v1/office_users/site_supervisor_list_by_role`,
    method: 'GET',
    headers: accessToken
  })
};

export function addProjectSiteSupervisor(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_projects/add_project_site_supervisor',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function getProjectSiteSupervisor(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/get_project_site_supervisor/${data.projectId}/${data.roleId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function  updateSiteSupervisor(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_users/update_user',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function disableUser(accessToken, userId) {
  return axios.request({
    url: `/api/v1/office_users/disable_user/${userId}`,
    method: 'POST',
    headers: accessToken,
  })
};

export function getSiteSupervisorsOfPm(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_users/get_supervisor_for_pm`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
}

export function assignSSToProject(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_users/assign_ss_to_project`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
}