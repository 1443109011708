import React, { Component } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import CircularIndeterminate from "../Loader";
import SupervisorIcon from "@material-ui/icons/SupervisorAccount";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Select,
  MenuItem,
  Grid,
  Badge,
  Chip,
  Tooltip,
  Avatar,
  InputLabel,
  Checkbox,
  Typography,
  TextField,
  TextareaAutosize,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getAllEscalation,
  getAllEscalationFilters,
  getEscalationFilter,
  updateEscalation,
  getEscalationLogsOnId,
} from "../../api";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationWrapper from "../Shared/TablePaginationWrapper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import Swal from "sweetalert2";
import moment from "moment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const textAreaStyles = {
  width: "100%",
  height: "100px",
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleModal = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const statusCheck = ["Reopened", "Open"];
class ManualEscalation extends Component {
  state = {
    isLoading: false,
    fromDate: "",
    toDate: "",
    storeFilterArray: [],
    storeFilter: [],
    storeList: [],
    escalationSourceFilterArray: [],
    escalationSourceFilter: [],
    escalationSourceList: [],
    escalationStageFilterArray: [],
    escalationStageFilter: [],
    escalationStageList: [],
    statusFilterArray: [],
    statusFilter: [],
    statusList: [
      {
        key: "open",
        value: "Open",
      },
      {
        key: "closed",
        value: "Closed",
      },
      {
        key: "resolved",
        value: "Resolved",
      },
      {
        key: "reopened",
        value: "Reopened",
      },
      {
        key: "",
        value: "All",
      },
    ],
    manualEscalationList: [],
    totalEntries: 0,
    page: 1,
    totalPages: 0,
    rowsPerPage: 15,
    isStatusModalOpen: false,
    statusChangeValue: null,
    statusChangeComment: undefined,
    statusChangeEscalationId: null,
    loadingStatusChange: false,
    isModalDescriptionOpen: false,
    modalDescription: "",
    modalDescriptionTitle: "",
    isLogsModalOpen: false,
    escalationLogs: [],
    currentEscalationId: null,
    logCurrentPage: 1,
    logPerPage: 10,
    logTotalEntries: null,
    logTotalPages: null,
    expanded: null,
  };

  handleStatusModalClickOpen = () => {
    this.setState({ isStatusModalOpen: true });
  };

  handleStatusModalClose = () => {
    this.setState({ isStatusModalOpen: false, statusChangeComment: undefined });
  };

  handleSubmitStatus() {
    this.setState({ loadingStatusChange: true });
    updateEscalation(
      this.props.token,
      this.state.statusChangeEscalationId,
      this.state.statusChangeComment,
      this.state.statusChangeValue
    )
      .then((res) => {
        if (res.status == 200) {
          this.handleFilter(this.state.page);
          this.setState({
            isStatusModalOpen: false,
            loadingStatusChange: false,
            statusChangeComment: undefined,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isStatusModalOpen: false,
          loadingStatusChange: false,
          statusChangeComment: undefined,
        });
      });
  }

  handleStatusChange(value, id) {
    this.setState({
      isStatusModalOpen: true,
      statusChangeValue: value,
      statusChangeEscalationId: id,
    });
  }

  componentDidMount() {
    this.props.history.push(`/pe/manual-escalation/1`);
    this.setState({ isLoading: true });
    getAllEscalation(this.props.token, this.state.page, this.state.rowsPerPage)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            manualEscalationList: res.data.data.manual_escalations,
            page: 1,
            isLoading: false,
            totalEntries: res.data.data.count,
            totalPages: res.data.data.pages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });

    getAllEscalationFilters(this.props.token)
      .then((res) => {
        this.setState({
          storeList: res.data.stores,
          escalationSourceList: res.data.sources,
          escalationStageList: res.data.stages,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  handleEscalationLogsOnId(id, page) {
    const { logPerPage } = this.state;
    this.setState({ isLogsModalOpen: true, currentEscalationId: id });
    getEscalationLogsOnId(this.props.token, id, page, logPerPage)
      .then((res) => {
        if (res.status == 200) {
          console.log("res", res.data);
          this.setState({
            escalationLogs: res.data.data.manual_escalation_logs,
            logTotalEntries: res.data.data.count,
            logCurrentPage: res.data.data.page_number,
            logTotalPages: res.data.data.pages,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEscalationLogModalClose() {
    this.setState({ isLogsModalOpen: false, escalationLogs: [] });
  }

  handleFilter(page) {
    this.setState({ page, isLoading: true });
    const {
      fromDate,
      toDate,
      storeFilter,
      escalationSourceFilter,
      escalationStageFilter,
      statusFilter,
      rowsPerPage,
    } = this.state;

    this.props.history.push(`/pe/manual-escalation/${page}`);
    getEscalationFilter(
      this.props.token,
      page,
      rowsPerPage,
      "",
      "",
      statusFilter,
      storeFilter,
      escalationStageFilter,
      fromDate,
      toDate,
      escalationSourceFilter
    )
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            manualEscalationList: res.data.data.manual_escalations,
            page: res.data.data.page_number,
            isLoading: false,
            totalEntries: res.data.data.count,
            totalPages: res.data.data.pages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  resetAllFilter() {
    this.setState({
      isLoading: true,
      totalEnteries: 0,
      rowsPerPage: 15,
      totalPages: 0,
      fromDate: "",
      toDate: "",
      storeFilter: [],
      storeFilterArray: [],
      escalationSourceFilter: [],
      escalationSourceFilterArray: [],
      escalationStageFilter: [],
      escalationStageFilterArray: [],
      statusFilter: [],
      statusFilterArray: [],
      page: 1,
    });

    this.props.history.push(`/pe/manual-escalation/1`);
    getAllEscalation(this.props.token)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            manualEscalationList: res.data.data.manual_escalations,
            page: res.data.data.page_number,
            isLoading: false,
            totalEntries: res.data.data.count,
            totalPages: res.data.data.pages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  onDateFromChange = (e) => {
    this.setState({
      fromDate: e.target.value,
    });
  };

  onDateToChange = (e) => {
    this.setState({
      toDate: e.target.value,
    });
  };

  handleStoreAutoComplete = (event, newValue) => {
    this.setState({
      storeFilter: newValue.map((v) => v.id) || [],
      storeFilterArray: newValue || [],
    });
  };

  handleEscalationSourceAutoComplete = (event, newValue) => {
    this.setState({
      escalationSourceFilter: newValue.map((v) => v.key) || [],
      escalationSourceFilterArray: newValue || [],
    });
  };

  handleEscalationStageAutoComplete = (event, newValue) => {
    this.setState({
      escalationStageFilter: newValue.map((v) => v.key) || [],
      escalationStageFilterArray: newValue || [],
    });
  };

  handleStatusAutoComplete = (event, newValue) => {
    this.setState({
      statusFilter: newValue.map((v) => v.key) || [],
      statusFilterArray: newValue || [],
    });
  };

  handleChangePage = async (event, page) => {
    let page_num = page + 1;
    this.handleFilter(page_num);
  };

  handleLogChangePage = async (event, page) => {
    let page_num = page + 1;
    this.handleEscalationLogsOnId(this.state.currentEscalationId, page_num);
  };

  handleModalDetails = (title, text) => {
    this.setState({
      isModalDescriptionOpen: true,
      modalDescription: text,
      modalDescriptionTitle: title,
    });
  };

  handleModalDetailsClose() {
    this.setState({
      isModalDescriptionOpen: false,
      modalDescription: "",
      modalDescriptionTitle: "",
    });
  }

  render() {
    const {
      isLoading,
      fromDate,
      toDate,
      storeFilterArray,
      storeList,
      escalationSourceFilterArray,
      escalationSourceList,
      escalationStageFilterArray,
      escalationStageList,
      statusFilterArray,
      statusList,
      totalEntries,
      totalPages,
      page,
      rowsPerPage,
      manualEscalationList,
      isStatusModalOpen,
      loadingStatusChange,
      isLogsModalOpen,
      escalationLogs,
    } = this.state;

    let url = new URL(window.location.href);
    let page_number = this.props?.project?.id
      ? page + 1
      : parseInt(url.pathname.split("/").reverse()[0]) || page + 1;

    const handleChange = (panel) => (event, isExpanded) => {
      this.setState({ expanded: isExpanded ? panel : false });
    };

    let log_page_number = this.state.logCurrentPage || page + 1;

    return (
      <React.Fragment>
        <MaterialTable
          title="Manual Escalation"
          isLoading={isLoading}
          columns={[
            {
              title: "Escalation No.",
              field: "id",
              render: (rowData) => {
                return (
                  <Typography
                    color={"primary"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleEscalationLogsOnId(
                        rowData.id,
                        this.state.logCurrentPage
                      )
                    }
                  >
                    {rowData.id}
                  </Typography>
                );
              },
            },
            {
              title: "Lead Name",
              render: (rowData) => {
                return (
                  <Typography>
                    {rowData.lead.lead_name + " (" + rowData.lead.lead_id + ")"}
                  </Typography>
                );
              },
            },
            { title: "BOQ No", field: "boq.boq_reference_no" },
            { title: "Escalation Source", field: "source" },
            { title: "Escalation Stage", field: "escalation_stage" },
            {
              title: "Description",
              render: (rowData) => {
                return rowData.description?.length > 40 ? (
                  <Typography>
                    {rowData.description.slice(0, 40)}...
                    <Typography
                      onClick={() =>
                        this.handleModalDetails(
                          "Description",
                          rowData.description
                        )
                      }
                      color={"primary"}
                      style={{ cursor: "pointer" }}
                    >
                      more
                    </Typography>
                  </Typography>
                ) : (
                  <Typography>{rowData.description}</Typography>
                );
              },
            },
            { title: "Primary Assigned", field: "assigned_team.role_value" },
            {
              title: "Secondary Assigned",
              field: "secondary_assigned_team.role_value",
            },
            {
              title: "Expected Action",
              render: (rowData) => {
                return rowData.expected_action?.length > 40 ? (
                  <Typography>
                    {rowData.expected_action.slice(0, 40)}...
                    <Typography
                      onClick={() =>
                        this.handleModalDetails(
                          "Expected Action",
                          rowData.expected_action
                        )
                      }
                      color={"primary"}
                      style={{ cursor: "pointer" }}
                    >
                      more
                    </Typography>
                  </Typography>
                ) : (
                  <Typography>{rowData.expected_action}</Typography>
                );
              },
            },
            {
              title: "Expected Resolution Date",
              field: "expected_resolution_date",
            },
            {
              title: "Notify",
              render: (rowData) => {
                return rowData.notify.join(", ");
              },
            },
            {
              title: "Status",
              field: "status",
              render: (rowData) => {
                return (
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography>{rowData.status} </Typography>
                    {rowData.comments != null ? (
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              {rowData.comments}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon fontSize="small" />
                      </HtmlTooltip>
                    ) : (
                      ""
                    )}
                  </Box>
                );
              },
            },
            {
              title: "Update Status",
              field: "status",
              render: (rowData) => {
                return (
                  <Select
                    native
                    value={"Select"}
                    disabled={!statusCheck.includes(rowData.status)}
                    onChange={(e) =>
                      this.handleStatusChange(e.target.value, rowData.id)
                    }
                  >
                    <option disabled>Select</option>
                    <option value={"resolved"}>Resolved</option>
                  </Select>
                );
              },
            },
          ]}
          data={manualEscalationList}
          options={{
            search: false,
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div>
                {/* <MTableToolbar {...props} /> */}
                <Grid
                  container
                  style={{ alignItems: "center", padding: "1rem" }}
                >
                  <Grid item xs={8}>
                    <h5>Manual Escalation</h5>
                  </Grid>
                  {/* <Grid item xs={4}>
                                <Grid item style={{padding:"10px", display: 'flex', justifyContent: 'flex-end'}}>
                                  <Button variant="contained" color="primary" onClick={() => this.handleExportFilter()}>Export</Button>
                                </Grid>
                              </Grid> */}
                </Grid>
                <Grid container spacing={3} className="w-auto m-2">
                  <Grid item xs={3}>
                    <TextField
                      id="date"
                      label="Filter By Expected Resolution Date From"
                      type="date"
                      defaultValue=""
                      className="w-100"
                      value={fromDate}
                      onChange={(e) => this.onDateFromChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="date"
                      label="To"
                      type="date"
                      defaultValue=""
                      className="w-100"
                      value={toDate}
                      onChange={(e) => this.onDateToChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={storeFilterArray}
                      options={storeList}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "16px 0px" }}
                          className="textField"
                          placeholder="Filter By Store"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleStoreAutoComplete(event, newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={escalationSourceFilterArray}
                      options={escalationSourceList}
                      getOptionSelected={(option, value) =>
                        option.key === value.key
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.value}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "16px 0px" }}
                          className="textField"
                          placeholder="Filter By Escalation Source"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleEscalationSourceAutoComplete(event, newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={escalationStageFilterArray}
                      options={escalationStageList}
                      getOptionSelected={(option, value) =>
                        option.key === value.key
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.value}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "16px 0px" }}
                          className="textField"
                          placeholder="Filter By Escalation Stage"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleEscalationStageAutoComplete(event, newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={statusFilterArray}
                      options={statusList}
                      getOptionSelected={(option, value) =>
                        option.key === value.key
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.value}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "16px 0px" }}
                          className="textField"
                          placeholder="Filter By Status"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleStatusAutoComplete(event, newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item style={{ padding: "10px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.handleFilter(this.state.page)}
                        >
                          Filter
                        </Button>
                      </Grid>
                      <Grid item style={{ padding: "10px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.resetAllFilter()}
                        >
                          Reset All Filters
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ),
          }}
        />
        <Box style={{ float: "right" }}>
          <TablePagination
            colSpan={8}
            count={totalEntries}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page_number - 1}
            onChangePage={this.handleChangePage}
            ActionsComponent={TablePaginationWrapper}
          />
        </Box>
        <Dialog
          open={isStatusModalOpen}
          onClose={this.handleStatusModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Status"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add Remarks to Status Update to Reopened{" "}
              <span style={{ color: "red" }}>*</span>
            </DialogContentText>
            <TextareaAutosize
              style={textAreaStyles}
              aria-label="minimum height"
              min-rows={3}
              placeholder="Add your Remark"
              value={this.state.statusChangeComment}
              onChange={(e) => {
                this.state.statusChangeComment = e.target.value;
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleStatusModalClose} color="primary">
              Cancel
            </Button>
            <div style={{ position: "relative" }}>
              <Button
                color="primary"
                disabled={loadingStatusChange}
                onClick={() => this.handleSubmitStatus()}
                autoFocus
              >
                Submit
              </Button>
              {loadingStatusChange && (
                <CircularProgress
                  size={18}
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "35%",
                  }}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>

        {/* Modal pop up for show details */}
        <Dialog
          onClose={() => this.handleModalDetailsClose()}
          aria-labelledby="customized-dialog-title"
          open={this.state.isModalDescriptionOpen}
        >
          <DialogTitleModal
            id="customized-dialog-title"
            onClose={() => this.handleModalDetailsClose()}
          >
            {this.state.modalDescriptionTitle}
          </DialogTitleModal>
          <DialogContent dividers>
            <Typography gutterBottom>{this.state.modalDescription}</Typography>
          </DialogContent>
        </Dialog>

        {/* Loges Modal */}
        <Dialog
          onClose={() => this.handleEscalationLogModalClose()}
          aria-labelledby="customized-dialog-title"
          open={isLogsModalOpen}
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitleModal
            id="customized-dialog-title"
            onClose={() => this.handleEscalationLogModalClose()}
          >
            Escalation Log
          </DialogTitleModal>
          <DialogContent dividers>
            {escalationLogs.map((item) => {
              return (
                <Accordion
                  key={item.id}
                  expanded={this.state.expanded === item.id}
                  onChange={handleChange(item.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${item.id}-content`}
                    id={`${item.id}-header`}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <PermContactCalendarIcon />
                      </Grid>
                      <Grid item xs={8}>
                        {item.user.user_name +
                          " (" +
                          item.user.user_role_name
                            .split("_")
                            .map(
                              (item) => item[0].toUpperCase() + item.slice(1)
                            )
                            .join(" ") +
                          ") Updated on " +
                          moment(item.created_date).format("DD-MM-YYYY")}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MaterialTable
                      style={{ width: "100%" }}
                      columns={[
                        {
                          title: "Field",
                          render: (rowData) => {
                            return (
                              <Typography>
                                {rowData.key
                                  .split("_")
                                  .map(
                                    (item) =>
                                      item[0].toUpperCase() + item.slice(1)
                                  )
                                  .join(" ")}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: "Original Value",
                          field: "old_value",
                        },
                        {
                          title: "New Value",
                          field: "new_value",
                        },
                      ]}
                      data={item.fields || []}
                      options={{
                        showTitle: false,
                        search: false,
                        paging: false,
                        toolbar: false,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Box style={{ float: "right" }}>
              <TablePagination
                colSpan={8}
                count={this.state.logTotalEntries}
                rowsPerPage={this.state.logPerPage}
                rowsPerPageOptions={[]}
                page={log_page_number - 1}
                onChangePage={this.handleLogChangePage}
                ActionsComponent={TablePaginationWrapper}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(ManualEscalation);
