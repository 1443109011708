import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import GanttChart from './GanttChart';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ProductionItemList from './ProductionItemList';
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { createOperation, getProcessOperation } from '../../../api';
import 'react-dates/initialize';
// import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
});

const subProcessList = [
  {value: 1, label: 'Pressing'},
  {value: 2, label: 'Cutting'},
  {value: 3, label: 'Edge Banning'},
  {value: 4, label: 'Boring'},
  {value: 5, label: 'Dry Fit'},
  {value: 6, label: 'Packing'},
]

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProductionPlan extends Component {
  state = {
    operationList: [],
    processId: 1,
    open: false,
    selectopen: false,
    selectValue: "",
    openDialog1: false,
    operation: {
      subProcess: subProcessList[0].value,
      startDateTime: new Date(moment().format()),
      endDateTime: new Date(moment().add(1, 'minute').format()),
    },
    selectedDate: new Date(moment().format('L')),
  };

  async componentDidMount() {
    const getProcessOperationList = await getProcessOperation(
      this.props.auth.token, 
      this.state.processId
      )
    this.setState({operationList: getProcessOperationList.data})
  }
  handleClickOpen = () => {
    this.setState({ open: true});
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialog1 = () => {
    this.setState({ openDialog1: true});
  };

  handleCloseDialog1 = () => {
    this.setState({ openDialog1: false, operationId: null });
  };

  handleChange = event => {
    let operation = {...this.state.operation};
    operation.subProcess = event.target.value
    this.setState({ operation });
  };

  handleStartDateChange = (date) => {
    let operation = {...this.state.operation};
    operation.startDateTime = date
    this.setState({ operation });
  }
  handleEndDateChange = (date) => {
    let operation = {...this.state.operation};
    operation.endDateTime = date
    this.setState({ operation });
  }

  setOperationId = (id) => {
    this.setState({operationId: id, openDialog1: true})
  }

  onSubmitOpertationForm = e => {
    e.preventDefault();
    createOperation(this.props.auth.token, this.state.operation)
    .then(response => {
      if(response.status){
        this.setState({ open: false });
        getProcessOperation(this.props.auth.token, this.state.processId)
        .then(response => {
          if(response.status) {
            this.setState({ operationList: response.data})
          }
        })
        .catch(error => {

        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  changeDateRange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate })
    getProcessOperation(this.props.auth.token, this.state.processId,{
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format()
    })
    .then(response => {
      if(response.status){
        this.setState({ operationList: response.data})
      }
    })
    .catch(error => {

    })
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    getProcessOperation(this.props.auth.token, this.state.processId,{
      selectedDate: moment(date).format()
    })
    .then(response => {
      if(response.status){
        this.setState({ operationList: response.data})
      }
    })
    .catch(error => console.log(error))
    
  }

  render() {
    const { classes } = this.props;
    return(
      <div>
        <div className={classes.root}>
          <Grid container spacing={40}>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker 
                  label="Select Date"
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  margin="normal"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Button 
                variant="contained" 
                color="primary" 
                className={classes.button}
                onClick={() => this.handleClickOpen()}>
                Add Operation
              </Button>
            </Grid>
          </Grid>
        </div>
        <br/>
        <Card>
          <CardContent>
            <GanttChart onClick={this.setOperationId} data={this.state.operationList} />
          </CardContent>
        </Card>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <ValidatorForm 
              className={classes.form} 
              onSubmit={this.onSubmitOpertationForm}
              onError={errors => console.log(errors)} >
            <DialogTitle id="form-dialog-title">Add New Operation</DialogTitle>
            <DialogContent>
              <MuiPickersUtilsProvider utils={MomentUtils}>
              
                <FormControl margin="normal" required fullWidth>
                  <InputLabel shrink htmlFor="age-label-placeholder">
                    Sub Process
                  </InputLabel>
                  <Select
                    value={this.state.operation.subProcess}
                    onChange={this.handleChange}
                    name="operation"
                    className={classes.selectEmpty}
                  >
                    { subProcessList.map((subprocess,key) => {
                      return(<MenuItem value={subprocess.value} key={key}>{subprocess.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>

                <FormControl  margin="normal" required fullWidth>
                  <InputLabel shrink htmlFor="age-label-placeholder">
                      Start Date and Time
                  </InputLabel>
                  <DateTimePicker
                    value={this.state.operation.startDateTime}
                    disablePast
                    onChange={this.handleStartDateChange}
                    showTodayButton
                    margin="normal"
                  />
                </FormControl>

                <FormControl  margin="normal" required fullWidth>
                  <InputLabel shrink htmlFor="age-label-placeholder">
                      End Date and Time
                  </InputLabel>
                  <DateTimePicker
                    value={this.state.operation.endDateTime}
                    disablePast
                    onChange={this.handleEndDateChange}
                    showTodayButton
                    margin="normal"
                  />
                </FormControl>
              
              </MuiPickersUtilsProvider>
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        <div>
        <Dialog
          fullScreen
          open={this.state.openDialog1}
          onClose={this.handleCloseDialog1}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleCloseDialog1} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" className={classes.flex}>
                Operation Task (Process : {this.state.operationId}) 
              </Typography>
            </Toolbar>
          </AppBar>
          <ProductionItemList operationId={this.state.operationId}/>
        </Dialog>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),connect(mapStateToProps))(ProductionPlan);
