import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as AuthActions from '../../ducks/auth';
import MoodBoard from './MoodBoard';
import Preset from './Preset';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({});



class ParentBoard extends Component {
    state = {
        open: false,
        navId: 1,
        activeRadio: "MoodBoard"
    };
    componentDidMount() {
        if (this.props.auth) {
            console.log("client id and designer id", this.props);
        }
    }

    navigationChange(navId) {
        this.setState({ navId: navId });
    }


    onChangeRadio = (e) => {
        const abcd = e.target.value;
        this.setState({ activeRadio: abcd });
        console.log(this.state.activeRadio)
    }


    render() {
        return (
            <div>
                <Typography>
                    <RadioGroup defaultValue="mdboard" aria-label="board" name="customized-radios" value={this.state.activeRadio} onChange={this.onChangeRadio}>
                        <FormControlLabel value="MoodBoard" control={<Radio color="primary" />} label="MoodBoard" />
                        <FormControlLabel value="Preset" control={<Radio color="primary" />} label="Preset" />
                    </RadioGroup>
                </Typography>
                {this.props.auth.token && this.state.navId === 1 && this.state.activeRadio == "MoodBoard" &&
                    <MoodBoard
                        client_id={(this.props.match && this.props.match.params && this.props.match.params.client_id) || 'data_entry'}
                        designer_id={(this.props.match && this.props.match.params && this.props.match.params.designer_id)}
                    />}
                {this.props.auth.token && this.state.navId === 2 && <Typography variant="h4">
                    Comming Soon!!
              </Typography>}
                {this.props.auth.token && this.state.navId === 1 && this.state.activeRadio == "Preset" &&
                    <Preset
                        client_id={(this.props.match && this.props.match.params && this.props.match.params.client_id) || 'data_entry'}
                        designer_id={(this.props.match && this.props.match.params && this.props.match.params.designer_id)}
                    />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(AuthActions, dispatch)
    }
}


export default compose(withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps))(ParentBoard)