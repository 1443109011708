import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ReactDropzone from "react-dropzone";
import Swal from 'sweetalert2';
import SubtaskListServices from './SubtaskListServices';
import SubtaskListInstallation from './SubtaskListInstallation';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  createTask,
  getVendorTaskList,
  updateTask,
  deleteTask,
  getVendorProjectSiteSupervisorList,
  // getTaskUpdatesList,
  // taskBulkUpload
} from '../../../../api/VENDOR/task';
import { AppBar, Tabs, Tab, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import MaterialTable from 'material-table'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'

const styles = theme => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 50,
    height: 50,
    padding: 2,
    boxSizing: 'border-box',
    borderWidth: 1,
    borderColor: 'black',
  },
  button: {
    margin: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogSubtaskTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class TaskList extends Component {
  state = {
    value: 0,
    TaskList: [],
    selectedTask: '',
    dependentOnOption: [],
    VendorOption: this.props.VendorOption,
    SiteSupervisorOption: [],
    openDialog: false,
    TaskUpdates: [],
    openDialogFile: false,
    csvData: null,
    newFile: {},
    openServicesSubtaskDialog: false,
    openInstallationSubtaskDialog: false,
    taskId: null,
    taskName: null,
    taskDisId: null,
    lineItem: null,
    spaceId: null,
    fileTypeTasks: null
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ VendorOption: nextProps.VendorOption });
    this.getData()
  }


  toggleDialogFile = () => {
    this.setState({ openDialogFile: !this.state.openDialogFile, newFile: {} });
  };

  toggleServicesSubtaskDialog = (id, name, disId, spaceId) => {
    this.setState({ openServicesSubtaskDialog: !this.state.openServicesSubtaskDialog });
    this.setState({ taskId: id, taskName: name, taskDisId: disId, spaceId: spaceId });
  };

  toggleInstallationSubtaskDialog = (id, name, disId, lineItem, spaceId) => {
    this.setState({ openInstallationSubtaskDialog: !this.state.openInstallationSubtaskDialog });
    this.setState({ taskId: id, taskName: name, taskDisId: disId, lineItem: lineItem, spaceId: spaceId });
  };

  handleFiles = file => {
    this.setState({
      newFile: file[0]
    })
  }

  // onSubmitTaskUpload = (e) => {
  //   const fd = new FormData();
  //   fd.append('project_id', this.props.project.id)
  //   fd.append('file', this.state.newFile)
  //   fd.append('parent_or_child', this.state.fileTypeTasks ? 'parent' : 'child')
  //   taskBulkUpload(this.props.auth.token, fd)
  //     .then(response => {
  //       if (response.status) {
  //         this.toggleDialogFile();
  //         this.getData()
  //         Swal.fire('Success', response.data.message, 'success')
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  // }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    if (this.props.project) {
      try {
        const TaskListRes = await getVendorTaskList(this.props.auth.token, { project_id: this.props.project.id })
        const SiteSupervisorListRes = await getVendorProjectSiteSupervisorList(this.props.auth.token, this.props.project.id)

        let SpaceOptions = {}
        let dependentOnOption = {}
        TaskListRes.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

        this.setState({
          TaskList: TaskListRes.data,
          dependentOnOption,
          SiteSupervisorOption
        })
      } catch (error) {
        console.log(error)
      }

    }
  }

  status = (id) => {
    switch (id) {
      case 0:
        return "Not Initiated";
      case 1:
        return "Initiated";
      case 2:
        return "Completed";
      default:
        break;
    }
  }

  materialDeliveryStatus = (id) => {
    switch (id) {
      case 0:
        return "Not Delivered";
      case 1:
        return "Partial Delivery";
      case 2:
        return "Delivered";
      default:
        break;
    }
  }

  servicesValidation = (data) => {
    if ((data.name !== "" && data.name !== null && data.name !== undefined) &&
      (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
      (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
      (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined) &&
      (data.plan_start_date_time !== "" && data.plan_start_date_time !== null && data.plan_start_date_time !== undefined) &&
      (data.plan_end_date_time !== "" && data.plan_end_date_time !== null && data.plan_end_date_time !== undefined) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) && (data.value !== "" && data.value !== null && data.value !== undefined && data.value > 0) &&
      (data.status !== "" && data.status !== null && data.status !== undefined)) {
      let plan_start_date = moment(data.plan_start_date_time)
      let plan_end_date = moment(data.plan_end_date_time)
      if (
        (plan_end_date.diff(plan_start_date, 'days') >= 0)
      ) {
        if (
          (data.start_date_time !== "" && data.start_date_time !== null && data.start_date_time !== undefined) &&
          (data.start_date_time !== "" && data.start_date_time !== null && data.start_date_time !== undefined)
        ) {
          let start_date = moment(data.start_date_time)
          let end_date = moment(data.end_date_time)
          if (
            (data.status == "0" || data.status == "1" || end_date.diff(start_date, 'days') >= 0)
          ) {
            return true
          } else {
            Swal.fire('Error', 'End date cannot be prior to start date.', 'info')
            return false
          }
        } else {
          return true
        }
      } else {
        Swal.fire('Error', 'End date cannot be prior to start date.', 'info')
        return false
      }
    } else {
      if (data.value <= 0) {
        Swal.fire('Error', 'Value must be greater than 0', 'info')
        return false
      } else {
        Swal.fire('Error', 'Fill all the fields', 'info')
        return false
      }
    }
  }

  installationValidation = (data) => {
    if ((data.line_item !== "" && data.line_item !== null && data.line_item !== undefined) &&
      (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
      (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
      (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined)
      && (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) && (data.value !== "" && data.value !== null && data.value !== undefined && data.value > 0) && (data.status !== "" && data.status !== null && data.status !== undefined)) {
      // let start_date_time = moment(data.start_date_time)
      // let end_date_time = moment(data.end_date_time)
      // if (data.end_date_time != null && data.start_date_time != null) {
      //   if (
      //     (end_date_time.diff(start_date_time, 'days') >= 0)
      //   ) {
      //     if (data.quantity !== null) {
      //       if (parseInt(data.material_delivery_status) === 2) {
      //         if (data.material_delivery_date !== "" && data.material_delivery_date !== null && data.material_delivery_date !== undefined) {
      //           return true
      //         } else {
      //           alert("Need Material Delivery Date")
      //           return false
      //         }
      //       } else {
      //         data.material_delivery_date = null
      //         return true
      //       }
      //     } else {
      //       alert('Invalid Quantity')
      //       return false
      //     }
      //   } else {
      //     alert('End date time cannot be prior to start date time');
      //     return false;
      //   }
      // } else {
      //   return true
      // }
      return true;
    }
    else {
      if (data.value <= 0) {
        Swal.fire('Error', 'Value must be greater than 0', 'info')
      } else {
        Swal.fire('Error', 'Fill all the fields', 'info')
      }
      return false
    }
  }

  // openDialog = (task_id) => {
  //   getTaskUpdatesList(this.props.auth.token, task_id)
  //     .then(res => {
  //       this.setState({
  //         openDialog: true,
  //         TaskUpdates: res.data
  //       })
  //     })
  //     .catch(error => console.log(error))
  // }

  // customeClick = () => {
  //   let div = document.getElementsByClassName("MuiTablePagination-selectIcon")[0];
  //   div.id = "myDD";
  //   document.getElementById("myDD").scrollIntoView({
  //     block: "end",
  //     behavior: "smooth"
  //   });
  // }

  render() {
    const { TaskList, SpaceOptions, dependentOnOption, SiteSupervisorOption, VendorOption, value, openDialog, TaskUpdates, taskId, taskName, taskDisId, lineItem, spaceId } = this.state;
    return (
      <React.Fragment>
        {/* <Button className="mb-3" variant="contained" color="primary" onClick={() => { { this.toggleDialogFile(); this.setState({ fileTypeTasks: true }) } }}>Parent Task Bulk Upload/Update</Button>
        <Button className="mb-3 ml-3" variant="contained" color="primary" onClick={() => { { this.toggleDialogFile(); this.setState({ fileTypeTasks: false }) } }}>Sub Task Bulk Upload/Update</Button> */}
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={(e, i) => this.setState({ value: i })}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Services" />
            <Tab label="Installation" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MaterialTable
            title="Services Tasks List"
            columns={[
              // {
              //   title: '',
              //   render: rowData => <button className="btn" onClick={() => this.toggleServicesSubtaskDialog(rowData.id, rowData.name, `P${rowData.project_id}T${rowData.id}`, rowData.office_space_id)}><i className="fa fa-plus mr-2" aria-hidden="true"></i>Subtasks {`(${rowData.sub_tasks_count})`}</button>,
              //   editable: false,
              //   filtering: false,
              //   sorting: false
              // },
              {
                title: 'Task ID',
                field: 'id',
                render: rowData => rowData !== undefined ? `P${rowData.project_id}T${rowData.id}` : null,
                editable: false,
                filtering: false
              },
              {
                title: 'Name',
                field: 'name',
                // render: rowData => <p className="cusAnchor" onClick={() => this.openDialog(rowData.id)} >{rowData.name}</p>
              },
              // {
              //   title: 'Description',
              //   field: 'description'
              // },
              {
                title: 'Space',
                field: 'office_space_id',
                lookup: SpaceOptions
              },
              {
                title: 'Site Supervisor',
                field: 'office_user_id',
                lookup: SiteSupervisorOption
              },
              {
                title: 'Vendor',
                field: 'office_vendor_id',
                lookup: VendorOption
              },
              {
                title: 'Dependent on',
                field: 'dependent_on',
                lookup: dependentOnOption
              },
              {
                title: 'Planned Start Date',
                field: 'plan_start_date_time',
                render: rowData => rowData.plan_start_date_time !== null ? moment(rowData.plan_start_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Planned End Date',
                field: 'plan_end_date_time',
                render: rowData => rowData.plan_end_date_time !== null ? moment(rowData.plan_end_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Actual Start Date',
                field: 'start_date_time',
                render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Actual End Date',
                field: 'end_date_time',
                render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Quantity',
                field: 'quantity',
                type: 'numeric',
                align: 'left'
              },
              {
                title: 'Unit of Measurement',
                field: 'unit_of_measurement'
              },
              {
                title: 'Value',
                field: 'value',
                type: 'numeric',
                align: 'left'
              },
              {
                title: 'Status',
                field: 'status',
                render: rowData => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                }
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
            ]}
            data={TaskList.filter(task => task.office_task_type === "services")}
            options={{
              filtering: true,
              exportButton: true,
              addRowPosition: 'first'
            }}
          // icons={{
          //   Add: props => { return (<button className="btn"><i className="fa fa-plus" ></i> Add New Task </button>) }
          // }}
          // editable={{
          //   onRowAdd: newData => new Promise((resolve, reject) => {
          //     newData.status = 0
          //     console.log("newData", newData)
          //     if (newData.length !== 0 && this.servicesValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           let data = {
          //             ...newData,
          //             project_id: this.props.project.id,
          //             office_task_type: 'services'
          //           }
          //           createTask(this.props.auth.token, data)
          //             .then(res => {
          //               if (res.status === 200) {
          //                 Swal.fire('Success', 'Task created successfully.', 'success')
          //                 getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                   .then(res => {
          //                     if (res.status === 200) {
          //                       let dependentOnOption = {}
          //                       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                       this.setState({ TaskList: res.data, dependentOnOption })
          //                     }
          //                   })
          //                   .catch(error => console.log(error))
          //               }
          //             })
          //         }
          //         resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         // {
          //         //   console.log('rejected', newData)
          //         //   Swal.fire('Error', 'Fill all the fields', 'info')
          //         // }
          //         return reject()
          //       }, 1000);
          //     }
          //   }),
          //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          //     if (newData.length !== 0 && this.servicesValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           updateTask(this.props.auth.token, newData)
          //             .then(res => {
          //               if (res.status === 200) {
          //                 Swal.fire('Success', 'Task updated successfully.', 'success')
          //                 getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                   .then(res => {
          //                     if (res.status === 200) {
          //                       let dependentOnOption = {}
          //                       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                       this.setState({ TaskList: res.data, dependentOnOption })
          //                     }
          //                   })
          //                   .catch(error => console.log(error))
          //               }
          //             })
          //         }
          //         return resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         // {
          //         //   console.log('rejected', newData)
          //         //   Swal.fire('Error', 'Fill all the fields', 'info')
          //         // }
          //         return reject()
          //       }, 1000);
          //     }
          //   }),
          //   onRowDelete: oldData => new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       {
          //         deleteTask(this.props.auth.token, oldData.id)
          //           .then(res => {
          //             if (res.status === 200) {
          //               Swal.fire('Success', 'Task deleted successfully.', 'success')
          //               getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                 .then(res => {
          //                   if (res.status === 200) {
          //                     let dependentOnOption = {}
          //                     res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                     this.setState({ TaskList: res.data, dependentOnOption })
          //                   }
          //                 })
          //                 .catch(error => console.log(error))
          //             }
          //           })
          //       }
          //       resolve();
          //     }, 1000);
          //   })
          // }}
          // onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MaterialTable
            title="Installation Tasks List"
            columns={[
              // {
              //   title: '',
              //   render: rowData => <button className="btn" onClick={() => this.toggleInstallationSubtaskDialog(rowData.id, rowData.name, `P${rowData.project_id}T${rowData.id}`, rowData.line_item, rowData.office_space_id)}><i className="fa fa-plus mr-2" aria-hidden="true"></i>Subtasks {`(${rowData.sub_tasks_count})`}</button>,
              //   editable: false,
              //   filtering: false,
              //   sorting: false
              // },
              {
                title: 'Task ID',
                field: 'id',
                render: rowData => rowData !== undefined && <p className="cusAnchor" onClick={() => this.openDialog(rowData.id)} >{`P${rowData.project_id}T${rowData.id}`}</p>,
                editable: false,
                filtering: false
              },
              {
                title: 'Space',
                field: 'office_space_id',
                lookup: SpaceOptions
              },
              {
                title: 'Line Item',
                field: 'line_item'
              },
              {
                title: 'Site Supervisor',
                field: 'office_user_id',
                lookup: SiteSupervisorOption
              },
              {
                title: 'Vendor',
                field: 'office_vendor_id',
                lookup: VendorOption
              },
              // {
              //   title: 'Installation Start Date',
              //   field: 'start_date_time',
              //   render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
              //   type: 'date'
              // },
              // {
              //   title: 'Installation End Date',
              //   field: 'end_date_time',
              //   render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
              //   type: 'date'
              // },
              {
                title: 'Quantity',
                field: 'quantity',
                type: 'numeric',
                align: 'left'
              },
              {
                title: 'Unit of Measurement',
                field: 'unit_of_measurement'
              },
              {
                title: 'Value',
                field: 'value',
                type: 'numeric',
                align: 'left'
              },
              {
                title: 'Current Status',
                field: 'status',
                render: rowData => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                }
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
              // {
              //   title: 'Installation Date',
              //   field: 'installation_date',
              //   render: rowData => rowData.installation_date !== null ? moment(rowData.installation_date).format("ll") : null,
              //   type: 'date'
              // },
              // {
              //   title: 'Material Delivery Status',
              //   field: 'material_delivery_status',
              //   render: rowData => <p>{this.materialDeliveryStatus(rowData.material_delivery_status)}</p>,
              //   lookup: {
              //     0: "Not Delivered",
              //     1: "Partial Delivery",
              //     2: "Delivered",
              //   }
              // },
              // {
              //   title: 'Material Delivery Date',
              //   field: 'material_delivery_date',
              //   render: rowData => rowData.material_delivery_status === 2 ? moment(rowData.material_delivery_date).format("ll") : null,
              //   type: 'date'
              // },
            ]}
            data={TaskList.filter(task => task.office_task_type === "installation")}
            options={{
              filtering: true,
              exportButton: true,
              addRowPosition: 'first'
            }}
          // icons={{
          //   Add: props => { return (<button className="btn"><i className="fa fa-plus" ></i> Add New Task </button>) }
          // }}
          // editable={{
          //   onRowAdd: newData => new Promise((resolve, reject) => {
          //     if (newData.length !== 0 && this.installationValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           let data = {
          //             ...newData,
          //             project_id: this.props.project.id,
          //             office_task_type: 'installation'
          //           }
          //           createTask(this.props.auth.token, data)
          //             .then(res => {
          //               if (res.status === 200) {
          //                 Swal.fire('Success', 'Task created successfully.', 'success')
          //                 getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                   .then(res => {
          //                     console.log('res', res);
          //                     if (res.status === 200) {
          //                       let dependentOnOption = {}
          //                       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                       this.setState({ TaskList: res.data, dependentOnOption })
          //                     }
          //                   })
          //                   .catch(error => console.log(error))
          //               }
          //             })
          //         }
          //         resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         // {
          //         //   console.log('rejected', newData)
          //         //   Swal.fire('Error', 'Fill all the fields', 'info')
          //         // }
          //         return reject()
          //       }, 1000);
          //     }
          //   }),
          //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          //     if (newData.length !== 0 && this.installationValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           updateTask(this.props.auth.token, newData)
          //             .then(res => {
          //               if (res.status === 200) {
          //                 Swal.fire('Success', 'Task updated successfully.', 'success')
          //                 getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                   .then(res => {
          //                     if (res.status === 200) {
          //                       let dependentOnOption = {}
          //                       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                       this.setState({ TaskList: res.data, dependentOnOption })
          //                     }
          //                   })
          //                   .catch(error => console.log(error))
          //               }
          //             })
          //         }
          //         return resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         // {
          //         //   console.log('rejected', newData)
          //         //   Swal.fire('Error', 'Fill all the fields', 'info')
          //         // }
          //         return reject()
          //       }, 1000);
          //     }
          //   }),
          //   onRowDelete: oldData => new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       {
          //         deleteTask(this.props.auth.token, oldData.id)
          //           .then(res => {
          //             if (res.status === 200) {
          //               Swal.fire('Success', 'Task deleted successfully.', 'success')
          //               getTaskList(this.props.auth.token, { project_id: this.props.project.id })
          //                 .then(res => {
          //                   if (res.status === 200) {
          //                     let dependentOnOption = {}
          //                     res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
          //                     this.setState({ TaskList: res.data, dependentOnOption })
          //                   }
          //                 })
          //                 .catch(error => console.log(error))
          //             }
          //           })
          //       }
          //       resolve();
          //     }, 1000);
          //   })
          // }}
          // onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false, TaskUpdates: [] })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => moment(rowData.created_at).format("ll"),
                  type: 'date'
                },
              ]}
              data={TaskUpdates}
              options={{
                filtering: true,
                exportButton: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false, TaskUpdates: [] })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openDialogFile}
          onClose={() => this.toggleDialogFile()}
          aria-labelledby="form-dialog-title"
          style={{ height: '100vh' }}
          maxWidth={'md'}
        >
          <DialogTitle id="form-dialog-title">
            Upload File
            <br />
            <Button
              variant="contained"
              color="secondary"
              style={styles.button}
              startIcon={<CloudDownloadIcon />}
              onClick={() => window.open(this.state.fileTypeTasks ? `${process.env.REACT_APP_API_BASE_URL}/task_bulk_upload.xlsx` : `${process.env.REACT_APP_API_BASE_URL}/subtask_bulk_upload.xlsx`)}
            >
              Download Template for Bulk Upload
            </Button>
          </DialogTitle>
          <ValidatorForm
            onSubmit={() => this.onSubmitTaskUpload()}
            onError={errors => console.log(errors)}
            instantValidate={false} >
            <DialogContent style={{ minWidth: '400px' }}>
              <ReactDropzone onDrop={this.handleFiles}>
                Drop your file here!!
              </ReactDropzone>
              <aside style={styles.thumbsContainer}>
                {this.state.newFile.name}
              </aside>
              <DialogActions>
                <Button
                  className="pull-right"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: '20px' }}
                  onClick={() => this.toggleDialogFile()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary">
                  Upload Files
                </Button>
              </DialogActions>
            </DialogContent>
          </ValidatorForm>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false, TaskUpdates: [] })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => moment(rowData.created_at).format("ll"),
                  type: 'date'
                },
              ]}
              data={TaskUpdates}
              options={{
                filtering: true,
                exportButton: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false, TaskUpdates: [] })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openServicesSubtaskDialog}
          onClose={() => { { this.toggleServicesSubtaskDialog(); this.getData() } }}
          aria-labelledby="subtask-dialog"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogSubtaskTitle onClose={() => { { this.toggleServicesSubtaskDialog(); this.getData() } }} className="text-capitalize">{taskName} <span className="text-secondary">{`(${taskDisId})`}</span> {SpaceOptions && SpaceOptions[spaceId]}, Subtasks List:</DialogSubtaskTitle>
          <DialogContent className="p-2">
            <SubtaskListServices project={this.props.project} VendorOption={VendorOption} taskId={taskId} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openInstallationSubtaskDialog}
          onClose={() => { { this.toggleInstallationSubtaskDialog(); this.getData() } }}
          aria-labelledby="subtask-dialog"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogSubtaskTitle onClose={() => { { this.toggleInstallationSubtaskDialog(); this.getData() } }} className="text-capitalize">{lineItem} <span className="text-secondary">{`(${taskDisId})`}</span> {SpaceOptions && SpaceOptions[spaceId]}, Subtasks List:</DialogSubtaskTitle>
          <DialogContent className="p-2">
            <SubtaskListInstallation project={this.props.project} VendorOption={VendorOption} taskId={taskId} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(TaskList);
