import React, { Component } from 'react';
import { getThumbnail } from '../../api';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Slide01 from '../../images/moodboard/CurtainsByArrivae-1.png';
import Slide02 from '../../images/moodboard/CurtainsByArrivae-2.png';
import Slide03 from '../../images/moodboard/CurtainsByArrivae-3.png';
import Slide04 from '../../images/moodboard/CurtainsByArrivae-4.png';
import LastSlide from '../../images/moodboard/slide_last.png'

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class SlideShowCurtain extends Component {

    state = {
        slideList: []
    }

    componentDidMount() {
        if (this.props.match.params && this.props.match.params.slide_list.length !== 0) {
            getThumbnail(decodeURI(this.props.match.params.slide_list))
                .then(res => {
                    if (res.status) {
                        this.setState({ slideList: res.data })
                    }
                })
                .catch(error => console.log(error))
        }

    }

    render() {
        const { slideList } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen={true}
                open={true}>
                <div className={classes.root} >
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <img src={Slide01} width={'100%'} alt="Arrivae" />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <img src={Slide02} width={'100%'} alt="Arrivae" />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <img src={Slide03} width={'100%'} alt="Arrivae" />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <img src={Slide04} width={'100%'} alt="Arrivae" />
                            </Paper>
                        </Grid>
                    </Grid>


                    {slideList && slideList.map((curtains, index) => {
                        if (curtains.is_active) {
                            return (
                                <Grid container spacing={24} key={index}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <img src={curtains.thumbnail.url} width={'100%'} alt="Arrivae" />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )
                        }
                        else {
                            return false
                        }
                    })
                    }
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <img src={LastSlide} width={'100%'} alt="Arrivae" />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

            </Dialog>

        )
    }
}

export default withStyles(styles)(SlideShowCurtain);
