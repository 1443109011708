import { validateToken } from '../api'

export function loadState() {
  try {
    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');
    const accessTokenState = localStorage.getItem('access-token');
    if(client === null || uid === null || accessTokenState === null) {
      return undefined;
    }
    return {
      "access-token": JSON.parse(accessTokenState),
      "client": JSON.parse(client),
      "uid": JSON.parse(uid)
    }
  } catch (err) {
    console.info('Session storage cannot be accessed!')
    return undefined;
  }
}

export function saveState(accessToken) {
  try {
    const client = JSON.stringify(accessToken.client);
    const uid = JSON.stringify(accessToken.uid);
    const accessTokenState = JSON.stringify(accessToken["access-token"]);
    localStorage.setItem('client', client);
    localStorage.setItem('uid', uid);
    localStorage.setItem('access-token', accessTokenState);
  } catch(err) {
    console.info('Saving state to session storage failed');
  }
}

export function saveCurrentUser(currentUser) {
  try {
    localStorage.setItem('current_user', JSON.stringify(currentUser));
  } catch(err) {
    console.info('Saving state to session storage failed');
  }
}

export function loadCurrentUser() {
  try {
    const currentUser = localStorage.getItem('current_user');
    if(currentUser === null) {
      return undefined;
    }
    return JSON.parse(currentUser)
  } catch (err) {
    console.info('Session storage cannot be accessed!')
    return undefined;
  }
}

export function saveAdmin(token) {
  try {
    localStorage.setItem('admin_user', JSON.stringify(token));
  } catch(err) {
    console.info('Saving state to session storage failed');
  }
}

export function loadAdmin() {
  try {
    let admin = null;
    if(localStorage.getItem('admin_user')) {
      admin = true;
    }else {
      admin = false;
    }
    return admin;
  } catch (error) {
    
  }
}

export function switchToAdmin() {
  try {
    const adminUser = JSON.parse(localStorage.getItem('admin_user'));
    localStorage.setItem('client', JSON.stringify(adminUser.client));
    localStorage.setItem('uid', JSON.stringify(adminUser.uid));
    localStorage.setItem('access-token', JSON.stringify(adminUser['access-token']));
    localStorage.removeItem('admin_user');
    var accessToken = {
      "access-token": adminUser['access-token'],
      "client": adminUser.client,
      "uid": adminUser.uid
    }
    validateToken(accessToken)
    .then(response => {
      if(response.status) {
        saveCurrentUser(response.data.data);
        window.location.reload(true);
      } else {
        //failed
        console.log("Token is invalid");
      }
    })
  } catch(err) {
    console.info('Saving state to session storage failed');
  }
}