import axios from '../base';

export function getUsersList(accessToken) {
  return axios.request({
    url: '/api/v1/office_users/list',
    method: 'GET',
    headers: accessToken
  })
};

export function changeUserRole(accessToken, userData) {
  return axios.request({
    url: '/api/v1/office_users/change_user_role',
    method: 'POST',
    headers: accessToken,
    data: userData,
  })
};

export function loginAsUser(accessToken, userData) {
  return axios.request({
    url: '/api/v1/office_users/login_as_user',
    method: 'POST',
    headers: accessToken,
    data: userData,
  })
};

export function getActivityLogs(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_activity_logs',
    method: 'GET',
    headers: accessToken,
    params: data,
  })
};

export function getApiRoutes(accessToken) {
  return axios.request({
    url: '/api/v1/office_activity_logs/get_routes_list',
    method: 'GET',
    headers: accessToken,
  })
};