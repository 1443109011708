import React from "react";

class GanttChart extends React.Component {
  state = {
  };

  componentWillReceiveProps(nextProps){
    if(nextProps.data!==this.state.data){
      this.setState({operationList: nextProps.data});
      const google = window.google;

      let drawChart = () => {
        var data = new google.visualization.DataTable(
        { cols: [
            {type:'string', id: 'Task ID'},
            {type:'string', id: 'Task Name'},
            {type: 'date', id: 'Start Date'},
            {type: 'date', id: 'End Date'},
          ],
        rows: this.state.operationList }
        );
        
        var options = {
          height: 300,
          timeline: {
            groupByRowLabel: true
          }
        };
        var chart = new google.visualization.Timeline(document.getElementById('chart_div'));
        chart.draw(data, options);
        let selectHandler = () => {
          var selection = chart.getSelection();
          if (selection.length > 0) {
            let operationId = data.getValue(selection[0].row, 1)
            this.props.onClick(operationId);
          }
        };
        google.visualization.events.addListener(chart, 'select', selectHandler); 	
      }

      google.charts.load('upcoming', {'packages':['timeline']});
      google.charts.setOnLoadCallback(drawChart);
    }

    else return null;
  }

  render() {
    return (
      <div id="chart_div"></div>
    );
  }
}

export default GanttChart;