import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar} from 'material-table'
import { getSnagQuotationList, getSnagList, getProjectSiteSupervisorAndPMList, getOfficeNatureOfIssues, getOfficeMistakeHead, updateSnags, addSnags, createSnags, snagClose, deleteSnag, SnagImgDelete } from '../../../api';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Badge, Select, AppBar, Tabs, Tab, Box, Typography } from '@material-ui/core';
import ReactDropzone from "react-dropzone";
import FileIcon from '@material-ui/icons/AttachFile';
import Swal from "sweetalert2";
import { ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { AddComment } from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';



const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror: {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};


const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
  width: '50vh',
  height: '50vh'
}

const createimg = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
}



const updateimgicon = {
  position : 'absolute',
  right : '6%',
  cursor : 'pointer',
  background: '#3f51b5',
  top: '3%',
  borderRadius: '50%',
  fontSize: '2rem',
  color: 'white'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
class Snags extends Component {
  
  state = {
    value: 0,
    Snags: [],
    editHardware:false,
    editRm:false,
    SiteSupervisorList: [],
    SnagStatus: {
      0: 'Not Resolved',
      1: 'In Progress',
      2: 'Resolved'
    },
    SnagIsUrgent: {
      true: 'Yes',
      false: 'No'
    },
    openDialog: false,
    newSnag: {
      project_id: this.props.project.id,
      files: null
    },
    files: [],
    filesDialog: false,
    filesList: [],
    filesList_one: [],
    addfilesDialog : false,
    fullImageDialog : false,
    base64Data : null,
    storeimages : [],
    newFile: {
      projectId: null,
      files: '',
    },
    displayCreateFile : false,
    arrayofImages : [],
    singleRowData : {},
    singleImage : '',
    isLoading : false,
    scopeofWorkValue : '',
     ScopeOfWork :[
      {id : '1' , value: 'kitchen', name : 'Kitchen'},
      {id : '2' , value: 'panel', name : 'Panel'},
      {id : '3' , value: 'mto', name : 'MTO'}
    ]
  };

  async componentDidMount() {
    if (this.props.project) {
      try {
        const SiteSupervisorListRes = await getProjectSiteSupervisorAndPMList(this.props.auth.token, this.props.project.id)
        // const snag_quotation = await getSnagQuotationList(this.props.auth.token, this.props.project.id)
        const SnagsRes = await getSnagList(this.props.auth.token, this.props.project.id)
        // const OfficeNatureOfIssuesRes = await getOfficeNatureOfIssues(this.props.auth.token, this.props.project.id)
        // const OfficeMistakeHeadRes = await getOfficeMistakeHead(this.props.auth.token, this.props.project.id)

        console.log("SnagsRes***",SnagsRes)
        // console.log("snag_quotation***",snag_quotation)
        // let SnagQuotationList = {}
        // snag_quotation.data.map(i => SnagQuotationList[i.id] = i.reference_number)
        // console.log("SnagQuotationList***",SnagQuotationList)

        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

        let OfficeNatureOfIssuesOptions = {}
        // OfficeNatureOfIssuesRes.data.map(i => OfficeNatureOfIssuesOptions[i.id] = i.name)

        let OfficeMistakeHeadOptions = {}
        // OfficeMistakeHeadRes.data.map(i => OfficeMistakeHeadOptions[i.id] = i.name)

        const snagHardwareData = SnagsRes.data.filter((data) => data.product_type == 'hardware')
        const snagRMData = SnagsRes.data.filter((data) => data.product_type == 'rm')
        console.log(snagHardwareData,snagRMData)
        this.setState({
          Snags: this.state.value == 1 ? snagRMData : snagHardwareData,
          SiteSupervisorList: SiteSupervisorListRes.data,
          SiteSupervisorOption,
          OfficeNatureOfIssuesOptions,
          OfficeMistakeHeadOptions,
          // SnagQuotationList,
          // snag_quotation
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  // SnagValidation = (data) => {
  //   if (
  //     (data.user_id !== "" && data.user_id !== null && data.user_id !== undefined) &&
  //     (data.product !== "" && data.product !== null && data.product !== undefined) &&
  //     (data.description !== "" && data.description !== null && data.description !== undefined) &&
  //     (data.item_required !== "" && data.item_required !== null && data.item_required !== undefined) &&
  //     (data.size !== "" && data.size !== null && data.size !== undefined) &&
  //     (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
  //     (data.office_snag_nature_of_issue_id !== "" && data.office_snag_nature_of_issue_id !== null && data.office_snag_nature_of_issue_id !== undefined) &&
  //     (data.office_mistake_head_id !== "" && data.office_mistake_head_id !== null && data.office_mistake_head_id !== undefined) &&
  //     (data.status !== "" && data.status !== null && data.status !== undefined) &&
  //     (data.is_urgent !== "" && data.is_urgent !== null && data.is_urgent !== undefined)
  //   ) {
  //     if ((data.size >= 0) && (data.quantity >= 0)) {
  //       return true
  //     } else if (data.size < 0) {
  //       alert('size is negative')
  //       return false
  //     } else if (data.quantity < 0) {
  //       alert('quantity is negative')
  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  SnagValidation = (data) => {
    if (
      (data.item_required !== "" && data.item_required !== null && data.item_required !== undefined) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.quotation_id !== "" && data.quotation_id !== null && data.quotation_id !== undefined) &&
      (data.rm_finish !== "" && data.rm_finish !== null && data.rm_finish !== undefined) &&
      (data.mistake_head !== "" && data.mistake_head !== null && data.mistake_head !== undefined) &&
      (data.snag_type !== "" && data.snag_type !== null && data.snag_type !== undefined) &&
      (data.rm_width !== "" && data.rm_width !== null && data.rm_width !== undefined) &&
      (data.rm_depth !== "" && data.rm_depth !== null && data.rm_depth !== undefined) &&
      (data.rm_height !== "" && data.rm_height !== null && data.rm_height !== undefined) &&
      (data.rm_material_and_thickness !== "" && data.rm_material_and_thickness !== null && data.rm_material_and_thickness !== undefined) 
      // (data.rm_front_finish !== "" && data.rm_front_finish !== null && data.rm_front_finish !== undefined) &&
      // (data.rm_back_finish !== "" && data.rm_back_finish !== null && data.rm_back_finish !== undefined) &&
      // (data.rm_edge_band !== "" && data.rm_edge_band !== null && data.rm_edge_band !== undefined) &&
      // (data.rm_problem_statement !== "" && data.rm_problem_statement !== null && data.rm_problem_statement !== undefined)
    ) {
      if (data.quantity >= 0) {
        return true
      } else if (data.quantity < 0) {
        alert('quantity is negative')
        return false
      }
    } else {
      return false
    }
  }

  SnagHardwareValidation = (data) => {
    if((data.item_required !== "" && data.item_required !== null && data.item_required !== undefined) &&
      (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.quotation_id !== "" && data.quotation_id !== null && data.quotation_id !== undefined) &&
      (data.mistake_head !== "" && data.mistake_head !== null && data.mistake_head !== undefined) &&
      (data.snag_type !== "" && data.snag_type !== null && data.snag_type !== undefined) &&
      (data.hw_item_code !== "" && data.hw_item_code !== null && data.hw_item_code !== undefined) &&
      (data.hw_brand !== "" && data.hw_brand !== null && data.hw_brand !== undefined)
    ) {
      if (data.quantity >= 0) {
        return true
      } else if (data.quantity < 0) {
        alert('quantity is negative')
        return false
      }
    } else {
      return false
    }
  }

  onDrop = (files) => {
    let newSnag = { ...this.state.newSnag };
    newSnag.files = files
    this.setState({
      newSnag,
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
    console.log(files);
    this.setState({storeimages :  [...this.state.storeimages, files[0]]})
    console.log(this.state.storeimages)
  }

  onDropCopy = (files) => {
    let newFile = { ...this.state.newFile };
    console.log(files);
    newFile.files = files
    this.setState({
      newFile,
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
    console.log(newFile);
  }

  createUploadImage(e){
    e.preventDefault()
    const { newFile } = this.state;
    // console.log(this.state.newSnag);
    console.log(newFile);
    for (const i in newFile.files) {
      if (newFile.files.hasOwnProperty(i)) {
        console.log((`file_${i}`, newFile.files[i]));
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    let newSnag = { ...this.state.newSnag };
    const fd = new FormData();
    fd.append('office_snag[project_id]', this.props.project.id)
    fd.append('office_snag[file_length]', newSnag.files.length)
    for (let i in newSnag.files) {
      if (newSnag.files.hasOwnProperty(i)) {
        fd.append(`office_snag[file][${parseInt(i) + 1}]`, newSnag.files[i])
      }
    }
    createSnags(this.props.auth.token, fd)
      .then(res => {
        if (res.status === 200) {
          alert(res.data)
        }
      })
      .catch(error => console.log(error))

  }
  handleStatusChange(value) {
    console.log("value***",value)
  }

  onTabChange = (e, i) => {
    this.setState({
      value: i
    });
    this.setState({ isLoading: true })
    getSnagList(this.props.auth.token, this.props.project.id).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        this.setState({ isLoading: false })
        const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
        const snagRMData = res.data.filter((data) => data.product_type == 'rm')
        console.log(snagHardwareData,snagRMData)
        this.setState({
          Snags: i == 1 ? snagRMData : snagHardwareData,
        })
      }
    });
  };

  onClickFileBadge = (e, rowData) => {
    this.setState({
      arrayofImages : []
      })
      this.setState({ filesDialog: true, filesList: rowData.files })
      this.setState({
        singleRowData : rowData 
      })
  }

  updateImages = (e) => {
    let singleData = this.state.singleRowData
    let data = {
      ...singleData,
      project_id : this.props.project.id,
      product_type :  this.state.value === 0 ? 'hardware' : 'rm',
      file_length : this.state.arrayofImages.length,
      origin : 'web'
    }
    this.setState({ isLoading: true })
    this.setState({ filesDialog: false })
    this.state.arrayofImages.map((item, index) =>{data[`file_${index + 1 }`] = item;})
    updateSnags(this.props.auth.token, data)
    .then(res => {
      if (res.status === 200) {
        this.setState({ isLoading: false })
        Swal.fire(
          "Success",
          "Snag updated successfully.",
          "success"
        );
        this.setState({
        arrayofImages : []
      })
        getSnagList(this.props.auth.token, this.props.project.id)
        .then(res => {
          if (res.status === 200) {
            const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
            const snagRMData = res.data.filter((data) => data.product_type == 'rm')
            this.setState({
              Snags: this.state.value == 1 ? snagRMData : snagHardwareData,
            })
          }
        })
        .catch(error => console.log(error))
      }  
    })
  }
  onUploadImages = () => {
    console.log('image upload')
    this.setState({
      addfilesDialog : true
    })
  }

  deleteSnagImg = (e, id) => {
    this.setState({ filesDialog: false }) 
    if(window.confirm("Are you sure to delete this image ") == true){
      this.setState({ isLoading: true })
      SnagImgDelete(this.props.auth.token , id)
      .then(res => {
        if(res.status === 200) {
            Swal.fire(
              "Success",
              "Snag image deleted  successfully.",
              "success"
            )
            getSnagList(this.props.auth.token, this.props.project.id)
            .then(res => {
              if (res.status === 200) {
                this.setState({ isLoading: false })
                const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
                const snagRMData = res.data.filter((data) => data.product_type == 'rm')
                this.setState({
                  Snags: this.state.value == 1 ? snagRMData : snagHardwareData,
                })
              }
            })
            .catch(error => console.log(error))
        }
      })
    }
  }
  fullImage = (e, file) => {
    console.log(file.file);
    this.setState({
      fullImageDialog : true
    })
    this.setState({
      singleImage : file.file
    })
  }
  createfile = () => {
    this.setState({
      displayCreateFile : true
    })
  }
  onClickClose = (e, rowData) => {
    snagClose(this.props.auth.token, rowData.id).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        Swal.fire("Success", 'Successfully closed', "success");
        getSnagList(this.props.auth.token, this.props.project.id).then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
            const snagRMData = res.data.filter((data) => data.product_type == 'rm')
            console.log(snagHardwareData,snagRMData)
            this.setState({
              Snags: this.state.value == 1 ? snagRMData : snagHardwareData,
            })
          }
        });
      }
    })
    .catch(err => {
      Swal.fire('Error', err.response.data.message, 'error')
    });
  }
  handleFileInputChange = async (e) => {
    let length = e.target.files.length
    let totalfiles = e.target.files
    for(var i = 0 ; i<length; i++){
      const file = totalfiles[i];
      var base64 = await this.convertBase64(file);
          this.setState({
        arrayofImages : [ ...this.state.arrayofImages , base64]
    })
    }
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  changeScopeofWork = (e,value , rowData) => {
    console.log(value);
    console.log(rowData);
    var arr = []
    var data = rowData.rowData ;
    data.quotation_id = null;
    data.quotation_listt =[];
    console.log(data.quotation_listt , 'befor calling function');
    console.log(data.quotation_id , 'befor calling function');
    getSnagQuotationList(this.props.auth.token , this.props.project.id , value).then((res) => {
      console.log(res , 'res with scope of work' , value);
      res.data.map(e => {
        arr.push({
          id : e.id,
          reference_number : e.reference_number
        })
        console.log(arr , 'arr');
        var data = rowData.rowData ;
        data.quotation_listt =arr;
        data.scope_of_work = value
        rowData.onRowDataChange(data);
      })
    } , (err) => {
      console.log(err);
      var data = rowData.rowData ;
      data.quotation_listt =[];
      data.scope_of_work = value
      rowData.onRowDataChange(data);
      data.quotation_id = null
      Swal.fire(
        "error",
        err.response.data.message,
        "error"
      );

    } )
  }
  changeQuotation = (e , value , rowData) => {
    console.log(value);
    var data = rowData.rowData ;
    data.quotation_id = value
    rowData.onRowDataChange(data);
  }
  render() {
    const { classes } = this.props;
    const { Snags,base64Data, SiteSupervisorOption, OfficeNatureOfIssuesOptions,
      OfficeMistakeHeadOptions, SnagStatus, SnagIsUrgent, openDialog, files, addfilesDialog, fullImageDialog, filesDialog, filesList, filesList_one, SnagQuotationList, defaultColumn, value, snag_quotation } = this.state;
    const thumbs = files.map(file => (
      <div style={thumb}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            alt="Arrivae"
          />
        </div>
      </div>
    ));
    // const ScopeOfWork = {
    //   'kitchen' : 'Kitchen',
    //   'panel' : 'Panel',
    //   'mto' : 'MTO'
    // }

    // console.log(SnagQuotationList);

    // const ScopeOfWork = [
    //   {id : '1' , value: 'kitchen', name : 'Kitchen'},
    //   {id : '2' , value: 'panel', name : 'Panel'},
    //   {id : '3' , value: 'mto', name : 'MTO'}
    // ]
    
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={(e, i) => this.onTabChange(e, i)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Hardware" />
            <Tab label="RM" />
          </Tabs>
        </AppBar>
        <TabPanel className="position-relative" value={value} index={0}>
          <MaterialTable 
            title="Snags List"
            isLoading = {this.state.isLoading}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ margin: '10px' }}>
                    {/* <Button onClick={() => this.setState({ openDialog: true })}>Create Snag</Button> */}
                  </div>
                </div>
              ),
            }}
            columns={[
              {
                title: 'Images',
                field: 'files',
                cellStyle : {
                  cursor : 'pointer'
                },
                // editable : 'never',
                render: rowData => {
                    return(
                      <Tooltip title="Images" aria-label="add">
                      <Badge
                        className={classes.button}
                        color="secondary"
                        badgeContent={rowData.files.length}
                        invisible={rowData.files.length > 0 ? false : true}
                        onClick={(e) => this.onClickFileBadge(e, rowData)}
                      >
                        <FileIcon color={rowData.files.length > 0 ? "primary" : "disabled"} />
                      </Badge>
                    </Tooltip>
                    )
                },
                editComponent : (rowData) => {
                    if(rowData.value === undefined){
                      return(
                        <Tooltip title="Files" aria-label="add">
                        <Badge
                          className={classes.button}
                          color="secondary"
                          onClick={() => this.onUploadImages()}
                        >
                          <FileIcon/>
                        </Badge>
                      </Tooltip>
                      )
                    }else{
                      return(
                        <Tooltip title="Files" aria-label="add">
                        <Badge
                          className={classes.button}
                          color="secondary"
                          badgeContent={rowData.rowData.files.length}
                          // invisible={rowData.rowData.files.length > 0 ? false : true}
                          onClick={(e) => this.onClickFileBadge(e, rowData.rowData)}
                        >
                          <FileIcon color={rowData.rowData.files.length > 0 ? "primary" : "disabled"} />
                        </Badge>
                      </Tooltip>
                      )
                    }
                },
              },
              {
                title : 'Snag Type',
                field : 'snag_type',
                align : 'left',
                // editable : 'never',
                cellStyle : {
                  padding : '0'
                },
                headerStyle : {
                  paddingLeft : '0'
                },
                lookup: {
                  'snag': 'Snag',
                  'short_supply': 'Short Supply',
                },
              },
              {
                title : 'Scope Of Work',
                field : 'scope_of_work',

                render : (rowData) => {
                  return(
                    <>
                      <span className='text-capitalize' >{rowData.scope_of_work === 'mto' ? 'MTO' : rowData.scope_of_work }</span>
                    </>
                  )
                },
                editComponent : (rowData) => {
                  console.log(rowData);
                    return(
                      <>
                      <Select value={rowData.rowData.scope_of_work} disabled={rowData.rowData.sli_created} className='text-capitalize'  onChange={ (e) => {
                        this.changeScopeofWork(e,e.target.value , rowData)
                      } } >
                        {this.state.ScopeOfWork.map((e) => {
                              return(
                            <MenuItem  key={e.id} value={e.value}>
                            {e.name}
                          </MenuItem>
                          )
                        })}
                      </Select >
                      </>
                    )
                }
              },
              {
                title: 'Snag ID',
                field: 'unique_id',
                editable: 'never'
              },
              {
                title: 'BOQ Number',
                field: 'quotation_id',
                align: 'left',
                cellStyle : {
                  textAlign : 'left',
                },
                render : (rowData) => {
                  return(
                    <div>
                      {rowData.reference_number}
                    </div>
                  )
                },
                editComponent : (rowData) =>{
                  console.log(rowData , 'root quotation ');
                  console.log(rowData.rowData,  'list of quotation eeeee');
                  console.log(rowData.rowData.quotation_listt,  'list of quotation jfdslkjflkdsjf');
                    return(
                      <Select value={rowData.rowData.quotation_id} disabled={rowData.rowData.sli_created}  onChange = {  (e) => {
                      this.changeQuotation(e , e.target.value , rowData)
                    } } >
                      <MenuItem value=""  disabled >Select</MenuItem>
                      {  rowData?.rowData?.quotation_listt?.map((e) => {
                        return(
                          <MenuItem  key={e.id} value={e.id}>
                          {e.reference_number}
                        </MenuItem>
                        )
                      })}
                    </Select>
                  )
                },
                editable: (_, rowData) => rowData && rowData.sli_created == true ? false : true
              },
              { title: 'Mistake Head', field: 'mistake_head',
                lookup : {
                  'category' : 'Category',
                  'community' : 'Community',
                  'fulfillment' : 'Fulfillment',
                  'validation' : 'Validation',
                  'production' : 'Production'
                }
              },
              { title: 'Item Name', field: 'item_required'},
              { title: 'Quantity', field: 'quantity' , type: 'numeric' , align : 'left'},
              { title: 'Brand', field: 'hw_brand' },
              { title: 'Item Code', field: 'hw_item_code'},
              {
                title : 'Additional Comments',
                field : 'additional_comments',
                render : rowData => {
                  return (
                    <>
                      {rowData.additional_comments ? rowData.additional_comments : '---'}
                    </>
                  )
                }
              },
              { title: 'Close Snag', field: 'snag',
                render: rowData => {
                  return (
                    <Tooltip title="Close Snag" aria-label="add">
                      {rowData?.status != 'closed' ? <a
                        onClick={(e) => this.onClickClose(e, rowData)}
                        disabled={rowData?.status == 'closed' ? true : false}
                        style = {{color:'#3f51b5', cursor:'pointer'}}
                      >
                        Close Snag
                      </a>
                      :
                      <p style={{color:'#000000DE', fontSize:'16px'}}>Closed</p>
                      }
                    </Tooltip>
                  )
                },
                editable: 'never'
              },
              {
                title: 'Status' , field: 'status',editable:'never',
                cellStyle: {
                  textTransform : 'capitalize'
                },
                render : rowData => {
                  return (
                    <>
                      {rowData.status == 'po_released' ? 'PO Released' :  rowData.status }
                    </>
                  )
                }
              }
            ]}
            // onRowClick={(event, rowData, togglePanel) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
            data={Snags}
            options={{
              // filtering: true,
              addRowPosition: 'first'
            }}
          
            icons={{
              Add: (props) => {
                return (
                  <button 
                  className="btn">
                    <i className="fa fa-plus"></i> Add New Snag{" "}
                  </button>
                );
              },
            }}
            editable={{
            isEditable: rowData => rowData.status === 'pending',
            isDeletable: rowData => rowData.status === 'pending',
            onRowAddCancelled: rowData => console.log('Row adding cancelled'),
            onRowUpdateCancelled: rowData => console.log('Row editing cancelled'),
            onRowAdd: newData =>
            new Promise((resolve, reject) => {
              console.log('trigger row add');
              if (this.SnagHardwareValidation(newData)) {
                this.setState({ isLoading: true })
              setTimeout(() => {
                {
                  let data;
                  data = {
                    ...newData,
                    project_id: this.props.project.id,
                    product_type:'hardware',
                    file_length : this.state.arrayofImages.length,
                    origin : 'web',
                  };
                  this.state.arrayofImages.map((item, index) =>{data[`file_${index + 1 }`] = item;})
                  console.log("data***", data)
                  addSnags(this.props.auth.token, data)
                  .then(res => {
                    if (res.status === 200) {
                      this.setState({ isLoading: false })
                      Swal.fire(
                        "Success",
                        "Snag added successfully.",
                        "success"
                      );
                      console.log(this.state.arrayofImages ,  'set arry to empty');
                      this.setState({
                        arrayofImages : []
                      })
                      getSnagList(this.props.auth.token, this.props.project.id)
                      .then(res => {
                        if (res.status === 200) {
                          const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
                          this.setState({Snags: snagHardwareData})
                        }
                      })
                      .catch(error => console.log(error))
                    }  
                  }).catch(error => {
                    this.setState({ isLoading: false })
                    console.log(error);
                    console.log(error.response.data);
                    if(error.response.data && error.response.data.hasOwnProperty('quantity')){
                      Swal.fire(
                        "error",
                        `${error.response.data.quantity[0]}`,
                        "error"
                      );
                    }else {
                      Swal.fire(
                        "error",
                        `Something Went Wrong`,
                        "error"
                      );
                    }
                  })
                }
                return resolve();
              }, 1000);
              } else {
                return setTimeout(() => {
                  {
                    console.log('rejected', newData)
                    alert("Fill all the fields")
                  }
                  return reject()
                }, 1000);
              }
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              console.log('on Row Update');
              this.setState({
                arrayofImages : []
                })
              if (this.SnagHardwareValidation(newData)) {
                this.setState({ isLoading: true })
                setTimeout(() => {
                  {
                    let data;
                    data = {
                      ...newData,
                      project_id: this.props.project.id,
                      product_type:'hardware',
                      file_length : this.state.arrayofImages.length,
                      origin : 'web'
                    };
                    this.state.arrayofImages.map((item, index) =>{data[`file_${index + 1 }`] = item;})
                    console.log("data***", data)
                    updateSnags(this.props.auth.token, data)
                    .then(res => {
                      if (res.status === 200) {
                        Swal.fire(
                          "Success",
                          "Snag updated successfully.",
                          "success"
                        );
                        this.setState({
                        arrayofImages : []
                        })
                        getSnagList(this.props.auth.token, this.props.project.id)
                        .then(res => {
                          if (res.status === 200) {
                            this.setState({ isLoading: false })
                            const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
                            this.setState({Snags: snagHardwareData})
                          }
                        })
                        .catch(error => console.log(error))
                      }  
                    })
                    .catch(error => {
                      this.setState({ isLoading: false })
                      console.log(error);
                      console.log(error.response.data);
                      if(error.response.data && error.response.data.hasOwnProperty('quantity')){
                        Swal.fire(
                          "error",
                          `${error.response.data.quantity[0]}`,
                          "error"
                        );
                      }else {
                        Swal.fire(
                          "error",
                          `Something Went Wrong`,
                          "error"
                        );
                      }
                    })
                  }
                  return resolve();
                }, 1000);
              } else {
                return setTimeout(() => {
                  {
                    console.log('rejected', newData)
                    alert("Fill all the fields")
                  }
                  return reject()
                }, 1000);
              }
            }),
            onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  deleteSnag(this.props.auth.token, oldData.id).then(
                    (res) => {
                      if (res.status === 200) {
                        Swal.fire(
                          "Success",
                          "Snag deleted successfully.",
                          "success"
                        );
                        getSnagList(this.props.auth.token, this.props.project.id)
                        .then(res => {
                          if (res.status === 200) {
                            const snagHardwareData = res.data.filter((data) => data.product_type == 'hardware')
                            this.setState({Snags: snagHardwareData})
                          }
                        })
                        .catch(error => console.log(error))
                      }
                    }
                  ).catch(error => {
                    console.log(error)
                    Swal.fire('Error' , error.response.data.message, 'error')
                  })
                }
                resolve();
              }, 1000);
            }),
          }}
          />
        </TabPanel>
        <TabPanel className="position-relative" value={value} index={1}>
          <MaterialTable
            isLoading = {this.state.isLoading}
            title="Snags List"
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ margin: '10px' }}>
                    {/* <Button onClick={() => this.setState({ openDialog: true })}>Create Snag</Button> */}
                  </div>
                </div>
              ),
            }}
            columns={[
              {
                title: 'Images',
                field: 'files',
                cellStyle : {
                  cursor : 'pointer'
                },
                render: rowData => {
                  return (
                    <Tooltip title="Images" aria-label="add">
                      <Badge
                        className={classes.button}
                        color="secondary"
                        badgeContent={rowData.files.length}
                        invisible={rowData.files.length > 0 ? false : true}
                        onClick={(e) => this.onClickFileBadge(e, rowData)}
                      >
                        <FileIcon
                          color={rowData.files.length > 0 ? "primary" : "disabled"} />
                      </Badge>
                    </Tooltip>
                  )
                },
                editComponent : (rowData) => {
                  if(rowData.value === undefined){
                    return(
                      <Tooltip title="Files" aria-label="add">
                      <Badge
                        className={classes.button}
                        color="secondary"
                        onClick={() => this.onUploadImages()}
                      >
                        <FileIcon/>
                        {/* <p>create new</p> */}
                      </Badge>
                    </Tooltip>
                    )
                  }else{
                    return(
                      <Tooltip title="Files" aria-label="add">
                      <Badge
                        className={classes.button}
                        color="secondary"
                        badgeContent={rowData.rowData.files.length}
                        invisible={rowData.rowData.files.length > 0 ? false : true}
                        onClick={(e) => this.onClickFileBadge(e, rowData.rowData)}
                      >
                        <FileIcon color={rowData.rowData.files.length > 0 ? "primary" : "disabled"} />
                        {/* <p>Edit</p> */}
                      </Badge>
                    </Tooltip>
                    )
                  }
                }
                // grouping: false,
                // filtering: false,
                // editable: 'never'
              },
              {
                title : 'Snag Type',
                field : 'snag_type',
                align : 'left',
                // editable : 'never',
                cellStyle : {
                  padding : '0'
                },
                headerStyle : {
                  paddingLeft : '0'
                },
                lookup: {
                  'snag': 'Snag',
                  'short_supply': 'Short Supply',
                }
              },

              {
                title : 'Scope Of Work',
                field : 'scope_of_work',

                render : (rowData) => {
                  return(
                    <>
                      <span className='text-capitalize' >{rowData.scope_of_work === 'mto' ? 'MTO' : rowData.scope_of_work }</span>
                    </>
                  )
                },
                editComponent : (rowData) => {
                  console.log(rowData);
                    return(
                      <>
                      <Select value={rowData.rowData.scope_of_work} disabled={rowData.rowData.sli_created} className='text-capitalize'  onChange={ (e) => {
                        this.changeScopeofWork(e,e.target.value , rowData)
                      } } >
                        {this.state.ScopeOfWork.map((e) => {
                              return(
                            <MenuItem  key={e.id} value={e.value}>
                            {e.name}
                          </MenuItem>
                          )
                        })}
                      </Select >
                      </>
                    )
                }
              },

              {
                title: 'Snag ID',
                field: 'unique_id',
                editable: 'never'
              },
              {
                title: 'BOQ Number',
                field: 'quotation_id',
                align: 'left',
                cellStyle : {
                  textAlign : 'left',
                },
                render : (rowData) => {
                  return(
                    <div>
                      {rowData.reference_number}
                    </div>
                  )
                },
                editComponent : (rowData) =>{
                  console.log(rowData , 'root quotation ');
                  console.log(rowData.rowData,  'list of quotation eeeee');
                  console.log(rowData.rowData.quotation_listt,  'list of quotation jfdslkjflkdsjf');
                    return(
                      <Select value={rowData.rowData.quotation_id} disabled={rowData.rowData.sli_created}  onChange = {  (e) => {
                      this.changeQuotation(e , e.target.value , rowData)
                    } } >
                      <MenuItem value=""  disabled >Select</MenuItem>
                      {  rowData?.rowData?.quotation_listt?.map((e) => {
                        return(
                          <MenuItem  key={e.id} value={e.id}>
                          {e.reference_number}
                        </MenuItem>
                        )
                      })}
                    </Select>
                  )
                },
                editable: (_, rowData) => rowData && rowData.sli_created == true ? false : true
              },
              { title: 'Mistake Head', field: 'mistake_head',
                lookup : {
                  'category' : 'Category',
                  'community' : 'Community',
                  'fulfillment' : 'Fulfillment',
                  'validation' : 'Validation',
                  'production' : 'Production'
                }
              },

              // {
              //   title: 'SNAG Item Category', field: 'product_type',
              //   lookup: {
              //     'hardware': 'Hardware',
              //     'rm': 'Module'
              //   },
              // },
              { 
                title: 'Finish', field: 'rm_finish',
                lookup: {
                  'pre_post_lam': 'Pre & Post Lam',
                  'pvc': 'PVC',
                  'acrylic': 'Acrylic',
                  'pu': 'PU',
                }
              },
              { title: 'Item Name', field: 'item_required'},
              { title: 'Problem Statement', field: 'rm_problem_statement',
                render: rowData => {
                  if(rowData.rm_problem_statement){
                    return rowData.rm_problem_statement
                  }else{
                    return '---'
                  }
                }
              },
              { title: 'Quantity', field: 'quantity' , type: 'numeric', align : 'left'},
              { title: 'Width (mm) ', field: 'rm_width' , type : 'numeric' },
              { title: 'Depth (mm)', field: 'rm_depth' , type : 'numeric' },
              { title: 'Height (mm)', field: 'rm_height' , type : 'numeric' },
              { title: 'Material & Thickness', field: 'rm_material_and_thickness' },
              { title: 'Front Finish', field: 'rm_front_finish',
                render : rowData => {
                  if(rowData.rm_front_finish){
                    return rowData.rm_front_finish
                  }else{
                    return '---'
                  }
                }
              },
              { title: 'Back Finish', field: 'rm_back_finish',
                render : rowData => {
                  if(rowData.rm_back_finish){
                    return rowData.rm_back_finish
                  }else {
                    return '---'
                  }
                }
            
            
              },
              { title: 'Edge Band', field: 'rm_edge_band',
                render : rowData => {
                  if(rowData.rm_edge_band){
                    return rowData.rm_edge_band
                  }else {
                  return '---'
                  }
                }
              },
              {
                title : 'Additional Comments',
                field : 'additional_comments',
                render : rowData => {
                  return(
                    <>
                      {rowData.additional_comments ? rowData.additional_comments : '---'}
                    </>
                  )
                }
              },

              { title: 'Close Snag', field: 'snag',
                render: rowData => {
                  return (
                    <Tooltip title="Close Snag" aria-label="add">
                      {rowData?.status != 'closed' ? <a
                        onClick={(e) => this.onClickClose(e, rowData)}
                        disabled={rowData?.status == 'closed' ? true : false}
                        style = {{color:'#3f51b5', cursor:'pointer'}}
                      >
                        Close Snag
                      </a>
                      :
                      <p style={{color:'#000000DE', fontSize:'16px'}}>Closed</p>
                      }
                    </Tooltip>
                  )
                },
                editable: 'never'
              },
              {
                title: 'Status' , field: 'status', editable : 'never',
                cellStyle: {
                  textTransform : 'capitalize'
                },
                render : rowData => {
                  return (
                    <>
                      {rowData.status == 'po_released' ? 'PO Released' :  rowData.status }
                    </>
                  )
                }
              }
            ]}
            // onRowClick={(event, rowData, togglePanel) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
            data={Snags}
            options={{
              // filtering: true,
              addRowPosition: 'first',
            }}
            icons={{
              Add: (props) => {
                return (
                  <button className="btn">
                    <i className="fa fa-plus"></i> Add New Snag{" "}
                  </button>
                );
              },
            }}
            editable={{
            isEditable: rowData => rowData.status === 'pending',
            isDeletable: rowData => rowData.status === 'pending',
            onRowAdd: newData =>
            new Promise((resolve, reject) => {
              if (this.SnagValidation(newData)) {
                setTimeout(() => {
                  {
                    let data;
                    data = {
                      ...newData,
                      project_id: this.props.project.id,
                      product_type:'rm',
                      file_length : this.state.arrayofImages.length,
                      origin : 'web'
                    };
                    this.state.arrayofImages.map((item, index) =>{data[`file_${index + 1 }`] = item;})
                    console.log("data***", data)
                    addSnags(this.props.auth.token, data)
                    .then(res => {
                      if (res.status === 200) {
                        Swal.fire(
                          "Success",
                          "Snag added successfully.",
                          "success"
                        );
                        this.setState({
                          arrayofImages : []
                        })
                        getSnagList(this.props.auth.token, this.props.project.id)
                        .then(res => {
                          if (res.status === 200) {
                            const snagRMData = res.data.filter((data) => data.product_type == 'rm')
                            this.setState({Snags: snagRMData})
                          }
                        })
                        .catch(error => console.log(error))
                      }  
                    }).catch(error => {
                      this.setState({ isLoading: false })
                      console.log(error);
                      console.log(error.response.data);
                      if(error.response.data && error.response.data.hasOwnProperty('quantity')){
                        Swal.fire(
                          "error",
                          `${error.response.data.quantity[0]}`,
                          "error"
                        );
                      }else {
                        Swal.fire(
                          "error",
                          `Something Went Wrong`,
                          "error"
                        );
                      }
                    })
                  }
                  return resolve();
                }, 1000);
              } else {
                return setTimeout(() => {
                  {
                    console.log('rejected', newData)
                    alert("Fill all the fields")
                  }
                  return reject()
                }, 1000);
              }
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              if (this.SnagValidation(newData)) {
                this.setState({ isLoading: true })
                setTimeout(() => {
                  {
                    let data;
                    data = {
                      ...newData,
                      project_id: this.props.project.id,
                      product_type:'rm',
                      origin : 'web',
                      file_length : this.state.arrayofImages.length
                    };
                    this.state.arrayofImages.map((item, index) =>{data[`file_${index + 1 }`] = item;})
                    updateSnags(this.props.auth.token, data)
                    .then(res => {
                      if (res.status === 200) {
                        Swal.fire(
                          "Success",
                          "Snag updated successfully.",
                          "success"
                        );
                        this.setState({
                          arrayofImages : []
                        })
                        getSnagList(this.props.auth.token, this.props.project.id)
                        .then(res => {
                          if (res.status === 200) {
                            this.setState({ isLoading: false })
                            const snagRMData = res.data.filter((data) => data.product_type == 'rm')
                            this.setState({Snags: snagRMData})
                          }
                        })
                        .catch(error => console.log(error))
                      }  
                    }).catch(error => {
                      this.setState({ isLoading: false })
                      console.log(error);
                      console.log(error.response.data);
                      if(error.response.data && error.response.data.hasOwnProperty('quantity')){
                        Swal.fire(
                          "error",
                          `${error.response.data.quantity[0]}`,
                          "error"
                        );
                      }else {
                        Swal.fire(
                          "error",
                          `Something Went Wrong`,
                          "error"
                        );
                      }
                    })
                  }
                  return resolve();
                }, 1000);
              } else {
                return setTimeout(() => {
                  {
                    console.log('rejected', newData)
                    alert("Fill all the fields")
                  }
                  return reject()
                }, 1000);
              }
            }),
            onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  deleteSnag(this.props.auth.token, oldData.id).then(
                    (res) => {
                      if (res.status === 200) {
                        Swal.fire(
                          "Success",
                          "Snag deleted successfully.",
                          "success"
                        );
                        getSnagList(this.props.auth.token, this.props.project.id)
                        .then(res => {
                          if (res.status === 200) {
                            const snagHardwareData = res.data.filter((data) => data.product_type == 'rm')
                            this.setState({Snags: snagHardwareData})
                          }
                        })
                        .catch(error => console.log(error))
                      }
                    },(err => {
                      Swal.fire(
                        "error",
                        err.response.data.message,
                        "error"
                      );
                    })
                  );
                }
                resolve();
              }, 1000);
            }),
          }}
          />
        </TabPanel>


        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <ReactDropzone onDrop={this.onDrop}>
              Drop your file here!!
            </ReactDropzone>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>

            <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={() => this.setState({ openDialog: false })}
            >
              Close
              </Button>
            <Button
              type="submit"
              onClick={this.onSubmit}
              variant="contained"
              color="primary">
              Upload Files
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableBackdropClick
          open={filesDialog}
          onClose={() => this.setState({ filesDialog: false })}
          aria-labelledby="task-updates"
          maxWidth="lg"
          fullWidth={true}
        >


{/* <div class="gallery">
  <a target="_blank" href="img_5terre.jpg">
    <img src="img_5terre.jpg" alt="Cinque Terre" width="600" height="400">
  </a>
  <div class="desc">Add a description of the image here</div>
</div> */}


          <DialogTitle className='mb-0 pb-0' id="task-updates">Update Images</DialogTitle>
          <DialogContent>
                  {filesList.length > 0 ? <div className='row'>
                    {
                      filesList.map((file , index) => (
                        <div  style={{
                          cursor : 'pointer'
                        }} className='mb-3 col-3' key={index}>
                          <div style={{ width: '100%' , height: '200px'}}>
                          { this.state.singleRowData.status == 'pending' ?   <CloseIcon onClick={(e) => {
                            this.deleteSnagImg(e , file.id)
                          }} style = {{
                            position : 'absolute',
                            top : '2%',
                            left : '86%',
                            color : '#3f51b5',
                            cursor : 'pointer',
                            borderRadius : '50%',
                            background : '#ccc'
                          }}  /> : null } 
                        <img onClick={(e) => {
                          this.fullImage(e , file)
                        }} className='img-fluid'
                           src={file.file}
                           alt="Arrivae"
                           style={{
                            width : '100%',
                            height: '100%',
                            objectFit: 'cover'
                           }}
                         />
                         </div>
                       </div>
                      ))
                    }
                  </div> : <>
                    <p className='mb-0' style={{
                      textAlign  : 'center',
                      width : '100%'
                    }} >No Images To Display</p>
                  </> }
          </DialogContent>
          <ValidatorForm
              className={classes.form}
              onSubmit={this.createUploadImage}
              // onError={errors => console.log(errors)}
              instantValidate={false} >
              <DialogContent style={{ minWidth: '400px' }}>
                {/* <ReactDropzone  onDrop={this.onDropCopy} >
                  Drop your Image here!!
                </ReactDropzone> */}
                 {/* <ReactDropzone  onChange={this.handleFileInputChange} >
                  Drop your Image here!!
                </ReactDropzone> */}
                <div className='mb-2'>
                { this.state.singleRowData.status == 'pending' ? <input multiple accept="image/*" type='file' onChange={this.handleFileInputChange} /> : null }
                </div>
                {/* <aside style={styles.thumbsContainer}>
                  {thumbs}
                </aside> */}

                <div>
                  {this.state.arrayofImages.map((e,i) => {
                    // return(
                    //   <aside style={styles.thumbsContainer} >
                    //     <img key={i} src={e} />
                    //   </aside>
                    // )

                    return(
                      <div style={thumb} >
                        <div style={createimg}>
                        <img style={img} alt='Arrivae' key={i} src={e} />
                        </div>
                      </div>
                    )

                  })}
                </div>
              
                <Button
                  className="pull-right"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: '20px' }}
                  // onClick={() => this.setState({ filesDialog: false })}
                  onClick = {() => {
                    this.setState({
                      filesDialog : false
                    })
                    this.setState({
                      arrayofImages : [] 
                    })
                  } }
                >
                  Close
                </Button>
                {this.state.singleRowData.status == 'pending' ? 
               <Button 
               onClick = { (e) => this.updateImages(e)} 
               disabled = {this.state.arrayofImages.length == 0} 
               variant='contained' color='primary'>Update</Button> : null }
                
              </DialogContent>
            </ValidatorForm>

          {/* <DialogActions>
            <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions> */}
        </Dialog>


          {/* for create files dialog */}
        <Dialog
          open={addfilesDialog}
          onClose={() => this.setState({ addfilesDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="add-file">Upload Images</DialogTitle>
          {/* <DialogContent>
            <aside style={thumbsContainer}>
              {
                filesList?.map((file, index) => (
                  <div key={index} style={thumbInner}>
                    <img
                      src={file.file}
                      style={img}
                      alt="Arrivae"
                    />
                  </div>
                ))
              }
            </aside>
          </DialogContent> */}
            <ValidatorForm
              className={classes.form}
              onSubmit={this.createUploadImage}
              // onError={errors => console.log(errors)}
              instantValidate={false} >
              <DialogContent style={{ minWidth: '400px' }}>
                {/* <ReactDropzone  onDrop={this.onDropCopy} >
                  Drop your Image here!!
                </ReactDropzone> */}
                {/* <ReactDropzone multiple onChange={(e)=>this.handleFileInputChange(e)}>
                  Drop your Image here!!
                </ReactDropzone> */}
             
                <div class='mb-2'>
                  <input accept="image/*"  multiple type='file' onChange={(e) => {
                    this.handleFileInputChange(e)
                  }} />
                </div>

                <div>
                  {this.state.arrayofImages.map((e,i) => {
                    // return(
                    //   <aside style={styles.thumbsContainer} >
                    //     <img key={i} src={e} />
                    //   </aside>
                    // )

                    return(
                      <div style={thumb} >
                        <div style={createimg}>
                        <img style={img} alt='Arrivae' key={i} src={e} />
                        </div>
                      </div>
                    )

                  })}
                </div>
              
                <Button
                  className="pull-right"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: '20px' }}
                  onClick={() => this.setState({ addfilesDialog: false })}
                >
                  Close
                </Button>
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary">
                  Upload Files
              </Button> */}
              </DialogContent>
            </ValidatorForm>
        </Dialog>

        <Dialog 
        open = {fullImageDialog}
        onClose={() => this.setState({ fullImageDialog: false })}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        // scroll={'paper'}
        // aria-labelledby="task-updates"
        maxWidth="lg"
        // fullWidth={true}
        >
          <img  style={{ width: 'auto', height: '100%' }}
           src={this.state.singleImage}/>
          <Button
          className="pull-right"
          type="button"
          variant="contained"
          onClick={() => this.setState({ fullImageDialog: false })}
          >
          Close
        </Button>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(Snags);
