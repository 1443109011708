
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import PendingIcon from '@material-ui/icons/Timer';
import { loadAdmin, switchToAdmin } from '../../utils/storage';
import StatsDashboard from './StatsDashboard';
import PendingPayment from './PendingPayments';
import FollowUpPayments from './FollowUpPayments';
import RedFlagPayments from './RedFlagPayments';
import PaymentsHistory from './PaymentsHistory';
import Tickets from './Tickets';
import Projects from './Projects';
import SiteSupervisor from './SiteSupervisor';
import Vendors from './Vendors';
import Tooltip from '@material-ui/core/Tooltip';  



const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
});

class FinDashboard extends React.Component {
  state = {
    open: false,
    navId: 1,
    navName: 'Dashboard',
    isAdmin: loadAdmin(),
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    localStorage.clear();
    document.location.reload(true);
  }

  switchBackToAdmin = () => {
    switchToAdmin();
  }

  navigationChange(navId, navName) {
    this.setState({
      navId,
      navName
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
                { this.state.navName }
              </Typography>
              <Typography color="inherit">
                {this.state.isAdmin? <Button color="inherit" onClick={this.switchBackToAdmin} className={classes.button}>Switch back to admin</Button>:
                <Button color="inherit" onClick={this.logout} className={classes.button}>Logout</Button>}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button onClick={() => this.navigationChange(1, 'Dashboard')}>
                <Tooltip title="Dashboard">
                  <ListItemIcon>
                    <DashboardIcon style={{ color: this.state.navId === 1? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(2, 'Pending Payment')}>
                <Tooltip title="Pending Payment">
                  <ListItemIcon>
                    <PendingIcon style={{ color: this.state.navId === 2? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Pending Payment" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(3, 'FollowUp Payments')}>
                <Tooltip title="FollowUp Payments">
                  <ListItemIcon>
                    <DashboardIcon style={{ color: this.state.navId === 3? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="FollowUp Payments" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(4,'Red Flag Payments')}>
                <Tooltip title="Red Flag Payments">
                  <ListItemIcon>
                    <DashboardIcon style={{ color: this.state.navId === 4? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Red Flag Payments" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(5, 'Payments History')}>
                <Tooltip title="Payments History">
                  <ListItemIcon>
                    <DashboardIcon style={{ color: this.state.navId === 5? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Payments History" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(6, 'Tickets')}>
                <Tooltip title="Tickets">
                  <ListItemIcon>
                    <AssignmentIcon style={{ color: this.state.navId === 6? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Tickets" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(7, 'Projects')}>
                <Tooltip title="Projects">
                  <ListItemIcon>
                    <BusinessIcon style={{ color: this.state.navId === 7? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Projects" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(8, 'Site Supervisor')}>
                <Tooltip title="Site Supervisor">
                  <ListItemIcon>
                    <SupervisorIcon style={{ color: this.state.navId === 8? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Site Supervisor" />
              </ListItem>
              <ListItem button onClick={() => this.navigationChange(9, 'Vendors')}>
                <Tooltip title="Vendors">
                  <ListItemIcon>
                    <PersonIcon style={{ color: this.state.navId === 9? '#007bff': '#868e95'}}/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Vendors" />
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            { this.state.navId === 1 && <StatsDashboard />}
            { this.state.navId === 2 && <PendingPayment />}
            { this.state.navId === 3 && <FollowUpPayments />}
            { this.state.navId === 4 && <RedFlagPayments />}
            { this.state.navId === 5 && <PaymentsHistory />}
            { this.state.navId === 6 && <Tickets />}
            { this.state.navId === 7 && <Projects />}
            { this.state.navId === 8 && <SiteSupervisor />}
            { this.state.navId === 9 && <Vendors />}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FinDashboard);