import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Badge } from '@material-ui/core';
import FileIcon from '@material-ui/icons/AttachFile';
import { getOfficeUpdateList } from '../../../api'

const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror : {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }
});

const img = {
  display: 'block',
  width: '50vh',
  height: '50vh'
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}


class SiteUpdate extends Component {
  state = {
    SiteUpdate:[],
    openDialog: false,
    files: [],
    filesDialog: false,
    filesList: []
  };

  componentDidMount() {
    getOfficeUpdateList(this.props.auth.token, this.props.project.id)
    .then(res => {
      if (res.status === 200) {
        this.setState({
          SiteUpdate: res.data
        })
      }
    })
    .catch(error => console.log(error))
  }

  render() {
    const {classes} = this.props;
    const { SiteUpdate, files, filesDialog, filesList} = this.state;
    return(
      <div>
          <MaterialTable
          title="Site Update"     
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{margin: '10px'}}>
                
                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Files',
              field: 'files',
              render: rowData => {
                return(
                  <Tooltip title="Files" aria-label="add">
                    <Badge
                      className={classes.button}
                      color="secondary"
                      badgeContent={rowData.files.length}
                      invisible={rowData.files.length > 0 ? false : true }
                    >
                    <FileIcon color={rowData.files.length > 0 ? "primary" : "disabled"} />
                    </Badge>
                  </Tooltip>
                )
              },
              grouping: false,
              filtering: false,
              editable: 'never'
            },
            {
              title: 'Type', field: 'updateable_type',
              lookup: {
                "Project": "Project",
                "OfficeTask": "Task",
                "OfficeUserSiteMeasurementRequest": "Site Measurement"
              }
            },
            { title: 'Remark', field: 'remark' },
            {
              title: 'Created on',
              field: 'created_at',
              render: rowData => moment(rowData.created_at).format("ll"),
              type: 'date'
            },
            
            {
              title: 'Is Client MoM',
              field: 'is_client_mom',
              lookup: {
                true: "Yes",
                false: "No"
              }
            },
            
            ]}
            onRowClick={(event, rowData, toggle) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
            data={SiteUpdate}        
            options={{
              filtering: true,
              exportButton: true
            }}
          />
          <Dialog
            open={filesDialog}
            onClose={() => this.setState({filesDialog: false })}
            scroll={'paper'}
            aria-labelledby="site-updates"
            maxWidth="lg"
            fullWidth={true}
          >
            <DialogTitle id="site-updates">Files</DialogTitle>
            <DialogContent dividers={true}>
                <aside style={thumbsContainer}>
                {
                  filesList.map((file,index) => (
                    <div key={index} style={thumbInner}>
                      <img
                        src={file.file}
                        style={img}
                        alt="Arrivae"
                      />
                    </div>
                  ))
                }
                </aside>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(SiteUpdate);
