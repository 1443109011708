import axios from '../base';


export function getVendorList(accessToken) {
  return axios.request({
    url: '/api/v1/office_vendors',
    method: 'GET',
    headers: accessToken,
  })
};

export function getAssignedVendorToProject(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_projects/assigned_vendors_to_project/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function AssignVendorToProject(accessToken, data, assign_to_all) {
  return axios.request({
    url: `/api/v1/office_projects/assign_vendor_to_project?assign_to_all_task=${assign_to_all}`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};


export function StartProject(accessToken, vendor_id, project_id) {
  console.log(vendor_id)
  return axios.request({
    url: `/api/v1/office_projects/project_start?project_id=${project_id}&vendor_id=${vendor_id}`,
    method: 'POST',
    headers: accessToken,
    data: ''
  })
};

export function getMilestoneList(accessToken, project_id, vendor_id) {
  console.log(project_id)
  return axios.request({
    url: `/api/v1/project_vendor_milestones?project_id=${project_id}&vendor_id=${vendor_id}`,
    method: 'GET',
    headers: accessToken,
    data: ''
  })
};
export function getVendorMilestoneList(auth, project_id) {
  console.log(project_id)
  return axios.request({
    url: `/api/v1/project_vendor_milestones/get_milestone_list_for_vendor?project_id=${project_id}`,
    method: 'GET',
    headers: auth.token,
    data: ''
  })
};



export function getVendorPresent(accessToken, project_id) {
  console.log(project_id)
  return axios.request({
    url: `/api/v1/office_tasks/vendors_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
    data: ''
  })
};

export function ResendCredentialAPI(accessToken, id) {
  return axios.request({
    url: `api/v1/office_users/send_credentials_in_mail/${id}`,
    method: 'GET',
    headers: accessToken,
  })
};


export function approveRejectApi(milestone_Id, status, data, auth) {
  return axios.request({
    url: `api/v1/project_vendor_milestones/${milestone_Id}/pm_approve_or_reject?status=${status}`,
    method: "PATCH",
    headers: auth.token,
    data: data
  });
}


// export function approveRejectApi(milestone_Id, status, data, auth) {
//   console.log(auth.token, "oo0000ooo")
//   return axios.request({
//     url: `http://qa-officeapi.arrivae.com/api/v1/project_vendor_milestones/${milestone_Id}/pm_approve_or_reject?status=${status}`,
//     method: "PATCH",
//     headers: auth.token,
//     data: data
//   });
// }

export function RejectApi(milestone_Id, auth, status) {
  console.log(milestone_Id, auth)
  return axios.request({
    url: `api/v1/project_vendor_milestones/${milestone_Id}/pm_approve_or_reject?status=${status}`,
    method: 'PATCH',
    headers: auth,
    data: ''
  })
};

export function getPurchaseOrderApi(milestone_Id) {
  console.log(milestone_Id)
  return axios.request({
    url: `api/v1/project_vendor_milestones/${milestone_Id}/list_of_purchase_order`,
    method: 'GET',
    headers: {
      accessToken: localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid")
    }
  })
};


export function getListOfPerformaInvoiceFile(milestone_Id, auth) {
  console.log(auth)
  return axios.request({
    url: `api/v1/project_vendor_milestones/${milestone_Id}/get_list_of_performa_invoice_files`,
    method: 'GET',
    headers: {
      accessToken: localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid")
    }
  })
};

export function getPrListForMilestone(milestone_Id, auth) {
  console.log(auth)
  return axios.request({
    url: `api/v1/project_vendor_milestones/${milestone_Id}/get_list_of_pr_for_milestone`,
    method: 'GET',
    headers: {
      accessToken: localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid")
    }
  })
};