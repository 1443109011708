import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getVendorList, ResendCredentialAPI } from '../../api';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  }
})

class VendorList extends Component {
  state = {
    Vendor: [],
    selectedProject: [],
    errorMessages: '',
    openError: false
  }

  async componentDidMount() {
    try {
      const vendorsData = await getVendorList(this.props.token)
      this.setState({
        Vendor: vendorsData.data,
      });
    } catch (error) {
      console.log(error)
    }
  }

  ResendCredentialHandler = (id) => {
    ResendCredentialAPI(this.props.token, id)
      .then(response => {
        if (response.status) {
          Swal.fire('Success', response.data.message, 'success')
        }
      })
      .catch(error => {
        console.log(error.response.data.message);
      })
  }

  render() {
    const { classes } = this.props;
    const { Vendor, errorMessages } = this.state;
    return (
      <div>
        <div>
          <MaterialTable
            title="Vendors List"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Address', field: 'address' },
              { title: 'City', field: 'city' },
              { title: 'Contact Person', field: 'contact_person' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact_number' },
              { title: 'DD Score', field: 'dd_score' },
              { title: 'Type', field: 'vendor_tag' },
              {
                title: 'Credentials',
                field: 'email',
                filtering: false,
                align: 'center',
                render: rowData => <button className="btn" onClick={() => this.ResendCredentialHandler(rowData.id)}> Resend</button>
              }
            ]}
            data={Vendor}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
          />
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openError}
          autoHideDuration={6000}
          onClose={() => this.setState({ openError: false, errorMessages: '' })}

          message={
            <span className={classes.message}>
              <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
              {errorMessages}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({ openError: false, errorMessages: '' })}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(VendorList);