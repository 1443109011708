import axios from '../base';

export function createClient(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_clients',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function getClientList(accessToken) {
  return axios.request({
    url: '/api/v1/office_clients',
    method: 'GET',
    headers: accessToken
  })
};

export function updateClient(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_clients/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function disableClient(accessToken, clientId) {
  return axios.request({
    url: `/api/v1/office_clients/disable_client/${clientId}`,
    method: 'POST',
    headers: accessToken
  })
};