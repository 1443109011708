import React, { Component } from 'react'
import {
  getAssignedPMToOMList, getSSListForPE,
  mapSiteSupervisortoProjectEngineer, changeProjectEngineerType
} from '../../api'
import MaterialTable from 'material-table';
import CircularIndeterminate from '../Loader';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import { connect } from 'react-redux';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
  Select, MenuItem
} from '@material-ui/core';
import Swal from 'sweetalert2';

class ProjectManagerList extends Component {
  state = {
    ProjectManagers: [],
    isLoading: false,
    isMapLoading: false,
    showDialog: false,
    ssList: [],
    seletedPE: null
  }

  componentDidMount() {
    this.getProjectManager()
  }

  getProjectManager = () => {
    this.setState({ isLoading: true })
    getAssignedPMToOMList(this.props.token, this.props.current_user.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            ProjectManagers: res.data.filter(user => user.is_pm_assigned === true),
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
  }

  SiteSupervisorModel = async (rowData) => {
    await getSSListForPE(this.props.token, rowData.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            ssList: res.data,
            showDialog: true,
            seletedPE: rowData.id
          })
        }
      })
      .catch(error => console.log(error))
  }

  assignSiteSupervisorToPE = (office_site_supervisor_id) => {
    this.setState({ isMapLoading: true })
    let data = {
      office_site_supervisor_id: office_site_supervisor_id,
      office_project_engineer_id: this.state.seletedPE
    }
    mapSiteSupervisortoProjectEngineer(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          // Swal.fire('Success', 'Site supervisor assignment updated', 'success')
          getSSListForPE(this.props.token, this.state.seletedPE)
            .then(res => {
              if (res.status === 200) {
                console.log(res.data)
                this.setState({
                  ssList: res.data,
                  isMapLoading: false
                })
              }
            })
        }
      })
      .catch(error => console.log(error))
  }

  handleChangePEType = (e, pe) => {
    this.setState({ isLoading: true })
    let data = {
      id: pe.id,
      pe_type: e.target.value
    }
    changeProjectEngineerType(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          Swal.fire('Success', 'Project engineer type updated', 'success')
          this.getProjectManager()
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { ProjectManagers, isLoading, showDialog, ssList, isMapLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title=""
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects.count' },
              {
                title: 'Type',
                field: 'pe_type',
                render: rowData => <Select
                  value={rowData.pe_type}
                  onChange={(e) => this.handleChangePEType(e, rowData)}
                >
                  <MenuItem value={'Services'}>Services</MenuItem>
                  <MenuItem value={'Installation'}>Installation</MenuItem>
                </Select>
              },
              {
                title: 'Assigned Site Supervisors',
                field: 'id',
                render: rowData => <SupervisorIcon
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.SiteSupervisorModel(rowData)} />,
                grouping: false,
                filtering: false
              },
            ]}
            data={ProjectManagers}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
          />
        }
        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='lg'
          onClose={() => this.setState({ showDialog: false })}
          scroll='paper'
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Assign Site Supervisor
          </DialogTitle>
          <DialogContent dividers={true}>
            {isMapLoading ?
              <CircularIndeterminate />
              :
              <MaterialTable
                title=""
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Email', field: 'email' },
                  { title: 'Contact', field: 'contact' },
                  { title: 'No. Active Projects', field: 'active_projects' }
                ]}
                data={ssList}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  grouping: true
                }}
                actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => this.assignSiteSupervisorToPE(rowData.id)
                  }
                ]}
                components={{
                  Action: props => {
                    return (
                      props.data.is_ss_assigned ?
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          Unassign
                  </Button>
                        :
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Assign
                  </Button>
                    )
                  }
                }}

              />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default connect(mapStateToProps)(ProjectManagerList);