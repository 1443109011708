import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField,Tooltip, Badge } from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import { updateOfficeUser, resetPassword } from '../../api';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import { loadCurrentUser, saveCurrentUser } from '../../utils/storage';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Grid, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';



const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

class RegisterUser extends Component {
  state = {
    open: false,
    openDialog2: false,
    openDialog: false,
    editUser: {
      name: '', 
      contact: ''
    },

    resetPass: {
      password: '',
      password_confirmation: ''
    },
    editProject: null,
    User: this.props.auth.current_user,
    page: 0,
    rowsPerPage: 5,
    selectedProject: [],
    errorMessages: '',
    openError: false,
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleDialog2 = () => {
    this.setState({ openDialog2: !this.state.openDialog2 });
    this.setState({
      editUser: {
        name: this.props.auth.current_user.name, 
        contact: this.props.auth.current_user.contact
      }
    })
  };

  resetPasswordDialog = () => {
    this.setState({openDialog: !this.state.openDialog});
    this.setState({
      resetPass: {
        password: '',
        password_confirmation: ''
      }
    })
  }


  changeHandler = (e) => {
    let newUser = this.state.newUser;
    newUser[e.target.name] = e.target.value;
    this.setState({newUser})
  }

  changeEditHandler = (e) => {
    let editUser = this.state.editUser;
    editUser[e.target.name] = e.target.value;
    this.setState({editUser})
  }

  changePasswordHandler = (e) => {
    let resetPass = this.state.resetPass;
    resetPass[e.target.name] = e.target.value;
    this.setState({resetPass})
  }


  onSubmitEditedDetails = (event) => {
    updateOfficeUser(this.props.auth.token, this.state.editUser)
    .then(response => {
      if (response.status) {
        saveCurrentUser(response.data);
        window.location.reload(true);
      }
    })
    .catch(error => {
      console.log(error);
      
    })
  }


  onSubmitResetPasswordDetails = (event) => {
    if (this.state.resetPass.password === this.state.resetPass.password_confirmation){
      resetPassword(this.props.auth.token, this.state.resetPass)
      .then(response => {
        if(response.status){
          alert("Password Updated Successfully");
          this.setState({openDialog: !this.state.openDialog});
        }
      })
      .catch(error => {
        console.log(error);
      })
    }
    else{
      alert("Password does not match!!");
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };


  render() {
    const { classes } = this.props;
    const { editUser, resetPass, User, errorMessages } = this.state;
    return ( 
      <div>
        <Typography variant="h4" gutterBottom style={{marginTop: '20px'}}>
          Profile                                                                                                                                                                                          
        </Typography>

        { User.length !== 0 && <Card>
          <CardContent>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <PersonIcon style={{ height: '50', color: '#868e96' }}/>    
                </ListItemAvatar>
                <ListItemText primary={User.name} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <EmailIcon style={{ height: '50', color: '#868e96' }}/>    
                </ListItemAvatar>
                <ListItemText primary={User.email} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <PhoneIcon style={{ height: '50', color: '#868e96' }}/>    
                </ListItemAvatar>
                <ListItemText primary={User.contact} />
              </ListItem>
            </List>          
          </CardContent>
          <CardActions>
            <Button variant="contained" className={classes.button} onClick={() => this.toggleDialog2()}>Edit Profile</Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.resetPasswordDialog()}>Update Password</Button>
          </CardActions>
        </Card>}
     
        <Dialog
          open={this.state.openDialog2}
          onClose={()=> this.toggleDialog2()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit User Details</DialogTitle>
          <DialogContent>
            <ValidatorForm 
              className={classes.form} 
              onSubmit={()=> this.onSubmitEditedDetails()}
              onError={errors => console.log(errors)}
              instantValidate={false} >
            
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="name"
                  value={editUser.name}
                  onChange={(e) => this.changeEditHandler(e)}
                  label="Name"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="contact"
                  value={editUser.contact}
                  onChange={(e) => this.changeEditHandler(e)}
                  label="Contact Number"
                  type="number"
                  validators={['required','matchRegexp:^\\d{10}$']}
                  errorMessages={['this field is required', 'Invalid contact number']}
              />
              </FormControl>
  
              <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={()=> this.toggleDialog2()}
              >
                Close
              </Button>
              <Button
              className="pull-right"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </ValidatorForm>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.openDialog}
          onClose={()=> this.resetPasswordDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
          <DialogContent>
            <ValidatorForm 
              className={classes.form} 
              onSubmit={()=> this.onSubmitResetPasswordDetails()}
              onError={errors => console.log(errors)}
              instantValidate={false} >

              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="password"
                  value={resetPass.password}
                  onChange={(e) => this.changePasswordHandler(e)}
                  label="Enter Password"
                  type="password"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="password_confirmation"
                  value={resetPass.password_confirmation}
                  onChange={(e) => this.changePasswordHandler(e)}
                  label="Enter Confirm Password"
                  type="password"
                  validators={['required']}
                  errorMessages={['this field is required', 'Invalid Password']}
              />
              </FormControl>
  
              <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={()=> this.resetPasswordDialog()}
              >
                Close
              </Button>
              <Button
              className="pull-right"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </ValidatorForm>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openError}
          autoHideDuration={6000}
          onClose={() => this.setState({openError: false, errorMessages: '' })}
          
          message={
            <span className={classes.message}>
              <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
              {errorMessages}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({openError: false, errorMessages: '' })}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(RegisterUser);