import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ReactDropzone from "react-dropzone";
import TextField from '@material-ui/core/TextField';
import { getMeasurementRequests, site_supervisor_list_by_role, assignSupervisor, createMeasurementFiles } from '../../../../api';
import CircularIndeterminate from '../../../Loader';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Collections, CloudDownload, Ballot } from '@material-ui/icons';
import { Grid, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Button, Chip, Tooltip, Avatar } from '@material-ui/core'
import Swal from 'sweetalert2';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  button: {
    margin: theme.spacing(),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: '500',
    textAlign: 'center',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textCenter: {
    textAlign: 'center',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 50,
    height: 50,
    padding: 2,
    boxSizing: 'border-box',
    borderWidth: 1,
    borderColor: 'black',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: 50,
  },
  dialogBlock: {
    width: '100%',
    height: 500,
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

class SiteMeasurement extends Component {
  state = {
    open: false,
    totalEnteries: 0,
    totalPages: 0,
    measurementRequests: [],
    measurementPendingRequests: [],
    measurementCompleteRequests: [],
    siteSupervisors: [],
    selectedSupervisor: null,
    selectErr: false,
    newFile: {
      projectId: null,
      files: '',
    },
    fileList: [],
    files: [],
    openPreview: false,
    previewUrl: '',
    remark: '',
    isLoading: true,
    selectedRequestId: null,
    updatable_type:'OfficeUserSiteMeasurementRequest',
    arrowIconID: null,
    siteimages: [],
    siteGalaryDialog: false,
    siteupdates: [],
    siteUpdateDialog: false,
    site_measurement_list_status: 'pending',
    btnTab: false
  };

  _getMeasurementRequest = () => {
    const { token, project } = this.props;
    let projectId = project != null ? project.id : null;
    const { site_measurement_list_status } = this.state;
    getMeasurementRequests(token, projectId, site_measurement_list_status)
      .then(response => {
        if (response.status === 200) {
          // this.setState({ measurementRequests: response.data })
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementRequests: response.data,
              measurementPendingRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementRequests: response.data,
              measurementCompleteRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
        else {
          Swal.fire({ title: response.data.message })
        }
      })
      .catch(error => { console.log(error) })
  }

  async componentDidMount() {
    await this.getInitalData()
    await this.getOtherSiteMeasurementRequest()
  }

  getInitalData = async () => {
    const { token, project } = this.props;
    const { site_measurement_list_status } = this.state;
    let projectId = project != null ? project.id : null;
    let SiteSupervisorListRes = await site_supervisor_list_by_role(this.props.token)
    let MeasurementRequestsRes = await getMeasurementRequests(token, projectId, site_measurement_list_status)
    let SiteSupervisorOption = {}
    SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

    if (MeasurementRequestsRes.status == 200) {
      this.setState({
        measurementPendingRequests: MeasurementRequestsRes.data,
        siteSupervisors: SiteSupervisorOption,
        isLoading: false,
        totalEnteries: MeasurementRequestsRes.headers['total-entries'],
        totalPages: MeasurementRequestsRes.headers['total-pages']
      })

    }
  }

  getOtherSiteMeasurementRequest = async () => {
    const { token, project } = this.props;
    const { site_measurement_list_status, totalPages } = this.state;
    let projectId = project != null ? project.id : null;
    for (let i = 1; i < totalPages; i++) {
      let NewMeasurementRequestsRes = await getMeasurementRequests(token, projectId, site_measurement_list_status, i + 1)
      if (NewMeasurementRequestsRes.status == 200) {
        if (site_measurement_list_status == 'pending') {
          this.setState({ measurementPendingRequests: this.state.measurementPendingRequests.concat(NewMeasurementRequestsRes.data) })
        } else {
          this.setState({ measurementCompleteRequests: this.state.measurementCompleteRequests.concat(NewMeasurementRequestsRes.data) })
        }
      }
    }
  }

  openDialog = (requestId) => {
    this.setState({ open: true, selectedRequestId: requestId });
  }

  handleClose = () => {
    this.setState({ open: false, remark: '', files: [] })
  }

  onDrop = (files) => {
    let newFile = { ...this.state.newFile };
    console.log(files);
    newFile.files = files
    this.setState({
      newFile,
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const remarkValue = document.getElementById("outlined-multiline-static").value
    this.setState({ isLoading: true, open: false, remark: remarkValue })
    const { newFile, selectedRequestId, updatable_type } = this.state;
    const fd = new FormData();
    fd.append('requestId', parseInt(selectedRequestId, 10))
    fd.append('file_length', newFile.files.length)
    for (const i in newFile.files) {
      if (newFile.files.hasOwnProperty(i)) {
        fd.append(`file_${i}`, newFile.files[i])
        fd.append(`remark`, remarkValue)
        fd.append(`is_client_mom`, false)
        fd.append(`office_visit_id`, null)
        fd.append(`status`, true)
        fd.append(`updateable_type`, updatable_type)
      }
    }
    createMeasurementFiles(this.props.token, selectedRequestId, fd)
      .then(res => {
        if (res.status === 200) {
          Swal.fire('Success', res.data.message );
          this._getMeasurementRequest();
          this.setState({files: []})
        } else {
          Swal.fire({ text: res.data.message })
        }
        this.setState({
          isLoading: false
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoading: false
        })
      })
  }

  selectSupervisor = (e, requestId) => {
    this.setState({ selectedSupervisor: e.value, arrowIconID: requestId })
  }

  // handleChange = (e) => {
  //   let key = e.target.name;
  //   this.setState({ [key]: e.target.value })
  // }

  setSupervisor = (requestId) => {
    this.setState({ isLoading: true })
    const { token } = this.props;
    assignSupervisor(token, { office_user_id: this.state.selectedSupervisor, site_measurement_request_id: requestId })
      .then(response => {
        Swal.fire({ title: response.data.message })
        this._getMeasurementRequest()
        this.setState({ isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false })
        console.log(error)
      })
  }

  getUpdatedMeasurementRequests = async (site_measurement_list_status) => {
    this.setState({ isLoading: true })
    const { token, project } = this.props;
    let projectId = project != null ? project.id : null;

    await getMeasurementRequests(token, projectId, site_measurement_list_status)
      .then(response => {
        if (response.status === 200) {
          if (site_measurement_list_status == 'pending') {
            this.setState({
              measurementPendingRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: false,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          } else {
            this.setState({
              measurementCompleteRequests: response.data,
              site_measurement_list_status,
              isLoading: false,
              btnTab: true,
              totalEnteries: response.headers['total-entries'],
              totalPages: response.headers['total-pages']
            })
          }
        }
        else {
          Swal.fire({ title: response.data.message })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
    await this.getOtherSiteMeasurementRequest()
  }

  render() {
    const { siteSupervisors,
      measurementRequests,
      open,
      isLoading,
      btnTab,
      measurementPendingRequests,
      measurementCompleteRequests,
      files,
      totalEnteries,
      arrowIconID, siteimages, siteGalaryDialog, siteupdates, siteUpdateDialog, site_measurement_list_status } = this.state;
    const { classes } = this.props;
    const thumbs = files.map(file => (
      <div key={file.preview} style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginTop: 8,
        marginBottom: 8,
        marginRight: 8,
        width: 50,
        height: 50,
        padding: 2,
        boxSizing: 'border-box',
      }}>
        <div style={styles.thumbInner}>
          <img
            src={file.preview}
            style={{
              display: 'block',
              width: 'auto',
              height: 50,
            }}
            alt="Arrivae"
          />
        </div>
      </div>
    ));
    return (
      <div className={classes.root}>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Site Measurement List"
            columns={[
              {
                title: 'Date of Measurement', field: 'scheduled_at',
                render: rowData => moment(rowData.scheduled_at).format("ll"),
                type: 'date',
                editable: 'never'
              },
              {
                title: 'Lead Id',
                field: 'project.lead_id',
                editable: 'never'
              },
              {
                title: 'Project Name',
                field: 'project.name',
                editable: 'never'
              },
              {
                title: 'Community Manager',
                field: 'assigned_cm.name',
                editable: 'never'
              },
              {
                title: 'Designer',
                field: 'assigned_designer.name',
                editable: 'never'
              },
              {
                title: 'Type of Measurement',
                field: 'request_type',
                editable: 'never'
              },
              {
                title: 'Address',
                field: 'address',
                editable: 'never'
              },
              {
                title: 'Request Status',
                field: 'office_user_site_measurement_requests.completed',
                render: rowData => (rowData && rowData.office_user_site_measurement_requests !== null && rowData.office_user_site_measurement_requests.completed) ? `Completed on ${moment(rowData.office_user_site_measurement_requests.completed_at).format('llll')}` : 'Pending',
                editable: 'never'
              },
              {
                title: 'Site Supervisor',
                field: 'office_user_site_measurement_requests.office_user_id',
                render: rowData => rowData && rowData.office_user_site_measurement_requests !== null ? siteSupervisors[rowData.office_user_site_measurement_requests && rowData.office_user_site_measurement_requests.length > 0 && rowData.office_user_site_measurement_requests[0].office_user_id] : null,
                lookup: siteSupervisors,
                editable: 'onUpdate'
              },
              {
                title: 'Site Images',
                field: '',
                render: rowData => {
                  return (
                    <Badge
                      className={classes.margin}
                      badgeContent={rowData.site_images.length || 0}
                      color="primary"
                      style={{ cursor: 'pointer' }}
                      showZero
                      onClick={() => rowData.site_images.length === 0 ? Swal.fire({ icon: 'info', title: "No files to view" }) : this.setState({ siteimages: rowData.site_images, siteGalaryDialog: true })}
                    >
                      <Collections />
                    </Badge>
                  )
                },
                editable: 'never'
              },
              {
                title: 'Site Updates',
                field: '',
                render: rowData => {
                  return (
                    <Badge
                      className={classes.margin}
                      badgeContent={(rowData.office_updates && rowData.office_updates.length) || 0}
                      color="primary"
                      style={{ cursor: 'pointer' }}
                      showZero
                      onClick={() => rowData.office_updates.length === 0 ? Swal.fire({ icon: 'info', title: "No Updates" }) : this.setState({ siteupdates: rowData.office_updates, siteUpdateDialog: true })}
                    >
                      <Ballot />
                    </Badge>
                  )
                },
                editable: 'never'
              },
            ]}
            data={btnTab ? measurementCompleteRequests : measurementPendingRequests}
            actions={[
              rowData => ({
                icon: (rowData.office_user_site_measurement_requests !== null && rowData.office_user_site_measurement_requests.completed) ? 'check_box' : 'check_box_outline_blank',
                tooltip: 'Mark Complete',
                onClick: (event, rowData) => this.openDialog(rowData.id),
                disabled: (rowData.office_user_site_measurement_requests !== null && rowData.office_user_site_measurement_requests.completed) ? true : false
              })
            ]}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      // if (oldData.office_user_site_measurement_requests && oldData.office_user_site_measurement_requests.length <= 0) {
                      this.setState({ isLoading: true })
                      assignSupervisor(this.props.token, {
                        office_user_id: parseInt(newData.office_user_site_measurement_requests.office_user_id),
                        site_measurement_request_id: newData.id
                      })
                        .then(response => {
                          Swal.fire({ icon: 'info', title: response.data.message })
                          this._getMeasurementRequest()
                          this.setState({ isLoading: false })
                        })
                        .catch(error => {
                          this.setState({ isLoading: false })
                          Swal.fire('Error', 'Something went wrong', 'error')
                          console.log(error)
                        })
                      // } else {
                      //   alert('Site Supervisor already assigned!')
                      //   return reject()
                      // }
                    }
                    resolve();
                  }, 1000);
                }),
            }}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ padding: '0px 10px' }}>
                    <Chip label="Pending"
                      avatar={site_measurement_list_status === 'pending' ? <Avatar>{totalEnteries}</Avatar> : null}
                      color={site_measurement_list_status === 'pending' ? "secondary" : 'default'}
                      onClick={() => this.getUpdatedMeasurementRequests('pending')}
                      style={{ margin: 5 }} />
                    <Chip label="Completed"
                      avatar={site_measurement_list_status === 'completed' ? <Avatar>{totalEnteries}</Avatar> : null}
                      color={site_measurement_list_status === 'completed' ? "secondary" : 'default'}
                      onClick={() => this.getUpdatedMeasurementRequests('completed')}
                      style={{ margin: 5 }} />
                  </div>
                </div>
              ),
            }}
          />
        }

        {/* Add files dailog */}
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ height: '100vh' }}
          maxWidth={'md'}
        >
          <DialogTitle id="form-dialog-title">Add Files</DialogTitle>
          <ValidatorForm
            className={classes.form}
            onSubmit={this.onSubmit}
            onError={errors => console.log(errors)}
            instantValidate={false} >
            <DialogContent style={{ minWidth: '400px' }}>

              <ReactDropzone onDrop={this.onDrop}>
                Drop your file here!!
              </ReactDropzone>
              <aside style={styles.thumbsContainer}>
                {thumbs}
              </aside>
              <TextField
                required
                id="outlined-multiline-static"
                label="Remark"
                name="remark"
                multiline
                rows="4"
                // value={this.state.remark}
                // onChange={(e) => this.handleChange(e)}
                className={styles.textField}
                margin="normal"
                variant="outlined"
              />
              <br />
              <br />
              <Button
                className="pull-right"
                type="button"
                variant="contained"
                style={{ marginLeft: '20px' }}
                onClick={this.handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Upload Files
            </Button>
            </DialogContent>
          </ValidatorForm>
        </Dialog>

        <Dialog onClose={() => this.setState({ siteimages: [], siteGalaryDialog: false })} aria-labelledby="customized-dialog-title" open={siteGalaryDialog}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ siteimages: [], siteGalaryDialog: false })}>
            Site Galary
        </DialogTitle>
          <DialogContent dividers>
            <MaterialTable
              title=""
              columns={[
                { title: 'File Name', field: 'site_image_file_name' },
                {
                  title: 'Uploaded Date',
                  field: 'site_image_updated_at',
                  render: rowData => moment(rowData.site_image_updated_at).format("ll"),
                  type: 'date'
                },
                {
                  title: 'File',
                  field: 'site_image',
                  render: rowData => {
                    return (
                      <CloudDownload onClick={() => window.open(rowData.site_image)} />
                    )
                  },
                  grouping: false
                },

              ]}
              data={siteimages}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                grouping: true
              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ siteimages: [], siteGalaryDialog: false })} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={() => this.setState({ siteupdates: [], siteUpdateDialog: false })} aria-labelledby="customized-dialog-title" open={siteUpdateDialog}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ siteupdates: [], siteUpdateDialog: false })}>
            Site Updates
						</DialogTitle>
          <DialogContent dividers>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => rowData.created_at !== null ? moment(rowData.created_at).format("ll") : null,
                  type: 'date'
                },
              ]}
              detailPanel={[
                {
                  icon: 'attachment',
                  tooltip: 'Files',
                  render: rowData => {
                    return (
                      <>
                        <Grid container spacing={1} style={{ margin: '15px' }}>
                          {
                            rowData.files.length !== 0 ?
                              rowData.files.map(file =>
                                (
                                  <React.Fragment key={file.id}>
                                    <Grid item xs={8}>{file.file_file_name}</Grid>
                                    <Grid item className={classes.alignCenter} xs={3}>
                                      <a href={file.file} target="_blank" download={file.file_file_name}>
                                        <CloudDownload color="primary" />
                                      </a>
                                    </Grid>
                                  </React.Fragment>
                                )
                              )
                              :
                              <div> No files found. </div>
                          }
                        </Grid>
                      </>
                    )
                  },
                }]}
              data={siteupdates}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                grouping: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ siteupdates: [], siteUpdateDialog: false })} color="primary">
              Close
							</Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteMeasurement);
