import axios from '../base';

export function getVendorOfficeUpdateList(accessToken, projectId) {
  return axios.request({
    // url: '/api/v1/office_updates',
    url:`api/v1/office_updates/vendor_site_update_lists`,
    method: 'GET',
    headers: accessToken,
    params: {
      project_id: projectId
    }
  })
};

