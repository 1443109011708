import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getResidentialProjectsForGM } from '../../api';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircularIndeterminate from '../Loader';
import { Chip, Badge, Tooltip, Avatar, Typography } from '@material-ui/core'
import ClientIcon from '@material-ui/icons/AccountCircle';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import moment from 'moment';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing(1),
  }
})



class ProjectList extends Component {
  state = {
    ResidentialProjects: [],
    isLoading: true,
    ProjectStatus: 'pre_10_percent'
  }

  componentDidMount() {
    this.getProjects()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.getProjects()
    }
  }

  getProjects = () => {
    this.setState({ isLoading: true });
    let url = new URL(window.location.href)
    let project_status = url.searchParams.get('project_status') || 'pre_10_percent'
    getResidentialProjectsForGM(this.props.token, project_status)
      .then(response => {
        if (response.status) {
          this.setState({
            ResidentialProjects: response.data,
            isLoading: false,
            ProjectStatus: project_status
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        console.log(error)
      })
  }

  render() {
    const { classes } = this.props;
    const { ResidentialProjects, isLoading } = this.state;
    let url = new URL(window.location.href)
    let project_status = url.searchParams.get('project_status') || 'pre_10_percent'
    return (
      <React.Fragment>

        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title=""
            columns={project_status === 'pre_10_percent' ?
              [
                { title: 'Lead ID', field: 'lead_id' },
                { title: 'Client Name', field: 'lead.name' },
                { title: 'Client Email', field: 'lead.email' },
                { title: 'Client Contact', field: 'lead.contact' },
                { title: 'Community Manager', field: 'assigned_cm.name' },
                { title: 'Designer', field: 'assigned_designer.name' },
                {
                  title: 'Address',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            (rowData.project_booking_form.flat_no || "") + ", " +
                            (rowData.project_booking_form.floor_no || "") + ", " +
                            (rowData.project_booking_form.building_name || "") + ", " +
                            (rowData.project_booking_form.location || "")
                          }
                        </>
                        : null
                    )
                  }
                },
                {
                  title: 'City',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            rowData.project_booking_form.city
                          }
                        </>
                        : null
                    )
                  }
                },
                {
                  title: 'Pin Code',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            rowData.project_booking_form.pincode
                          }
                        </>
                        : null
                    )
                  }
                }
              ]
              :
              [
                { title: 'Lead ID', field: 'lead_id' },
                { title: 'Client Name', field: 'lead.name' },
                { title: 'Client Email', field: 'lead.email' },
                { title: 'Client Contact', field: 'lead.contact' },
                { title: 'Community Manager', field: 'assigned_cm.name' },
                { title: 'Designer', field: 'assigned_designer.name' },
                {
                  title: 'No of PM',
                  field: 'assigned_project_manager',
                  render: rowData => {
                    return (
                      <Tooltip title="Assigned Project Manager" aria-label="add">
                        <Badge
                          className={classes.button}
                          color="secondary"
                          badgeContent={rowData.assigned_project_manager.length}
                          invisible={rowData.assigned_project_manager.length > 0 ? false : true}
                        >
                          <ClientIcon
                            color={rowData.assigned_project_manager.length > 0 ? "primary" : "disabled"} />
                        </Badge>
                      </Tooltip>
                    )
                  },
                  grouping: false
                },
                {
                  title: 'Assigned PM',
                  field: 'assigned_project_manager.length',
                  render: rowData => {
                    return (
                      <>
                        {
                          rowData.assigned_project_manager && (rowData.assigned_project_manager.length !== 0) &&
                          rowData.assigned_project_manager.map(item => {
                            return <Typography key={item.id}>{item.name}, </Typography>
                          })
                        }
                      </>
                    )
                  },
                  grouping: false
                },
                {
                  title: 'No of SS',
                  field: 'assigned_site_supervisor',
                  render: rowData => {
                    console.log(rowData)
                    return (
                      <Tooltip title="Assigned Site Supervisor" aria-label="add">
                        <Badge
                          className={classes.button}
                          color="secondary"
                          badgeContent={rowData.assigned_site_supervisor.length}
                          invisible={rowData.assigned_site_supervisor.length > 0 ? false : true}
                        >
                          <SupervisorIcon
                            color={rowData.assigned_site_supervisor.length > 0 ? "primary" : "disabled"} />
                        </Badge>
                      </Tooltip>
                    )
                  },
                  grouping: false
                },
                {
                  title: 'Assigned SS',
                  field: 'assigned_site_supervisor.length',
                  render: rowData => {
                    return (
                      <>
                        {
                          rowData.assigned_site_supervisor && (rowData.assigned_site_supervisor.length !== 0) &&
                          rowData.assigned_site_supervisor.map(item => {
                            return <Typography key={item.id}>{item.name}, </Typography>
                          })
                        }
                      </>
                    )
                  },
                  grouping: false
                },
                {
                  title: 'Date first 40% payment',
                  field: 'date_of_first_40_percent_payment',
                  render: rowData => rowData.date_of_first_40_percent_payment !== null ? moment(rowData.date_of_first_40_percent_payment).format('ll') : null
                },
                {
                  title: 'Address',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            (rowData.project_booking_form.flat_no || "") + ", " +
                            (rowData.project_booking_form.floor_no || "") + ", " +
                            (rowData.project_booking_form.building_name || "") + ", " +
                            (rowData.project_booking_form.location || "")
                          }
                        </>
                        : null
                    )
                  }
                },
                {
                  title: 'City',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            rowData.project_booking_form.city
                          }
                        </>
                        : null
                    )
                  }
                },
                {
                  title: 'Pin Code',
                  field: '',
                  render: rowData => {
                    return (
                      (rowData.project_booking_form && rowData.project_booking_form.length !== 0) ?
                        <>
                          {
                            rowData.project_booking_form.pincode
                          }
                        </>
                        : null
                    )
                  }
                }
              ]}
            data={ResidentialProjects}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
            onRowClick={(e, n) => this.props.history.push(`/gm/projects/${n.id}?project_status=${project_status}`)}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ margin: '10px' }}>
                    <Chip
                      avatar={project_status === 'pre_10_percent' ? <Avatar>{ResidentialProjects.length}</Avatar> : null}
                      label="10% Received - No PM Assigned"
                      color={project_status === 'pre_10_percent' ? "secondary" : 'default'}
                      onClick={() => this.props.history.push({
                        pathname: '/gm/projects',
                        search: '?project_status=pre_10_percent'
                      })}
                      style={{ margin: 5 }} />
                    <Chip
                      avatar={project_status === '10_50_percent' ? <Avatar>{ResidentialProjects.length}</Avatar> : null}
                      label="10% Received - PM Assigned"
                      color={project_status === '10_50_percent' ? "secondary" : 'default'}
                      onClick={() => this.props.history.push({
                        pathname: '/gm/projects',
                        search: '?project_status=10_50_percent'
                      })}
                      style={{ margin: 5 }} />
                    <Chip
                      avatar={project_status === '50_percent' ? <Avatar>{ResidentialProjects.length}</Avatar> : null}
                      label="Post 40%"
                      color={project_status === '50_percent' ? "secondary" : 'default'}
                      onClick={() => this.props.history.push({
                        pathname: '/gm/projects',
                        search: '?project_status=50_percent'
                      })}
                      style={{ margin: 5 }} />
                    <Chip
                      avatar={project_status === 'completed' ? <Avatar>{ResidentialProjects.length}</Avatar> : null}
                      label="Handover"
                      color={project_status === 'completed' ? "secondary" : 'default'}
                      onClick={() => this.props.history.push({
                        pathname: '/gm/projects',
                        search: '?project_status=completed'
                      })}
                      style={{ margin: 5 }} />
                  </div>
                </div>
              ),
            }}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(ProjectList);