import axios from '../base';

export function getVendorLaborPlanList(accessToken, projectId){
  return axios({
    url: `/api/v1/office_labour_plans/office_labour_plan_for_vendor`,
    params: {
      project_id: projectId
    },
    method: 'GET',
    headers: accessToken,
  })
}