/**
 * All states, action and action creator realted to authentication
 */

import {
  login as userLogin,
  validateToken,
  signup
} from '../api';
import { loadState, saveState, saveCurrentUser, loadCurrentUser } from '../utils/storage';
import {
  LOGIN,
  LOGOUT,
  SIGNUP
} from './actionType';

// default state
const defaultState = {
  token: loadState(),
  current_user: loadCurrentUser(),
}

// Reducers
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case LOGIN:
    case SIGNUP:
      return { ...state, token: action.token, current_user: action.current_user, message: action.message };
    case LOGOUT:
      return { ...state, token: null };
    default:
      return state;
  }
}

// Action creators
export function verifyUserAndLogin(email, password) {
  // use thunk to call the api
  // when we have the data call the reducer to set the access token
  // userLogin(email, password);
  return (dispatch) => {
    userLogin(email, password)
      .then((response) => {
        const data = response.data;
        if (response.status) {
          // success
          var accessToken = {
            "access-token": response.headers["access-token"],
            "client": response.headers.client,
            "uid": response.headers.uid
          }
          validateToken(accessToken)
            .then((res) => {
              if (res.status) {
                saveState(accessToken);
                saveCurrentUser(res.data.data);
                dispatch(login({
                  token: accessToken,
                  current_user: res.data.data,
                }));
              } else {
                //failed
                console.log("Token is invalid");
              }
            })
        } else {
          //failed
          dispatch(login({ message: data.message }));
        }
      })
      .catch(error => {
        dispatch(login({ message: error.response.data.errors }));
      })
  }
}

export function RegisterUser(data) {
  return (dispatch) => {
    signup(data)
      .then((response) => {
        const data = response.data;
        if (response.status) {
          // success
          var accessToken = {
            "access-token": response.headers["access-token"],
            "client": response.headers.client,
            "uid": response.headers.uid
          }
          localStorage.setItem("access", accessToken);

          validateToken(accessToken)
            .then((res) => {
              if (res.status) {
                saveState(accessToken);
                dispatch(login({
                  token: accessToken,
                  current_user: res.data.data,
                }));
              } else {
                //failed
                console.log("Token is invalid");
              }
            })
            .catch((error) => {
              console.log(error);
            })
        } else {
          //failed
          dispatch(login({ message: data.message }));
        }
      })
  }
}

export function logout() {
  return (dispatch) => {
    // remove the token from storage
    saveState('');
    dispatch({ type: LOGOUT });
  }
}

// private action creators
function login({ token, current_user, message }) {
  return {
    type: LOGIN,
    token,
    current_user,
    message
  }
}


