import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  getSiteSupervisorsOfPm
} from '../../api';
import MaterialTable from 'material-table';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  }
})

class SiteSupervisorList extends Component {
  state = {
    SiteSupervisor: [],
  }

  componentDidMount() {
    getSiteSupervisorsOfPm(this.props.token, { id: this.props.current_user.id })
      .then(response => {
        if (response.status) {
          this.setState({
            SiteSupervisor: response.data,
          });
        }
      })
  }

  render() {
    const { SiteSupervisor } = this.state;
    return (
      <div>
        <MaterialTable
          title="Site Supervisors List"
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Contact', field: 'contact' },
            { title: 'No. Active Projects', field: 'active_projects' },
          ]}
          data={SiteSupervisor}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            exportButton: true,
            grouping: true,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100]
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteSupervisorList);