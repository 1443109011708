import axios from './base';

export function getUserDetails(accessToken, Id) {
  return axios.request({
    url: `/api/v1/office_users/user_details/${Id}`,
    method: 'GET',
    headers: accessToken
  })
};

export function updateOfficeUser(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_users/update_user',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function resetPassword(accessToken, data) {
  return axios.request({
    url: `/office_auth/password`,
    method: 'PUT',
    headers: accessToken,
    params: data
  })
};

export function getRoleList(accessToken) {
  return axios.request({
    url: '/api/v1/office_users/role_list',
    method: 'GET',
    headers: accessToken
  })
};

export function createNewUser(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_users/register_new_user',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};