import axios from '../base';

export function getOfficeUpdateList(accessToken, projectId) {
  return axios.request({
    // url: '/api/v1/office_updates',
    url:`api/v1/office_updates?project_id=${projectId}`,
    method: 'GET',
    headers: accessToken,
    params: {
      project_id: projectId
    }
  })
};

export function getOfficeUpdateFilesList(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_updates/files_list/${projectId}`,
    method: 'GET',
    headers: accessToken,
  })
};