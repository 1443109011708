import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = theme => ({
  progress: {
    margin: 2,  
  },
});

class CircularIndeterminate extends Component{
  render(){
    const classes = this.props;
    return (
      <div style={{display: 'flex', justifyContent: 'center', marginTop:'5%'}}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  } 
}

export default withStyles(useStyles,{ withTheme: true })(CircularIndeterminate);