import React, { Component } from 'react'
import { getUsersListByRole } from '../../api'
import MaterialTable from 'material-table';
import CircularIndeterminate from '../Loader';
import { connect } from 'react-redux';

class CommunityManagerList extends Component {
  state = {
    CommunityManagers: [],
    isLoading: false
  }

  componentDidMount() {
    this.getProjectManager()
  }

  getProjectManager = () => {
    this.setState({ isLoading: true })
    getUsersListByRole(this.props.token, 'community_manager')
      .then(res => {
        if (res.status === 200) {
          this.setState({
            CommunityManagers: res.data,
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
  }
  render() {
    const { CommunityManagers, isLoading } = this.state;
    return (
      <React.Fragment>

        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title=""
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: '', field: '' },
            ]}
            data={CommunityManagers}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
          />
        }
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default connect(mapStateToProps)(CommunityManagerList);