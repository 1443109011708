import React, { Component } from 'react';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../Loader';
import {
  AssignVendorToProject, getAssignedVendorToProject
} from '../../../api'
import { connect } from 'react-redux';
import {
  Button
} from '@material-ui/core';

class AssignVendor extends Component {
  state = {
    isLoading: false,
    VendorList: [],
    isMapLoading: false,
    showDialog: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
  
    await getAssignedVendorToProject(this.props.token, this.props.project.id)
			.then(res => {
      if (res.status === 200) {
        this.setState({
          VendorList: res.data,
          isLoading: false
        })
      }
    })
    .catch(error => {
      console.log(error);
      this.setState({isLoading: false})
    } )
  }

  assignVendor = (vendor_id) => {
    this.setState({ isLoading: true })
    const data = {
      vendor_id: vendor_id,
      project_id: this.props.project.id
    }
    AssignVendorToProject(this.props.token, data)
    .then(res => {
			if (res.status === 200) {
				getAssignedVendorToProject(this.props.token, this.props.project.id)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              VendorList: res.data,
              isLoading: false
            })
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({isLoading: false})
        })
        this.props.getVendorOptionList()
      } 
    })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        })
    })
  }

  render() {
    const { VendorList, isLoading} = this.state;
    return (
      <React.Fragment>
        { isLoading ?
          <CircularIndeterminate/>
          :
          <MaterialTable
            title="Assign Vendor"
            columns={[
              { title: 'Name', field: 'name'},
              { title: 'Address', field: 'address'},
              { title: 'City', field: 'city'},
              { title: 'Contact Person', field: 'contact_person'},
              { title: 'Contact', field: 'contact_number'},
              { title: 'Email', field: 'email' },
              { title: 'No. Active Projects', field: 'active_projects' },
              {
                title: 'Is Assigned',
                field: 'is_assigned',
                render: rowData => rowData.is_assigned ? 'Assigned' : 'Not Assigned',
                lookup: { true : 'Assigned', false : 'Not Assigned' }
              },
              
            ]}
            data={VendorList}
            actions={[
              {
                icon: 'save',
                tooltip: 'Save User',
                onClick: (event, rowData) => this.assignVendor(rowData.id) 
              }
            ]}
            components={{
              Action: props => {
                return (
                  props.data.is_assigned ? 
                  <Button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  Unassign
                </Button>
                  :
                <Button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Assign
                </Button>
              )
              }
            }}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true
            }}
          />
        }
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default connect(mapStateToProps)(AssignVendor);