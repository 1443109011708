import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import { getTicketsList, 
  createTicket, 
  updateTicket, 
  deleteTicket,
  changeTicketStatus,
  changeTicketPaymentStatus
} from '../../api/PE/ticket';
import { getProjectList, getTaskList } from '../../api';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Select from 'react-select';
import moment from 'moment';

const styles = theme => ({
 
});

class Ticket extends Component {
  state = {
    open: false,
    editForm: false,
    page: 0,
    rowsPerPage: 5,
    newTicket: {
      projectId: '',
      taskId: '',
      // userId: "",
      requested: "",
      description: "",
      price: ""
    },
    editTicket: {

    },
    TicketList: [],
    ticketOption: [
      {value: 0, label: 'Open'},
      {value: 1, label: 'In progress'},
      {value: 2, label: 'Closed'},
    ],
    ticketPaymentOption: [
      {value: 0, label: 'Pending'},
      {value: 1, label: 'In progress'},
      { value: 2, label: 'Success' },
      {value: 2, label: 'Failed'},
    ],
    projectList: [],
  };



  componentDidMount() {
    getTicketsList(this.props.auth.token)
    .then(response => {
      if(response.status){
        this.setState({TicketList: response.data})
      }
    })
    .catch(error => console.log(error))
  }

  changeHandler = (e) => {
    let newTicket = this.state.newTicket;
    newTicket[e.target.name] = e.target.value;
    this.setState({newTicket})
  }

  changeEditHandler = (e) => {
    let editTicket = this.state.editTicket;
    editTicket[e.target.name] = e.target.value;
    this.setState({editTicket})
  }

  selectProject = (e) => {
    let newTicket = { ...this.state.newTicket };
    newTicket.projectId = e.value;
    newTicket.projectName = e.name;
    getTaskList(this.props.auth.token, {project_id: e.value})
    .then(res => {
      if (res.status) {
        let tasksList = res.data.map(x => ({
          value: x.id,
          label: x.name,
        }))
        this.setState({newTicket, tasksList})
      }  
    })
    .catch(error => console.log(error))
  }

  selectTask = (e) => {
    let newTicket = { ...this.state.newTicket };
    newTicket.taskId = e.value;
    newTicket.taskName = e.name;
    
    this.setState({newTicket})
  }

  handleClickOpen = () => {
    getProjectList(this.props.auth.token)
    .then(response => {
      if (response.status) {
        let projectList = response.data.map(x => ({
          value: x.id,
          label: x.name,
        }))
        this.setState({
          projectList,
          open: true 
        });
      }
    })
    .catch(error => console.log(error))
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenEditForm = (editTicket) => {
    this.setState({ editForm: true, editTicket });
  };

  handleEditFormClose  = () => {
    this.setState({ editForm: false });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  deleteTicket = (id) => {
    deleteTicket(this.props.auth.token, id)
    .then(response => {
      if(response.status){
        getTicketsList(this.props.auth.token)
        .then(response => {
          if(response.status){
            this.setState({TicketList: response.data, open: false})
          }
        })
        .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  onSubmit = (e) => {
    e.preventDefault();
    createTicket(this.props.auth.token, this.state.newTicket)
    .then(response => {
      if(response.status){
        getTicketsList(this.props.auth.token)
      .then(response => {
        if(response.status){
          this.setState({TicketList: response.data, open: false})
        }
      })
      .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  updateTicket = (e) => {
    e.preventDefault();
    updateTicket(this.props.auth.token, this.state.editTicket)
    .then(response => {
      if(response.status){
        getTicketsList(this.props.auth.token)
      .then(response => {
        if(response.status){
          this.setState({TicketList: response.data, editForm: false})
        }
      })
      .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  TicketStatus = (status) => {
    let statusLabel = ''
    switch (status) {
      case 0:
        statusLabel = 'Open'
        break;
      case 1:
        statusLabel = 'In progress'
        break;
      case 2:
        statusLabel = 'Closed'
        break;
      default:
        break;
    }
    return statusLabel;
  }

  TicketPaymentStatus = (status) => {
    let statusLabel = ''
    switch (status) {
      case 0:
        statusLabel = 'Pending'
        break;
      case 1:
        statusLabel = 'In progress'
        break;
      case 2:
        statusLabel = 'Success'
        break;
      case 3:
        statusLabel = 'Failed'
        break;
      default:
        break;
    }
    return statusLabel;
  } 

  changeStatus = (e, id) => {
    changeTicketStatus(this.props.auth.token, {
      ticketId: id,
      status: e.value
    })
    .then(response => {
      if(response.status){
        getTicketsList(this.props.auth.token)
      .then(response => {
        if(response.status){
          this.setState({TicketList: response.data, open: false})
        }
      })
      .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  changePaymentStatus = (e, id) => {
    changeTicketPaymentStatus(this.props.auth.token, {
      ticketId: id,
      status: e.value
    })
    .then(response => {
      if(response.status){
        getTicketsList(this.props.auth.token)
      .then(response => {
        if(response.status){
          this.setState({TicketList: response.data, open: false})
        }
      })
      .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  render() {
    const {classes} = this.props;
    const { TicketList, newTicket, editTicket } = this.state;
    return(
      <div>
        <Button  
          className="pull-right"
          variant="contained" 
          color="primary" 
          onClick={this.handleClickOpen}>Create New Ticket</Button>
        <Typography variant="h4" gutterBottom style={{marginTop: '20px'}}>
          Ticket List
        </Typography>
        <div className="table-container">
          { TicketList.length !== 0 &&   
            <Paper className="user-list-root">
              <Table className="user-list-table">
                  <TableHead>
                    <TableRow>
                      <TableCell numeric style={{textAlign: 'left'}}>Project</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Task</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Created by</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Email</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Requested</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Description</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Price</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Raised Date</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Ticket Status</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>Closing Date</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>Payment Status</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TicketList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((n,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.project_name}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.task_name}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.site_supervisor}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.site_supervisor_email}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.requested}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.description}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.price}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{moment(n.created_at).format('LLL')}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>
                            <div style={{width: '120px'}}>
                              <Select
                                placeholder={this.TicketStatus(n.status)}
                                options={this.state.ticketOption}
                                onChange={(e) => this.changeStatus(e, n.id)}
                              />
                            </div>
                          </TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{(n.closed_date && moment(n.closed_date).format('LLL')) || ''}</TableCell>
                          <TableCell numeric style={{ textAlign: 'left' }}>
                            {/* <div style={{width: '120px'}}>
                              <Select
                                placeholder={this.TicketPaymentStatus(n.payment_status)}
                                options={this.state.ticketPaymentOption}
                                onChange={(e) => this.changePaymentStatus(e, n.id)}
                              />
                            </div> */}
                            {this.TicketPaymentStatus(n.payment_status)}
                          </TableCell>
                          <TableCell numeric style={{ textAlign: 'left' }}>
                            <Tooltip title="Edit Details">
                              <IconButton aria-label="Edit Details" 
                                onClick={() => this.handleOpenEditForm(n)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton 
                                variant="fab" 
                                color="secondary" 
                                aria-label="Delete"
                                onClick={() => this.deleteTicket(n.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={11}
                      count={TicketList.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationWrapper}
                    />
                  </TableRow>
                </TableFooter>
                </Table>
              </Paper>
          }
        </div>
        
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create Ticket</DialogTitle>
          <DialogContent>
            <ValidatorForm 
              className={classes.form} 
              onSubmit={this.onSubmit}
              onError={errors => console.log(errors)}
              instantValidate={false} >
              <FormControl margin="normal" required fullWidth>
                <Select
                  classes={classes}
                  options={this.state.projectList}
                  value={newTicket.projectName}
                  onChange={(e) => this.selectProject(e)}
                  placeholder={newTicket.projectName || "Select Project"}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Select
                  classes={classes}
                  options={this.state.tasksList}
                  value={newTicket.taskName}
                  onChange={(e) => this.selectTask(e)}
                  placeholder={newTicket.taskName || "Select Task"}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="requested"
                  value={newTicket.requested}
                  onChange={(e) => this.changeHandler(e)}
                  label="Request Item"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  multiline
                  rows="4"
                  name="description"
                  value={newTicket.description}
                  onChange={(e) => this.changeHandler(e)}
                  label="Description"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="price"
                  value={newTicket.price}
                  onChange={(e) => this.changeHandler(e)}
                  label="Price"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              
              <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={this.handleClose}
              >
                Close
              </Button>
              <Button
              className="pull-right"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </ValidatorForm>
          </DialogContent>
        </Dialog>


        <Dialog
          open={this.state.editForm}
          onClose={this.handleEditFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Ticket</DialogTitle>
          <DialogContent>
            <ValidatorForm 
              className={classes.form} 
              onSubmit={this.updateTicket}
              onError={errors => console.log(errors)}
              instantValidate={false} >
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="requested"
                  value={editTicket.requested}
                  onChange={(e) => this.changeEditHandler(e)}
                  label="Request Item"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  multiline
                  rows="4"
                  name="description"
                  value={editTicket.description}
                  onChange={(e) => this.changeEditHandler(e)}
                  label="Description"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  name="price"
                  value={editTicket.price}
                  onChange={(e) => this.changeEditHandler(e)}
                  label="Price"
                  type="text"
                  validators={['required']}
                  errorMessages={['this field is required']}
              />
              </FormControl>
              
              <Button
              className="pull-right"
              type="button"
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={this.handleEditFormClose}
              >
                Close
              </Button>
              <Button
              className="pull-right"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(Ticket);
