import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';

import {
  createOfficeSubTask,
  getOfficeSubTaskList,
  getOfficeSpaceList,
  getProjectSiteSupervisorList,
  getTaskUpdatesList,
  updateTask,
  deleteTask,
} from '../../../../api';
import MaterialTable from 'material-table';

class SubtaskListInstallation extends Component {
  state = {
    openDialog: false,
    TaskUpdates: [],
    TaskList: [],
    dependentOnOption: [],
    VendorOption: this.props.VendorOption,
    SiteSupervisorOption: [],
    openSubtaskDialog: false,
    taskId: this.props.taskId,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ VendorOption: nextProps.VendorOption });
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    if (this.props.project) {
      try {
        const SpaceListRes = await getOfficeSpaceList(this.props.auth.token)
        const TaskListRes = await getOfficeSubTaskList(this.props.auth.token, { project_id: this.props.project.id }, this.state.taskId)
        const SiteSupervisorListRes = await getProjectSiteSupervisorList(this.props.auth.token, this.props.project.id)

        let SpaceOptions = {}
        SpaceListRes.data.map(i => SpaceOptions[i.id] = i.name)
        let dependentOnOption = {}
        TaskListRes.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)

        this.setState({
          TaskList: TaskListRes.data,
          SpaceList: SpaceListRes.data,
          SpaceOptions,
          dependentOnOption,
          SiteSupervisorOption
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  status = (id) => {
    switch (id) {
      case 0:
        return "Not Initiated";
      case 1:
        return "Initiated";
      case 2:
        return "Completed";
      default:
        break;
    }
  }

  materialDeliveryStatus = (id) => {
    switch (id) {
      case 0:
        return "Not Delivered";
      case 1:
        return "Partial Delivery";
      case 2:
        return "Delivered";
      default:
        break;
    }
  }

  installationValidation = (data) => {
    if ((data.line_item !== "" && data.line_item !== null && data.line_item !== undefined) &&
      (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
      (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined)
      && (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
      (data.plan_start_date_time !== "" && data.plan_start_date_time !== null && data.plan_start_date_time !== undefined) &&
      (data.plan_end_date_time !== "" && data.plan_end_date_time !== null && data.plan_end_date_time !== undefined) &&
      (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) && (data.status !== "" && data.status !== null && data.status !== undefined)) {
      let plan_start_date = moment(data.plan_start_date_time)
      let plan_end_date = moment(data.plan_end_date_time)
      if (
        (plan_end_date.diff(plan_start_date, 'days') >= 0)
      ) {
        if (
          (data.start_date_time !== "" && data.start_date_time !== null && data.start_date_time !== undefined) &&
          (data.end_date_time !== "" && data.end_date_time !== null && data.end_date_time !== undefined)
        ) {
          let start_date = moment(data.start_date_time)
          let end_date = moment(data.end_date_time)
          if (
            (data.status == "0" || data.status == "1" || end_date.diff(start_date, 'days') >= 0)
          ) {
            return true
          } else {
            Swal.fire('Error', 'End date cannot be prior to start date.', 'info')
            return false
          }
        } else {
          if(data.status == "0"){
            return true
          }else {
            if(!data.start_date_time) {
              Swal.fire('Error', 'Actual start date is not present', 'info')
              return false
            }else {
              if(data.status == "1") {
                return true
              }else {
                if(!data.end_date_time) {
                  Swal.fire('Error', 'Actual end date is not present', 'info')
                  return false
                }else {
                  return true
                }
              }
            }
          }
        }
      } else {
        Swal.fire('Error', 'End date cannot be prior to start date.', 'info')
        return false
      }
    } else {
      if (data.value <= 0) {
        Swal.fire('Error', 'Value must be greater than 0', 'info')
        return false
      } else {
        if(!data.plan_start_date_time){
          Swal.fire('Error', 'Plan start date is not present.', 'info')
          return false
        }else if(!data.plan_end_date_time) {
          Swal.fire('Error', 'Plan end date is not present.', 'info')
          return false
        }else{
          Swal.fire('Error', 'Fill all the fields', 'info')
          return false
        }
      }
    }
  }

  openDialog = (task_id) => {
    getTaskUpdatesList(this.props.auth.token, task_id)
      .then(res => {
        this.setState({
          openDialog: true,
          TaskUpdates: res.data
        })
      })
      .catch(error => console.log(error))
  }

  render() {
    const { TaskList, SpaceOptions, dependentOnOption, SiteSupervisorOption, VendorOption, taskId, openDialog, TaskUpdates } = this.state;

    return (
      <React.Fragment>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Task ID',
              field: 'id',
              render: rowData => rowData !== undefined && <p className="cusAnchor" onClick={() => this.openDialog(rowData.id)} >{`P${rowData.project_id}T${rowData.id}`}</p>,
              editable: false,
              filtering: false
            },
            {
              title: 'Space',
              field: 'office_space_id',
              lookup: SpaceOptions,
              editable: false,
              filtering: false
            },
            {
              title: 'Line Item',
              field: 'line_item'
            },
            {
              title: 'Site Supervisor',
              field: 'office_user_id',
              lookup: SiteSupervisorOption
            },
            {
              title: 'Vendor',
              field: 'office_vendor_id',
              lookup: VendorOption
            },
            {
              title: 'Planned Start Date',
              field: 'plan_start_date_time',
              render: rowData => rowData.plan_start_date_time !== null ? moment(rowData.plan_start_date_time).format("ll") : null,
              type: 'date'
            },
            {
              title: 'Planned End Date',
              field: 'plan_end_date_time',
              render: rowData => rowData.plan_end_date_time !== null ? moment(rowData.plan_end_date_time).format("ll") : null,
              type: 'date'
            },
            {
              title: 'Actual Start Date',
              field: 'start_date_time',
              render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
              type: 'date'
            },
            {
              title: 'Actual End Date',
              field: 'end_date_time',
              render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
              type: 'date'
            },
            {
              title: 'Quantity',
              field: 'quantity',
              type: 'numeric',
              align: 'left'
            },
            {
              title: 'Unit of Measurement',
              field: 'unit_of_measurement'
            },
            {
              title: 'Current Status',
              field: 'status',
              render: rowData => <p>{this.status(rowData.status)}</p>,
              lookup: {
                0: "Not Initiated",
                1: "Initiated",
                2: "Completed",
              }
            }
          ]}
          data={TaskList.filter(task => task.office_task_type === "installation")}
          options={{
            filtering: true,
            exportButton: true,
            addRowPosition: 'first'
          }}
          icons={{
            Add: props => { return (<button className="btn"><i className="fa fa-plus" ></i> Add New Subtask</button>) }
          }}
          editable={{
            onRowAdd: newData => new Promise((resolve, reject) => {
              if (newData.length !== 0 && this.installationValidation(newData)) {
                setTimeout(() => {
                  {
                    let data
                    if(newData.status == 0) {
                      data = {
                        ...newData,
                        start_date_time:"",
                        end_date_time:"",
                        project_id: this.props.project.id,
                        office_task_type: 'installation'
                      }
                    }else if (newData.status == 1) {
                      data = {
                        ...newData,
                        end_date_time:"",
                        project_id: this.props.project.id,
                        office_task_type: 'installation'
                      }
                    }else{
                      data = {
                        ...newData,
                        project_id: this.props.project.id,
                        office_task_type: 'installation'
                      }
                    }
                    createOfficeSubTask(this.props.auth.token, data, taskId)
                      .then(res => {
                        if (res.status === 200) {
                          Swal.fire('Success', 'Subtask created successfully.', 'success')
                          getOfficeSubTaskList(this.props.auth.token, { project_id: this.props.project.id }, taskId)
                            .then(res => {
                              console.log('res', res);
                              if (res.status === 200) {
                                let dependentOnOption = {}
                                res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                                this.setState({ TaskList: res.data, dependentOnOption })
                              }
                            })
                            .catch(error => console.log(error))
                        }
                      })
                  }
                  resolve();
                }, 1000);
              } else {
                return setTimeout(() => {
                  // {
                  //   console.log('rejected', newData)
                  //   Swal.fire('Error', 'Fill all the fields', 'info')
                  // }
                  return reject()
                }, 1000);
              }
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              if (newData.length !== 0 && this.installationValidation(newData)) {
                setTimeout(() => {
                  {
                    let data
                    if(newData.status == 0) {
                      data = {
                        ...newData,
                        start_date_time:"",
                        end_date_time:"",
                      }
                    }else if (newData.status == 1) {
                      data = {
                        ...newData,
                        end_date_time:"",
                      }
                    }else{
                      data = {
                        ...newData,
                      }
                    }
                    updateTask(this.props.auth.token, newData)
                      .then(res => {
                        if (res.status === 200) {
                          Swal.fire('Success', 'Subtask updated successfully.', 'success')
                          getOfficeSubTaskList(this.props.auth.token, { project_id: this.props.project.id }, taskId)
                            .then(res => {
                              if (res.status === 200) {
                                let dependentOnOption = {}
                                res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                                this.setState({ TaskList: res.data, dependentOnOption })
                              }
                            })
                            .catch(error => console.log(error))
                        }
                      })
                  }
                  return resolve();
                }, 1000);
              } else {
                return setTimeout(() => {
                  // {
                  //   console.log('rejected', newData)
                  //   Swal.fire('Error', 'Fill all the fields', 'info')
                  // }
                  return reject()
                }, 1000);
              }
            }),
            onRowDelete: oldData => new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  deleteTask(this.props.auth.token, oldData.id)
                    .then(res => {
                      if (res.status === 200) {
                        Swal.fire('Success', 'Subtask deleted successfully.', 'success')
                        getOfficeSubTaskList(this.props.auth.token, { project_id: this.props.project.id }, taskId)
                          .then(res => {
                            if (res.status === 200) {
                              let dependentOnOption = {}
                              res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                              this.setState({ TaskList: res.data, dependentOnOption })
                            }
                          })
                          .catch(error => console.log(error))
                      }
                    })
                }
                resolve();
              }, 1000);
            })
          }}
        // onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
        />
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false, TaskUpdates: [] })}
          scroll={'paper'}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <MaterialTable
              title=""
              columns={[
                { title: 'Remark', field: 'remark' },
                {
                  title: 'Created on',
                  field: 'created_at',
                  render: rowData => moment(rowData.created_at).format("ll"),
                  type: 'date'
                },
              ]}
              data={TaskUpdates}
              options={{
                filtering: true,
                exportButton: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false, TaskUpdates: [] })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(SubtaskListInstallation);
