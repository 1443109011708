import axios from '../base';
export function createPPT(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_moodboards/create_ppt?slide_type=${data.slide_type}`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

// create ppt for curtain
// export function createPPTForCurtain(accessToken, data) {
//   return axios.request({
//     url: '/api/v1/office_moodboards/create_ppt?slide_type=curtains',
//     method: 'POST',
//     headers: accessToken,
//     data: data
//   })
// };

export function createMoodboard(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_moodboards',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function updateMoodboard(accessToken, data) {
  let id = "";
  for (var pair of data.entries()) {
    if (pair[0] === "id") {
      id = pair[1]
    }
  }
  return axios.request({
    url: `/api/v1/office_moodboards/${id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function MoodboardList(accessToken, data = null) {
  return axios.request({
    url: '/api/v1/office_moodboards',
    method: 'GET',
    headers: {
      ...accessToken,
      'Content-Type': 'application/json'
    },
    params: data
  })
};

export function MoodboardListForCurtains(accessToken, data = null) {
  return axios.request({
    url: '/api/v1/office_moodboards',
    method: 'GET',
    headers: {
      ...accessToken,
      'Content-Type': 'application/json'
    },
    params: data
  })
};

export function deleteMoodboard(accessToken, id) {
  return axios.request({
    url: `/api/v1/office_moodboards/${id}`,
    method: 'DELETE',
    headers: accessToken,
  })
};

export function getThumbnail(data) {
  return axios.request({
    url: `/api/v1/office_moodboards/get_thumbnails/${data}`,
    method: 'GET',
  })
};

export function MoodboardPptList(accessToken) {
  return axios.request({
    url: '/api/v1/office_moodboards/moodboard_ppt_stat',
    method: 'GET',
    headers: accessToken,
  })
};

export function MarketingPortfolios(accessToken) {
  return axios.request({
    url: '/v1/portfoliois',
    method: 'GET',
    headers: accessToken,
  })
};
