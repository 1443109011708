import axios from '../base';

export function getProjectVendorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/vendors_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getVendorTaskList(accessToken, params) {
  return axios.request({
    url: '/api/v1/office_tasks/office_task_for_vendor',
    method: 'GET',
    headers: accessToken,
    params: params
  })
};

export function getVendorProjectSiteSupervisorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/site_supervisor_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};