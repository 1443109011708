import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProjectDetails from './ProjectDetails';
import SiteMeasurement from './SiteMeasurement';
import ProjectFiles from './ProjectFiles';
import ProjectBOQs from './ProjectBOQs';
import { Typography, Breadcrumbs, Link } from '@material-ui/core'
import { getResidentialProjectDetails, getProjectVendorList } from '../../../api';
import CircularIndeterminate from '../../Loader';
import ProjectBOQJourney from './ProjectBOQJourney';
import AssignProjectManager from './AssignProjectManager';
import Snags from './Snags';
import LaborReport from './LaborReport';
import ClientMeetingMoM from './ClientMeetingMoM';
import SiteUpdate from './SiteUpdate';
import CustomerReport from './CustomerReport'
import TaskList from './TaskList';
import Ticket from './Ticket';
import AssignVendor from './AssignVendor';
import ProjectHandover from './ProjectHandover';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '5px'
  },
});

class Project extends Component {
  state = {
    value: 0,
    isLoading: true,
    project: [],
    projectStatus: null,
    VendorOption: []
  };

  componentDidMount(){
    let url = new URL(window.location.href)
    let project_status = url.searchParams.get('project_status') || null;
    if (project_status !== null){
      this.setState({ projectStatus : project_status });
    }
    this.getProjectDetails()
    this.getVendorOptionList()
  }
  
  getProjectDetails = () => {
    let projectId = this.props.match.params.project_id || undefined
    getResidentialProjectDetails(this.props.token, projectId)
    .then(res => {
      if (res.status === 200) {
        this.setState({
          isLoading: false,
          project: res.data,
          projectId
        })
      }
    })
    .catch(error => console.log(error))
  }

  getVendorOptionList = () => {
    let projectId = this.props.match.params.project_id || undefined
    getProjectVendorList(this.props.token, projectId)
    .then(res => {
      if (res.status) {
        let VendorOption = {}
        res.data.map(i => VendorOption[i.id] = i.name)
        this.setState({ VendorOption })
      }
    })
    .catch(error => console.log(error))
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { isLoading, projectId, project, projectStatus, VendorOption } = this.state;
    return(
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '5px' }}>
          <Link color="inherit" href={`/gm/projects${ projectStatus !== null ? `?project_status=${projectStatus}`: ''}`}>
            Projects List
          </Link>
          <Typography color="textPrimary">{(project.lead && project.lead.name)|| "Project"}</Typography>
        </Breadcrumbs>
        { isLoading ?
            <CircularIndeterminate/>
          :
          <React.Fragment>
            {(( project.length !== 0 && project.closure == true && project.pushed_to_production == false) &&

            <React.Fragment>
              <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"    
                  >
                  <Tab label="Project Details" />
                  <Tab label="Assign Project Manager" />
                  <Tab label="Files" />
                  <Tab label="Assign Vendor"/>
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                  >
                  <TabContainer><ProjectBOQJourney project={project} /></TabContainer>
                  <TabContainer><AssignProjectManager project={project} /></TabContainer>
                  <TabContainer><ProjectFiles project={project} /></TabContainer>
                  <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()}/></TabContainer>
                      
                </SwipeableViews>
            </React.Fragment>)
             ||
               ((project.length !== 0 && project.closure == true && project.pushed_to_production == true) &&
                 <div>
                   {( project.office_project_handover_details !== undefined && 
                    project.office_project_handover_details.length !== 0 ) ? 
                    <>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"            
                      >
                        <Tab label="Project Details" />
                        <Tab label="Project BOQ Journey" />
                        <Tab label="Project Handover" />
                        <Tab label="Final BOQs" />
                        <Tab label="Assign Project Manager" />
                        <Tab label="Final Files" />
                        <Tab label="Project Schedule" />
                        <Tab label="Snags" />
                        <Tab label="Tickets" />
                        <Tab label="Daily Labour Report" />
                        <Tab label="Client Meeting MOM" />
                        <Tab label="Site Update" />
                        <Tab label="Customer Report" />
                        <Tab label="Assign Vendor"/>
                        <Tab label="Site Measurement" />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer><ProjectDetails project={project} /></TabContainer>
                      <TabContainer><ProjectBOQJourney project={project} /></TabContainer>
                      <TabContainer><ProjectHandover project={project} /></TabContainer>
                      <TabContainer><ProjectBOQs project={project} /></TabContainer>
                      <TabContainer><AssignProjectManager project={project} /></TabContainer>
                      <TabContainer><ProjectFiles project={project} /></TabContainer>
                      <TabContainer><TaskList project={project} VendorOption={VendorOption} /></TabContainer>
                      <TabContainer><Snags project={project}/></TabContainer>
                      <TabContainer><Ticket project={project} /></TabContainer>
                      <TabContainer><LaborReport project={project} VendorOption={VendorOption}/></TabContainer>
                      <TabContainer><ClientMeetingMoM project={project} /></TabContainer>
                      <TabContainer><SiteUpdate project={project} /></TabContainer>
                      <TabContainer><CustomerReport project={project} /></TabContainer>
                      <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()}/></TabContainer>
                      <TabContainer><SiteMeasurement project={project} /></TabContainer>
                    
                    </SwipeableViews>
                   </> :
                   <>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"            
                      >
                        <Tab label="Project Details" />
                        <Tab label="Project BOQ Journey" />
                        <Tab label="Final BOQs" />
                        <Tab label="Assign Project Manager" />
                        <Tab label="Final Files" />
                        <Tab label="Project Schedule" />
                        <Tab label="Snags" />
                        <Tab label="Tickets" />
                        <Tab label="Daily Labour Report" />
                        <Tab label="Client Meeting MOM" />
                        <Tab label="Site Update" />
                        <Tab label="Customer Report" />
                        <Tab label="Assign Vendor"/>
                        <Tab label="Site Measurement" />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer><ProjectDetails project={project} /></TabContainer>
                      <TabContainer><ProjectBOQJourney project={project} /></TabContainer>
                      <TabContainer><ProjectBOQs project={project} /></TabContainer>
                      <TabContainer><AssignProjectManager project={project} /></TabContainer>
                      <TabContainer><ProjectFiles project={project} /></TabContainer>
                      <TabContainer><TaskList project={project} VendorOption={VendorOption}/></TabContainer>
                      <TabContainer><Snags project={project}/></TabContainer>
                      <TabContainer><Ticket project={project} /></TabContainer>
                      <TabContainer><LaborReport project={project} VendorOption={VendorOption}/></TabContainer>
                      <TabContainer><ClientMeetingMoM project={project} /></TabContainer>
                      <TabContainer><SiteUpdate project={project} /></TabContainer>
                      <TabContainer><CustomerReport project={project} /></TabContainer>
                      <TabContainer><AssignVendor project={project} getVendorOptionList={() => this.getVendorOptionList()}/></TabContainer>
                      <TabContainer><SiteMeasurement project={project} /></TabContainer>
                    
                    </SwipeableViews>
                   </> }
                 </div>)
               ||
                 <div>No Project Found</div>}
          </React.Fragment>
        }
      </React.Fragment>

    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(Project);
