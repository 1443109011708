import axios from '../base';

export function createTicket(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_tickets',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function updateTicket(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_tickets/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function getTaskTickets(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_tickets/get_tickets/${data.project_id}/${data.task_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getTicketsList(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_tickets`,
    method: 'GET',
    headers: accessToken,
    params: {
      project_id: projectId
    }
  })
};

export function deleteTicket(accessToken, taskId) {
  return axios.request({
    url: `/api/v1/office_tickets/${taskId}`,
    method: 'DELETE',
    headers: accessToken,
  })
};

export function changeTicketStatus(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_tickets/change_status',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function changeTicketPaymentStatus(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_tickets/change_payment_status',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function getTicketCategories(accessToken){
  return axios.request({
    url: '/api/v1/office_tickets/office_ticket_category/1',
    method: 'GET',
    headers: accessToken
  })
}



