import axios from '../base';

export function getResidentialProjects(accessToken) {
  return axios.request({
    url: '/api/v1/office_projects/residential_projects_for_vendor/50_percent',
    method: 'GET',
    headers: accessToken
  })
};

export function getResidentialProjectsForVendor(accessToken, project_status, page, limit, search) {
  return axios.request({
    url: `/api/v1/office_projects/residential_projects_for_vendor/${project_status}`,
    method: 'GET',
    headers: accessToken
  })
};

// export function getProjectFiles(accessToken, projectId, filesFrom) {
//   return axios.request({
//     url: `/api/v1/office_projects/get_project_files/${projectId}?files_from=${filesFrom}`,
//     method: 'GET',
//     headers: accessToken
//   })
// }

export function getResidentialProjectDetails(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/get_residential_project/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getProjectVendorList(accessToken, project_id) {
  return axios.request({
    url: `/api/v1/office_tasks/vendors_list/${project_id}`,
    method: 'GET',
    headers: accessToken,
  })
};

// All 5 tabs

export function getVendorFinalFiles(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_updates/vendor_files_list/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getVendorProjectSchedule(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_tasks/office_task_for_vendor/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getVendorDailyLabourReport(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_labour_plans/office_labour_plan_for_vendor/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getVendorClientMeetingMoM(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/client_meeting_mom_for_vendor/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getVendorSiteUpdate(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_updates/vendor_site_update_lists/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};