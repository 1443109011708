import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Badge, Grid, TextField } from '@material-ui/core';
import FileIcon from '@material-ui/icons/AttachFile';
import { getCustomerList, sendBulkCustomerEmail } from '../../api'
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';
import ReactDropzone from "react-dropzone";
import CircularIndeterminate from '../Loader'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = theme => ({
  
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }
});

class ReportUpdate extends Component {
  state = {
    CudtomerList: [],
    customerFilterId: [],
    newFile: {},
    loader:false,
    respMessage:[],
    messageSection:false,
  };

  // componentDidMount() {
  //   getCustomerList(this.props.auth.token)
  //     .then(res => {
  //       if (res.status === 200) {
  //         this.setState({
  //           CudtomerList: res.data
  //         })
  //       }
  //     })
  //     .catch(error => console.log(error))
  // }

  // handleSelectCustomerAutoComplete = (event, newValue) => {
  //   console.log("newValue", newValue)
  //   console.log("event target", event)
  //   this.setState({
  //     customerFilterId:newValue.map(v => v.id) || [],
  //     customerFilterArray:newValue || [],
  //   })
  // }

  // handleSubmitEmail = () => {
  //   const customerId = this?.state?.customerFilterId?.length > 0 ? this?.state?.customerFilterId : ""
  //   sendBulkCustomerEmail(this.props.auth.token, customerId)
  //   .then(res => {
  //     if(res.status == 200) {
  //       console.log("res*****", res)
  //     }
  //   })
  // }

  toggleDialogFile = () => {
    this.setState({ openDialogFile: !this.state.openDialogFile, newFile: {} });
  };

  handleFiles = file => {
    this.setState({
      newFile: file[0]
    })
  }

  onCloseMessage = () => {
    this.setState({messageSection:false})
  }

  onSubmitTaskUpload = (e) => {
    if(this.state?.newFile?.name && this.state?.newFile?.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      this.setState({loader:true})
      const fd = new FormData();
      // fd.append('project_id', this.props.project.id)
      fd.append('file', this.state.newFile)
      sendBulkCustomerEmail(this.props.auth.token, fd)
      .then(response => {
        if (response.status) {
          this.setState({loader:false, respMessage:response.data, messageSection:true})
          this.toggleDialogFile();
          // this.getData()
          let array = response.data
          let list = '';
          for (var i = 0; i < array.length; i++)
              list += array[i].message + '\n';
              console.log(list)
          Swal.fire('Success', "File uploaded successfully", 'success')
        }
      })
      .catch(error => {
        this.setState({loader:false})
        Swal.fire('Error', 'Something went wrong', 'error')
      })
    }else{
      this.setState({loader:false})
      Swal.fire('Info', "Please upload xslx file", 'info')
    }
  }

  render() {
    const { classes } = this.props;
    const { CudtomerList, files, filesDialog, filesList, loader, messageSection, respMessage  } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs>
            <h4>Send Bulk Customer WPR Email</h4>
          </Grid>
          {/* <Grid item xs style={{textAlign:"right"}}>
            <Button className="mb-3 ml-3" variant="contained" color="primary" onClick={() => this.toggleDialogFile() }>Customer Email Bulk Upload/Update</Button>
          </Grid> */}
        </Grid>
        {/* <Grid container>
          <Grid item xs={8}>
            <Grid container alignItems="flex-end">
              <Autocomplete
                value={this.state.customerFilterArray}
                multiple
                // id="checkboxes-tags-demo"
                options={CudtomerList}
                disableCloseOnSelect
                limitTags = {6}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField style={{width:"95%", padding:"16px 0px"}} className="textField" {...params} placeholder="Send Bulk Customer Email" />
                )}
                onChange={(event, newValue) => this.handleSelectCustomerAutoComplete(event, newValue)}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems="flex-end">
              <Grid item style={{padding:"10px"}}>
                <Button variant="contained" color="primary" onClick={() => this.handleSubmitEmail()}>Send Bulk Email</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Dialog
          open={this.state.openDialogFile}
          onClose={() => this.toggleDialogFile()}
          aria-labelledby="form-dialog-title"
          style={{ height: '100vh' }}
          maxWidth={'md'}
        > */}
        <Grid container>
          <Grid item xs={6}>
            <DialogTitle id="form-dialog-title">
              Upload File
              <br />
              <Button
                variant="contained"
                color="secondary"
                style={styles.button}
                startIcon={<CloudDownloadIcon />}
                onClick={() => window.open(`${process.env.REACT_APP_API_BASE_URL}/lead_id_bulk_upload.xlsx`)}
              >
                Bulk WPR Email Excel format
              </Button>
            </DialogTitle>
            <ValidatorForm
              onSubmit={() => this.onSubmitTaskUpload()}
              onError={errors => console.log(errors)}
              instantValidate={false} >
              <DialogContent style={{ minWidth: '400px', textAlign:'center' }}>
                <ReactDropzone onDrop={this.handleFiles} style={{display:"flex", justifyContent:"center", alignItems:"center", height:"200px", width:"200px", border:"2px", borderColor: "rgb(102, 102, 102)",borderStyle: "dashed", borderRadius:"5px", marginLeft:"10px", color:"blue"}}>
                  {loader == false && <p>Drop your file here!!</p>}
                  {loader == true && <div><CircularIndeterminate/></div>}
                </ReactDropzone>
                <aside style={styles.thumbsContainer, {textAlign:"left", color:"blue", marginLeft:"10px"}}>
                  {this.state.newFile.name}
                </aside>
                <DialogActions style={{ justifyContent:"flex-start" }}>
                  <Button
                    className="pull-right"
                    type="button"
                    variant="contained"
                    // style={{ marginLeft: '20px' }}
                    onClick={() => this.toggleDialogFile()}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary">
                    Upload File
                  </Button>
                </DialogActions>
              </DialogContent>
            </ValidatorForm>
          </Grid>
          {messageSection && respMessage.length > 0 && <Grid item xs={5}>
            <Grid style={{padding:"10px", backgroundColor:"gray", height:"500px", position:"relative", maxHeight:"500px", overflow:"scroll"}}>
            {respMessage?.map(value => {
            return<Grid style={{padding:"5px", backgroundColor:"gray"}}>
                <Grid style={{height:"50px", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"10px", backgroundColor:"white"}}>
                  {value.message}
                </Grid>
              </Grid>
            })}
            </Grid>
              <Grid onClick={this.onCloseMessage} style={{backgroundColor:"blue", justifyContent:"center", display:"flex", alignItems:"center", cursor:"pointer"}}>
                <Button style={{color:"white"}}>Close</Button>
              </Grid>
          </Grid>}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(ReportUpdate);