import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField,Tooltip, Badge } from '@material-ui/core';
import { getTicketsList, getProjectSiteSupervisorList, getTicketCategories, updateTicket} from '../../../api';
import FileIcon from '@material-ui/icons/AttachFile';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumbInner = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
  width: '50vh',
  height: '50vh'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

class Ticket extends Component {
  state = {
    Snags: [],
    SiteSupervisorList: [],
    TickectStatus: {
      0: 'Open',
      1: 'In Progress',
      2: 'Closed'
    },
    files: [],
    filesDialog: false,
    filesList: []
  };

  async componentDidMount() {
    if (this.props.project) {
      try {
        const TicketsListRes = await getTicketsList(this.props.auth.token, this.props.project.id)
        const SiteSupervisorListRes = await getProjectSiteSupervisorList(this.props.auth.token, this.props.project.id)
        const TicketCategoriesRes = await getTicketCategories(this.props.auth.token)

        let SiteSupervisorOption = {}
        SiteSupervisorListRes.data.map(i => SiteSupervisorOption[i.id] = i.name)
        let TicketCategoriesOptions = {}
        TicketCategoriesRes.data.map(i => TicketCategoriesOptions[i.id] = i.name)
        
        this.setState({
          TicketsList: TicketsListRes.data,
          SiteSupervisorList: SiteSupervisorListRes.data,
          SiteSupervisorOption,
          TicketCategoriesOptions
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  TicketValidation = (data) => {
    if (
      (data.user_id !== undefined && data.user_id !== "" && data.user_id !== null ) &&
      (data.description !== undefined && data.description !== "" && data.description !== null ) &&
      (data.price !== undefined && data.price !== "" && data.price !== null ) &&
      (data.status !== undefined && data.status !== "" && data.status !== null ) &&
      (data.closed_date !== undefined && data.closed_date !== "" && data.closed_date !== null ) &&
      (data.office_ticket_category_id !== undefined && data.office_ticket_category_id !== "" && data.office_ticket_category_id !== null ) &&
      (data.quantity !== undefined && data.quantity !== "" && data.quantity !== null )
    ) {
      if ((data.price >= 0 && !isNaN(data.price)) && ( data.quantity >= 0 && !isNaN(data.quantity))) {
        return true
      }else if(data.price < 0){
        alert('price is Invalid')
        return false
      }else if(data.quantity < 0){
        alert('quantity is Invalid')
        return false
      } else {
        alert("Invalid Field")
        return false
      }
    } else {
      return false
    }
  }

  render() {
    const { TicketsList, SiteSupervisorOption, TicketCategoriesOptions, TickectStatus, files, filesDialog, filesList } = this.state;
    return(
      <div>
          <MaterialTable
          title="Tickets List"     
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{margin: '10px'}}>
                
                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Files',
              field: 'files',
              render: rowData => {
                return (
                  <Tooltip title="Files" aria-label="add">
                    <Badge
                      color="secondary"
                      badgeContent={rowData.files && rowData.files.length}
                      invisible={rowData.files && rowData.files.length > 0 ? false : true}
                      >
                      <FileIcon
                        color={rowData.files && rowData.files.length > 0 ? "primary" : "disabled"} />
                    </Badge>
                  </Tooltip>
                )
              },
              grouping: false,
              filtering: false,
              editable: 'never'
            },
            {
              title: 'Ticket Id',
              field: 'unique_id',
              editable: 'never'
            },
            {
              title: 'Raised by',
              field: 'user_id',
              lookup: SiteSupervisorOption
            }, 
            {
              title: 'Raised on',
              field: 'created_at',
              render: rowData=> moment(rowData.created_at).format("ll"),
              type: 'date',
              editable: 'never'
            }, 
            { title: 'Description' , field: 'description' }, 
            {
              title: 'Type',
              field: 'office_ticket_category_id',
              lookup: TicketCategoriesOptions
            },
            { title: 'Price' , field: 'price' }, 
            { title: 'Quantity' , field: 'quantity' }, 
            {
              title: 'Status',
              field: 'status',
              lookup: TickectStatus
            }, 
            {
              title: 'Closed date',
              field: 'closed_date',
              render: rowData=> rowData.status === 2 ? moment(rowData.closed_date).format("ll") : null,
              type: 'date',
            }, 
            {
              title: 'Is Urgent',
              field: 'is_urgent',
              lookup: {
                true: "Yes",
                false: "No"
              },
              editable: 'never'
            },
          ]}
          onRowClick={(event, rowData, togglePanel) => (rowData.files && rowData.files.length > 0) ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
          data={TicketsList}        
          options={{
            filtering: true,
            exportButton: true
          }}
          // editable={{
          //   onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          //     if (this.TicketValidation(newData)) {
          //       setTimeout(() => {
          //         {
          //           updateTicket(this.props.auth.token, newData)
          //           .then(res => {
          //             if (res.status === 200) {
          //               getTicketsList(this.props.auth.token, this.props.project.id)
          //               .then(res => {
          //                 if (res.status === 200) {
          //                   this.setState({TicketsList: res.data})
          //                 }
          //               })
          //               .catch(error => console.log(error))
          //             }  
          //           })
          //         }
          //         return resolve();
          //       }, 1000);
          //     } else {
          //       return setTimeout(() => {
          //         {
          //           console.log('rejected', newData)
          //           alert("Fill all the fields")
          //         }
          //         return reject()
          //       }, 1000);
          //     }
          //   })
          // }}
          />
          <Dialog
            open={filesDialog}
            onClose={() => this.setState({ filesDialog: false })}
            scroll={'paper'}
            aria-labelledby="task-updates"
            maxWidth="lg"
            fullWidth={true}
            >
            <DialogTitle id="task-updates">Files</DialogTitle>
            <DialogContent dividers={true}>
              <aside style={thumbsContainer}>
              {
                filesList.map(file => (
                  <div style={thumbInner}>
                    <img
                      src={file.file}
                      style={img}
                      alt="Arrivae"
                    />
                  </div>
                ))
                }
              </aside>
            </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
                  Cancel
                </Button>
              </DialogActions>
          </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(Ticket);
