import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as AuthActions from '../ducks/auth';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ArrivaeLogo from '../arrivae.png'
import ArrivaeBackgroundImage from '../images/arrivae_bg_black.png'
import Eye from '../images/eye.png'
import EyeOff from '../images/eye-off.png'
import { InputAdornment } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  paperFullScreen: {
    width: '100%',
    margin: 0,
    height: '100%',
    maxWidth: '100%',
    maxHeight: 'none',
    borderRadius: 0,
    backgroundColor: '#3857d4',
    backgroundImage: `url(${ArrivaeBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
  overlayDialog :{
    backgroundColor: 'black',
    opacity: 0.5
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class SignIn extends Component {
  
  state = {
    email: '',
    password: '',
    showPassword: false,
    showLoader: false,
    errorMessages: '',
    open: false,
  }

  onSubmit = e => {
    this.setState({showLoader: true})
    e.preventDefault();
    const { email, password } = this.state;
    this.props.verifyUserAndLogin(email.trim(), password);
  }

  handleBlur = (event) => {
    this.refs[event.target.name].validate(event.target.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.auth.message){
      this.setState({
        open: true,
        errorMessages: nextProps.auth.message,
        showLoader: false,
      })
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render(){
    const token = this.props.auth && this.props.auth.token;
    const { classes } = this.props;
    const { showPassword, showLoader, errorMessages } = this.state;
    
    return (
      token ?
      <Redirect to='/' /> :
      <Dialog 
        fullScreen={true} 
        open={true}
        classes={{paperFullScreen: classes.paperFullScreen}}>
        {showLoader && <LinearProgress />}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <img src={ArrivaeLogo} alt="Arrivae" width="200"/>
            <br/>
            <Typography variant="h4">Sign in</Typography>
            
            <ValidatorForm 
              className={classes.form} 
              onSubmit={this.onSubmit}
              onError={errors => console.log(errors)}
              instantValidate={false} >
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  ref="email"
                  label="Email"
                  onBlur={(e) => this.handleBlur(e)}
                  onChange={(e) => this.setState({email: e.target.value})}
                  name="email"
                  value={this.state.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['this field is required', 'email is not valid']}
                  fullWidth={true}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  label="Password"
                  onChange={(e) => this.setState({password: e.target.value})}
                  name="password"
                  type={showPassword? 'text': 'password'}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={this.state.password}
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        {!showPassword && <img src={Eye} alt="ShowPassword" onClick={() => this.setState({showPassword: true})} />}
                        {showPassword && <img src={EyeOff} alt="ShowPassword" onClick={() => this.setState({showPassword: false})} />}
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign in
              </Button>
            </ValidatorForm>
            <div>
              <br/>
              <Link style={{float:'right'}} to="/forgot-password">
                Forgot password?
              </Link>
            </div>
          </Paper>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          
          message={
            <span className={classes.message}>
              <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
              {errorMessages[0]}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Dialog>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(AuthActions, dispatch)
  }
}

export default compose(withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps))(SignIn);