import React, { Component } from 'react';
import { updatePassword } from '../api';
import queryString from 'query-string';

class PasswordForgotConfirmation extends Component {
  state = {
    password: '',
    passwordConfirmation: '',
    accessToken:'',
    showDanger: true,
    showError: false,
    message: ''
  }

  onSubmit = (e) =>{
    e.preventDefault();
    let params = queryString.parse(this.props.location.search)
    const { password,passwordConfirmation} = this.state;
    updatePassword(password,passwordConfirmation,params)
    .then(response => {
      console.log('response',response)
      if(response.status === 200){
        this.setState({showDanger:false,showError:true, message: response.data.message})
      }else{
        this.setState({showDanger:true,showError:true, message:response.data.message})
      }
    })
    .catch((error)=>{
      console.log('error:',error.response.data.errors)
      this.setState({showError:true, showDanger:true, message: error.response.data.errors.full_messages})
    });
  }

  render() {
    return(
      <div className="auth-wraper">
        <form onSubmit={this.onSubmit}>
          <div className="auth-box">
            <div className="logo-head">
              <img src="" alt=""/>
            </div>
            {/* error messages */}
            { this.state.showError &&
              <div style={{color: this.state.showDanger? 'red' : 'green'}}>
                <p>{this.state.message}</p>
              </div>
            }

            <div className="form-group">
              <label>Password</label>
              <div className="input-group">
                <span className="input-group-addon"><i className="fa fa-key"></i></span>
                <input
                  value={this.state.password}
                  onChange={(e) => this.setState({password: e.target.value})}
                  type="password" className="form-control" placeholder="Enter password "
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label>Password Confirmation</label>
              <div className="input-group">
                <span className="input-group-addon"><i className="fa fa-key"></i></span>
                <input
                  value={this.state.passwordConfirmation}
                  onChange={(e) => this.setState({passwordConfirmation: e.target.value})}
                  type="password" className="form-control" placeholder="Enter password confirmation"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary form-btn">Send</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default PasswordForgotConfirmation;
