import React from "react";
import Workbook from 'react-excel-workbook'
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  iconSmall: {
    fontSize: 20,
  }
});

class DownloadReport extends React.Component {
  state = {
    operationList: []
  }
  componentDidMount(){
    let newOperationList = [];
    if(this.props.operationList.length !== 0){
      const operationList = this.props.operationList
      for (const operation in operationList) {
        if (operationList.hasOwnProperty(operation)) {
          newOperationList.push({
            "id": operationList[operation].id,
            "sub_factory_process_id": operationList[operation].sub_factory_process_id,
            "name": operationList[operation].name,
            "start_date_time": moment(operationList[operation].start_date_time).format('LLL'),
            "end_date_time": moment(operationList[operation].end_date_time).format('LLL'),
            "duration": moment(operationList[operation].end_date_time).diff(moment(operationList[operation].start_date_time),'minutes')
          })
        }
      }
      this.setState({ operationList: newOperationList})
    }
  }
    render() {
      const { classes } = this.props;
      const { operationList } = this.state;
        return (
          <div className="row text-center">
            <Workbook filename="operationsList.xlsx" 
              element={
                <Button variant="contained" size="small" className={classes.button}>
                  <DownloadIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                  Download
                </Button>
              }>
              <Workbook.Sheet data={operationList} name="Operation">
                <Workbook.Column label="ID" value="id"/>
                <Workbook.Column label="Sub Process Id" value="sub_factory_process_id"/>
                <Workbook.Column label="Name" value="name"/>
                <Workbook.Column label="Start Date" value="start_date_time"/>
                <Workbook.Column label="End Date" value="end_date_time"/>
                <Workbook.Column label="Duration (mins)" value="duration"/>
              </Workbook.Sheet>
            </Workbook>
          </div>
            
        );
    }
}

export default withStyles(styles)(DownloadReport);