import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Snackbar } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  getSiteSupervisorList,
  mapSiteSupervisortoProjectEngineer, getPEListForSS
} from '../../api';
import CircularIndeterminate from '../Loader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import ClientIcon from '@material-ui/icons/AccountCircle';
import MaterialTable from 'material-table'

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  }
})

class SiteSupervisorList extends Component {
  state = {
    SiteSupervisor: [],
    selectedProject: [],
    pmList: [],
    isMapLoading: false,
    seletedSS: null,
    showDialog: false,
    errorMessages: '',
    openError: false,
  }

  componentDidMount() {
    getSiteSupervisorList(this.props.token, 'site_supervisor')
      .then(response => {
        if (response.status) {
          this.setState({
            SiteSupervisor: response.data,
          });
        }
      })
  }

  ProjectManagerModel = async (rowData) => {
    await getPEListForSS(this.props.token, rowData.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            pmList: res.data,
            showDialog: true,
            seletedSS: rowData.id
          })
        }
      })
      .catch(error => console.log(error))
  }

  assignPEToSS = (office_project_engineer_id) => {
    this.setState({ isMapLoading: true })
    let data = {
      office_site_supervisor_id: this.state.seletedSS,
      office_project_engineer_id: office_project_engineer_id
    }
    mapSiteSupervisortoProjectEngineer(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          getPEListForSS(this.props.token, this.state.seletedSS)
            .then(res => {
              if (res.status === 200) {
                console.log(res.data)
                this.setState({
                  pmList: res.data,
                  isMapLoading: false
                })
              }
            })
        }
      })
      .catch(error => console.log(error))
  }

  render() {
    const { classes } = this.props;
    const { SiteSupervisor, errorMessages,
      pmList,
      isMapLoading,
      showDialog,
    } = this.state;
    return (
      <div>
        <div>
          <MaterialTable
            title="Site Supervisors List"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects.count' },
              {
                title: 'Assigned Project Manager',
                field: 'id',
                render: rowData => <ClientIcon
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.ProjectManagerModel(rowData)} />,
                grouping: false,
                filtering: false
              },
            ]}
            data={SiteSupervisor}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
          />
        </div>

        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='lg'
          onClose={() => this.setState({ showDialog: false })}
          scroll='paper'
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Assign Project Manager
          </DialogTitle>
          <DialogContent dividers={true}>
            {isMapLoading ?
              <CircularIndeterminate />
              :
              <MaterialTable
                title=""
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Email', field: 'email' },
                  { title: 'Contact', field: 'contact' },
                  { title: 'No. Active Projects', field: 'active_projects' },
                  { title: 'Type', field: 'pe_type' },
                ]}
                data={pmList}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  grouping: true
                }}
                actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => this.assignPEToSS(rowData.id)
                  }
                ]}
                components={{
                  Action: props => {
                    return (
                      props.data.is_ss_assigned ?
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          Unassign
                  </Button>
                        :
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Assign
                  </Button>
                    )
                  }
                }}

              />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openError}
          autoHideDuration={6000}
          onClose={() => this.setState({ openError: false, errorMessages: '' })}

          message={
            <span className={classes.message}>
              <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
              {errorMessages}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({ openError: false, errorMessages: '' })}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteSupervisorList);