import axios from '../base';

export function createOperation(accessToken, operationData) {
  return axios.request({
    url: '/api/v1/operations',
    method: 'POST',
    headers: accessToken,
    data: operationData
  })
};

export function getProcessOperation(accessToken,processId,data = null) {
  return axios.request({
    url: `/api/v1/operations/get_process_operations/${processId}`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function getProcessOperationList(accessToken,processId,data = null) {
  return axios.request({
    url: `/api/v1/operations/get_process_operations_list/${processId}`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};