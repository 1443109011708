import axios from '../base';

export function getLaborPlanList(accessToken, projectId){
  return axios({
    url: `/api/v1/office_labour_plans`,
    params: {
      project_id: projectId
    },
    method: 'GET',
    headers: accessToken,
  })
}
