
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
});

class PaymentsHistory extends React.Component {
  state = {
    open: false,
  }


  render() {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          Payments History
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(PaymentsHistory);