import React, { Component } from 'react';
import { getOfficeUserListByRole, getAssignedSSToPMList, mapSSToPM } from '../../../api';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../Loader';
import { connect } from 'react-redux';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';

class PmSsMapping extends Component {

  state = {
    isLoading: false,
    ProjectManagers: [],
    showDialog: false,
    SSList: [],
    seletedPM: null,
    isMapLoading: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    try {

      let ProjectManagersRes = await getOfficeUserListByRole(this.props.token, 'project_engineer')
      this.setState({
        ProjectManagers: ProjectManagersRes.data,
        isLoading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  AssignCMModel = async (rowData) => {
    await getAssignedSSToPMList(this.props.token, rowData.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            SSList: res.data,
            showDialog: true,
            seletedPM: rowData.id
          })
        }
      })
      .catch(error => console.log(error))
  }

  assignSsToPm = (user_id) => {
    this.setState({ isMapLoading: true })
    let data = {
      office_project_engineer_id: this.state.seletedPM,
      office_site_supervisor_id: user_id
    }
    mapSSToPM(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          getAssignedSSToPMList(this.props.token, this.state.seletedPM)
            .then(res => {
              if (res.status === 200) {
                this.setState({
                  SSList: res.data,
                  isMapLoading: false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.setState({ isMapLoading: false })
            })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isMapLoading: false })
      })
  }

  render() {
    const { ProjectManagers, isLoading, showDialog, SSList, isMapLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Project Manager List"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects.count' },
              {
                title: 'Assigned Site Supervisor',
                field: 'id',
                render: rowData => <AssignmentIndIcon
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.AssignCMModel(rowData)} />,
                grouping: false,
                filtering: false
              }
            ]}
            data={ProjectManagers}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100]
            }}
          />
        }
        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='lg'
          onClose={() => this.setState({ showDialog: false })}
          scroll='paper'
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Assign Site Supervisor
            </DialogTitle>
          <DialogContent dividers={true}>
            {isMapLoading ?
              <CircularIndeterminate />
              :
              <MaterialTable
                title=""
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Email', field: 'email' },
                  { title: 'Contact', field: 'contact' },
                  { title: 'No. Active Projects', field: 'active_projects' }
                ]}
                data={SSList}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  grouping: true
                }}
                actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => this.assignSsToPm(rowData.id)
                  }
                ]}
                components={{
                  Action: props => {
                    return (
                      props.data.is_ss_assigned ?
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          Unassign
                    </Button>
                        :
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Assign
                    </Button>
                    )
                  }
                }}

              />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })} color="primary">
              Close
              </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default connect(mapStateToProps)(PmSsMapping);