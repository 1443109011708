import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.css'
import './fonts.scss';
import './App.scss';

// import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import PasswordForgot from './components/PasswordForgot';
import PasswordForgotVerification from './components/PasswordForgotVerification';
import PasswordForgotConfirmation from './components/PasswordForgotConfirmation';
import PasswordReset from './components/PasswordReset';
import NotFound from './components/NotFound';
import SignIn from './components/SignIn';
import Designer from './components/Designer/DesignerDashboard';
import SlideShow from './components/Designer/SlideShow';
import SlideShowCurtain from './components/Designer/SlideShowCurtain';
import ApproveForm from './components/ProjectEngineer/Projects/Residential/ApproveForm';

class App extends Component {
  render() {
    const token = this.props.auth.token;
    console.log("DashboardToken", token);
    return (
      <Router>
        <Switch>
          <PrivateRoute exact path="/reset_password" component={PasswordReset} token={token} />
          <Route exact path="/sign_in" component={SignIn} />
          <Route exact path="/delta/designer/:client_id/:designer_id" component={Designer} />
          <Route exact path="/delta/designer/:client_id" component={Designer} />
          <Route exact path="/moodboard/slides/:slide_list" component={SlideShow} />
          <Route exact path="/moodboard/curtains/:slide_list" component={SlideShowCurtain} />
          <Route exact path="/forgot-password" component={PasswordForgot} />
          <Route exact path="/forgot-password-verification" component={PasswordForgotVerification} />
          <Route exact path="/forgot-password-confirmation" component={PasswordForgotConfirmation} />
          <Route exact path="/approveForm" component={ApproveForm} />
          <Route exact path="/not-found" component={NotFound} />
          <PrivateRoute path="/" component={Dashboard} token={token} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}


/**
 * A helper private route component
 * check if the autentication granted, then only allow to route to the component
 * refactor to another file if required at other palces
 */
const PrivateRoute = ({ component: Component, token, ...rest }) => (
  <Route {...rest} render={props => (
    authenticated(token) ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/sign_in',
        state: { from: props.location }
      }} />
    )
  )} />
)


/**
 * Authenticated checks if an access token is available in the state
 */
const authenticated = (token) => {
  return !!token;
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(App);


// export default App;
