import axios from '../base';

export function createPLI(accessToken, PLIData) {
  return axios.request({
    url: '/api/v1/production_line_items',
    method: 'POST',
    headers: accessToken,
    data: PLIData
  })
};

export function getAllPLI(accessToken,data = null) {
  return axios.request({
    url: '/api/v1/production_line_items',
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function deletePLItem(accessToken, PLIId) {
  return axios.request({
    url: `/api/v1/production_line_items/${PLIId}`,
    method: 'DELETE',
    headers: accessToken
  })
};

export function changePLIStatus(accessToken, PLIId, data) {
  return axios.request({
    url: `/api/v1/production_line_items/change_pli_status/${PLIId}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function completeAllPLI(accessToken, data) {
  return axios.request({
    url: '/api/v1/production_line_items/compelete_all_plis',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function updateSpecification(accessToken, PLIId, data) {
  return axios.request({
    url: `/api/v1/production_line_items/update_specification/${PLIId}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function getClientListPPM(accessToken,data) {
  return axios.request({
    url: `/api/v1/delta_data/get_client_details`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function getBoqList(accessToken,data) {
  return axios.request({
    url: `/api/v1/delta_data/get_boq_list`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function getBoqLineItemList(accessToken,data) {
  return axios.request({
    url: `/api/v1/delta_data/get_boq_items_list`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};

export function getSliList(accessToken,data) {
  return axios.request({
    url: `/api/v1/delta_data/get_sli_list`,
    method: 'GET',
    headers: accessToken,
    params: data
  })
};