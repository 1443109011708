
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import * as AuthActions from '../../ducks/auth';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { loadAdmin, switchToAdmin } from '../../utils/storage';
// import MoodBoard from './MoodBoard';
import ParentBoard from './ParentBoard';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
});

class DesignerDashboard extends Component {
  state = {
    open: false,
    navId: 1,
    isAdmin: loadAdmin(),
  };

  componentDidMount() {
    if (this.props.auth.current_user) {
      const role_id = this.props.auth.current_user.office_role_id;
      if (role_id !== 9) {
        this.props.verifyUserAndLogin('designer@arrivae.com', 'Arrivae@3691');
      }
    } else {
      this.props.verifyUserAndLogin('designer@arrivae.com', 'Arrivae@3691');
    }
  }

  navigationChange(navId) {
    this.setState({ navId: navId });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    localStorage.clear();
    window.location.reload(true);
  }

  logoutDesigner = () => {
    localStorage.clear();
    window.location.href = "https://delta.arrivae.com";
  }

  switchBackToAdmin = () => {
    switchToAdmin();
  }

  render() {
    const { classes } = this.props;

    const role_id = (this.props.auth.current_user && this.props.auth.current_user.office_role_id) || '';
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
                Dashboard
              </Typography>
              {role_id === 9 ? <Typography color="inherit">
                {this.state.isAdmin ? <Button color="inherit" onClick={this.switchBackToAdmin} className={classes.button}>Switch back to admin</Button> :
                  <Button color="inherit" onClick={this.logout} className={classes.button}>Logout</Button>}
              </Typography> :
                <Typography color="inherit"> <Button color="inherit" onClick={this.logoutDesigner} className={classes.button}>Logout</Button></Typography>
              }
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button onClick={() => this.navigationChange(1)}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              {/* <ListItem button onClick={() => this.navigationChange(2)}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>  */}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {this.props.auth.token && this.state.navId === 1 &&
              <ParentBoard
                client_id={(this.props.match && this.props.match.params && this.props.match.params.client_id) || 'data_entry'}
                designer_id={(this.props.match && this.props.match.params && this.props.match.params.designer_id)}
              />}
            {this.props.auth.token && this.state.navId === 2 && <Typography variant="h4" gutterBottom>
              Comming Soon!!
              </Typography>}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(AuthActions, dispatch)
  }
}

export default compose(withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps))(DesignerDashboard);