import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { 
  getActivityLogs,
} from '../../api';
import Typography from '@material-ui/core/Typography';
import { 
  loadState, 
} from '../../utils/storage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class ActivityLog extends Component {
  state = {
    token: loadState(),
    ActivityLog: null,
    page: 0,
    rowsPerPage: 100,
    filter: {
      date: new Date(moment().format()),
      start_date: '',
      end_date: ''

    },
    routesAction: {

    }
  }

  // componentDidMount(){
  //   getApiRoutes(this.state.token)
  //   .then(res => {
  //     if (res.status) {
  //       // this.setState({
  //       //   routesAction: 
  //       // })
  //     }
  //   })
  //   .catch(e => console.log(e))
  // }

  handleDateChange = (date) => {
    let filter = {...this.state.filter}
    filter.date = date
    this.setState({ filter });
    getActivityLogs(this.state.token, {
      date: moment(date).format()
    })
    .then(response => {
      if(response.status){
        this.setState({ ActivityLog: response.data})
      }
    })
    .catch(error => console.log(error))
    
  }
 

  render() {
    
    const { ActivityLog } = this.state;
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          Activity Log
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker 
              label="Select Date"
              value={this.state.filter.date}
              onChange={this.handleDateChange}
              margin="normal"
            />
        </MuiPickersUtilsProvider>
        <div className="table-container">
          { ActivityLog !== null &&   <Paper className="user-list-root">
          <Table className="user-list-table">
              <TableHead>
                <TableRow>
                  <TableCell numeric style={{textAlign: 'left'}}>Action</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Controller</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>Logs</TableCell>
                  <TableCell numeric style={{textAlign: 'left'}}>User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ActivityLog.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(n => {
                  return (
                    <TableRow key={n.id}>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.action}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{n.controller}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{JSON.stringify(n.log)}</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>{JSON.stringify(n.user)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={5}
                  count={ActivityLog.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationWrapper}
                />
              </TableRow>
            </TableFooter>
            </Table>
          </Paper>
          }
        </div>
      </div>
    );
  }
}

export default ActivityLog;