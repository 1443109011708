import axios from '../base';

export function getUsersListByRole(accessToken, roleId) {
  return axios.request({
    url: `/api/v1/office_users/users_list_by_role/${roleId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function changeProjectEngineerType(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_users/change_project_engineer_type`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};
