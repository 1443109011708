
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux'; 
import CssBaseline from '@material-ui/core/CssBaseline';
import { Collapse, Tooltip, Avatar } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { loadAdmin, switchToAdmin } from '../../utils/storage';
import NotFound from '../../components/NotFound';
import Profile from '../Shared/Profile';  
import {
  Switch,
  Route,
} from 'react-router-dom';
import DelayProjectDashboard from './DelayProjects/DelayProjectDashboard';
import SiteMeaseurement from './DelayProjects/SiteMeasurement';
import Drawing from './DelayProjects/Drawing';
import DesignQc from './DelayProjects/DesignQc';
import CustomerWalkthrough from './DelayProjects/CustomerWalkthrough';
import SiteValidation from './DelayProjects/SiteValidation';
import FortyPercentPaid from './DelayProjects/FortyPercentPaid';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import {
  ArtTrack as DrawingIcon,
  Policy as DesignQcIcon,
  DirectionsWalk as CustomerWalkthroughIcon,
  AssignmentTurnedIn as SiteValidationIcon,
  Payment as PaymentIcon,
  StarBorder, Star,
  AddAlert
} from '@material-ui/icons'
import { getNocNotifcation } from '../../api'

const drawerWidth = 300;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRight: {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
    marginLeft: '6%'
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
});

class NocLeadDashboard extends React.Component {
  state = {
    open: false,
    isAdmin: loadAdmin(),
    openDelayProjects: false,
    openOfficeNocNotification: false,
    NocNotifications: []
  };

  componentDidMount() {
    getNocNotifcation(this.props.auth.token)
    .then(res => {
      this.setState({ NocNotifications: res.data })
    })
    .catch(error => console.log(error))
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };


  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }


  logout = () => {
    localStorage.clear();
    document.location.reload(true);
  }

  switchBackToAdmin = () => {
    switchToAdmin();
  }

  navigationChange(navId) {
    this.setState({navId: navId});
  }

  render() {
    const { classes } = this.props;
    const { openDelayProjects, openOfficeNocNotification, NocNotifications } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift, openOfficeNocNotification && classes.appBarShiftRight)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
                
              </Typography>
              
              <IconButton
                color="inherit"
                aria-label="Open drawer 2"
                onClick={() => this.setState({ openOfficeNocNotification: !openOfficeNocNotification })}
                >
                <Star />

              </IconButton>
              <IconButton
                color="inherit"
                aria-label="Open drawer 2"
                onClick={() => this.setState({ openOfficeNocNotification: !openOfficeNocNotification })}
                style={{ marginRight: '15px' }}>
                <AddAlert />
                
              </IconButton>
              <Typography color="inherit">
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    { this.props.auth.current_user.name || "User" }
                  </Typography> <br />
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    NOC Lead
                  </Typography>
                </React.Fragment>
              </Typography>
              <Typography color="inherit">
                {this.state.isAdmin? <Button color="inherit" onClick={this.switchBackToAdmin} className={classes.button}>Switch back to admin</Button>:
                  <Button color="inherit" onClick={this.logout} className={classes.button}>
                    <Tooltip title="Logout">
                      <PowerIcon style={{ color: 'white', '&:hover': { background: 'black' } }} />
                    </Tooltip>
                  </Button>}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <Tooltip title="10-40% Projects">
                <ListItem >
                    <ListItemIcon button selected={this.activeRoute('delay-projects')} button onClick={() => this.props.history.push('/noc_lead/delay-projects')}>
                      <DashboardIcon style={{ color: this.activeRoute('delay-projects') ? '#007bff': '#868e95', cursor: 'pointer'}}/>
                    </ListItemIcon>
                    <ListItemText style={{ cursor: 'pointer' }} primary="10-40% Projects" selected={this.activeRoute('delay-projects')} button onClick={() => this.props.history.push('/noc_lead/delay-projects')}/>
                  {
                    openDelayProjects ?
                    <ExpandLess button onClick={() => this.setState({ openDelayProjects: !openDelayProjects })} /> :
                    <ExpandMore button onClick={() => this.setState({ openDelayProjects: !openDelayProjects })} />
                  }
                </ListItem> 
              </Tooltip>
                <Collapse in={openDelayProjects} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Tooltip title="Site Measurement">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/site-measurement')} onClick={() => this.props.history.push('/noc_lead/delay-projects/site-measurement')}>
                        <ListItemIcon>
                          <ControlCameraIcon style={{ color: this.activeRoute('delay-projects/site-measurement') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="Site Measurement" />
                      </ListItem>
                      </Tooltip>
                  </List>
                  <List component="div" disablePadding>
                    <Tooltip title="Drawing">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/drawing')} onClick={() => this.props.history.push('/noc_lead/delay-projects/drawing')}>
                        <ListItemIcon>
                          <DrawingIcon style={{ color: this.activeRoute('delay-projects/drawing') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="Drawing" />
                      </ListItem>
                      </Tooltip>
                  </List>
                  <List component="div" disablePadding>
                    <Tooltip title="Design QC">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/design-qc')} onClick={() => this.props.history.push('/noc_lead/delay-projects/design-qc')}>
                        <ListItemIcon>
                          <DesignQcIcon style={{ color: this.activeRoute('delay-projects/design-qc') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="Design QC" />
                      </ListItem>
                      </Tooltip>
                  </List>
                  <List component="div" disablePadding>
                    <Tooltip title="Customer Walkthrough">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/customer-walkthrough')} onClick={() => this.props.history.push('/noc_lead/delay-projects/customer-walkthrough')}>
                        <ListItemIcon>
                          <CustomerWalkthroughIcon style={{ color: this.activeRoute('delay-projects/customer-walkthrough') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="Customer Walkthrough" />
                      </ListItem>
                      </Tooltip>
                  </List>
                  <List component="div" disablePadding>
                    <Tooltip title="Site Validation">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/site-validation')} onClick={() => this.props.history.push('/noc_lead/delay-projects/site-validation')}>
                        <ListItemIcon>
                          <SiteValidationIcon style={{ color: this.activeRoute('delay-projects/site-validation') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="Site Validation" />
                      </ListItem>
                      </Tooltip>
                  </List>
                  <List component="div" disablePadding>
                    <Tooltip title="40% Paid">
                      <ListItem button className={classes.nested} selected={this.activeRoute('delay-projects/40-percent-paid')} onClick={() => this.props.history.push('/noc_lead/delay-projects/40-percent-paid')}>
                        <ListItemIcon>
                          <PaymentIcon style={{ color: this.activeRoute('delay-projects/40-percent-paid') ? '#007bff': '#868e95'}} />
                        </ListItemIcon>
                        <ListItemText primary="40% Paid" />
                      </ListItem>
                    </Tooltip>
                  </List>
                </Collapse>
              
            </List>
            <List style={{ position: 'absolute', bottom: '0' }}>
            <Divider/>
              <ListItem selected={this.activeRoute('profile')} button onClick={() => this.props.history.push('/noc_lead/profile')}>
                <Tooltip title="Profile">
                  <ListItemIcon>
                    {this.props.auth.current_user.name ? <Avatar className={classes.purpleAvatar}>{this.props.auth.current_user.name[0].toUpperCase()}</Avatar> : <PersonIcon style={{ color: this.activeRoute('profile')? '#007bff': '#868e95'}}/>}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={ this.props.auth.current_user.name || "User Profile" }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ display: 'inline' }}
                        color="textPrimary"
                      >
                        NOC Lead
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Drawer>
          
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Route
              path={"/noc_lead"}
              render={({ match: { url } }) => {
                return (
                  <Switch>
                    <Route path={url + '/profile'} component={Profile} />
                    <Route path={url + '/delay-projects/site-measurement'} component={SiteMeaseurement} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects/drawing'} component={Drawing} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects/design-qc'} component={DesignQc} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects/customer-walkthrough'} component={CustomerWalkthrough} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects/site-validation'} component={SiteValidation} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects/40-percent-paid'} component={FortyPercentPaid} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route path={url + '/delay-projects'} component={DelayProjectDashboard} NocNotifications={NocNotifications && NocNotifications.map(i =>i.id)} />
                    <Route component={NotFound} />
                  </Switch>
                )}
              }
            />
          </main>
          
        </div>
        <Drawer
          className={openOfficeNocNotification && classes.drawer}
          variant="persistent"
          anchor="right"
          open={openOfficeNocNotification}
          classes={{
            paper: classNames(openOfficeNocNotification && classes.drawerPaper),
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => this.setState({ openOfficeNocNotification: !openOfficeNocNotification })}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          {
            NocNotifications && NocNotifications.length !== 0 &&
            NocNotifications.map(notification => {

            })
          }
        </Drawer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(NocLeadDashboard);