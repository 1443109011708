import React, { Component } from "react";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ReactDropzone from "react-dropzone";
import moment from "moment";
import {
  createTask,
  getTaskList,
  getOfficeSpaceList,
  getProjectSiteSupervisorList,
  updateTask,
  deleteTask,
  getTaskUpdatesList,
  taskBulkUpload,
  serviceTaskReport,
  shareWithCustomer,
  serviceTaskReportFilter,
  getExportAllTasksBulkUploadReport,
  shareEmailWithAny,
} from '../../../api';
import { AppBar, Tabs, Tab, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import SubtaskListServices from './SubtaskListServices';
import SubtaskListInstallation from './SubtaskListInstallation';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import DialogContentText from '@material-ui/core/DialogContentText';
import FilterListIcon from '@material-ui/icons/FilterList';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const statusList = [
  { id: 0, name: "Not Initiated" },
  { id: 1, name: "Initiated" },
  { id: 2, name: "Completed" },
];
const schedule_type = [
  { id: 0, value: "plan_start_date_time", name: "Planned Start Date" },
  { id: 0, value: "plan_end_date_time", name: "Planned End Date" },
  { id: 0, value: "start_date_time", name: "Actual Start Date" },
  { id: 0, value: "end_date_time", name: "Actual End Date" },
];

const styles = (theme) => ({
  button: {
    margin: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogSubtaskTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class TaskList extends Component {
  state = {
    value: 0,
    TaskList: [],
    selectedTask: "",
    dependentOnOption: [],
    VendorOption: this.props.VendorOption,
    SiteSupervisorOption: [],
    openDialog: false,
    TaskUpdates: [],
    openDialogFile: false,
    newFile: {},
    openServicesSubtaskDialog: false,
    openInstallationSubtaskDialog: false,
    taskId: null,
    taskName: null,
    taskDisId: null,
    lineItem: null,
    spaceId: null,
    anchorEl: null,
    shareEmail: false,
    SpaceList: [],
    SiteSupervisorList: [],
    vendorData: this.props.vendorData,
    dependOnList: [],
    searchText: "",
    spaceFilterId: [],
    spaceFilterArray: [],
    superVFilterId: [],
    superVFilterArray: [],
    vendorFilterId: [],
    vendorFilterArray: [],
    dependFilterId: [],
    dependFilterArray: [],
    statusFilterId: [],
    statusFilterArray: [],
    scheduleFilterName: "",
    scheduleFilterArray: [],
    fromDate: "",
    toDate: "",
    lineItemSearch: "",
    shareToEmails: [],
    openDialogShareToEmail: false,
    isMailSentLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ VendorOption: nextProps.VendorOption });
  }

  toggleServicesSubtaskDialog = (id, name, disId, spaceId) => {
    this.setState({
      openServicesSubtaskDialog: !this.state.openServicesSubtaskDialog,
    });
    this.setState({
      taskId: id,
      taskName: name,
      taskDisId: disId,
      spaceId: spaceId,
    });
  };

  toggleInstallationSubtaskDialog = (id, name, disId, lineItem, spaceId) => {
    this.setState({
      openInstallationSubtaskDialog: !this.state.openInstallationSubtaskDialog,
    });
    this.setState({
      taskId: id,
      taskName: name,
      taskDisId: disId,
      lineItem: lineItem,
      spaceId: spaceId,
    });
  };

  handleFiles = (file) => {
    this.setState({
      newFile: file[0],
    });
  };

  onSubmitTaskUpload = (e) => {
    const fd = new FormData();
    fd.append("project_id", this.props.project.id);
    fd.append("file", this.state.newFile);
    fd.append("parent_or_child", this.state.fileTypeTasks ? "parent" : "child");
    taskBulkUpload(this.props.auth.token, fd)
      .then((response) => {
        if (response.status) {
          this.toggleDialogFile();
          this.getData();
          Swal.fire("Success", response.data.message, "success");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getData();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickEmail = () => {
    this.setState({ shareEmail: true });
    this.setState({ anchorEl: null });
  };

  handleShareToEmail = () => {
    this.setState({isMailSentLoading: true});
    let emails = this.state.shareToEmails;

    shareEmailWithAny(this.props.auth.token, this.props.project.id, emails)
      .then((res) => {
        this.setState({shareToEmails: [], isMailSentLoading: false, openDialogShareToEmail: false});
        Swal.fire("Success", `${res?.data?.message}`, "success");
      })
      .catch((error) => {
        this.setState({isMailSentLoading: false});
        Swal.fire("Error", `${error?.data?.message || "Something went wrong! Please try again."}`);
      });
  }

  handleClickShareToEmail = () => {
    this.setState({ shareToEmail: true });
  }

  toggleDialogShareFile = () => {
    this.setState({ openDialogShareToEmail: !this.state.openDialogShareToEmail })
  }

  toggleDialogFile = () => {
    this.setState({ openDialogFile: !this.state.openDialogFile, newFile: {} });
  };
  getData = async () => {
    if (this.props.project) {
      try {
        const SpaceListRes = await getOfficeSpaceList(this.props.auth.token);
        const TaskListRes = await getTaskList(this.props.auth.token, {
          project_id: this.props.project.id,
        });
        const SiteSupervisorListRes = await getProjectSiteSupervisorList(
          this.props.auth.token,
          this.props.project.id
        );

        let SpaceOptions = {};
        SpaceListRes.data.map((i) => (SpaceOptions[i.id] = i.name));
        let dependentOnOption = {};
        TaskListRes.data.map((i) => {
          if (i.office_task_type === "services") {
            return (dependentOnOption[i.id] = i.name);
          }
        });
        let SiteSupervisorOption = {};
        SiteSupervisorListRes.data.map(
          (i) => (SiteSupervisorOption[i.id] = i.name)
        );
        console.log("tasklistFirstResp", TaskListRes);

        this.setState({
          TaskList: TaskListRes.data,
          SpaceList: SpaceListRes.data,
          SiteSupervisorList: SiteSupervisorListRes.data,
          dependOnList: TaskListRes.data.filter(
            (v) => v.office_task_type == "services"
          ),
          SpaceOptions,
          dependentOnOption,
          SiteSupervisorOption,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  status = (id) => {
    switch (id) {
      case 0:
        return "Not Initiated";
      case 1:
        return "Initiated";
      case 2:
        return "Completed";
      default:
        break;
    }
  };

  materialDeliveryStatus = (id) => {
    switch (id) {
      case 0:
        return "Not Delivered";
      case 1:
        return "Partial Delivery";
      case 2:
        return "Delivered";
      default:
        break;
    }
  };

  servicesValidation = (data) => {
    if (
      data.name !== "" &&
      data.name !== null &&
      data.name !== undefined &&
      data.office_space_id !== "" &&
      data.office_space_id !== null &&
      data.office_space_id !== undefined &&
      data.office_user_id !== "" &&
      data.office_user_id !== null &&
      data.office_user_id !== undefined &&
      data.office_vendor_id !== "" &&
      data.office_vendor_id !== null &&
      data.office_vendor_id !== undefined &&
      data.plan_start_date_time !== "" &&
      data.plan_start_date_time !== null &&
      data.plan_start_date_time !== undefined &&
      data.plan_end_date_time !== "" &&
      data.plan_end_date_time !== null &&
      data.plan_end_date_time !== undefined &&
      data.quantity !== "" &&
      data.quantity !== null &&
      data.quantity !== undefined &&
      data.unit_of_measurement !== "" &&
      data.unit_of_measurement !== null &&
      data.unit_of_measurement !== undefined &&
      data.value !== "" &&
      data.value !== null &&
      data.value !== undefined &&
      data.value > 0 &&
      data.status !== "" &&
      data.status !== null &&
      data.status !== undefined
    ) {
      let plan_start_date = moment(data.plan_start_date_time);
      let plan_end_date = moment(data.plan_end_date_time);
      if (plan_end_date.diff(plan_start_date, "days") >= 0) {
        if (
          data.start_date_time !== "" &&
          data.start_date_time !== null &&
          data.start_date_time !== undefined &&
          data.end_date_time !== "" &&
          data.end_date_time !== null &&
          data.end_date_time !== undefined
        ) {
          let start_date = moment(data.start_date_time);
          let end_date = moment(data.end_date_time);
          if (
            data.status == "0" ||
            data.status == "1" ||
            end_date.diff(start_date, "days") >= 0
          ) {
            return true;
          } else {
            Swal.fire(
              "Error",
              "End date cannot be prior to start date.",
              "info"
            );
            return false;
          }
        } else {
          if (data.status == "0") {
            return true;
          } else {
            if (!data.start_date_time) {
              Swal.fire("Error", "Actual start date is not present", "info");
              return false;
            } else {
              if (data.status == "1") {
                return true;
              } else {
                if (!data.end_date_time) {
                  Swal.fire("Error", "Actual end date is not present", "info");
                  return false;
                } else {
                  return true;
                }
              }
            }
          }
        }
      } else {
        Swal.fire("Error", "End date cannot be prior to start date.", "info");
        return false;
      }
    } else {
      if (data.value <= 0) {
        Swal.fire("Error", "Value must be greater than 0", "info");
        return false;
      } else {
        if (!data.plan_start_date_time) {
          Swal.fire("Error", "Plan start date is not present.", "info");
          return false;
        } else if (!data.plan_end_date_time) {
          Swal.fire("Error", "Plan end date is not present.", "info");
          return false;
        } else {
          Swal.fire("Error", "Fill all the fields", "info");
          return false;
        }
      }
    }
  };
  installationValidation = (data) => {
    if (
      data.line_item !== "" &&
      data.line_item !== null &&
      data.line_item !== undefined &&
      data.office_space_id !== "" &&
      data.office_space_id !== null &&
      data.office_space_id !== undefined &&
      data.office_user_id !== "" &&
      data.office_user_id !== null &&
      data.office_user_id !== undefined &&
      data.office_vendor_id !== "" &&
      data.office_vendor_id !== null &&
      data.office_vendor_id !== undefined &&
      data.quantity !== "" &&
      data.quantity !== null &&
      data.quantity !== undefined &&
      data.unit_of_measurement !== "" &&
      data.unit_of_measurement !== null &&
      data.unit_of_measurement !== undefined &&
      data.value !== "" &&
      data.value !== null &&
      data.value !== undefined &&
      data.value > 0 &&
      data.status !== "" &&
      data.status !== null &&
      data.status !== undefined
    ) {
      let plan_start_date = moment(data.plan_start_date_time);
      let plan_end_date = moment(data.plan_end_date_time);

      if (plan_end_date.diff(plan_start_date, "days") >= 0) {
        if (
          data.start_date_time !== "" &&
          data.start_date_time !== null &&
          data.start_date_time !== undefined &&
          data.end_date_time !== "" &&
          data.end_date_time !== null &&
          data.end_date_time !== undefined
        ) {
          let start_date = moment(data.start_date_time);
          let end_date = moment(data.end_date_time);
          if (
            data.status == "0" ||
            data.status == "1" ||
            end_date.diff(start_date, "days") >= 0
          ) {
            return true;
          } else {
            Swal.fire(
              "Error",
              "End date cannot be prior to start date.",
              "info"
            );
            return false;
          }
        } else {
          if (data.status == "0") {
            return true;
          } else {
            if (!data.start_date_time) {
              Swal.fire("Error", "Actual start date is not present", "info");
              return false;
            } else {
              if (data.status == "1") {
                return true;
              } else {
                if (!data.end_date_time) {
                  Swal.fire("Error", "Actual end date is not present", "info");
                  return false;
                } else {
                  return true;
                }
              }
            }
          }
        }
      } else {
        Swal.fire("Error", "End date cannot be prior to start date.", "info");
        return false;
      }
    } else {
      if (data.value <= 0) {
        Swal.fire("Error", "Value must be greater than 0", "info");
        return false;
      } else {
        if (!data.plan_start_date_time) {
          Swal.fire("Error", "Plan start date is not present.", "info");
          return false;
        } else if (!data.plan_end_date_time) {
          Swal.fire("Error", "Plan end date is not present.", "info");
          return false;
        } else {
          Swal.fire("Error", "Fill all the fields", "info");
          return false;
        }
      }
    }
  };

  serviceTaskReportFile = () => {
    serviceTaskReport(this.props.auth.token, this.props.project.id)
      .then((res) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.file}`;
        console.log("linksource******", linkSource);
        const downloadLink = document.createElement("a");
        const fileName = res.data.file_name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        Swal.fire("Success", "Downloaded successfully.", "success");
        this.setState({
          anchorEl: null,
        });
        //   this.setState({
        //   openDialog: true,
        //   TaskUpdates: res.data
        // })
      })
      .catch((error) => console.log(error));
  };

  handleExport = () => {
    getExportAllTasksBulkUploadReport(this.props.auth.token, this.props.project.id)
      .then(res => {
        if (res.data.status == 200) {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.file}`;
          const downloadLink = document.createElement("a");
          const fileName = res.data.file_name;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          Swal.fire('Success', 'Downloaded successfully.', 'success');
        } else {
          Swal.fire('Error', res?.data?.message, 'success');
        }
      })
      .catch(err => {
        Swal.fire('Error', 'Something went wrong!', 'error');
      })
  }

  // servicesValidation = (data) => {
  //   if (
  //     (data.name !== "" && data.name !== null && data.name !== undefined) &&
  //     (data.description !== "" && data.description !== null && data.description !== undefined) &&
  //     (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
  //     (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
  //     (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined) &&
  //     (data.plan_start_date_time !== "" && data.plan_start_date_time !== null && data.plan_start_date_time !== undefined) &&
  //     (data.plan_end_date_time !== "" && data.plan_end_date_time !== null && data.plan_end_date_time !== undefined) &&
  //     (data.start_date_time !== "" && data.start_date_time !== null && data.start_date_time !== undefined) &&
  //     (data.end_date_time !== "" && data.end_date_time !== null && data.end_date_time !== undefined) &&
  //     (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
  //     (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) &&
  //     (data.status !== "" && data.status !== null && data.status !== undefined)
  //   ) {
  //     let plan_start_date = moment(data.plan_start_date_time)
  //     let plan_end_date = moment(data.plan_end_date_time)
  //     let start_date = moment(data.start_date_time)
  //     let end_date = moment(data.end_date_time)
  //     if (
  //       (plan_end_date.diff(plan_start_date, 'days') >= 0) &&
  //       (end_date.diff(start_date, 'days') >= 0)
  //     ) {
  //       if (!isNaN(data.quantity) && data.quantity > 0) {
  //         return true
  //       } else {
  //         alert('Invalid Quantity')
  //         return false
  //       }
  //     } else {
  //       alert('End date cannot be greater than Start Date')
  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  // installationValidation = (data) => {
  //   if (
  //     (data.line_item !== "" && data.line_item !== null && data.line_item !== undefined) &&
  //     (data.office_space_id !== "" && data.office_space_id !== null && data.office_space_id !== undefined) &&
  //     (data.office_user_id !== "" && data.office_user_id !== null && data.office_user_id !== undefined) &&
  //     (data.office_vendor_id !== "" && data.office_vendor_id !== null && data.office_vendor_id !== undefined) &&
  //     (data.installation_date !== "" && data.installation_date !== null && data.installation_date !== undefined) &&
  //     (data.material_delivery_status !== "" && data.material_delivery_status !== null && data.material_delivery_status !== undefined) &&
  //     (data.quantity !== "" && data.quantity !== null && data.quantity !== undefined) &&
  //     (data.unit_of_measurement !== "" && data.unit_of_measurement !== null && data.unit_of_measurement !== undefined) &&
  //     (data.status !== "" && data.status !== null && data.status !== undefined)
  //   ) {
  //     if (!isNaN(data.quantity) && data.quantity > 0) {
  //       if (parseInt(data.material_delivery_status) === 2) {
  //         if (data.material_delivery_date !== "" && data.material_delivery_date !== null && data.material_delivery_date !== undefined) {
  //           return true
  //         } else {
  //           alert("Need Material Delivery Date")
  //           return false
  //         }
  //       } else {
  //         data.material_delivery_date = null
  //         return true
  //       }
  //     } else {
  //       alert('Invalid Quantity')
  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  openDialog = (task_id) => {
    getTaskUpdatesList(this.props.auth.token, task_id)
      .then((res) => {
        this.setState({
          openDialog: true,
          TaskUpdates: res.data,
        });
      })
      .catch((error) => console.log(error));
  };

  handleCloseEmail = () => {
    this.setState({
      shareEmail: false,
    });
  };

  handleSendEmail = () => {
    this.setState({
      anchorEl: null,
      shareEmail: false,
    });
    shareWithCustomer(this.props.auth.token, this.props.project.id)
      .then((res) => {
        console.log("share email value", res);
        Swal.fire("Success", `${res?.data?.message}`, "success");
      })
      .catch((error) => console.log(error));
  };

  handleNameSearch = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleLineItemSearch = (e) => {
    this.setState({
      lineItemSearch: e.target.value,
    });
  };

  handleSpaceAutoComplete = (event, newValue) => {
    console.log("newValue", newValue)
    console.log("event target", event)
    this.setState({
      spaceFilterId: newValue.map((v) => v.id) || [],
      spaceFilterArray: newValue || [],
    });
  };

  handleSuperVisorAutoComplete = (event, newValue) => {
    console.log("newValue", newValue);
    console.log("event target", event);
    this.setState({
      superVFilterId: newValue.map((v) => v.id) || [],
      superVFilterArray: newValue || [],
    });
  };

  handleVendorAutoComplete = (event, newValue) => {
    console.log("newValue", newValue);
    console.log("event target", event);
    this.setState({
      vendorFilterId: newValue.map((v) => v.id) || [],
      vendorFilterArray: newValue || [],
    });
  };

  handleDependAutoComplete = (event, newValue) => {
    console.log("newValue", newValue);
    console.log("event target", event);
    this.setState({
      dependFilterId: newValue.map((v) => v.id) || [],
      dependFilterArray: newValue || [],
    });
  };

  handleStatusAutoComplete = (event, newValue) => {
    console.log("newValue", newValue);
    console.log("event target", event);
    this.setState({
      statusFilterId: newValue.map((v) => v.id) || [],
      statusFilterArray: newValue || [],
    });
  };

  // handleScheduleAutoComplete = (event, newValue) => {
  //   console.log("newValue", newValue)
  //   console.log("event target", event)
  //   this.setState({
  //     scheduleFilterName:newValue.map(v => v.id) || [],
  //     scheduleFilterArray:newValue || []
  //   })
  // }

  handleScheduleSelect = (event) => {
    this.setState({
      scheduleFilterName: event.target.value || "",
      // scheduleFilterArray:newValue || []
    });
  };

  onDateToChange = (e) => {
    this.setState({
      toDate: e.target.value,
    });
  };

  onDateFromChange = (e) => {
    this.setState({
      fromDate: e.target.value,
    });
  };

  onTabChange = (e, i) => {
    this.setState({
      value: i,
      searchText: "",
      spaceFilterId: [],
      spaceFilterArray: [],
      superVFilterId: [],
      superVFilterArray: [],
      vendorFilterId: [],
      vendorFilterArray: [],
      dependFilterId: [],
      dependFilterArray: [],
      statusFilterId: [],
      statusFilterArray: [],
      scheduleFilterName: "",
      scheduleFilterArray: [],
      fromDate: "",
      toDate: "",
      lineItemSearch: "",
    });
    getTaskList(this.props.auth.token, {
      project_id: this.props.project.id,
    }).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        let dependentOnOption = {};
        res.data.map((i) => {
          if (i.office_task_type === "services") {
            return (dependentOnOption[i.id] = i.name);
          }
        });
        this.setState({ TaskList: res.data, dependentOnOption });
      }
    });
  };

  resetAllFilter = () => {
    this.setState({
      searchText: "",
      spaceFilterId: [],
      spaceFilterArray: [],
      superVFilterId: [],
      superVFilterArray: [],
      vendorFilterId: [],
      vendorFilterArray: [],
      dependFilterId: [],
      dependFilterArray: [],
      statusFilterId: [],
      statusFilterArray: [],
      scheduleFilterName: "",
      scheduleFilterArray: [],
      fromDate: "",
      toDate: "",
      lineItemSearch: "",
    });
    getTaskList(this.props.auth.token, {
      project_id: this.props.project.id,
    }).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        let dependentOnOption = {};
        res.data.map((i) => {
          if (i.office_task_type === "services") {
            return (dependentOnOption[i.id] = i.name);
          }
        });
        this.setState({ TaskList: res.data, dependentOnOption });
      }
    });
  };

  handleFilter = () => {
    const service_type = this.state.value == 0 ? "services" : "installation"
    const search = this.state.searchText || ""
    // const spaceId = this.state.spaceFilterId || ""
    // const superVisorId = this.state.superVFilterId || ""
    // const vendorFiltId = this.state.vendorFilterId || ""
    // const dependFiltId = this.state.dependFilterId || ""
    // const statusFiltId = this.state.statusFilterId || ""
    const spaceId =
      this?.state?.spaceFilterId?.length > 0 ? this?.state?.spaceFilterId : "";
    const superVisorId =
      this?.state?.superVFilterId?.length > 0
        ? this?.state?.superVFilterId
        : "";
    const vendorFiltId =
      this?.state?.vendorFilterId?.length > 0
        ? this?.state?.vendorFilterId
        : "";
    const dependFiltId =
      this?.state?.dependFilterId?.length > 0
        ? this?.state?.dependFilterId
        : "";
    const statusFiltId =
      this?.state?.statusFilterId?.length > 0
        ? this?.state?.statusFilterId
        : "";
    const scheduleFiltName = this.state.scheduleFilterName || "";
    const fromFiltDate = this.state.fromDate || "";
    const toFiltDate = this.state.toDate || "";
    const lineFiltitem = this.state.lineItemSearch || "";
    serviceTaskReportFilter(
      this.props.auth.token,
      search,
      this.props.project.id,
      spaceId,
      superVisorId,
      vendorFiltId,
      dependFiltId,
      statusFiltId,
      scheduleFiltName,
      fromFiltDate,
      toFiltDate,
      service_type,
      lineFiltitem
    ).then((res) => {
      if (res.status == 200) {
        console.log("res*****", res);
        console.log("tasklist2ndResp", res.data);
        this.setState({
          TaskList: res.data.tasks,
          dependOnList: res.data.tasks.filter(
            (v) => v.office_task_type == "services"
          ),
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      TaskList,
      SpaceOptions,
      dependentOnOption,
      SiteSupervisorOption,
      VendorOption,
      value,
      openDialog,
      TaskUpdates,
      taskId,
      taskName,
      taskDisId,
      lineItem,
      spaceId,
      SpaceList,
      SiteSupervisorList,
      vendorData,
      dependOnList,
      isMailSentLoading,
    } = this.state;
    const completed_services = TaskList.filter(
      (task) => task.office_task_type === "services" && task.status === 2
    )
      .map((item) => item.value)
      .reduce((prev, next) => {
        return prev + next;
      }, 0);
    const total_services = TaskList.filter(
      (task) => task.office_task_type === "services"
    )
      .map((item) => item.value)
      .reduce((prev, next) => {
        return prev + next;
      }, 0);
    const completed_installations = TaskList.filter(
      (task) => task.office_task_type === "installation" && task.status === 2
    )
      .map((item) => item.value)
      .reduce((prev, next) => {
        return prev + next;
      }, 0);
    const total_installations = TaskList.filter(
      (task) => task.office_task_type === "installation"
    )
      .map((item) => item.value)
      .reduce((prev, next) => {
        return prev + next;
      }, 0);
    let services_status =
      total_services == 0
        ? 0
        : ((completed_services / total_services) * 100).toFixed(2);
    let installations_status =
      total_installations == 0
        ? 0
        : ((completed_installations / total_installations) * 100).toFixed(2);

    return (
      <React.Fragment>
        {/* <Grid
          item
          xs={12}
          style={{ textAlign: "center", marginBottom: "15px" }}
        > */}
        <Button
          className="mb-3"
          variant="contained"
          color="primary"
          onClick={() => {
            {
              this.toggleDialogFile();
              this.setState({ fileTypeTasks: true });
            }
          }}
        >
          Parent Task Bulk Upload/Update
        </Button>
        <Button
          className="mb-3 ml-3"
          variant="contained"
          color="primary"
          onClick={() => {
            {
              this.toggleDialogFile();
              this.setState({ fileTypeTasks: false });
            }
          }}
        >
          Sub Task Bulk Upload/Update
        </Button>
        <Button
          className="mb-3 ml-3"
          variant="contained"
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Customer Report
        </Button>
        <Button className="mb-3 ml-3" variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleExport}>
          Schedule TaskList Export
        </Button>
        {/* </Grid> */}
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={() => this.serviceTaskReportFile()}>
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Download Report</Typography>
          </MenuItem>
          <MenuItem onClick={this.handleClickEmail}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Share with Customer</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            this.toggleDialogShareFile();
            this.handleClickShareToEmail();
            }}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Share with Any</Typography>
          </MenuItem>
        </Menu>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={(e, i) => this.onTabChange(e, i)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Services" />
            <Tab label="Installation" />
          </Tabs>
        </AppBar>
        <TabPanel className="position-relative" value={value} index={0}>
          <Grid container>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <FilterListIcon />
                </Grid>
                <Grid item style={{ width: "80%" }}>
                  <TextField
                    id="input-with-icon-grid"
                    label="Search by name, po, taskId"
                    onChange={(e) => this.handleNameSearch(e)}
                    // onKeyPress={(e) => this.handleKeyPress(e)}
                    style={{ width: "100%" }}
                    value={this.state.searchText || ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  value={this.state.spaceFilterArray}
                  multiple
                  // id="checkboxes-tags-demo"
                  options={SpaceList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Space"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSpaceAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.superVFilterArray}
                  // id="checkboxes-tags-demo"
                  options={SiteSupervisorList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Site Supervisor"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSuperVisorAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.vendorFilterArray}
                  // id="checkboxes-tags-demo"
                  options={vendorData}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Vendor"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleVendorAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.dependFilterArray}
                  // id="checkboxes-tags-demo"
                  options={dependOnList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Dependent on"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleDependAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.statusFilterArray}
                  // id="checkboxes-tags-demo"
                  options={statusList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Status"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleStatusAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <FormControl style={{ width: 400 }}>
                  <InputLabel id="demo-simple-select-label">
                    Schedule Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.scheduleFilterName}
                    onChange={this.handleScheduleSelect}
                  >
                    {schedule_type?.map((val) => (
                      <MenuItem value={val.value}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {this?.state?.scheduleFilterName && (
              <Grid item xs={2}>
                <Grid container alignItems="flex-end">
                  <TextField
                    id="date"
                    label="From"
                    type="date"
                    defaultValue=""
                    style={{ width: 200 }}
                    value={this.state.fromDate}
                    onChange={(e) => this.onDateFromChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {this?.state?.scheduleFilterName && (
              <Grid item xs={2}>
                <Grid container alignItems="flex-end">
                  <TextField
                    id="date"
                    label="To"
                    type="date"
                    defaultValue=""
                    style={{ width: 200 }}
                    value={this.state.toDate}
                    onChange={(e) => this.onDateToChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={2}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleFilter()}
                  >
                    Filter Task List
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.resetAllFilter()}
                  >
                    Reset All Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <a className="button-style" onClick={() => this.serviceTaskReportFile()}>Download Services Tasks List Report</a> */}
          {/* <Grid item xs={12} style={{textAlign:"center"}}>
            <Button variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
              Service Task List Report
            </Button>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={() => this.serviceTaskReportFile()}>
              <ListItemIcon>
                <GetAppIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Download Report</Typography>
            </MenuItem>
            <MenuItem onClick={this.handleClickEmail}>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Share with Customer</Typography>
            </MenuItem>
          </Menu> */}
          <MaterialTable
            title={`Services Tasks List (${services_status}% Completed)`}
            columns={[
              {
                title: "",
                render: (rowData) => (
                  <button
                    className="btn"
                    onClick={() =>
                      this.toggleServicesSubtaskDialog(
                        rowData.id,
                        rowData.name,
                        `P${rowData.project_id}T${rowData.id}`,
                        rowData.office_space_id
                      )
                    }
                  >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Subtasks {`(${rowData.sub_tasks_count})`}
                  </button>
                ),
                editable: false,
                filtering: false,
                sorting: false,
              },
              {
                title: "Task ID",
                field: "id",
                render: (rawData) =>
                  rawData !== undefined && rawData?.project_id
                    ? `P${rawData.project_id}T${rawData.id}`
                    : rawData !== undefined && rawData?.task_id
                    ? rawData?.task_id
                    : null,
                editable: false,
                filtering: false,
              },
              {
                title: "Name",
                field: "name",
                render: (rowData) => (
                  <p
                    className="cusAnchor"
                    onClick={() => this.openDialog(rowData.id)}
                  >
                    {rowData.name}
                  </p>
                ),
              },
              {
                title: "Space",
                field: "office_space_id",
                lookup: SpaceOptions,
              },
              {
                title: "Site Supervisor",
                field: "office_user_id",
                lookup: SiteSupervisorOption,
              },
              {
                title: "Vendor",
                field: "office_vendor_id",
                lookup: VendorOption,
              },
              {
                title: "Dependent on",
                field: "dependent_on",
                lookup: dependentOnOption,
              },
              {
                title: "Planned Start Date",
                field: "plan_start_date_time",
                render: (rowData) =>
                  rowData.plan_start_date_time !== null
                    ? moment(rowData.plan_start_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Planned End Date",
                field: "plan_end_date_time",
                render: (rowData) =>
                  rowData.plan_end_date_time !== null
                    ? moment(rowData.plan_end_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Actual Start Date",
                field: "start_date_time",
                render: (rowData) =>
                  rowData.start_date_time !== null
                    ? moment(rowData.start_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Actual End Date",
                field: "end_date_time",
                render: (rowData) =>
                  rowData.end_date_time !== null
                    ? moment(rowData.end_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Quantity",
                field: "quantity",
              },
              {
                title: "Unit of Measurement",
                field: "unit_of_measurement",
              },
              {
                title: "Value",
                field: "value",
                render: (rowData) => Math.round(rowData.value),
              },
              {
                title: "Status",
                field: "status",
                render: (rowData) => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                },
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
            ]}
            data={TaskList.filter(
              (task) => task.office_task_type === "services"
            )}
            options={{
              // filtering: true,
              // exportButton: true,
              addRowPosition: 'first',
              search:false
            }}
            icons={{
              Add: (props) => {
                return (
                  <button className="btn">
                    <i className="fa fa-plus"></i> Add New Task{" "}
                  </button>
                );
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  // newData.status = 0
                  console.log("newData", newData);
                  if (
                    newData.length !== 0 &&
                    this.servicesValidation(newData)
                  ) {
                    setTimeout(() => {
                      {
                        let data;
                        if (newData.status == 0) {
                          data = {
                            ...newData,
                            start_date_time: "",
                            end_date_time: "",
                            project_id: this.props.project.id,
                            office_task_type: "services",
                          };
                        } else if (newData.status == 1) {
                          data = {
                            ...newData,
                            end_date_time: "",
                            project_id: this.props.project.id,
                            office_task_type: "services",
                          };
                        } else {
                          data = {
                            ...newData,
                            project_id: this.props.project.id,
                            office_task_type: "services",
                          };
                        }
                        createTask(this.props.auth.token, data).then((res) => {
                          if (res.status === 200) {
                            Swal.fire(
                              "Success",
                              "Task created successfully.",
                              "success"
                            );
                            // getTaskList(this.props.auth.token, { project_id: this.props.project.id })
                            // .then(res => {
                            //   if (res.status === 200) {
                            //     let dependentOnOption = {}
                            //     res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                            //     this.setState({ TaskList: res.data, dependentOnOption })
                            //   }
                            // })
                            // .catch(error => console.log(error))
                            this.handleFilter();
                          }
                        });
                      }
                      resolve();
                    }, 1000);
                  } else {
                    return setTimeout(() => {
                      // {
                      //   console.log('rejected', newData)
                      //   Swal.fire('Error', 'Fill all the fields', 'info')
                      // }
                      return reject();
                    }, 1000);
                  }
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (
                    newData.length !== 0 &&
                    this.servicesValidation(newData)
                  ) {
                    setTimeout(() => {
                      {
                        let data;
                        if (newData.status == 0) {
                          data = {
                            ...newData,
                            start_date_time: "",
                            end_date_time: "",
                          };
                        } else if (newData.status == 1) {
                          data = {
                            ...newData,
                            end_date_time: "",
                          };
                        } else {
                          data = {
                            ...newData,
                          };
                        }
                        updateTask(this.props.auth.token, data).then((res) => {
                          if (res.status === 200) {
                            Swal.fire(
                              "Success",
                              "Task updated successfully.",
                              "success"
                            );
                            // getTaskList(this.props.auth.token, { project_id: this.props.project.id })
                            //   .then(res => {
                            //     if (res.status === 200) {
                            //       let dependentOnOption = {}
                            //       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                            //       this.setState({ TaskList: res.data, dependentOnOption })
                            //     }
                            //   })
                            //   .catch(error => console.log(error))
                            this.handleFilter();
                          }
                        });
                      }
                      return resolve();
                    }, 1000);
                  } else {
                    return setTimeout(() => {
                      // {
                      //   console.log('rejected', newData)
                      //   Swal.fire('Error', 'Fill all the fields', 'info')
                      // }
                      return reject();
                    }, 1000);
                  }
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      deleteTask(this.props.auth.token, oldData.id).then(
                        (res) => {
                          if (res.status === 200) {
                            Swal.fire(
                              "Success",
                              "Task deleted successfully.",
                              "success"
                            );
                            getTaskList(this.props.auth.token, {
                              project_id: this.props.project.id,
                            })
                              .then((res) => {
                                if (res.status === 200) {
                                  let dependentOnOption = {};
                                  res.data.map((i) => {
                                    if (i.office_task_type === "services") {
                                      return (dependentOnOption[i.id] = i.name);
                                    }
                                  });
                                  this.setState({
                                    TaskList: res.data,
                                    dependentOnOption,
                                  });
                                }
                              })
                              .catch((error) => console.log(error));
                          }
                        }
                      );
                    }
                    resolve();
                  }, 1000);
                }),
            }}
            // onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <FilterListIcon />
                </Grid>
                <Grid item style={{ width: "80%" }}>
                  <TextField
                    id="input-with-icon-grid"
                    label="Search by name, po, taskId"
                    value={this.state.searchText}
                    onChange={(e) => this.handleNameSearch(e)}
                    // onKeyPress={(e) => this.handleKeyPress(e)}
                    style={{ width: "100%" }}
                    value={this.state.searchText || ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <FilterListIcon />
                </Grid>
                <Grid item style={{ width: "80%" }}>
                  <TextField
                    id="input-with-icon-grid"
                    label="Serach Line Items"
                    value={this.state.lineItemSearch}
                    onChange={(e) => this.handleLineItemSearch(e)}
                    // onKeyPress={(e) => this.handleKeyPress(e)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  value={this.state.spaceFilterArray}
                  multiple
                  // id="checkboxes-tags-demo"
                  options={SpaceList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Space"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSpaceAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.superVFilterArray}
                  // id="checkboxes-tags-demo"
                  options={SiteSupervisorList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Site Supervisor"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSuperVisorAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.vendorFilterArray}
                  // id="checkboxes-tags-demo"
                  options={vendorData}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Vendor"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleVendorAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.dependFilterArray}
                  // id="checkboxes-tags-demo"
                  options={dependOnList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField style={{width:"95%", padding:"16px 0px"}} className="textField" {...params} placeholder="Dependent on" />
                  )}
                  onChange={(event, newValue) => this.handleDependAutoComplete(event, newValue)}
                /> 
              </Grid>
            </Grid> */}
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <Autocomplete
                  multiple
                  value={this.state.statusFilterArray}
                  // id="checkboxes-tags-demo"
                  options={statusList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "95%", padding: "16px 0px" }}
                      className="textField"
                      {...params}
                      placeholder="Status"
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.handleStatusAutoComplete(event, newValue)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="flex-end">
                <FormControl style={{ width: 400 }}>
                  <InputLabel id="demo-simple-select-label">
                    Schedule Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.scheduleFilterName}
                    onChange={this.handleScheduleSelect}
                  >
                    {schedule_type?.map((val) => (
                      <MenuItem value={val.value}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {this?.state?.scheduleFilterName && (
              <Grid item xs={2}>
                <Grid container alignItems="flex-end">
                  <TextField
                    id="date"
                    label="From"
                    type="date"
                    defaultValue=""
                    style={{ width: 200 }}
                    value={this.state.fromDate}
                    onChange={(e) => this.onDateFromChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {this?.state?.scheduleFilterName && (
              <Grid item xs={2}>
                <Grid container alignItems="flex-end">
                  <TextField
                    id="date"
                    label="To"
                    type="date"
                    defaultValue=""
                    style={{ width: 200 }}
                    value={this.state.toDate}
                    onChange={(e) => this.onDateToChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={2}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleFilter()}
                  >
                    Filter Task List
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.resetAllFilter()}
                  >
                    Reset All Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <MaterialTable
            title={`Installation Tasks List (${installations_status}% Completed)`}
            columns={[
              {
                title: "",
                render: (rowData) => (
                  <button
                    className="btn"
                    onClick={() =>
                      this.toggleInstallationSubtaskDialog(
                        rowData.id,
                        rowData.name,
                        `P${rowData.project_id}T${rowData.id}`,
                        rowData.line_item,
                        rowData.office_space_id
                      )
                    }
                  >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Subtasks {`(${rowData.sub_tasks_count})`}
                  </button>
                ),
                editable: false,
                filtering: false,
                sorting: false,
              },
              {
                title: "Task ID",
                field: "id",
                render: (rowData) =>
                  rowData !== undefined && (
                    <p
                      className="cusAnchor"
                      onClick={() => this.openDialog(rowData.id)}
                    >
                      {rowData !== undefined && rowData?.project_id
                        ? `P${rowData.project_id}T${rowData.id}`
                        : rowData !== undefined && rowData?.task_id
                        ? rowData?.task_id
                        : null}
                    </p>
                  ),
                editable: false,
                filtering: false,
              },
              {
                title: "Space",
                field: "office_space_id",
                lookup: SpaceOptions,
              },
              {
                title: "Line Item",
                field: "line_item",
              },
              {
                title: "Site Supervisor",
                field: "office_user_id",
                lookup: SiteSupervisorOption,
              },
              {
                title: "Vendor",
                field: "office_vendor_id",
                lookup: VendorOption,
              },
              {
                title: "Planned Start Date",
                field: "plan_start_date_time",
                render: (rowData) =>
                  rowData.plan_start_date_time !== null
                    ? moment(rowData.plan_start_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Planned End Date",
                field: "plan_end_date_time",
                render: (rowData) =>
                  rowData.plan_end_date_time !== null
                    ? moment(rowData.plan_end_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Actual Start Date",
                field: "start_date_time",
                render: (rowData) =>
                  rowData.start_date_time !== null
                    ? moment(rowData.start_date_time).format("ll")
                    : null,
                type: "date",
              },
              {
                title: "Actual End Date",
                field: "end_date_time",
                render: (rowData) =>
                  rowData.end_date_time !== null
                    ? moment(rowData.end_date_time).format("ll")
                    : null,
                type: "date",
              },
              // {
              //   title: 'Installation Start Date',
              //   field: 'start_date_time',
              //   render: rowData => rowData.start_date_time !== null ? moment(rowData.start_date_time).format("ll") : null,
              //   type: 'date'
              // },
              // {
              //   title: 'Installation End Date',
              //   field: 'end_date_time',
              //   render: rowData => rowData.end_date_time !== null ? moment(rowData.end_date_time).format("ll") : null,
              //   type: 'date'
              // },
              {
                title: "Quantity",
                field: "quantity",
                type: "numeric",
                align: "left",
              },
              {
                title: "Unit of Measurement",
                field: "unit_of_measurement",
              },
              {
                title: "Value",
                field: "value",
                type: "numeric",
                align: "left",
                render: (rowData) => Math.round(rowData.value),
              },
              {
                title: "Current Status",
                field: "status",
                render: (rowData) => <p>{this.status(rowData.status)}</p>,
                lookup: {
                  0: "Not Initiated",
                  1: "Initiated",
                  2: "Completed",
                },
              },
              {
                title: 'PO Num.',
                field: 'po_number',
                render: rowData => <p>{rowData && typeof (rowData?.po_number) === "string"
                ? rowData.po_number : rowData?.po_number.join(", ")}</p>,
                editable: false,
              },
              // {
              //   title: 'Installation Date',
              //   field: 'installation_date',
              //   render: rowData => rowData.installation_date !== null ? moment(rowData.installation_date).format("ll") : null,
              //   type: 'date'
              // },
              // {
              //   title: 'Material Delivery Status',
              //   field: 'material_delivery_status',
              //   render: rowData => <p>{this.materialDeliveryStatus(rowData.material_delivery_status)}</p>,
              //   lookup: {
              //     0: "Not Delivered",
              //     1: "Partial Delivery",
              //     2: "Delivered",
              //   }
              // },
              // {
              //   title: 'Material Delivery Date',
              //   field: 'material_delivery_date',
              //   render: rowData => rowData.material_delivery_status === 2 ? moment(rowData.material_delivery_date).format("ll") : null,
              //   type: 'date'
              // },
            ]}
            data={TaskList.filter(
              (task) => task.office_task_type === "installation"
            )}
            options={{
              // filtering: true,
              // exportButton: true,
              addRowPosition: "first",
              search: false,
            }}
            icons={{
              Add: (props) => {
                return (
                  <button className="btn">
                    <i className="fa fa-plus"></i> Add New Task{" "}
                  </button>
                );
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  if (
                    newData.length !== 0 &&
                    this.installationValidation(newData)
                  ) {
                    setTimeout(() => {
                      {
                        let data;
                        if (newData.status == 0) {
                          data = {
                            ...newData,
                            start_date_time: "",
                            end_date_time: "",
                            project_id: this.props.project.id,
                            office_task_type: "installation",
                          };
                        } else if (newData.status == 1) {
                          data = {
                            ...newData,
                            end_date_time: "",
                            project_id: this.props.project.id,
                            office_task_type: "installation",
                          };
                        } else {
                          data = {
                            ...newData,
                            project_id: this.props.project.id,
                            office_task_type: "installation",
                          };
                        }
                        createTask(this.props.auth.token, data).then((res) => {
                          if (res.status === 200) {
                            Swal.fire(
                              "Success",
                              "Task created successfully.",
                              "success"
                            );
                            // getTaskList(this.props.auth.token, { project_id: this.props.project.id })
                            //   .then(res => {
                            //     console.log('res', res);
                            //     if (res.status === 200) {
                            //       let dependentOnOption = {}
                            //       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                            //       this.setState({ TaskList: res.data, dependentOnOption })
                            //     }
                            //   })
                            //   .catch(error => console.log(error))
                            this.handleFilter();
                          }
                        });
                      }
                      resolve();
                    }, 1000);
                  } else {
                    return setTimeout(() => {
                      // {
                      //   console.log('rejected', newData)
                      //   Swal.fire('Error', 'Fill all the fields', 'info')
                      // }
                      return reject();
                    }, 1000);
                  }
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (
                    newData.length !== 0 &&
                    this.installationValidation(newData)
                  ) {
                    setTimeout(() => {
                      {
                        let data;
                        if (newData.status == 0) {
                          data = {
                            ...newData,
                            start_date_time: "",
                            end_date_time: "",
                          };
                        } else if (newData.status == 1) {
                          data = {
                            ...newData,
                            end_date_time: "",
                          };
                        } else {
                          data = {
                            ...newData,
                          };
                        }
                        updateTask(this.props.auth.token, newData).then(
                          (res) => {
                            if (res.status === 200) {
                              Swal.fire(
                                "Success",
                                "Task updated successfully.",
                                "success"
                              );
                              // getTaskList(this.props.auth.token, { project_id: this.props.project.id })
                              //   .then(res => {
                              //     if (res.status === 200) {
                              //       let dependentOnOption = {}
                              //       res.data.map(i => { if (i.office_task_type === "services") { return dependentOnOption[i.id] = i.name } })
                              //       this.setState({ TaskList: res.data, dependentOnOption })
                              //     }
                              //   })
                              //   .catch(error => console.log(error))
                              this.handleFilter();
                            }
                          }
                        );
                      }
                      return resolve();
                    }, 1000);
                  } else {
                    return setTimeout(() => {
                      // {
                      //   console.log('rejected', newData)
                      //   Swal.fire('Error', 'Fill all the fields', 'info')
                      // }
                      return reject();
                    }, 1000);
                  }
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      deleteTask(this.props.auth.token, oldData.id).then(
                        (res) => {
                          if (res.status === 200) {
                            Swal.fire(
                              "Success",
                              "Task deleted successfully.",
                              "success"
                            );
                            getTaskList(this.props.auth.token, {
                              project_id: this.props.project.id,
                            })
                              .then((res) => {
                                if (res.status === 200) {
                                  let dependentOnOption = {};
                                  res.data.map((i) => {
                                    if (i.office_task_type === "services") {
                                      return (dependentOnOption[i.id] = i.name);
                                    }
                                  });
                                  this.setState({
                                    TaskList: res.data,
                                    dependentOnOption,
                                  });
                                }
                              })
                              .catch((error) => console.log(error));
                          }
                        }
                      );
                    }
                    resolve();
                  }, 1000);
                }),
            }}
            // onRowClick={(event, rowData, togglePanel) => this.openDialog(rowData.id)}
          />
        </TabPanel>
        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false, TaskUpdates: [] })}
          scroll={"paper"}
          aria-labelledby="task-updates"
        >
          <DialogTitle id="task-updates">Task Updates</DialogTitle>
          <DialogContent dividers={true}>
            <MaterialTable
              title=""
              columns={[
                { title: "Remark", field: "remark" },
                {
                  title: "Created on",
                  field: "created_at",
                  render: (rowData) => moment(rowData.created_at).format("ll"),
                  type: "date",
                },
              ]}
              data={TaskUpdates}
              options={{
                // filtering: true,
                exportButton: true,
                search: false,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ openDialog: false, TaskUpdates: [] })
              }
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDialogFile}
          onClose={() => this.toggleDialogFile()}
          aria-labelledby="form-dialog-title"
          style={{ height: "100vh" }}
          maxWidth={"md"}
        >
          <DialogTitle id="form-dialog-title">
            Upload File
            <br />
            <Button
              variant="contained"
              color="secondary"
              style={styles.button}
              startIcon={<CloudDownloadIcon />}
              onClick={() =>
                window.open(
                  this.state.fileTypeTasks
                    ? `${process.env.REACT_APP_API_BASE_URL}/task_bulk_upload.xlsx`
                    : `${process.env.REACT_APP_API_BASE_URL}/subtask_bulk_upload.xlsx`
                )
              }
            >
              Download Template for Bulk Upload
            </Button>
          </DialogTitle>
          <ValidatorForm
            onSubmit={() => this.onSubmitTaskUpload()}
            onError={(errors) => console.log(errors)}
            instantValidate={false}
          >
            <DialogContent style={{ minWidth: "400px" }}>
              <ReactDropzone onDrop={this.handleFiles}>
                Drop your file here!!
              </ReactDropzone>
              <aside style={styles.thumbsContainer}>
                {this.state.newFile.name}
              </aside>
              <DialogActions>
                <Button
                  className="pull-right"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => this.toggleDialogFile()}
                >
                  Close
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Upload Files
                </Button>
              </DialogActions>
            </DialogContent>
          </ValidatorForm>
        </Dialog>
        <Dialog
          open={this.state.openDialogShareToEmail}
          onClose={() => {
            this.setState({shareToEmails: []})
            this.toggleDialogShareFile();
          }}
          aria-labelledby="form-dialog-title"
          maxWidth={"sm"}
        >
          <DialogTitle id="form-dialog-title">
            Share file
          </DialogTitle>
          <ValidatorForm
            ref="form"
            onSubmit={() => this.handleShareToEmail()}
            onError={(errors) => console.log(errors)}
            instantValidate={false}
          >
            <DialogContent>
              <TextValidator
                label="Email Address"
                autoFocus
                type="email"
                fullWidth
                style={{minWidth: '400px'}}
                onChange={(event) => {
                  this.setState({shareToEmails: event.target.value})
                }}
                name="email"
                value={this.state.shareToEmails}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Email is not valid']}
              />
              <DialogActions>
                <Button
                  className="pull-right"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    this.setState({shareToEmails: []})
                    this.toggleDialogShareFile();
                  }}
                >
                  Close
                </Button>
                <div 
                  className={"m-1"}
                  style={{position: 'relative'}}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    disabled={isMailSentLoading}
                  >
                    Share
                  </Button>
                  {isMailSentLoading && 
                  <CircularProgress size={24} 
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />}
                </div>
              </DialogActions>
            </DialogContent>
          </ValidatorForm>
        </Dialog>
        <Dialog
          open={this.state.openServicesSubtaskDialog}
          onClose={() => {
            this.toggleServicesSubtaskDialog()
            this.getData();
          }}
          aria-labelledby="subtask-dialog"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogSubtaskTitle
            onClose={() => {
              this.toggleServicesSubtaskDialog()
              this.getData();
            }}
            className="text-capitalize"
          >
            {taskName}{" "}
            <span className="text-secondary">{`(${taskDisId})`}</span>{" "}
            {SpaceOptions && SpaceOptions[spaceId]}, Subtasks List:
          </DialogSubtaskTitle>
          <DialogContent className="p-2">
            <SubtaskListServices
              project={this.props.project}
              VendorOption={VendorOption}
              taskId={taskId}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openInstallationSubtaskDialog}
          onClose={() => {
            this.toggleInstallationSubtaskDialog()
            this.getData();
          }}
          aria-labelledby="subtask-dialog"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogSubtaskTitle
            onClose={() => {
              this.toggleInstallationSubtaskDialog()
              this.getData();
            }}
            className="text-capitalize"
            
          >
            {lineItem}{" "}
            <span className="text-secondary">{`(${taskDisId})`}</span>{" "}
            {SpaceOptions && SpaceOptions[spaceId]}, Subtasks List:
          </DialogSubtaskTitle>
          <DialogContent className="p-2">
            <SubtaskListInstallation
              project={this.props.project}
              VendorOption={VendorOption}
              taskId={taskId}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.shareEmail}
          onClose={this.handleCloseEmail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Share report with customer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEmail} color="primary">
              No
            </Button>
            <Button onClick={this.handleSendEmail} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(TaskList);
