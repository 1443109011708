import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DatePicker} from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { getProcessOperationList, getAllPLI } from '../../../api';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../../Shared/TablePaginationWrapper';
import DownloadReport from './DownloadReport';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  tableRoot: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  iconSmall: {
    fontSize: 20,
  }
});

class ProdPlanReport extends Component {
  state = {
    open: false,
    processId: 1,
    startDate: new Date(moment().format('L')),
    endDate: new Date(moment().format('L')),
    operationList: [],
    page: 0,
    rowsPerPage: 5,
    productionItemList: []
  }

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  }

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  }

  getOperationsListData = () => {
    getProcessOperationList(this.props.auth.token, this.state.processId, {
      startDate: moment(this.state.startDate).format(),
      endDate: moment(this.state.endDate).format()
    })
    .then(response => {
      if(response.status){
        this.setState({ operationList: response.data})
      }
    })
    .catch(error => {

    })
  }

  getPLIListData = (operationId) => {
    this.setState({open: true})
    getAllPLI(this.props.auth.token,{ operation_id: operationId})
    .then(response => {
      if(response.status){
        this.setState({ productionItemList: response.data })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { startDate, endDate, operationList, productionItemList } = this.state;
    return(
      <div className={classes.root}>
        <Grid container spacing={40}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={4}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={this.handleStartDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={this.handleEndDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button 
                variant="contained" 
                color="primary" 
                className={classes.button}
                onClick={this.getOperationsListData}>
                <SearchIcon className={classes.leftIcon}/>
                Search
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
          {operationList.length !== 0 && 
            <Grid item xs={12} sm={12}>
              <DownloadReport operationList={operationList}/>
              <Paper className={classes.tableRoot}> 
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell numeric style={{textAlign: 'left'}}>Operation Name</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Start Date</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>End Date</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Duration (mins)</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}> View PLI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {operationList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {moment(row.start_date_time).format('L')}
                          </TableCell>
                          <TableCell>
                            {moment(row.end_date_time).format('L')}
                          </TableCell>
                          <TableCell>
                            {moment(row.end_date_time).diff(moment(row.start_date_time),'minutes')}
                          </TableCell>
                          <TableCell>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              className={classes.button}
                              onClick={() => this.getPLIListData(row.id)}>
                              View PLI
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={5}
                        count={operationList.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationWrapper}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Grid>
          }
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
        >
            <DialogTitle id="form-dialog-title">View PLI</DialogTitle>
            <DialogContent>
              {productionItemList.length !== 0 ? <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell numeric style={{textAlign: 'left'}} >PLI</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >Client Name</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >BOQ Number</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >BOQ Line Item</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >SLI</TableCell>
                    <TableCell numeric style={{textAlign: 'left'}} >Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productionItemList && productionItemList.map(row => {
                    let PLIStatusValue = null;
                    if(row.status === true){
                      PLIStatusValue = "Compelete"
                    }else if(row.status === false){
                      PLIStatusValue = "Incompelete"
                    }
                    return (
                      <TableRow key={row.id}>
                        <TableCell numeric>{row.pli_name}</TableCell>
                        <TableCell numeric>{row.client_name}</TableCell>
                        <TableCell numeric>{row.boq_num}</TableCell>
                        <TableCell numeric>{row.boq_line_item}</TableCell>
                        <TableCell numeric>{row.sli}</TableCell>
                        <TableCell numeric>{PLIStatusValue || "None"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table> : <div style={{width: '300px'}}>
                  <h5>No PLI Found</h5>
                </div>}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),connect(mapStateToProps))(ProdPlanReport);
