import axios from '../base';

export function getSiteUpdateList(accessToken) {
  return axios.request({
    url: '/api/v1/office_updates/site_update_list_for_pm',
    method: 'GET',
    headers: accessToken,
  })
};

export function getCustomerList(accessToken) {
  return axios.request({
    url: 'api/v1/office_projects/get_lead_lists_on_current_office_user',
    method: 'GET',
    headers: accessToken,
  })
};

export function sendBulkCustomerEmail(accessToken, data) {
  return axios.request({
    url: `api/v1/office_tasks/send_bulk_wpr_email_to_client`,
    method: 'POST',
    headers: accessToken,
    data:data
  })
};

export function getDynamicSiteUpdateFilterPMDropdowns(accessToken) {
  return axios.request({
    url: `/api/v1/office_updates/site_update_dynamic_filter_pm`,
    method: 'GET',
    headers: accessToken,
  })
};

export function getSiteUpdatePMFilter(accessToken, selectedPms, page_num, search, fromFilterDate, toFilterDate, type, task, vender, siteSupervisor, isClientMom) {
  return axios.request({
    url: `/api/v1/office_updates/site_update_list_for_pm?assigned_pms=${selectedPms}&page=${page_num}&search=${search}&from_date=${fromFilterDate}&to_date=${toFilterDate}&type=${type}&task_type=${task}&vendors=${vender}&site_supervisor=${siteSupervisor}&is_client_mom=${isClientMom}`,
    method: 'GET',
    headers: accessToken,
  })
};
