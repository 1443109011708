import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table'
import { getClientMeetingMomListVendor } from '../../../../api/VENDOR/clientMeetingMoM';
import { Grid, Table, TableBody, TableCell, TableRow, TableHead, Tooltip, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';


const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror: {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  img: {
    display: 'block',
    maxWidth: '300px',
    maxHeight: '300px'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  }
});

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumbInnerPreview = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
  width: '50vh',
  height: '50vh'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}


class ClientMeetingMom extends Component {
  state = {
    ClientMeetingMom: [],
    filesDialog: false,
    filesList: [],
  };

  componentDidMount() {
    if (this.props.project) {
      getClientMeetingMomListVendor(this.props.auth.token, this.props.project.id)
        .then(response => {
          if (response.status) {
            let list = response.data;
            list.map(l => {
              l.created_at = moment(l.created_at).format('ll');
            })
            this.setState({ ClientMeetingMom: list })
          }
        })
        .catch(error => {
          this.setState({ ClientMeetingMom: [] })
          console.log(error)
        })
    }
  }

  render() {
    const { classes } = this.props;
    const { ClientMeetingMom, filesDialog, filesList } = this.state;
    return (
      <div>
        <MaterialTable
          title="Client Meeting Mom List"
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ margin: '10px' }}>

                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Created on',
              field: 'created_at',
              defaultGroupOrder: 0
            },
            {
              title: 'Remark',
              field: 'remark',
            },
            {
              title: 'Files',
              field: 'files',
              render: rowData => {
                return (
                  <Tooltip title="Files" aria-label="add">
                    <Badge
                      className={classes.button}
                      color="secondary"
                      badgeContent={rowData.files.length}
                      invisible={rowData.files.length > 0 ? false : true}
                    >
                      <FileIcon color={rowData.files.length > 0 ? "primary" : "disabled"} />
                    </Badge>
                  </Tooltip>
                )
              },
              grouping: false,
              filtering: false,
              editable: 'never'
            },

          ]}
          onRowClick={(event, rowData, toggle) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
          data={ClientMeetingMom}
          options={{
            grouping: true,
            filtering: true,
            exportButton: true
          }}
        />
        <Dialog
          open={filesDialog}
          onClose={() => this.setState({ filesDialog: false })}
          scroll={'paper'}
          aria-labelledby="site-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="site-updates">Files</DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={1}>
              {
                filesList.map(file => (
                  <Grid key={file.id} item>
                    <img
                      src={file.file}
                      style={{
                        height: '300px',
                        width: '300px'
                      }}
                      alt="Arrivae"
                    />
                  </Grid>
                ))
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(ClientMeetingMom);
