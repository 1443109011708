import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Avatar,
  TextareaAutosize,
} from "@material-ui/core";
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import compose from 'recompose/compose';
import moment from 'moment';
import { updateOfficeProjectHandover, handOverSubmit } from '../../../../api'
import GetAppIcon from '@material-ui/icons/GetApp';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

const styles = theme => ({
  closeIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  imageFileContainer: {
    position: 'relative',
    marginTop: '1rem',
    width: '70px',
    height: '70px',
  },
  imageFile: {
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  removeImage: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    color: 'white',
    background: '#dc3545',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: '1',
  },
  remarksContainer: {
    marginTop: '1rem',
  },
  remark_text_area: {
    width: '100%',
  }
});

class ProjectHandover extends Component {

  state = {
    office_project_handover_details: this.props.project.office_project_handover_details,
    modalOpen: false,
    imageUrl: null,
    files: [],
    fileData: null,
    remark: null,
  }

  approveNow = () => {
    const { project } = this.props;
    const data = {
      id: project.office_project_handover_details.id,
      is_approved: true
    }
    updateOfficeProjectHandover(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          this.setState({ office_project_handover_details: res.data })
        }
      })
      .catch(error => console.log(error))
  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true })
  };

  handleClose = () => {
    this.setState({ modalOpen: false, files: [], fileData: null, remark: null })
  };

  removeFile = (file) => {
    let res = this.state.files.filter((item) => {
      if (item.name == file) return false;
      else return true;
    });
    this.setState({ files: res });
  }

  onChange = (file) => {
    if (file) {
      this.setState({ files: []});
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append('files[]', file[i])
      }
      
      this.setState({fileData: formData});

      Array.from(file).forEach((item, i) => {
        const name = item.name;
        this.setState((prev) => ({ files: [
          ...prev.files,
          {
            key: Math.floor(Math.random() * 100),
            name: name,
            preview: URL.createObjectURL(item),
            type: item.type
          }]}
        ));
      });
    }
  }

  submitForm = () => {
    const remark = this.state.remark || '';
    const project_id = this.props.project_id || '';
    handOverSubmit(this.props.token, this.state.fileData, remark, project_id)
      .then(res => {
        if (res.status == 200) {
          this.handleClose();
          Swal.fire("Success", "Handover Submitted", "success")
          .then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == 202) {
          this.handleClose();
          Swal.fire("Warning", res.data.message, "warning");
        } else {
          this.handleClose();
          Swal.fire("Error", res.data.message, "error");
        }
      }).catch(err => {
        this.handleClose();
        Swal.fire("Error", err.data.message, "error");
      });
  }
  
  render() {
    const { classes } = this.props;
    const { office_project_handover_details } = this.state;
    return (
      (office_project_handover_details && office_project_handover_details.length !== 0) ?
        <div>
          <Card>
            <CardHeader
              action={
                <>
                  {!office_project_handover_details.is_approved &&
                    <Button variant="contained" color="primary" onClick={this.approveNow}>
                      Approve Now
                  </Button>
                  }
                </>
              }
              title="Project Handover Details"
            />
            <CardContent>
              <Grid container spacing={0}>

                <Grid item xs={12} sm={4}><strong> Approval Status: </strong></Grid>
                <Grid item xs={12} sm={8}>{office_project_handover_details.is_approved ? "Yes" : "No"}</Grid>

                {office_project_handover_details.is_approved &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Approved By(Project Manager): </strong></Grid>
                    <Grid item xs={12} sm={8}>
                      {office_project_handover_details.approved_by_details.name} <br />
                      {office_project_handover_details.approved_by_details.email} <br />
                      {office_project_handover_details.approved_by_details.contact} <br />
                    </Grid>
                  </>
                }
                {office_project_handover_details.raised_by_details !== undefined &&
                  office_project_handover_details.raised_by_details.length !== 0 &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor): </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.name}</Grid>

                    <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor) Details: </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.email}</Grid>

                    <Grid item xs={12} sm={4}><strong></strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.contact}</Grid>
                  </>
                }

                <Grid item xs={12} sm={4}><strong> Raised on: </strong></Grid>
                <Grid item xs={12} sm={8}>{moment(office_project_handover_details.created_at).format('lll')}</Grid>
                {office_project_handover_details.is_approved &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Approved Date: </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.approved_date && moment(office_project_handover_details.approved_date).format('lll')}</Grid>
                  </>
                }
                <Grid item xs={12} sm={4}><strong> Remark: </strong></Grid>
                <Grid item xs={12} sm={8}>{office_project_handover_details.remark}</Grid>
                
                <Grid container xs={12} wrap spacing={3}>
                {
                  office_project_handover_details.files.length !== 0 &&
                  office_project_handover_details.files.map((file, index) => {
                    return (
                      <React.Fragment key={index}>
                      <Grid item>
                        {file.file_content_type == "application/pdf" ? (
                          <a href={file.file} target="_blank" alt="download">
                            <Avatar
                              variant="square"
                              className={classes.imageFile}
                              style={{ height: '100px', width: '100px', objectFit: 'cover'}}
                            >
                              <DescriptionIcon style={{ width: '50px', height: '60px'}} />
                            </Avatar>
                          </a>
                        ) : (
                          <a href={file.file} target="_blank" alt="download">
                          <img
                            src={file.file}
                            alt={file.file_file_name}
                            height="200"
                            weight="200"
                            style={{ height: '100px', width: '100px', objectFit: 'cover'}}
                          />
                          </a>
                        )}
                        </Grid>
                      </React.Fragment>
                    )
                  })
                }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        : 
        <React.Fragment>
        <Card>
          <CardHeader
            title="Project Handover"
          />
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
                <Button 
                  size='large'
                  variant='contained'
                  color="primary"
                  onClick={this.handleOpenModal}
                >
                  Complete Handover
                </Button>
            </Grid>
          </CardContent>
        </Card>

        <Dialog 
          onClose={this.handleClose} 
          aria-labelledby="customized-dialog-title" 
          open={this.state.modalOpen}
          maxWidth={'lg'}
        >
          <ValidatorForm
            onSubmit={() => this.submitForm()}
            onError={errors => console.log(errors)}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              Project Handover
              <span onClick={this.handleClose} className={classes.closeIcon}>
                <CloseIcon />
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Typography style={{ width: '600px', marginBottom: '1rem'}}>Handover Files :</Typography>
              
                <div className='d-flex'>
                  <div className={classes.image_container} style={{ width: '50%'}}>
                    <Grid container spacing={3}>
                      {this.state.files.length > 0 ? this.state.files.map((ele) => {
                        return (
                          <Grid key={ele.key} item>
                            <div className={classes.imageFileContainer}>
                              <span onClick={() => this.removeFile(ele.name)} className={classes.removeImage}>X</span>
                              {(ele.type == 'image/png' || ele.type == 'image/jpeg') ?
                                <img
                                  className={classes.imageFile}
                                  alt="uploadImage"
                                  src={ele.preview}
                                  style={{ objectFit: 'cover'}}
                                /> : 
                                <Avatar variant="square" className={classes.imageFile}>
                                  <DescriptionIcon />
                                </Avatar>
                                }
                            </div>
                          </Grid> 
                        )
                      }) : 
                      <Grid item>
                        <Typography>Please upload Image/Files</Typography>
                      </Grid>
                      }
                    </Grid>
                  </div>
                
                  <div className='input-field' style={{ width: '50%'}}>
                    <input 
                      type="file"
                      accept="image/jpeg,image/png,application/pdf" 
                      name={"attachment_file" + 'approveMap.id'} 
                      className="file-input" 
                      id={'userImgUpload' + 'approveMap.id'}
                      onChange={(e) => this.onChange(e.target.files)}
                      multiple
                    />
                  </div>
                </div>
                <div className={classes.remarksContainer}>
                  <Typography>Remarks: </Typography>
                  <TextareaAutosize 
                    aria-label="minimum height" 
                    value={this.state.remark}
                    rowsMin={3}
                    placeholder="Enter Remarks here." 
                    className={classes.remark_text_area}
                    onChange={(e) => this.setState({ remark: e.target.value})}
                  />
                </div>
              </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
              <Button type='submit' variant="contained" color="primary">
                Submit Handover
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),connect(mapStateToProps))(ProjectHandover);
