import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProjectDetails from "./ProjectDetails";
import SiteMeasurement from "./SiteMeasurement";
import ProjectFiles from "./ProjectFiles";
import ProjectBOQs from "./ProjectBOQs";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import {
  getResidentialProjectDetails,
  getProjectVendorList,
} from "../../../api";
import CircularIndeterminate from "../../Loader";
import ProjectBOQJourney from "./ProjectBOQJourney";
import AssignProjectManager from "./AssignProjectManager";
import Snags from "./Snags";
import LaborReport from "./LaborReport";
import ClientMeetingMoM from "./ClientMeetingMoM";
import SiteUpdate from "./SiteUpdate";
import CustomerReport from "./CustomerReport";
import TaskList from "./TaskList";
import Ticket from "./Ticket";
import AssignVendor from "./AssignVendor";
import ProjectHandover from "./ProjectHandover";
// import { Link } from 'react-router-dom';
import PaymentMilestone from "../../ProjectEngineer/Projects/Residential/PaymentMilestone";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "5px",
  },
});

class Project extends Component {
  state = {
    value: parseInt(window?.location?.hash?.split("#")[1]) || 0,
    isLoading: true,
    project: [],
    projectStatus: null,
    VendorOption: [],
    vendorData: [],
  };

  componentDidMount() {
    let url = new URL(window.location.href);
    // let HasLocation = window.location && window.location.hash && window.location.hash.split('#')[1];
    // if(HasLocation) {
    //   this.setState({
    //     value:HasLocation || 0
    //   });
    // }
    let project_status = url.searchParams.get("project_status") || null;
    const index = url?.href?.indexOf("project_status=50_percent");
    if (index > -1) {
      project_status = "50_percent";
    }
    if (project_status !== null) {
      this.setState({ projectStatus: project_status });
    }
    this.getProjectDetails();
    this.getVendorOptionList();
  }

  getProjectDetails = () => {
    let projectId = this.props.match.params.project_id || undefined;
    getResidentialProjectDetails(this.props.token, projectId)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading: false,
            project: res.data,
            projectId,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  getVendorOptionList = () => {
    let projectId = this.props.match.params.project_id || undefined;
    getProjectVendorList(this.props.token, projectId)
      .then((res) => {
        if (res.status) {
          let VendorOption = {};
          res.data.map((i) => (VendorOption[i.id] = i.name));
          this.setState({ VendorOption, vendorData: res.data });
          console.log("resppppppppppp", res.data);
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  onChangeTab = (tabName, proj) => {
    this.props.history.push({
      // pathname: `/pe/projects/${this.state.projectId || this.props?.location?.state?.project?.id}?project_status=${this.props?.location?.state?.ProjectStatus}#${tabName}`,
      pathname: `/om/projects/${
        this.state.projectId || this.props?.location?.state?.project?.id
      }`,
      search: `?project_status=${this.props?.location?.state?.ProjectStatus}#${tabName}`,
      state: {
        offset: this.props?.location?.state.offset,
        tabNumber: this.props?.location?.state.tabNumber,
        project: this.props?.location?.state?.project?.id,
        ProjectStatus: this.props?.location?.state?.ProjectStatus,
      },
    });
    if (proj == "projectDetails") {
      this.getVendorOptionList();
      this.getProjectDetails();
    }
  };

  render() {
    const {
      isLoading,
      projectId,
      project,
      projectStatus,
      VendorOption,
      vendorData,
    } = this.state;
    const { offset, tabNumber } = this.props.location.state;
    console.log("checking for props :", this.props);
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "5px" }}>
          {/* <Link color="inherit" href={`/om/projects${projectStatus !== null ? `?project_status=${projectStatus}` : ''}`}>
            Projects List
          </Link> */}
          <Link
            color="inherit"
            href={`/om/projects-list/${offset}#${tabNumber}-${projectStatus}`}
          >
            Projects List
          </Link>

          <Typography color="textPrimary">
            {(project.lead && project.lead.name) || "Project"}
          </Typography>
        </Breadcrumbs>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <React.Fragment>
            {(project.length !== 0 &&
              project.closure == true && project.pushed_to_production == false &&
              projectStatus !== "50_percent" &&
              projectStatus !== "completed" && (
                <React.Fragment>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="on"
                    >
                      <Tab
                        label="Project Details"
                        onClick={() => this.onChangeTab(0, "projectDetails")}
                      />
                      <Tab
                        label="Assign Project Manager"
                        onClick={() => this.onChangeTab(1)}
                      />
                      <Tab label="Files" onClick={() => this.onChangeTab(2)} />
                      <Tab
                        label="Assign Vendor"
                        onClick={() => this.onChangeTab(3)}
                      />
                    </Tabs>
                  </AppBar>
                  <SwipeableViews
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                  >
                    <TabContainer>
                      <ProjectBOQJourney project={project} />
                    </TabContainer>
                    <TabContainer>
                      <AssignProjectManager project={project} />
                    </TabContainer>
                    <TabContainer>
                      <ProjectFiles project={project} />
                    </TabContainer>
                    <TabContainer>
                      <AssignVendor
                        project={project}
                        getVendorOptionList={() => this.getVendorOptionList()}
                      />
                    </TabContainer>
                  </SwipeableViews>
                </React.Fragment>
              )) ||
              (project.length !== 0 &&
                ((project.closure == true && project.pushed_to_production == true) ||
                  projectStatus === "50_percent" ||
                  projectStatus === "completed") && (
                  <div>
                    {project.office_project_handover_details !== undefined &&
                    project.office_project_handover_details.length !== 0 ? (
                      <>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="on"
                          >
                            <Tab
                              label="Project Details"
                              onClick={() =>
                                this.onChangeTab(0, "projectDetails")
                              }
                            />
                            <Tab
                              label="Project BOQ Journey"
                              onClick={() => this.onChangeTab(1)}
                            />
                            <Tab
                              label="Project Handover"
                              onClick={() => this.onChangeTab(2)}
                            />
                            <Tab
                              label="Final BOQs"
                              onClick={() => this.onChangeTab(3)}
                            />
                            <Tab
                              label="Assign Project Manager"
                              onClick={() => this.onChangeTab(4)}
                            />
                            <Tab
                              label="Final Files"
                              onClick={() => this.onChangeTab(5)}
                            />
                            <Tab
                              label="Project Schedule"
                              onClick={() => this.onChangeTab(6)}
                            />
                            <Tab
                              label="Snags"
                              onClick={() => this.onChangeTab(7)}
                            />
                            <Tab
                              label="Tickets"
                              onClick={() => this.onChangeTab(8)}
                            />
                            <Tab
                              label="Daily Labour Report"
                              onClick={() => this.onChangeTab(9)}
                            />
                            <Tab
                              label="Client Meeting MOM"
                              onClick={() => this.onChangeTab(10)}
                            />
                            <Tab
                              label="Site Update"
                              onClick={() => this.onChangeTab(11)}
                            />
                            <Tab
                              label="Customer Report"
                              onClick={() => this.onChangeTab(12)}
                            />
                            <Tab
                              label="Assign Vendor"
                              onClick={() => this.onChangeTab(13)}
                            />
                            <Tab
                              label="Site Measurement"
                              onClick={() => this.onChangeTab(14)}
                            />
                            <Tab
                              label="Payment Milestone"
                              onClick={() => this.onChangeTab(15)}
                            />
                          </Tabs>
                        </AppBar>
                        <SwipeableViews
                          index={this.state.value}
                          onChangeIndex={this.handleChangeIndex}
                        >
                          <TabContainer>
                            <ProjectDetails project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectBOQJourney project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectHandover project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectBOQs project={project} />
                          </TabContainer>
                          <TabContainer>
                            <AssignProjectManager project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectFiles project={project} />
                          </TabContainer>
                          <TabContainer>
                            <TaskList
                              project={project}
                              VendorOption={VendorOption}
                              vendorData={vendorData}
                            />
                          </TabContainer>
                          <TabContainer>
                            <Snags project={project} />
                          </TabContainer>
                          <TabContainer>
                            <Ticket project={project} />
                          </TabContainer>
                          <TabContainer>
                            <LaborReport
                              project={project}
                              VendorOption={VendorOption}
                            />
                          </TabContainer>
                          <TabContainer>
                            <ClientMeetingMoM project={project} />
                          </TabContainer>
                          <TabContainer>
                            <SiteUpdate project={project} />
                          </TabContainer>
                          <TabContainer>
                            <CustomerReport project={project} />
                          </TabContainer>
                          <TabContainer>
                            <AssignVendor
                              project={project}
                              getVendorOptionList={() =>
                                this.getVendorOptionList()
                              }
                            />
                          </TabContainer>
                          <TabContainer>
                            <SiteMeasurement project={project} />
                          </TabContainer>
                          <TabContainer>
                            <PaymentMilestone
                              vendorData={vendorData}
                              project={project}
                              authenticate={this.props.token}
                            />
                          </TabContainer>
                        </SwipeableViews>
                      </>
                    ) : (
                      <>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="on"
                          >
                            <Tab
                              label="Project Details"
                              onClick={() =>
                                this.onChangeTab(0, "projectDetails")
                              }
                            />
                            <Tab
                              label="Project BOQ Journey"
                              onClick={() => this.onChangeTab(1)}
                            />
                            <Tab
                              label="Project Handover"
                              onClick={() => this.onChangeTab(2)}
                            />
                            <Tab
                              label="Final BOQs"
                              onClick={() => this.onChangeTab(3)}
                            />
                            <Tab
                              label="Assign Project Manager"
                              onClick={() => this.onChangeTab(4)}
                            />
                            <Tab
                              label="Final Files"
                              onClick={() => this.onChangeTab(5)}
                            />
                            <Tab
                              label="Project Schedule"
                              onClick={() => this.onChangeTab(6)}
                            />
                            <Tab
                              label="Snags"
                              onClick={() => this.onChangeTab(7)}
                            />
                            <Tab
                              label="Tickets"
                              onClick={() => this.onChangeTab(8)}
                            />
                            <Tab
                              label="Daily Labour Report"
                              onClick={() => this.onChangeTab(9)}
                            />
                            <Tab
                              label="Client Meeting MOM"
                              onClick={() => this.onChangeTab(10)}
                            />
                            <Tab
                              label="Site Update"
                              onClick={() => this.onChangeTab(11)}
                            />
                            <Tab
                              label="Customer Report"
                              onClick={() => this.onChangeTab(12)}
                            />
                            <Tab
                              label="Assign Vendor"
                              onClick={() => this.onChangeTab(13)}
                            />
                            <Tab
                              label="Site Measurement"
                              onClick={() => this.onChangeTab(14)}
                            />
                            <Tab
                              label="Payment Milestone"
                              onClick={() => this.onChangeTab(15)}
                            />
                          </Tabs>
                        </AppBar>
                        <SwipeableViews
                          index={this.state.value}
                          onChangeIndex={this.handleChangeIndex}
                        >
                          <TabContainer>
                            <ProjectDetails project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectBOQJourney project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectHandover project_id={projectId} project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectBOQs project={project} />
                          </TabContainer>
                          <TabContainer>
                            <AssignProjectManager project={project} />
                          </TabContainer>
                          <TabContainer>
                            <ProjectFiles project={project} />
                          </TabContainer>
                          <TabContainer>
                            <TaskList
                              project={project}
                              VendorOption={VendorOption}
                              vendorData={vendorData}
                            />
                          </TabContainer>
                          <TabContainer>
                            <Snags project={project} />
                          </TabContainer>
                          <TabContainer>
                            <Ticket project={project} />
                          </TabContainer>
                          <TabContainer>
                            <LaborReport
                              project={project}
                              VendorOption={VendorOption}
                            />
                          </TabContainer>
                          <TabContainer>
                            <ClientMeetingMoM project={project} />
                          </TabContainer>
                          <TabContainer>
                            <SiteUpdate project={project} />
                          </TabContainer>
                          <TabContainer>
                            <CustomerReport project={project} />
                          </TabContainer>
                          <TabContainer>
                            <AssignVendor
                              project={project}
                              getVendorOptionList={() =>
                                this.getVendorOptionList()
                              }
                            />
                          </TabContainer>
                          <TabContainer>
                            <SiteMeasurement project={project} />
                          </TabContainer>
                          <TabContainer>
                            <PaymentMilestone
                              vendorData={vendorData}
                              project={project}
                              authenticate={this.props.token}
                            />
                          </TabContainer>
                        </SwipeableViews>
                      </>
                    )}
                  </div>
                )) || <div>No Project Found</div>}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(Project);
