
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PaymentCompelteIcon from '@material-ui/icons/MonetizationOnOutlined';
import ProjectIcon from '@material-ui/icons/Business';
import PaymentPendingIcon from '@material-ui/icons/Timer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { getFinanceDashboardStats } from '../../api'

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  icons:{
    color: '#868e95',
    fontSize: '40',
    marginTop: '5'
  },  
});

class StatsDashboard extends React.Component {
  state = {
    open: false,
    stats: []
  }

  componentDidMount() {
    getFinanceDashboardStats(this.props.auth.token)
    .then(res => {
      if (res.status) {
        this.setState({
          stats: res.data
        })
      }
    })
    .catch(error => console.log(error))
  }


  render() {
    const { classes } = this.props;
    let { stats } = this.state;
    return (
      <div>
        
        {stats.length !== 0 &&
          <div className={classes.root}>
          <Grid container spacing={24}>
            <Grid item xs={12} >
              {/* <Typography variant="h4" gutterBottom>
                Dashboard
              </Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <ProjectIcon style={{ color: '#6F42C3', fontSize: '80' }}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Projects
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_projects || 0} </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <PaymentCompelteIcon style={{ color: '#28A744', fontSize: '80' }}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Payment Done
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_payment || 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <PaymentPendingIcon style={{ color: '#007bff', fontSize: '80'}}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Payment Pending
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_pending_payment || 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <AssignmentIcon style={{ color: '#007bff', fontSize: '80' }}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Pending Tickets
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_tickets_pending || 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <AssignmentReturnedIcon style={{ color: '#17a2b8', fontSize: '80' }}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Followup Tickets
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_tickets_follow_up || 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <CardContent>
                  
                  <Grid container>
                    <Grid item xs={4}>
                      <AssignmentLateIcon style={{ color: '#dc3545', fontSize: '80' }}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="center" variant="subheading" gutterBottom>
                        Total Redflag Tickets
                      </Typography>
                      <Typography align="center" variant="display2">{stats.total_redflag_ticket || 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(StatsDashboard);