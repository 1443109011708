import axios from '../base';

export function getResidentialProjectsForOM(accessToken, project_status, page, limit, search) {
  return axios.request({
    url: `/api/v1/office_projects/residential_projects_for_om/${project_status}/?page=${page}&limit=${limit}&search=${search}`,
    method: 'GET',
    headers: accessToken 
  })
};

export function getResidentialProjectDetails(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/get_residential_project/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getResidentialProjectPEList(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/project_engineer_list/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function assignResidentialProjectPE(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/residential_project_assign_pe`,
    method: 'POSt',
    headers: accessToken,
    data: data
  })
};

export function getSSListForPE(accessToken, PEId) {
  return axios.request({
    url: `/api/v1/office_projects/assigned_ss_list_to_pe/${PEId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getPEListForSS(accessToken, SSId) {
  return axios.request({
    url: `/api/v1/office_projects/assigned_pe_list_to_ss/${SSId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function mapSiteSupervisortoProjectEngineer(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/office_pe_ss_mapping`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};