import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { getPendingPayments, 
  changeTicketStatus,
} from '../../api';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { DialogActions } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
 
});

class PendingPayments extends Component {
  state = {
    open: false,
    page: 0,
    rowsPerPage: 5,
    ticketOption: [
      {value: 0, label: 'Open'},
      {value: 1, label: 'In progress'},
      {value: 2, label: 'Closed'},
    ],
    PaymentList: [],
    selectedPayment: "",
  };

  componentDidMount() {
    getPendingPayments(this.props.auth.token)
    .then(response => {
      if(response.status){
        this.setState({PaymentList: response.data})
      }
    })
    .catch(error => console.log(error))
  }


  handleClickOpen = (selectedPayment) => {
    this.setState({open: true, selectedPayment})
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };


  PaymentStatus = (status) => {
    let statusLabel = ''
    switch (status) {
      case 0:
        statusLabel = 'Pending'
        break;
      case 1:
        statusLabel = 'In progress'
        break;
      case 2:
        statusLabel = 'Success'
        break;
      case 3:
        statusLabel = 'Failed'
        break;
      default:
        break;
    }
    return statusLabel;
  }

  changeStatus = (e, id) => {
    changeTicketStatus(this.props.auth.token, {
      ticketId: id,
      status: e.value
    })
    .then(response => {
      if(response.status){
        getPendingPayments(this.props.auth.token)
      .then(response => {
        if(response.status){
          this.setState({TicketList: response.data, open: false})
        }
      })
      .catch(error => console.log(error))
      }
    })
    .catch(error => console.log(error))
  }

  changeHandle = (e) => {
    let selectedPayment = this.state.selectedPayment;
    selectedPayment[e.target.name] = e.target.value;
    this.setState({selectedPayment})
  }

  payByCash = (e) => {
    e.preventDefault();
    alert("By cash")
  }

  payByPaytm = (e) => {
    e.preventDefault();
    alert("By Paytm")
  }

  render() {
    const {classes} = this.props;
    const { PaymentList, selectedPayment} = this.state;
    return(
      <div>
        <Typography variant="h4" gutterBottom style={{marginTop: '20px'}}>
          Pending Payment List
        </Typography>
        <div className="table-container">
          { PaymentList.length !== 0 &&   
            <Paper className="user-list-root">
              <Table className="user-list-table" style={{  width: "auto", tableLayout: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell numeric style={{ textAlign: 'left' }}>ID</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Project </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Task</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Ticket ID </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Requested </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Description </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Price </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Created By</TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Created Date </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Last Modified Date </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Payment status </TableCell>
                    <TableCell numeric style={{ textAlign: 'left' }}>Action </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PaymentList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((n,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.id} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.project_name} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.task_name} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.ticket_unique_id} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.ticket_requested} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left', padding: 20 }}>{n.ticket_description} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.ticket_price} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{n.office_payee_name} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left', padding: 20 }}>{moment(n.created_at).format('llll')} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left', padding: 20 }}>{moment(n.updated_at).format('llll')} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>{this.PaymentStatus(n.payment_status)} </TableCell>
                        <TableCell numeric style={{ textAlign: 'left' }}>
                          <Tooltip title="Pay">
                            <Button variant="contained" size="medium" color="primary"
                              onClick={() => this.handleClickOpen(n)}>
                              Pay
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                <TableRow>
                    <TablePagination
                      colSpan={11}
                      count={PaymentList.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationWrapper}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          }
        </div>
        
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({open: false})}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Payment Option</DialogTitle>
          <form>
            <DialogContent>
              <FormControl fullWidth>
                <TextField
                  required
                  type="number"
                  id="amount"
                  name="amount"
                  label="Amount"
                  error={(["", null].includes(selectedPayment.amount) || parseInt(selectedPayment.amount) === 0 ) ? true : false}
                  value={selectedPayment.amount || ""}
                  onChange={(e) => this.changeHandle(e)}
                  className={classes.textField}
                  margin="normal"
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  required
                  id="note"
                  label="Note"
                  name="note"
                  error={["", null].includes(selectedPayment.note) ? true : false}
                  value={selectedPayment.note || ""}
                  onChange={(e) => this.changeHandle(e)}
                  className={classes.textField}
                  margin="normal"
                  multiline
                  rows="4"
                />
              </FormControl>

              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" size="medium" color="primary"
                  onClick={(e) => this.payByCash(e)}>
                  Pay by Cash
                </Button>
                {"\n"}
                <Button type="submit" variant="contained" size="medium" color="primary"
                  onClick={(e) => this.payByPaytm(e)}>
                  Pay By Paytm
                </Button>
                <Button variant="contained" size="medium"
                  onClick={() => this.setState({open: false})}>
                  Close
                </Button>
              </DialogActions>
          </form>
        </Dialog>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(PendingPayments);
