import axios from '../base';

export function getNocDelayedProjects(accessToken) {
  return axios.request({
    url: `/api/v1/office_nocs/noc_10_to_40_percent_projects`,
    method: 'GET',
    headers: accessToken
  })
};

export function getNocNotifcation(accessToken) {
  return axios.request({
    url: `/api/v1/office_nocs`,
    method: 'GET',
    headers: accessToken
  })
};

export function createNocNotifcation(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_nocs/project_notification/${data.project_id}`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};