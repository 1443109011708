import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { createPPT, createMoodboard, MoodboardList, updateMoodboard, deleteMoodboard, MoodboardListForCurtains, createPPTForCurtain ,MarketingPortfolios} from '../../api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SlideShowIcon from '@material-ui/icons/Slideshow';
import FilterIcon from '@material-ui/icons/FilterList';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@material-ui/core/Checkbox';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { ThemeProvider } from '@material-ui/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  validate: {
    color: 'red',
    fontSize: 'small'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  media: {
    height: 500,
    width: '100%'
  },
  dialogContent: {
    minWidth: 400
  }
});

const options = [
  { value: 'Living Room', label: 'Living Room' },
  { value: 'Kitchen', label: 'Kitchen' },
  { value: 'Bedroom', label: 'Bedroom' },
  { value: 'Kids Bedroom', label: 'Kids Bedroom' }
];

const categories = [
  { value: 'Joy', label: 'Joy' },
  { value: 'Bliss', label: 'Bliss' },
  { value: 'Delight ', label: 'Delight ' },
];

class Preset extends Component {

  state = {
    open: false,
    openDialog2: false,
    openDialog3: false,
    filterDialog: false,
    slide_type: "slides",
    newMoodboard: {
      mood_type: '',
      size: '',
      low_price: '',
      high_price: '',
      boq_value: '',
      scope_of_work: '',
      image_1: '',
      image_2: '',
      image_3: '',
      layout: '',
      thumbnail: '',
      category: '',
    },
    filter: {
      type: '',
      category: '',
      size: { min: 0, max: 100 },
      price: { min: 0, max: 500000 }
    },
    editMoodboard: {},
    moodTypeValidate: false,
    categoryTypeValidate: false,
    moodBoardList: [],
    page: 0,
    rowsPerPage: 5,
    openSnack: false,
    snackMessage: '',
    selected: [],
    checkedA: false,
    client_id: parseInt(this.props.client_id, 10) || '',
    designer_id: parseInt(this.props.designer_id, 10) || '',
    previewDialog: false,
    previewUrl: '',
  }

  componentDidMount() {
    MarketingPortfolios(this.props.auth.token)
      .then(res => {
          console.log(res)
        if (res.status) {
          this.setState({ moodBoardList: res.data })
        }
      })
      .catch(e => console.log(e))

  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  FilterDialogOpen = () => {
    this.setState({ filterDialog: true });
  };

  FilterDialogClose = () => {
    this.setState({ filterDialog: false });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  changeHandler = (e, name = null) => {
    let newMoodboard = this.state.newMoodboard;
    if (name === null) {
      newMoodboard[e.target.name] = e.target.value;
    } else {
      newMoodboard[name] = e.value;
    }
    this.setState({ newMoodboard })
  }

  changeEditHandler = (e, name = null) => {
    let editMoodboard = this.state.editMoodboard;
    if (name === null) {
      editMoodboard[e.target.name] = e.target.value;
    } else {
      editMoodboard[name] = e.value;
    }
    this.setState({ editMoodboard })
  }

  handleClickOpenDialog3 = (n) => {
    let editMoodboard = n
    this.setState({ editMoodboard, openDialog3: true });
  }

  handleCloseEditDialog = () => this.setState({ openDialog3: false });

  generatePPT = () => {

    console.log("radiofunction", this.state.slide_type)
    createPPT(this.props.auth.token, {
      selected: this.state.selected,
      client_id: this.state.client_id,
      designer_id: this.state.designer_id,
      slide_type: this.state.slide_type,
    })
      .then(res => {
        if (res.status) {
          window.open(res.data.url)
          this.setState({
            openSnack: true,
            snackMessage: 'Ppt Generated Sucessfully!!',
            openDialog2: false
          })
        }
      })
      .catch(e => {
        this.setState({
          openSnack: true,
          snackMessage: e.response.data.message,
          openDialog2: false
        })
      })
  }

  onSubmit = (e) => {
    e.preventDefault();
    let newMoodboard = { ...this.state.newMoodboard };
    if (newMoodboard.type === '') {
      this.setState({ moodTypeValidate: true })
    } else {
      const fd = new FormData();
      fd.append('mood_type', newMoodboard.mood_type)
      fd.append('size', newMoodboard.size)
      fd.append('low_price', newMoodboard.low_price)
      fd.append('high_price', newMoodboard.high_price)
      fd.append('boq_value', newMoodboard.boq_value)
      fd.append('scope_of_work', newMoodboard.scope_of_work)
      fd.append('image_1', newMoodboard.image_1)
      fd.append('image_2', newMoodboard.image_2)
      fd.append('image_3', newMoodboard.image_3)
      fd.append('layout', newMoodboard.layout)
      fd.append('thumbnail', newMoodboard.thumbnail)
      fd.append('category', newMoodboard.category)
      createMoodboard(this.props.auth.token, fd)
        .then(response => {
          if (response.status) {

            MoodboardList(this.props.auth.token)
              .then(res => {
                if (res.status) {
                  this.setState({
                    moodBoardList: res.data,
                    open: false,
                    moodTypeValidate: false,
                    newMoodboard: {
                      mood_type: '',
                      size: '',
                      low_price: '',
                      high_price: '',
                      boq_value: '',
                      scope_of_work: '',
                      image_1: '',
                      image_2: '',
                      image_3: '',
                      layout: '',
                      thumbnail: '',
                      category: ''
                    }
                  })
                }
              })
              .catch(e => console.log(e))
          }
        })
        .catch(e => console.log(e))
    }
  }

  onSubmitEditForm = (e) => {
    e.preventDefault();
    let editMoodboard = { ...this.state.editMoodboard };
    if (editMoodboard.type === '') {
      this.setState({ moodTypeValidate: true })
    } else {
      const fd = new FormData();
      fd.append('id', editMoodboard.id)
      fd.append('mood_type', editMoodboard.mood_type)
      fd.append('size', editMoodboard.size)
      fd.append('low_price', editMoodboard.low_price)
      fd.append('high_price', editMoodboard.high_price)
      fd.append('boq_value', editMoodboard.boq_value)
      fd.append('scope_of_work', editMoodboard.scope_of_work)
      fd.append('image_1', editMoodboard.image_1)
      fd.append('image_2', editMoodboard.image_2)
      fd.append('image_3', editMoodboard.image_3)
      fd.append('layout', editMoodboard.layout)
      fd.append('thumbnail', editMoodboard.thumbnail)
      fd.append('category', editMoodboard.category)
      updateMoodboard(this.props.auth.token, fd)
        .then(response => {
          if (response.status) {

            MoodboardList(this.props.auth.token)
              .then(res => {
                if (res.status) {
                  this.setState({
                    moodBoardList: res.data,
                    openDialog3: false,
                    moodTypeValidate: false,
                    newMoodboard: {
                      mood_type: '',
                      size: '',
                      low_price: '',
                      high_price: '',
                      boq_value: '',
                      scope_of_work: '',
                      image_1: '',
                      image_2: '',
                      image_3: '',
                      layout: '',
                      thumbnail: '',
                      category: ''
                    }
                  })
                }
              })
              .catch(e => console.log(e))
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  deleteMoodBoardItem = (id) => {
    deleteMoodboard(this.props.auth.token, id)
      .then(res => {
        if (res.status) {
          MoodboardList(this.props.auth.token)
            .then(res => {
              if (res.status) {
                this.setState({ moodBoardList: res.data })
              }
            })
            .catch(e => console.log(e))
        }
      })
      .catch(e => console.log(e))
  }

  onDrop = (e) => {
    let newMoodboard = this.state.newMoodboard;
    newMoodboard.image_1 = e.target.files[0]
    newMoodboard.image_2 = e.target.files[1]
    newMoodboard.image_3 = e.target.files[2]
    newMoodboard.layout = e.target.files.length
    this.setState({ newMoodboard })
  }

  enterTumbnail = (e) => {
    let newMoodboard = this.state.newMoodboard;
    newMoodboard.thumbnail = e.target.files[0]
    this.setState({ newMoodboard })
  }

  onDropEdit = (e) => {
    let editMoodboard = this.state.editMoodboard;
    editMoodboard.image_1 = e.target.files[0]
    editMoodboard.image_2 = e.target.files[1]
    editMoodboard.image_3 = e.target.files[2]
    editMoodboard.layout = e.target.files.length
    this.setState({ editMoodboard })
  }

  onThumbnailEdit = (e) => {
    let editMoodboard = this.state.editMoodboard;
    editMoodboard.thumbnail = e.target.files[0]
    this.setState({ editMoodboard })
  }

  snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, snackMessage: '' });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  onSelectAllClick = () => {
    let { moodBoardList, selected } = this.state;
    let newSelected = [];
    if (moodBoardList.length !== selected.length) {
      for (let i = 0; i < moodBoardList.length; i++) {
        newSelected = newSelected.concat(moodBoardList[i].id);
      }
    }
    this.setState({
      selected: newSelected,
    })
  }

  openContactDialog = () => {
    this.setState({ openDialog2: true })
  }

  handleCloseDialog2 = () => {
    this.setState({ openDialog2: false })
  }

  handleChange = name => event => {
    let newMoodboard = { ...this.state.newMoodboard };
    if (event.target.checked) {
      newMoodboard.boq_value = ""
      this.setState({ [name]: event.target.checked, newMoodboard });
    } else {
      newMoodboard.high_price = "";
      newMoodboard.low_price = "";
      this.setState({ [name]: event.target.checked, newMoodboard });
    }
  };

  handleChangeEditSwitch = name => event => {
    let editMoodboard = { ...this.state.editMoodboard };
    if (event.target.checked) {
      editMoodboard.boq_value = ""
      this.setState({ [name]: event.target.checked, editMoodboard });
    } else {
      editMoodboard.high_price = "";
      editMoodboard.low_price = "";
      this.setState({ [name]: event.target.checked, editMoodboard });
    }
  };


  changeFilterHandler = (e, name = null) => {
    let filter = this.state.filter;
    if (name === null) {
      filter[e.target.name] = e.target.value;
    } else {
      filter[name] = e.value;
    }
    this.setState({ filter })
  }

  applyFilter = (e) => {
    e.preventDefault();
    let filter = this.state.filter;
    MoodboardList(this.props.auth.token, filter)
      .then(res => {
        if (res.status) {
          this.setState({
            moodBoardList: res.data,
            filterDialog: false
          })
        }
      })
      .catch(e => console.log(e))
  }

  filterSize = (value) => {
    let filter = { ...this.state.filter };
    filter.size = value
    this.setState({ filter })
  }

  filterPrice = (value) => {
    let filter = { ...this.state.filter };
    filter.price = value
    this.setState({ filter })
  }

  clearFilter = () => {
    let filter = this.state.filter;
    filter = {
      type: '',
      size: { min: 0, max: 100 },
      price: { min: 0, max: 500000 }
    }
    this.setState({
      filter
    })
    MoodboardList(this.props.auth.token)
      .then(res => {
        if (res.status) {
          this.setState({
            moodBoardList: res.data,
            filterDialog: false
          })
        }
      })
      .catch(e => console.log(e))
  }

  handleClickOpenDialog4 = (url) => {
    this.setState({
      previewDialog: true,
      previewUrl: url
    })
  }

  previewPpt = () => {
    console.log(window.location.href)
    if (this.state.slide_type === "slides") {
      console.log("priview ppt for bedroom");
      window.open(`/moodboard/slides/[${this.state.selected}]`)
    }
    if (this.state.slide_type === "curtains") {
      console.log("priview ppt for curtain");
      window.open(`/moodboard/curtains/[${this.state.selected}]`)
    }
  }


  onChangeRadio = e => {
    const slide_type = e.target.value;
    if (slide_type === "slides") {
      MoodboardList(this.props.auth.token)
        .then(res => {
          if (res.status) {
            console.log("api for bedroom");
            this.setState({ moodBoardList: res.data, slide_type })
          }
        })
        .catch(e => console.log(e))
    } else {
      MoodboardListForCurtains(this.props.auth.token)
        .then(res => {
          if (res.status) {
            console.log("api for curtain");
            this.setState({ moodBoardList: res.data, slide_type })
          }
        })
        .catch(e => console.log(e))
    }

    this.setState({ slide_type: e.target.value })
  }
  render() {
    const { slide_type } = this.state;
    const { classes } = this.props;
    const { newMoodboard, moodBoardList, selected, editMoodboard, filter } = this.state;
    const role_id = this.props.auth.current_user.office_role_id
    return (
      <div>
        <Typography variant="h4">
          Preset  &nbsp;
          
            <RadioGroup row aria-label="radioButtons" name="radio1" value={slide_type} onChange={this.onChangeRadio}>
              <FormControlLabel value="slides" control={<Radio color="primary" />} label="Arrivae" />
              <FormControlLabel value="curtains" control={<Radio color="primary" />} label="Curtain" />
            </RadioGroup>
          
        </Typography>

        { role_id === 9 &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.handleClickOpen()}>
            Add MoodBoPard Item
        </Button>}
        { selected.length !== 0 && role_id === 8 &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.openContactDialog()}>
            Generate PPT and Send To Client
          </Button>}
        { selected.length !== 0 &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.previewPpt()}>
            Preview Ppt
          </Button>}
        <div className="pull-right">
          <Typography variant="button" gutterBottom>
            Filter :
            <Tooltip title="Apply Filter">
              <IconButton aria-label="Apply Filter"
                onClick={this.FilterDialogOpen}>
                <FilterIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </div>

        { moodBoardList.length !== 0 &&
          <Paper className="user-list-root">
            <Table className="user-list-table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={moodBoardList.length === selected.length ? true : false}
                      onChange={this.onSelectAllClick}
                    />
                  </TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Id</TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Type</TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Size</TableCell>
                  {/* <TableCell numeric style={{textAlign: 'left'}}>Low Price</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>High Price</TableCell> */}
                  <TableCell numeric style={{ textAlign: 'left' }}>Category</TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Boq Value</TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Scope of work</TableCell>
                  <TableCell numeric style={{ textAlign: 'left' }}>Preview</TableCell>
                  {this.props.client_id === 'data_entry' && role_id === 9 && <TableCell numeric style={{ textAlign: 'left' }}>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {moodBoardList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow key={n.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onClick={event => this.handleClick(event, n.id)} />
                      </TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.id}</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.mood_type}</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.size}</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.category}</TableCell>
                      {/* <TableCell numeric style={{textAlign: 'left'}}>{n.low_price}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.high_price}</TableCell> */}
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.boq_value}</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>{n.scope_of_work}</TableCell>
                      <TableCell numeric style={{ textAlign: 'left' }}>
                        <Tooltip title="Preview">
                          <IconButton aria-label="Preview"
                            onClick={() => this.handleClickOpenDialog4(n.thumbnail.url)}>
                            <SlideShowIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {this.props.client_id === 'data_entry' && role_id === 9 && <TableCell numeric style={{ textAlign: 'left' }}>
                        <Tooltip title="Edit Details">
                          <IconButton aria-label="Edit Details"
                            onClick={() => this.handleClickOpenDialog3(n)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            variant="fab"
                            color="secondary"
                            aria-label="Delete"
                            onClick={() => this.deleteMoodBoardItem(n.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={8}
                    count={moodBoardList.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationWrapper}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        }

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <ValidatorForm
            className={classes.form}
            onSubmit={this.onSubmit} >
            <DialogTitle id="form-dialog-title">Add MoodBoard Item</DialogTitle>
            <DialogContent>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Type
                </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="mood_type"
                  classes={classes}
                  options={options}
                  value={{ value: newMoodboard.mood_type, label: newMoodboard.mood_type }}
                  onChange={(e) => this.changeHandler(e, 'mood_type')}
                />
                {this.state.moodTypeValidate &&
                  <p className={classes.validate}>this filed is required</p>
                }
              </FormControl>

              <FormControl required fullWidth>
                <TextValidator
                  label="Size"
                  className={classes.textField}
                  name="size"
                  value={newMoodboard.size}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Category
                </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="category"
                  classes={classes}
                  options={categories}
                  value={{ value: newMoodboard.category, label: newMoodboard.category }}
                  onChange={(e) => this.changeHandler(e, 'category')}
                />
                {/* { this.state.categoryTypeValidate && 
                  <p className={classes.validate}>this filed is required</p>
                } */}
              </FormControl>
              {/* <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.checkedA}
                      onChange={this.handleChange('checkedA')}
                      value="checkedA"
                    />
                  }
                  label={this.state.checkedA? "Price Range": "BOQ Value"}
                />
              </FormGroup> */}
              {this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="Low Price"
                  className={classes.textField}
                  name="low_price"
                  value={newMoodboard.low_price}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              {this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="High Price"
                  className={classes.textField}
                  name="high_price"
                  value={newMoodboard.high_price}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              {!this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="BOQ Value"
                  className={classes.textField}
                  name="boq_value"
                  value={newMoodboard.boq_value}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  id="multiline-static"
                  label="Scope Of work"
                  multiline
                  rows="6"
                  className={classes.textField}
                  margin="normal"
                  name="scope_of_work"
                  value={newMoodboard.scope_of_work}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>
              <InputLabel shrink htmlFor="age-label-placeholder">
                Enter Files (max 3)*
                </InputLabel>
              <ThemeProvider>
                <Button variant="contained"
                  containerElement="label"
                  icon={<AttachmentIcon />}
                  primary
                  style={{ minWidth: 40, width: 40 }}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={this.onDrop} multiple />
                </Button>
              </ThemeProvider>

              <br /> <br />

              <InputLabel shrink htmlFor="age-label-placeholder">
                Enter Thumbnails*
                </InputLabel>
              <ThemeProvider>
                <Button variant="contained"
                  containerElement="label"
                  icon={<SlideShowIcon />}
                  secondary
                  style={{ minWidth: 40, width: 40 }}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={this.enterTumbnail} />
                </Button>
              </ThemeProvider>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        {/* ===============Contact dialog================ */}
        <Dialog
          open={this.state.openDialog2}
          onClose={this.handleCloseDialog2}
          aria-labelledby="form-dialog-title"
        >
          <ValidatorForm
            className={classes.form}
            onSubmit={this.generatePPT} >
            <DialogTitle id="form-dialog-title">Send SMS to Client</DialogTitle>
            <DialogContent>

              {this.state.designer_id === "" &&
                <div className="alert alert-warning" role="alert">
                  No Designer Found. Invalid URL. No SMS send to client.
                </div>
              }

              <InputLabel shrink htmlFor="age-label-placeholder">
                Contact No
                </InputLabel>
              <InputLabel htmlFor="age-label-placeholder">
                +91 XXXXXXXXXX
                </InputLabel>

              {/* <FormControl required fullWidth>
                <TextValidator
                  label="Mobile Number"
                  className={classes.textField}
                  name="contact"
                  value={this.state.contact}
                  onChange={(e) => this.setState({contact: e.target.value})}
                  validators={['required','matchRegexp:^\\d{10}$']}
                  errorMessages={['this field is required', 'Mobile Number']}
                />
              </FormControl> */}

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog2} color="primary">
                Cancel
              </Button>
              {this.state.designer_id !== "" &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary">
                  Send
              </Button>}
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        {/* ====================EDIT DIALOG======================= */}
        <Dialog
          open={this.state.openDialog3}
          onClose={this.handleCloseEditDialog}
          aria-labelledby="form-dialog-title"
        >
          <ValidatorForm
            className={classes.form}
            onSubmit={this.onSubmitEditForm} >
            <DialogTitle id="form-dialog-title">Edit MoodBoard Item</DialogTitle>
            <DialogContent>
              <div className="alert alert-warning" role="alert">
                Updating the data will affect the filter. It will not affect the slide. <br />
              To update the slide kindly update <strong>Thumbnails Image</strong>
              </div>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Type
                </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="mood_type"
                  classes={classes}
                  options={options}
                  value={{ value: editMoodboard.mood_type, label: editMoodboard.mood_type }}
                  onChange={(e) => this.changeEditHandler(e, 'mood_type')}
                />
                {this.state.moodTypeValidate &&
                  <p className={classes.validate}>this filed is required</p>
                }
              </FormControl>


              <FormControl required fullWidth>
                <TextValidator
                  label="Size"
                  className={classes.textField}
                  name="size"
                  value={editMoodboard.size}
                  onChange={(e) => this.changeEditHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Category
                </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="category"
                  classes={classes}
                  options={categories}
                  value={{ value: editMoodboard.category, label: editMoodboard.category }}
                  onChange={(e) => this.changeEditHandler(e, 'category')}
                />
                {/* { this.state.moodTypeValidate && 
                  <p className={classes.validate}>this filed is required</p>
                } */}
              </FormControl>

              {/* <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.checkedA}
                      onChange={this.handleChangeEditSwitch('checkedA')}
                      value="checkedA"
                    />
                  }
                  label={this.state.checkedA? "Price Range": "BOQ Value"}
                />
              </FormGroup> */}
              {this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="Low Price"
                  className={classes.textField}
                  name="low_price"
                  value={editMoodboard.low_price}
                  onChange={(e) => this.changeEditHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              {this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="High Price"
                  className={classes.textField}
                  name="high_price"
                  value={editMoodboard.high_price}
                  onChange={(e) => this.changeEditHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              {!this.state.checkedA && <FormControl required fullWidth>
                <TextValidator
                  label="BOQ Value"
                  className={classes.textField}
                  name="boq_value"
                  value={editMoodboard.boq_value}
                  onChange={(e) => this.changeEditHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>}
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  id="multiline-static"
                  label="Scope Of work"
                  multiline
                  rows="6"
                  className={classes.textField}
                  margin="normal"
                  name="scope_of_work"
                  value={editMoodboard.scope_of_work}
                  onChange={(e) => this.changeEditHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </FormControl>

              <InputLabel shrink htmlFor="age-label-placeholder">
                Enter New Files (max 3)
                </InputLabel>
              <ThemeProvider>
                <Button variant="contained"
                  containerElement="label"
                  icon={<AttachmentIcon />}
                  primary
                  style={{ minWidth: 40, width: 40 }}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={this.onDropEdit} multiple />
                </Button>
              </ThemeProvider>

              <br /> <br />

              <InputLabel shrink htmlFor="age-label-placeholder">
                Enter Thumbnails
                </InputLabel>
              <ThemeProvider>
                <Button variant="contained"
                  containerElement="label"
                  icon={<SlideShowIcon />}
                  secondary
                  style={{ minWidth: 40, width: 40 }}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={this.onThumbnailEdit} />
                </Button>
              </ThemeProvider>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseEditDialog} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        {/* ===========FILTER DIALOG=============== */}

        <Dialog
          open={this.state.filterDialog}
          onClose={this.FilterDialogClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'xs'}
        >
          <ValidatorForm
            className={classes.form}
            onSubmit={this.applyFilter} >
            <DialogTitle id="form-dialog-title">Filters</DialogTitle>
            <DialogContent >

              <FormControl margin="normal" fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Type
              </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="mood_type"
                  classes={classes}
                  options={options}
                  value={{ value: filter.mood_type, label: filter.mood_type }}
                  onChange={(e) => this.changeFilterHandler(e, 'mood_type')}
                />
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Category
              </InputLabel>
                <br />
                <Select
                  styles={{ zIndex: 999 }}
                  name="category"
                  classes={classes}
                  options={categories}
                  value={{ value: filter.category, label: filter.category }}
                  onChange={(e) => this.changeFilterHandler(e, 'category')}
                />
              </FormControl>
              <div style={{ width: '270px' }}>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Size
              </InputLabel> <br /> <br />
                <InputRange
                  maxValue={2000}
                  minValue={0}
                  value={this.state.filter.size}
                  onChange={value => this.filterSize(value)} /> <br /> <br />

                <InputLabel shrink htmlFor="age-label-placeholder">
                  Price Range
              </InputLabel> <br /> <br />
                <InputRange
                  maxValue={1000000}
                  minValue={0}
                  value={this.state.filter.price}
                  onChange={value => this.filterPrice(value)} /> <br /> <br />
              </div>


              {/* {<FormControl fullWidth>
              <InputLabel shrink htmlFor="age-label-placeholder">
                  Price Range 
              </InputLabel> <br/> <br/>
              <InputRange
                maxValue={1000000}
                minValue={0}
                value={this.state.filter.price}
                onChange={value => this.filterBoqPrice(value)} /> <br/> <br/>
            </FormControl>} */}

            </DialogContent>
            <DialogActions>
              <Button onClick={this.clearFilter} color="primary">
                Clear Filter
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Apply Filter
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        {/* =========PREVIEW DIALOG============== */}

        <Dialog
          open={this.state.previewDialog}
          onClose={() => this.setState({ previewDialog: false })}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle id="form-dialog-title">Preview</DialogTitle>
          <DialogContent>
            {this.state.previewUrl &&
              <img src={this.state.previewUrl} height={'auto'} width={'100%'} alt="Arrivae" />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ previewDialog: false })} color="primary">
              Cancel
              </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openSnack}
          autoHideDuration={3000}
          onClose={this.snackClose}

          message={
            <span className={classes.message}>
              {/* <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> */}
              {this.state.snackMessage}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.snackClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles), connect(mapStateToProps))(Preset);