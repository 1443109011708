
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UsersList from './UsersList';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Menu, MenuItem } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ActivityLog from './ActivityLog';
import Moodboard from './Moodboard';
import Tooltip from '@material-ui/core/Tooltip';  
import {
  Switch,
  Route,
} from 'react-router-dom';
import NotFound from '../../components/NotFound';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import compose from 'recompose/compose';
import { connect } from 'react-redux'; 
import Profile from '../Shared/Profile';
import OmCmMapping from './ProjectsUsersMapping/OmCmMapping';
import OmPmMapping from './ProjectsUsersMapping/OmPmMapping';
import PmSsMapping from './ProjectsUsersMapping/PmSsMapping';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
});

class AdminDashboard extends React.Component {
  state = {
    open: false,
    navId: 1,
    mappingOption: false
  };

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }


  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    localStorage.clear();
    document.location.reload(true);
  }

  navigationChange(navId) {
    this.setState({navId: navId});
  }
  render() {
    const { classes } = this.props;
    const { mappingOption } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
                { this.activeRoute('users') && 'Users List' }
                { this.activeRoute('activity-logs') && 'Activity Logs' }
                { this.activeRoute('moodboard') && 'Moodboard' }
                {this.activeRoute('mapping/om-cm') && 'Operational Manager and Community Manager Mapping'}
                { this.activeRoute('mapping/om-pm') && 'Operational Manager and Project Manager Mapping' }
                { this.activeRoute('mapping/pm-ss') && 'Project Manager and Site Supervisor Mapping' }
              </Typography>

              <Typography color="inherit">
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    { this.props.auth.current_user.name || "User" }
                  </Typography> <br />
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ margin: '0px' }}
                    color="white"
                  >
                    Admin
                  </Typography>
                </React.Fragment>
              </Typography>
              <Typography color="inherit">
                <Button color="inherit" onClick={this.logout} className={classes.button}>
                    <Tooltip title="Logout">
                      <PowerIcon style={{ color: 'white', '&:hover': { background: 'black' } }} />
                    </Tooltip>
                  </Button>
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem selected={this.activeRoute('users')} button onClick={() => this.props.history.push('/admin/users')}>
                <Tooltip title="Users">
                  <ListItemIcon>
                    <PeopleIcon style={{ color: this.activeRoute('users') ? '#007bff': '#868e95'}} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem selected={this.activeRoute('activity-logs')} button onClick={() => this.props.history.push('/admin/activity-logs')}>
                <Tooltip title="Activity Logs">
                  <ListItemIcon>
                    <ListIcon style={{ color: this.activeRoute('activity-logs') ? '#007bff': '#868e95'}} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Activity Logs" />
              </ListItem>
              <ListItem selected={this.activeRoute('moodboard')} button onClick={() => this.props.history.push('/admin/moodboard')}>
                <Tooltip title="Moodboard">
                  <ListItemIcon>
                    <SlideshowIcon style={{ color: this.activeRoute('moodboard') ? '#007bff': '#868e95'}} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Moodboard" />
              </ListItem>

              <ListItem selected={this.activeRoute('mapping')} button onClick={() => this.setState({ mappingOption: true })}>
                <Tooltip title="Projects Users Mapping">
                  <ListItemIcon>
                    <SettingsEthernetIcon style={{ color: this.activeRoute('mapping') ? '#007bff': '#868e95'}} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Projects Users Mapping" />
              </ListItem>

            </List>
            <Menu
              keepMounted
              open={mappingOption}
              onClose={() => this.setState({ mappingOption: false })}
            >
              <MenuItem onClick={() => { this.props.history.push('/admin/mapping/om-cm'); this.setState({ mappingOption: false })}}>{'OM <=> CM'}</MenuItem>
              <MenuItem onClick={() => { this.props.history.push('/admin/mapping/om-pm'); this.setState({ mappingOption: false })}}>{'OM <=> PM'}</MenuItem>
              <MenuItem onClick={() => { this.props.history.push('/admin/mapping/pm-ss'); this.setState({ mappingOption: false })}}>{'PM <=> SS'}</MenuItem>
            </Menu>
            <List style={{ position: 'absolute', bottom: '0' }}>
            <Divider/>
              <ListItem selected={this.activeRoute('profile')} button onClick={() => this.props.history.push('/admin/profile')}>
                <Tooltip title="Profile">
                  <ListItemIcon>
                    {this.props.auth.current_user.name ? <Avatar className={classes.purpleAvatar}>{this.props.auth.current_user.name[0].toUpperCase()}</Avatar> : <PersonIcon style={{ color: this.activeRoute('profile')? '#007bff': '#868e95'}}/>}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={ this.props.auth.current_user.name || "User Profile" }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={{ display: 'inline' }}
                        color="textPrimary"
                      >
                        Admin
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
              
            <Route
              path={"/admin"}
              render={({ match: { url } }) => {
                return (
                  <Switch>
                    <Route path={url + '/profile'} component={Profile}/>
                    <Route path={url + '/users'} component={UsersList}/>
                    <Route path={url + '/activity-logs'} component={ActivityLog} />
                    <Route path={url + '/moodboard'} component={Moodboard} />
                    <Route exact path={url + '/mapping/om-cm'} component={OmCmMapping} />
                    <Route exact path={url + '/mapping/om-pm'} component={OmPmMapping} />
                    <Route exact path={url + '/mapping/pm-ss'} component={PmSsMapping} />
                    <Route path={url + '/mapping'} component={OmCmMapping} />
                    <Route component={NotFound} />
                  </Switch>
                )}
              }
            />
          </main>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(AdminDashboard);