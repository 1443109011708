import React, { Component } from 'react';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../../Loader';
import { AssignVendorToProject, getAssignedVendorToProject, StartProject } from '../../../../api'
import { connect } from 'react-redux';
import { Dialog, DialogContent, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import Swal from 'sweetalert2';

class AssignVendor extends Component {
  state = {
    isLoading: false,
    VendorList: [],
    isMapLoading: false,
    showDialog: false,
    actionDialog: false,
    selectAllCheckbox: false,
    vendor_id: null,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await getAssignedVendorToProject(this.props.token, this.props.project.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            VendorList: res.data,
            isLoading: false
          })
          console.log("vendorCheck", res.data)
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false })
      })
  }

  assignVendor = () => {
    this.toggleActionDialog()
    this.setState({ isLoading: true })
    const data = {
      vendor_id: this.state.vendor_id,
      project_id: this.props.project.id
    }
    AssignVendorToProject(this.props.token, data, this.state.selectAllCheckbox)
      .then(res => {
        if (res.status === 200) {
          getAssignedVendorToProject(this.props.token, this.props.project.id)
            .then(res => {
              if (res.status === 200) {
                this.setState({
                  VendorList: res.data,
                  isLoading: false
                })
                Swal.fire('Success', 'Vendor assignment updated', 'success')
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ isLoading: false })
            })
          this.props.getVendorOptionList()

        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        })
      })
  }

  unassignVendor = (vendor_id) => {
    this.setState({ isLoading: true })
    const data = {
      vendor_id: vendor_id,
      project_id: this.props.project.id
    }
    AssignVendorToProject(this.props.token, data, this.state.selectAllCheckbox)
      .then(res => {
        if (res.status === 200) {
          getAssignedVendorToProject(this.props.token, this.props.project.id)
            .then(res => {
              if (res.status === 200) {
                this.setState({
                  VendorList: res.data,
                  isLoading: false
                })
                Swal.fire('Success', 'Vendor assignment updated', 'success')
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ isLoading: false })
            })
          this.props.getVendorOptionList();
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        })
      })
  }

  projectStartButton = (vendor_id) => {
    this.setState({ isLoading: true })
    console.log(this.props.token, vendor_id, this.props.project.id, "start project data")
    StartProject(this.props.token, vendor_id, this.props.project.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isLoading: false
          })
          if (res.data && res.data.message === 'Task not present') {
            Swal.fire('Error', 'Task not present', 'error')
          }
          else {
            Swal.fire('Success', res.data && res.data.message, 'success')
            this.componentDidMount();
          }
          this.props.getVendorOptionList();
          this.props.componentDidMount();
        }

      })
      .catch(error => {
        console.log("error", error)
        if (error.status != 200) {
          // Swal.fire('Error', error.data && error.data.message, 'error')
          this.setState({
            isLoading: false
          })
        }
      })
  }

  toggleActionDialog = (id) => {
    this.setState({ actionDialog: !this.state.actionDialog, vendor_id: id });
  };

  CheckboxHandler = () => {
    this.setState({ selectAllCheckbox: !this.state.selectAllCheckbox });
  }


  render() {
    const { VendorList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Assign Vendor"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Address', field: 'address' },
              { title: 'City', field: 'city' },
              { title: 'Contact Person', field: 'contact_person' },
              { title: 'Contact', field: 'contact_number' },
              { title: 'Email', field: 'email' },
              { title: 'Type', field: 'type' },
              { title: 'No. Active Projects', field: 'active_projects' },
              {
                title: 'Is Assigned',
                field: 'is_assigned',
                render: rowData => rowData.is_assigned ?
                  <Button
                    onClick={() => { this.unassignVendor(rowData.id) }}
                    color="secondary"
                    variant="contained"
                    size="small"
                  >
                    Unassign
                  </Button>
                  :
                  <Button
                    onClick={() => { this.toggleActionDialog(rowData.id) }}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Assign
                  </Button>,
              },
              {
                title: 'Project Status',
                field: 'is_assigned',
                render: rowData => rowData.is_assigned &&
                  <Button
                    onClick={() => { this.projectStartButton(rowData.id) }}
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={rowData.start}
                  >
                    {rowData.start ? 'Already Started' : 'Start Project'}

                  </Button>
              }
            ]}
            data={VendorList}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true,
              pageSize: 20
            }}
          />
        }
        <Dialog
          open={this.state.actionDialog}
          onClose={() => { this.toggleActionDialog() }}
          aria-labelledby="action-dialog"
          fullWidth={true}
          maxWidth={'xs'}
        >
          <DialogContent className="p-4">
            <h5>Assigned/Not Assigned for all tasks</h5>
            <FormControlLabel
              control={<Checkbox checked={this.state.selectAllCheckbox} onChange={() => this.CheckboxHandler()} />}
              label="All tasks"
            />

            <Button className="mt-3 d-block mx-auto" color="primary" variant="contained" onClick={() => this.assignVendor()} >Submit</Button>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default connect(mapStateToProps)(AssignVendor);