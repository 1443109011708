import axios from '../base';

export function getSupervisorSignInList(accessToken){
  return axios.request({
    url: `/api/v1/office_attendances`,
    method: 'GET',
    headers: accessToken
  })
}

export function getAddress(lat,long){
  return axios.request({
    url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyD3YPek3ha13VBetnfdCCslQA41ngV8_7Y`,
    method: 'GET'
  })
}