import axios from '../base';

export function getClientMeetingMomList(accessToken, projectId){
  return axios.request({
    url: `/api/v1/office_projects/client_meeting_mom/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
}

