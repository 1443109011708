import axios from '../base';
import { func } from 'prop-types';

export function createProject(accessToken, data) {
  return axios.request({
    url: '/api/v1/office_projects',
    method: 'POST',
    headers: accessToken,
    data: data
  })
};

export function getProjectList(accessToken) {
  return axios.request({
    url: '/api/v1/office_projects',
    method: 'GET',
    headers: accessToken
  })
};

export function updateProject(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
};

export function disableProject(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/disable_project/${projectId}`,
    method: 'POST',
    headers: accessToken
  })
};

export function disableProjectSiteSupervisor(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/disable_project_site_supervisor`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
};


export function getProjectsStats(accessToken) {
  return axios.request({
    url: '/api/v1/office_projects/projects_stat',
    method: 'GET',
    headers: accessToken
  })
};

export function getResidentialProjects(accessToken) {
  return axios.request({
    url: '/api/v1/office_projects/residential_projects_for_pm/50_percent',
    method: 'GET',
    headers: accessToken
  })
};

export function getResidentialProjectsForPM(accessToken, project_status, page, limit, search) {
  return axios.request({
    url: `/api/v1/office_projects/residential_projects_for_pm/${project_status}/?page=${page}&limit=${limit}&search=${search}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getProjectBoqs(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/get_project_boqs/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getProjectFinalBoqs(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/get_project_final_boqs/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
};

export function getBoqDetails(accessToken, boqId) {
  return axios.request({
    url: `/api/v1/office_projects/get_boq_details/${boqId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function getPurchaseOrder(accessToken, boqId) {
  return axios.request({
    url: `/api/v1/office_projects/get_purchase_orders/${boqId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function getMaterialTracking(accessToken, boqId) {
  return axios.request({
    url: `/api/v1/office_projects/get_material_tracking/${boqId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function getClientPayments(accessToken, boqId) {
  return axios.request({
    url: `/api/v1/office_projects/get_client_payment_details/${boqId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function getMeasurementRequests(accessToken, projectId, site_measurement_list_status, page) {
  return axios.request({
    url: `/api/v1/office_projects/get_site_measurement_requests/${projectId}`,
    method: 'GET',
    headers: accessToken,
    params: {
      site_measurement_list_status: site_measurement_list_status,
      page: page || 1,
    }
  })
}

export function getFilterMeasurementRequests(accessToken, projectId, site_measurement_list_status, page, search, request_status_filter, fromFiltDate, toFiltDate, cm, designer_id, site_measurement_type, site_supervisor, request_done_by) {
  return axios.request({
    url: `/api/v1/office_projects/get_site_measurement_requests/${projectId}?request_status=${request_status_filter}&from_date=${fromFiltDate}&to_date=${toFiltDate}&search_community_manager=${cm}&site_measurement_type=${site_measurement_type}&search_sitesupervisor=${site_supervisor}&request_done=${request_done_by}&search_designers=${designer_id}`,
    method: 'GET',
    headers: accessToken,
    params: {
      site_measurement_list_status: site_measurement_list_status,
      page: page || 1,
      search: search
    }
  })
}

export function assignSupervisor(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/assign_site_measurement_supervisor`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
}

export function getProjectFiles(accessToken, projectId, filesFrom) {
  return axios.request({
    url: `/api/v1/office_projects/get_project_files/${projectId}?files_from=${filesFrom}`,
    method: 'GET',
    headers: accessToken
  })
}

export function createMeasurementFiles(accessToken, requestId, data) {
  return axios.request({
    url: `/api/v1/office_projects/measurement_request_file_upload/${requestId}`,
    method: 'POST',
    headers: accessToken,
    data: data
  })
}

export function getQCStatus(accessToken, projectId) {
  return axios.request({
    url: `/api/v1/office_projects/project_qc_status/${projectId}`,
    method: 'GET',
    headers: accessToken
  })
}

export function updateOfficeProjectHandover(accessToken, data) {
  return axios.request({
    url: `/api/v1/office_projects/update_office_project_handover/${data.id}`,
    method: 'PUT',
    headers: accessToken,
    data: data
  })
}

export function handOverSubmit(assessToken, data, remark, project_id) {
  return axios.request({
    url: `/api/v1/office_projects/handover_submit`,
    method: 'PUT',
    headers: assessToken,
    data: data,
    params: {
      remark: remark,
      project_id: project_id,
    }
  })
}

export function getVisitsByPE(accessToken, pe_id, visit_status) {
  return axios.request({
    url: `/api/v1/office_projects/visits_list/${pe_id}`,
    method: 'GET',
    headers: accessToken,
    params: {
      visit_status: visit_status
    }
  })
}
export function getRoles(accessToken) {
  return axios.request({
    url: `/api/v1/office_projects/office_user_role_list`,
    method: 'GET',
    headers: accessToken,

  })
}

export function getDynamicFilterDropdowns(accessToken, request_status) {
  return axios.request({
    url: `/api/v1//office_projects/site_measurement_dynamic_filter/`,
    method: 'GET',
    headers: accessToken,
    params: {
      site_measurement_list_status: request_status,
    }
  })
}

export function getExportFilterReport(accessToken, projectId, site_measurement_list_status, search, request_status_filter, fromFiltDate, toFiltDate, cm, designer_id, site_measurement_type, site_supervisor, request_done_by) {
  return axios.request({
    url: `/api/v1/office_projects/site_measurement_export/${projectId}?request_status=${request_status_filter}&from_date=${fromFiltDate}&to_date=${toFiltDate}&search_community_manager=${cm}&site_measurement_type=${site_measurement_type}&search_sitesupervisor=${site_supervisor}&request_done=${request_done_by}&search_designers=${designer_id}`,
    method: 'GET',
    headers: accessToken,
    params: {
      site_measurement_list_status: site_measurement_list_status,
      search: search
    }
  })
}
