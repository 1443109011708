import axios from './base';

export function login(username, password) {
  return axios.request({
    url: '/office_auth/sign_in',
    method: 'POST',
    data: {
      'email': username,
      'password': password
    }
  })
};

export function validateToken(accessToken) {
  return axios.request({
    url: '/office_auth/validate_token',
    method: 'GET',
    headers: accessToken
  })
};

export function signup(data) {
  return axios.request({
    url: '/office_auth',
    method: 'POST',
    data: data
  })
};

export function forgotPassword(email) {
  return axios.request({
    // url: '/office_auth/password',
    url: '/api/v1/office_users/reset_password_and_send_mail',
    method: 'POST',
    data: { 'email': email, 'redirect_url': 'https://office.arrivae.com/forgot-password-confirmation' }
  })
}

export function updatePassword(password, passwordConfirm, params) {
  return axios.request({
    url: '/office_auth/password',
    method: 'PUT',
    headers: { 'access-token': params['access-token'], 'uid': params['uid'], 'client': params['client'], 'token-type': 'Bearer' },
    data: {
      'password': password,
      'password_confirmation': passwordConfirm
    }
  })
}