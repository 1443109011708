import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  getVisitsByPE
} from '../../api';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment-timezone';
import { Chip, Badge, Tooltip, Avatar } from '@material-ui/core';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  }
})

class SiteSupervisorVisits extends Component {
  state = {
    Visits: [],
    visit_status: 'upcoming'
  }

  componentDidMount() {
    getVisitsByPE(this.props.token, this.props.current_user.id, this.state.visit_status)
      .then(response => {
        if (response.status) {
          this.setState({ Visits: response.data })
        }
      })
      .catch(error => console.log(error))
  }

  getUpdatedVisits = (visit_status) => {

    getVisitsByPE(this.props.token, this.props.current_user.id, visit_status)
      .then(response => {
        if (response.status) {
          this.setState({ Visits: response.data, visit_status })
        }
      })
      .catch(error => console.log(error))
  }

  render() {
    const { Visits, visit_status } = this.state;
    return (
      <div>
        <MaterialTable
          title="Site Supervisors Visits"
          columns={[
            { title: 'Site Supervisor', field: 'office_user.name' },
            { title: 'Site Supervisor Contact', field: 'office_user.contact' },
            { title: 'Project', field: 'project.name' },
            {
              title: 'Scheduled Date',
              field: 'scheduled_datetime',
              render: rowData => rowData.scheduled_datetime !== null ? moment.tz(rowData.scheduled_datetime, 'Asia/Calcutta').format("ll") : null,
              type: 'date'
            },
          ]}
          data={Visits}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            exportButton: true,
            grouping: true,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100]
          }}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: '0px 10px' }}>
                  <Chip label="Upcoming Visits"
                    avatar={visit_status === 'upcoming' ? <Avatar>{Visits.length}</Avatar> : null}
                    color={visit_status === 'upcoming' ? "secondary" : 'default'}
                    onClick={() => this.getUpdatedVisits('upcoming')}
                    style={{ margin: 5 }} />
                  <Chip label="Past Visits"
                    avatar={visit_status === 'past' ? <Avatar>{Visits.length}</Avatar> : null}
                    color={visit_status === 'past' ? "secondary" : 'default'}
                    onClick={() => this.getUpdatedVisits('past')}
                    style={{ margin: 5 }} />
                  <Chip label="All Visits"
                    avatar={visit_status === 'all' ? <Avatar>{Visits.length}</Avatar> : null}
                    color={visit_status === 'all' ? "secondary" : 'default'}
                    onClick={() => this.getUpdatedVisits('all')}
                    style={{ margin: 5 }} />
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteSupervisorVisits);
