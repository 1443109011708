import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getResidentialProjectsForVendor } from '../../../api/VENDOR/project';
import CircularIndeterminate from '../../Loader';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Badge, Tooltip } from '@material-ui/core';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import moment from 'moment'
import { Grid, InputBase, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReactPaginate from "react-paginate";
import ClientIcon from '@material-ui/icons/AccountCircle';
import './ProjectList.scss';

// const styles = theme => ({
//   form: {
//     width: '100%',
//     marginTop: theme.spacing(),
//   },
//   appBar: {
//     position: 'relative',
//   },
//   button: {
//     margin: theme.spacing(1),
//   }
// })


const useStyles = theme => ({
  title: {
    fontFamily: 'Montserrat'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#a5a5a5',
  },
  inputInput: {
    fontWeight: '500',
    fontSize: '14px',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      border: '1px solid #ccc',
      borderRadius: '5px',
      '&:focus': {
        width: '26ch',
      },
    },
  },
  viewDetails: {
    border: '1px solid #a0a0a0'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  pills: {
    color: 'black',
    backgroundColor: '#e0e0e0',
    borderRadius: '14px',
    paddingLeft: '12px',
    padding: '8px 12px',
    cursor: 'pointer'
  },
  activePills: {
    color: '#fff',
    backgroundColor: '#c51162',
    borderRadius: '14px',
    paddingLeft: '12px',
    padding: '8px 12px',
    cursor: 'pointer'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResidentialProjects: [],
      isLoading: true,
      ProjectStatus: '50_percent',
      open: false,
      selectedProject: null,
      limit: 10,
      offset: this.props.location.pageNum ? this.props.location.pageNum : this.props.match.params.pageNum,
      activePage: this.props.location.pageNum ? 0 : this.props.match.params.pageNum ? this.props.match.params.pageNum - 1 : 0,
      pageCount: 2,
      search: '',
      tabNumber: 1,
    }
  }

  componentDidMount() {
    let HasLocation = window.location && window.location.hash && window.location.hash.split('#')[1].split('-');
    this.setState({
      tabNumber: HasLocation[0] ? eval(HasLocation[0]) : 1,
      ProjectStatus: HasLocation[1] ? HasLocation[1] : '50_percent',
      activePage: this.state.activePage ? this.state.activePage : 0,
      offset: this.state.offset ? this.state.offset : 1
    }, () => {
      this.getProjects(HasLocation[1] ? HasLocation[1] : '50_percent', '');
    });
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      let HasLocation = window.location && window.location.hash && window.location.hash.split('#')[1].split('-');
      this.setState({
        tabNumber: eval(HasLocation[0]),
        ProjectStatus: HasLocation[1],
        activePage: this.props.location.pageNum ? 0 : this.props.match.params.pageNum ? this.props.match.params.pageNum - 1 : 0,
        offset: this.props.location.pageNum ? this.props.location.pageNum : this.props.match.params.pageNum
      }, () => {
        this.getProjects(HasLocation[1], '');
      });
    }
  }

  getProjects = (status, search) => {
    console.log(this.state.offset);
    this.setState({ isLoading: true });
    getResidentialProjectsForVendor(this.props.token, status, this.state.offset, this.state.limit, search)
      .then(response => {
        if (response.status) {
          this.setState({
            ResidentialProjects: response.data.projects,
            isLoading: false,
            ProjectStatus: status,
            pageCount: response.data.total_pages
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        console.log(error)
      })
  }

  toggleDialog = () => {
    this.setState({ open: !(this.state.open) });
  };

  selectProject = (e, project) => {
    console.log("here is vendor");
    this.setState({ selectedProject: project });
    this.props.history.push({
      pathname: `/ve/projects/${project.id}?project_status=${this.state.ProjectStatus}`,
      // api/v1/office_projects/residential_projects_for_vendor/50_percent
      // /api/v1/office_updates/vendor_files_list/12372
      state: { offset: this.state.offset, tabNumber: this.state.tabNumber, project, ProjectStatus:this.state.ProjectStatus }
    })
  }

  setOffsetHandler = (e) => {
    console.log("page change")
    this.setState({
      activePage: e.selected,
      offset: e.selected + 1,
    }, () => {
      this.getProjects(this.state.ProjectStatus, '');
    });
    this.props.history.push(`/ve/projects-list/${e.selected + 1}#${this.state.tabNumber}-${this.state.ProjectStatus}`);
  }

  searchSubmitHandler = () => {
    this.setState({
      activePage: 0,
      offset: 1,
    }, () => {
      this.getProjects(this.state.ProjectStatus, this.state.search);
    });
    this.props.history.push(`/ve/projects-list/${1}`);
  }

  changeTab = (num, status) => {
    this.setState({
      tabNumber: num,
      ProjectStatus: status,
      activePage: 0,
      offset: 1,
      search: '',
    }, () => {
      this.getProjects(status, '');
    });
    this.props.history.push(`/ve/projects-list/${1}#${num}-${status}`);
  }

  render() {
    const { ResidentialProjects, isLoading, open, selectedProject } = this.state;
    let url = new URL(window.location.href)
    let project_status = url.searchParams.get('project_status') || '10_50_percent';
    const { classes } = this.props;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <Grid className={`bg-white br_5 p-3`}>
            <Typography variant="h6" className={`fw_6`}>Project List</Typography>
            <Grid className={`d-flex mb-3 mt-3 `}>
              <Grid className="d-flex">
                <Grid className={`${this.state.tabNumber === 1 ? classes.activePills : classes.pills} ml-2`} onClick={() => this.changeTab(1, '50_percent')}>
                  Current Project
                </Grid>
                <Grid className={`${this.state.tabNumber === 2 ? classes.activePills : classes.pills} ml-2`} onClick={() => this.changeTab(2, 'completed')}>
                  Completed Project
                </Grid>
              </Grid>

              <Grid className="ml-auto">
                <form onSubmit={this.searchSubmitHandler}>
                  <Grid className={classes.search}>
                    <Grid className={classes.searchIcon}>
                      <SearchIcon />
                    </Grid>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      value={this.state.search}
                      onChange={(e) => this.setState({ search: e.target.value })}
                    />
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={`mt-1`}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className="bg_f5f5f5">
                    <TableCell className="MontBold">Lead ID</TableCell>
                    <TableCell className="MontBold">Client Name</TableCell>
                    <TableCell className="MontBold">Client Contact</TableCell>
                    <TableCell className="MontBold">Designer</TableCell>
                    {
                      this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                        <TableCell className="MontBold" >No of PM</TableCell>
                        : ''
                    }

                    {
                      this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                        <TableCell className="MontBold" >Assigned PM</TableCell>
                        : ''
                    }

                    {
                      this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                        <TableCell className="MontBold" >No of SS</TableCell>
                        : ''
                    }

                    {
                      this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                        <TableCell className="MontBold" >Assigned SS</TableCell>
                        : ''
                    }

                    <TableCell className="MontBold">Address</TableCell>
                    <TableCell className="MontBold">City</TableCell>
                    <TableCell className="MontBold">Pincode</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.ResidentialProjects && this.state.ResidentialProjects.length > 0 ?
                      this.state.ResidentialProjects.map(rowData => (
                        <TableRow hover onClick={(e) => this.selectProject(e, rowData)} style={{ cursor: 'pointer' }}>
                          <TableCell className="cusTableCell">
                            <Grid className="d-flex align-items-center">
                              {rowData && rowData.lead_id && rowData.lead_id}
                            </Grid>
                          </TableCell>
                          <TableCell className="cusTableCell">
                            <Grid className="d-flex align-items-center">
                              {rowData && rowData.lead.name && rowData.lead.name}
                            </Grid>
                          </TableCell>
                          {/* <TableCell>
                            <Grid className="d-flex align-items-center">
                              {rowData && rowData.lead.email && rowData.lead.email}
                            </Grid>
                          </TableCell> */}
                          <TableCell>
                            <Grid className="d-flex align-items-center">
                              {rowData && rowData.lead && rowData.lead.contact && rowData.lead.contact}
                            </Grid>
                          </TableCell>
                          {/* <TableCell>
                            <Grid className="d-flex align-items-center">
                              {
                                rowData && rowData.assigned_cm && rowData.assigned_cm.name && rowData.assigned_cm.name
                              }
                            </Grid>
                          </TableCell> */}
                          <TableCell>
                            <Grid className="d-flex align-items-center">
                              {
                                rowData && rowData.assigned_designer && rowData.assigned_designer.name && rowData.assigned_designer.name
                              }
                            </Grid>
                          </TableCell>
                          <>
                            {
                              this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                                <TableCell>
                                  <Grid className="d-flex align-items-center">
                                    {

                                      <Tooltip title="Assigned Project Manager" aria-label="add">
                                        <Badge
                                          className={classes.button}
                                          color="secondary"
                                          badgeContent={
                                            rowData && rowData.assigned_project_manager && rowData.assigned_project_manager.length && rowData.assigned_project_manager.length
                                          }
                                          invisible={
                                            rowData && rowData.assigned_project_manager && rowData.assigned_project_manager.length > 0 ? false : true
                                          }
                                        >
                                          <ClientIcon
                                            color={
                                              rowData && rowData.assigned_project_manager && rowData.assigned_project_manager.length > 0 ? "primary" : "disabled"
                                            } />
                                        </Badge>
                                      </Tooltip>
                                    }
                                  </Grid>
                                </TableCell>
                                : ''
                            }
                          </>
                          <>
                            {
                              this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                                <TableCell>
                                  <Grid className="align-items-center">
                                    {
                                      rowData && rowData.assigned_project_manager && rowData.assigned_project_manager.length > 0 &&
                                      rowData.assigned_project_manager.map(item => (
                                        <Typography variant="div" component="div" key={item.id}>{item.name},</Typography>
                                      ))
                                    }
                                  </Grid>
                                </TableCell> : ''
                            }
                          </>

                          <>
                            {
                              this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                                <TableCell>
                                  <Grid className="d-flex align-items-center">
                                    {
                                      <Tooltip title="Assigned Site Supervisor" aria-label="add">
                                        <Badge
                                          className={classes.button}
                                          color="secondary"
                                          badgeContent={rowData && rowData.assigned_site_supervisor && rowData.assigned_site_supervisor.length}
                                          invisible={rowData.assigned_site_supervisor.length > 0 ? false : true}
                                        >
                                          <SupervisorIcon
                                            color={rowData && rowData.assigned_site_supervisor && rowData.assigned_site_supervisor.length > 0 ? "primary" : "disabled"} />
                                        </Badge>
                                      </Tooltip>
                                    }
                                  </Grid>
                                </TableCell>
                                : ''
                            }
                          </>

                          <>
                            {
                              this.state.tabNumber === 1 || this.state.tabNumber === 2 ?
                                <TableCell>
                                  <Grid className="align-items-center">
                                    {
                                      rowData && rowData.assigned_site_supervisor && rowData.assigned_site_supervisor.length > 0 ?
                                        rowData.assigned_site_supervisor.map(item => (
                                          <Typography variant="div" component="div" key={item.id}>{item.name},</Typography>
                                        )) : '-'
                                    }
                                  </Grid>
                                </TableCell> : ''
                            }
                          </>


                          {/* {
                            this.state.tabNumber === 2 || this.state.tabNumber === 3
                              || this.state.tabNumber === 4 ?
                              <TableCell className="MontBold" >
                                {
                                  rowData && rowData.date_of_first_40_percent_payment && rowData.date_of_first_40_percent_payment !== null ? moment(rowData.date_of_first_40_percent_payment).format('ll') : '-'
                                }
                              </TableCell>
                              : ''
                          } */}
                          <TableCell>
                            <Grid className="d-flex align-items-center">
                              {
                                rowData && rowData.lead && rowData.lead.address ? rowData.lead.address : '-'
                              }
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid className="d-flex align-items-center">
                              {rowData && rowData.lead && rowData.lead.city ? rowData.lead.city : '-'}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid className="d-flex align-items-center">
                              {
                                rowData && rowData.lead && rowData.lead.pincode ? rowData.lead.pincode : '-'
                              }
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan={this.state.tabNumber === 2 || this.state.tabNumber === 3
                          || this.state.tabNumber === 4 ? 14 : 9} className='text-center'>
                          No Data To Show
                        </TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Grid className="mt-5">
              <ReactPaginate
                previousLabel={<i className="fa fa-chevron-left" />}
                nextLabel={<i className="fa fa-chevron-right" />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                forcePage={this.state.activePage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(e) => this.setOffsetHandler(e)}
                containerClassName={"pagination mt-n4 justify-content-end"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </Grid>
          </Grid>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(useStyles),
  connect(mapStateToProps))(ProjectsList);