import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography } from '@material-ui/core';
import { getCustomerReportList, shareCustomerReport, createCustomerReport, getOfficeUpdateFilesList } from '../../../../api';
import CircularIndeterminate from '../../../Loader';
import ReactDropzone from "react-dropzone";
import FileIcon from '@material-ui/icons/AttachFile';
import MissingFile from '../../../../images/missing.png'

const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror: {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }

});


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};


const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  overflow: 'hidden',
};


const thumbInnerPreview = {
  display: 'flex',
  overflow: 'hidden',
  padding: '5px',
  width: '50vh',
  height: '50vh'
}

const imgPreview = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const imgClose = {
  position: 'absolute',
  top: '2px',
  left: '70px',
  zIndex: '100',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '5px',
  cursor: 'pointer',
  color: 'red',
  borderRadius: '50px'
}

const img = {
  display: 'block',
  width: '100px',
  height: '100%'
};


class CustomerReport extends Component {
  state = {
    CustomerReport: [],
    isLoading: true,
    isCreateLoading: false,
    openDialog: false,
    newCustomerReport: {
      remark: "",
      files: [], // files
      siteFiles: []
    },
    files: [], // files + preview
    filesDialog: false,
    filesList: [],
    SiteFilesRes: []
  };

  async componentDidMount() {
    if (this.props.project) {
      try {
        const CustomerReportRes = await getCustomerReportList(this.props.token, this.props.project.id)
        const SiteFilesRes = await getOfficeUpdateFilesList(this.props.token, this.props.project.id)
        this.setState({
          CustomerReport: CustomerReportRes.data,
          SiteFilesRes: SiteFilesRes.data,
          isLoading: false
        })
      } catch (error) {
        console.log(error)
        this.setState({ isLoading: false })
      }
    }
  }

  shareCustomerReportDetails = (reportId) => {
    this.setState({ isLoading: true })
    shareCustomerReport(this.props.token, reportId)
      .then(res => {
        if (res.status === 200) {
          getCustomerReportList(this.props.token, this.props.project.id)
            .then(response => {
              if (response.status) {
                this.setState({ CustomerReport: response.data, isLoading: false })
                alert("Report Shared with Client Sucessfully!")
              }
            })
            .catch(error => {
              console.log(error)
              this.setState({ isLoading: false })
            })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      })
  }

  handleChange = (e) => {
    let newCustomerReport = this.state.newCustomerReport;
    newCustomerReport.remark = e.target.value;
    this.setState({ newCustomerReport })
  }

  fileValidation = (data) => {
    const fileType = data[0].name.split(".").pop();
    const validImageTypes = ['gif', 'jpeg', 'jpg', 'png']
    if (!validImageTypes.includes(fileType)) {
      return false
    } else {
      return true
    }
  }

  onDrop = (new_files) => {
    if (this.fileValidation(new_files)) {
      let newCustomerReport = this.state.newCustomerReport;
      let files = this.state.files;
      new_files.map(file => {
        newCustomerReport.files.push(file)
        files.push({ file, preview: URL.createObjectURL(file) })
      }
      )
      this.setState({ newCustomerReport, files });
    } else {
      alert('This is not a image file!')
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isCreateLoading: true })
    let newCustomerReport = this.state.newCustomerReport;
    if (newCustomerReport.remark !== "") {

      const fd = new FormData();
      fd.append('office_customer_report[project_id]', this.props.project.id)
      fd.append('office_customer_report[remark]', newCustomerReport.remark)
      fd.append('office_customer_report[file_length]', newCustomerReport.files.length)
      fd.append('office_customer_report[site_files]', JSON.stringify(newCustomerReport.siteFiles))
      for (let i in newCustomerReport.files) {
        if (newCustomerReport.files.hasOwnProperty(i)) {
          fd.append(`office_customer_report[file][${parseInt(i) + 1}]`, newCustomerReport.files[i])
        }
      }
      createCustomerReport(this.props.token, fd)
        .then(res => {
          if (res.status === 200) {
            getCustomerReportList(this.props.token, this.props.project.id)
              .then(response => {
                if (response.status) {
                  this.setState({ CustomerReport: response.data, isCreateLoading: false, openDialog: false })
                }
              })
              .catch(error => {
                console.log(error)
                this.setState({ isCreateLoading: false })
              })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({ isCreateLoading: false })
        })
    } else {
      alert("Enter valid Remark")
      this.setState({ isCreateLoading: false })
    }

  }

  deleteImage = (index) => {
    let { newCustomerReport, files } = this.state;
    files = files.filter(i => i !== files[index])
    newCustomerReport.files = newCustomerReport.files.filter(i => i !== newCustomerReport.files[index]);
    this.setState({ files, newCustomerReport });
  }

  addSiteFileToReport = (file) => {
    let { newCustomerReport } = this.state;
    if (newCustomerReport.siteFiles.includes(file.id)) {
      var index = newCustomerReport.siteFiles.indexOf(file.id);
      newCustomerReport.siteFiles = newCustomerReport.siteFiles.filter(i => i !== newCustomerReport.siteFiles[index]);
    } else {
      newCustomerReport.siteFiles.push(file.id)
    }
    this.setState({
      newCustomerReport
    })
  }

  render() {
    const { classes } = this.props;
    const { CustomerReport, isLoading, openDialog, newCustomerReport, files, isCreateLoading, filesDialog, filesList, SiteFilesRes } = this.state;
    const thumbs = files.map((file, index) => (
      <div style={thumb} key={index}>
        <div style={{ position: 'relative', height: '100px', width: '100px' }}>
          <span style={imgClose} onClick={() => this.deleteImage(index)}>&times;</span>
          <img
            src={file.preview}
            style={img}
            alt="Arrivae"
          />
        </div>
      </div>
    ));
    return (
      <div>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Customer Report"
            columns={[
              { title: 'Remark', field: 'remark' },
              {
                title: 'Shared Date time',
                field: 'shared_datetime',
                render: rowData => rowData.shared_datetime !== null ? moment(rowData.shared_datetime).format("ll") : null,
                type: 'date'
              },
              {
                title: 'Created On',
                field: 'created_at',
                render: rowData => rowData.created_at !== null ? moment(rowData.created_at).format("ll") : null,
                type: 'date'
              },
              // {
              //   title: 'Files',
              //   field: 'files',
              //   render: rowData => {
              //     return (
              //       <Tooltip title="Files" aria-label="add">
              //         <Badge
              //           className={classes.button}
              //           color="secondary"
              //           badgeContent={rowData.files.length}
              //           invisible={rowData.files.length > 0 ? false : true}
              //           >
              //           <FileIcon
              //             color={rowData.files.length > 0 ? "primary" : "disabled"} />
              //         </Badge>
              //       </Tooltip>
              //     )
              // },
              //   grouping: false,
              //   filtering: false,
              //   editable: 'never'
              // },
            ]}
            actions={[
              rowData => ({
                icon: 'screen_share',
                tooltip: 'Share with Client',
                onClick: (event, rowData) => rowData.shared_datetime === null ? this.shareCustomerReportDetails(rowData.id) : alert("Already Shared"),
                disabled: rowData.shared_datetime !== null
              })
            ]}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ padding: '0px 10px' }}>
                    <Button variant="contained" color="primary" onClick={() => this.setState({
                      openDialog: true, newCustomerReport: {
                        remark: "",
                        files: [],
                        siteFiles: []
                      }, files: [], filesList: []
                    })}>
                      Create Client Report
                    </Button>
                  </div>
                </div>
              )
            }}
            detailPanel={[
              {
                icon: 'attachment',
                tooltip: 'Files',
                render: rowData => {
                  return (
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <aside style={thumbsContainer}>
                        {
                          rowData.files.length !== 0 ?
                            rowData.files.map(file => (
                              <div style={thumbInnerPreview}>
                                <img
                                  src={file.file}
                                  style={imgPreview}
                                  alt="Arrivae"
                                />
                              </div>
                            )) :
                            <h3>No Files</h3>
                        }
                      </aside>
                    </div>
                  )
                },
              }]}
            data={CustomerReport}
            options={{
              filtering: true,
              exportButton: true,
              actionsColumnIndex: -1
            }}
          />
        }

        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="task-updates">Create Customer Report</DialogTitle>
          {isCreateLoading ?
            <CircularIndeterminate />
            :
            <DialogContent dividers={true}>
              <TextField
                label="Remark"
                value={newCustomerReport.remark}
                onChange={this.handleChange}
                margin="normal"
                multiline
                rows="4"
                fullWidth
              />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <ReactDropzone onDrop={this.onDrop}>
                    Drop your file here!!
                  </ReactDropzone>
                </Grid>
                <Grid item xs={8}>
                  <Typography> Select Files from Site Updates </Typography>
                  <div style={{ overflow: 'scroll', height: '200px' }}>
                    <Grid container spacing={1}>
                      {SiteFilesRes && SiteFilesRes.length !== 0 &&
                        SiteFilesRes.map(file => {
                          if (file.file !== "/images/original/missing.png") {
                            return (
                              <Grid key={file.id} item>
                                <img
                                  src={file.file}
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                    border: newCustomerReport.siteFiles.includes(file.id) ? '5px solid red' : ''
                                  }}
                                  alt="Arrivae"
                                  onClick={() => this.addSiteFileToReport(file)}
                                />
                              </Grid>
                            )
                          }
                        })
                      }
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <aside style={thumbsContainer}>
                    {thumbs}
                  </aside>
                </Grid>
              </Grid>

            </DialogContent>
          }
          <DialogActions>
            <Button
              type="submit"
              onClick={this.onSubmit}
              variant="contained"
              color="primary">
              Create Report
            </Button>
            <Button onClick={() => this.setState({ openDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={filesDialog}
          onClose={() => this.setState({ filesDialog: false })}
          scroll={'paper'}
          aria-labelledby="task-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="task-updates">Files</DialogTitle>
          <DialogContent dividers={true}>
            <aside style={thumbsContainer}>
              {
                filesList.map(file => (
                  <div key={file.id} style={thumbInnerPreview}>
                    <img
                      src={file.file}
                      style={imgPreview}
                      alt="Arrivae"
                    />
                  </div>
                ))
              }
            </aside>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(CustomerReport);
