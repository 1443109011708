import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getVendorLaborPlanList } from '../../../../api/VENDOR/laborReport';

const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror: {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});


class LabourReport extends Component {
  state = {
    LabourReport: [],
    VendorOption: this.props.VendorOption,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ VendorOption: nextProps.VendorOption });
  }

  async componentDidMount() {
    if (this.props.project) {
      try {
        const LabourPlanRes = await getVendorLaborPlanList(this.props.auth.token, this.props.project.id)
        this.setState({
          LabourPlan: LabourPlanRes.data
        })

      } catch (error) {
        console.log(error)
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { LabourPlan, VendorOption, SiteSupervisorOption } = this.state;
    return (
      <div>
        <MaterialTable
          title="Labour Report"
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ margin: '10px' }}>

                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Planned By', field: 'office_user_id',
              lookup: SiteSupervisorOption
            },
            {
              title: 'Planned Date',
              field: 'planned_date',
              render: rowData => moment(rowData.planned_date).format("ll"),
              type: 'date'
            },
            {
              title: "Vendor",
              field: 'vendor_id',
              lookup: VendorOption
            },
            { title: "Planned Plumber Count", field: 'plumber.planned' },
            { title: "Actual Plumber Count", field: 'plumber.actual' },
            { title: "Planned Electrician Count", field: 'electrician.planned' },
            { title: "Actual Electrician Count", field: 'electrician.actual' },
            { title: "Planned Painter Count", field: 'painter.planned' },
            { title: "Actual Painter Count", field: 'painter.actual' },
            { title: "Planned Pop Count", field: 'pop.planned' },
            { title: "Actual Pop Count", field: 'pop.actual' },
            { title: "Planned Installer Count", field: 'installer.planned' },
            { title: "Actual Installer Count", field: 'installer.actual' },
            { title: "Planned Service1 Count", field: 'service1.planned' },
            { title: "Actual Service1 Count", field: 'service1.actual' },
            { title: "Planned Service2 Count", field: 'service2.planned' },
            { title: "Actual Service2 Count", field: 'service2.actual' },
            { title: "Planned Others Count", field: 'others.planned' },
            { title: "Actual Others Count", field: 'others.actual' },
          ]}
          data={LabourPlan}
          options={{
            filtering: true,
            exportButton: true
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(LabourReport);
