import React, { Component } from 'react';
import SwitchUserIcon from '@material-ui/icons/TransferWithinAStation';
import Swal from 'sweetalert2';
import {
  getUsersList,
  changeUserRole,
  loginAsUser,
  validateToken,
  getRoleList,
  createNewUser
} from '../../api';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  loadState,
  saveCurrentUser,
  saveState,
  saveAdmin
} from '../../utils/storage';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { InputLabel } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table'


const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  }
})

class UsersList extends Component {

  state = {
    token: loadState(),
    usersList: [],
    open: false,
    selectopen: false,
    selectValue: "",
    openUserCreateDialog: false,
    roleOptions: [],
    newUser: {
      name: "",
      email: "",
      contact: "",
      roleId: ""
    },
    hasRoleError: false
  }

  handleClickOpen = (id) => {
    this.setState({ open: true, id });
  };

  switchUser = (uid) => {
    const data = {
      uid: uid
    }
    loginAsUser(this.state.token, data)
      .then(response => {
        if (response.status) {
          var accessToken = {
            "access-token": response.data["access-token"],
            "client": response.data.client,
            "uid": response.data.uid
          }

          saveAdmin(this.state.token)
          validateToken(accessToken)
            .then((res) => {
              if (res.status) {
                saveState(accessToken);
                saveCurrentUser(res.data.data);
                window.location.reload(true);
              } else {
                //failed
                console.log("Token is invalid");
              }
            })
        }

      })
      .catch(error => {
        alert(error);
      })
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSelectChange = event => {
    const data = {
      id: this.state.id,
      office_role_id: event.target.value
    }
    changeUserRole(this.state.token, data)
      .then((response) => {
        if (response.status) {
          this.setState({ open: false });
          document.location.reload(true);
        }
      })
      .catch((error) => {
        alert(error);
      })
  };

  handleSelectClose = () => {
    this.setState({ selectopen: false });
  };

  handleSelectOpen = () => {
    this.setState({ selectopen: true });
  };

  handleClickOpenUserCreate = () => {
    this.setState({
      newUser: {
        name: "",
        email: "",
        contact: "",
        roleId: ""
      }
    });
    this.setState({ openUserCreateDialog: true })
  };

  onSubmitCreateUser = (event) => {
    event.preventDefault();
    this.setState({ hasRoleError: false });
    let { newUser } = this.state;
    if (newUser.roleId === undefined || newUser.roleId.length === 0) {
      this.setState({ hasRoleError: true });
    } else {
      createNewUser(this.state.token, newUser)
        .then(response => {
          Swal.fire('Success', response.data.message, 'success')
          console.log('response message',response.data.message)
          if (response.status  ) {
            getUsersList(this.state.token)
              .then((response) => {
                console.log("success !!",response.data.message);              
                if (response.status ===200) {
                  this.setState({ usersList: response.data, openUserCreateDialog:false });
                }
              })
          }
        })
        .catch(error => {
          console.log(error);
          alert("User already exist!");
        })
    }
  };

  componentDidMount() {
    getUsersList(this.state.token)
      .then((response) => {
        if (response.status) {
          this.setState({ usersList: response.data });
        }
      })
      .catch(error => console.log(error))
    getRoleList(this.state.token)
      .then((response) => {
        if (response.status) {
          let roleOptions = response.data
            .filter(item => ['admin', 'site_supervisor', 'project_engineer', 'operational_manager', 'general_manager', 'noc_lead', 'noc_executive'].includes(item.name))
            .map(item => { return { label: item.name, value: item.id } });
          this.setState({ roleOptions });
        }
      })
      .catch((error) => {
        alert(error);
      })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  OnRoleSelect = (e) => {
    let newUser = this.state.newUser
    newUser.roleId = e.target.value
    this.setState({ newUser })
  }

  handleCloseUserCreate = () => {
    this.setState({ openUserCreateDialog: false })
  };

  changeHandler = (e) => {
    let newUser = this.state.newUser;
    newUser[e.target.name] = e.target.value;
    this.setState({ newUser })
  }

  render() {

    const { usersList, newUser } = this.state;
    const { classes } = this.props;
    return (
      <div>


        <MaterialTable
          title="Users List"
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '10px' }}
                  onClick={this.handleClickOpenUserCreate}>Register New User</Button>
              </div>
            )
          }}
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Contact', field: 'contact' },
            {
              title: 'Role Name',
              field: 'role.name',
              lookup: {
                'admin': "Admin",
                'site_supervisor': "Site Supervisor",
                'project_engineer': "Project Engineer",
                'operational_manager': "Operational Manager",
                'general_manager': "General Manager",
                'noc_lead': "NOC Lead",
                'noc_executive': "NOC Executive"
              }
            },
            // {
            //   title: 'Login as User', field: 'office_role_id',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         { rowData.office_role_id !== 1 && <Tooltip title="Login as User">
            //             <IconButton aria-label="Login as User"  onClick={() => this.switchUser(rowData.uid)}>
            //               <SwitchUserIcon />
            //             </IconButton>
            //           </Tooltip>}
            //       </React.Fragment>
            //     )
            //   }
            // },
          ]}
          data={usersList}
          // actions={[{
          //   icon: 'edit',
          //   tooltip: 'Change Role',
          //   onClick: (event, rowData) => this.handleClickOpen(rowData.id)
          // }]}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            exportButton: true,
            grouping: true,
            padding: 'dense',
            pageSize: 20,
            pageSizeOptions: [20, 50, 100]
          }}
        />


        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change User Role</DialogTitle>
          <DialogContent>
            <Select
              value={this.state.selectValue}
              open={this.state.selectopen}
              onClose={this.handleSelectClose}
              onOpen={this.handleSelectOpen}
              onChange={this.handleSelectChange}
              inputProps={{
                name: 'age',
                id: 'demo-controlled-open-select',
              }}
            >
              <MenuItem value="" disabled>Select Role</MenuItem>
              {this.state.roleOptions.length !== 0 && this.state.roleOptions.map((role, i) => {
                return <MenuItem key={i} value={role.value}>{role.label}</MenuItem>
              })}
            </Select>
          </DialogContent>
        </Dialog>


        { /*User Creation Dialog */}
        <Dialog
          open={this.state.openUserCreateDialog}
          onClose={this.handleCloseUserCreate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create User</DialogTitle>
          <ValidatorForm
            className={classes.form}
            onSubmit={this.onSubmitCreateUser}
            onError={errors => console.log(errors)}
            instantValidate={false} >
            <DialogContent>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  name="name"
                  type="text"
                  value={newUser.name}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  fullWidth
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  value={newUser.email}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required', 'isEmail']}
                  errorMessages={['this field is required', 'email is not valid']}
                  fullWidth
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  margin="dense"
                  id="phone"
                  label="Phone Number"
                  type="text"
                  name="contact"
                  value={newUser.contact}
                  onChange={(e) => this.changeHandler(e)}
                  validators={['required', 'matchRegexp:^\\d{10}$']}
                  errorMessages={['this field is required', 'Invalid Phone Number']}
                  fullWidth
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Select Role
                </InputLabel>
                <Select
                  value={this.state.newUser.roleId}
                  onChange={(e) => this.OnRoleSelect(e)}
                  name="roleId"
                  id="role"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="" disabled>Select Role</MenuItem>
                  {this.state.roleOptions.length !== 0 && this.state.roleOptions.map((role, i) => {
                    return <MenuItem key={i} value={role.value}>{role.label}</MenuItem>
                  })}
                </Select>
                {this.state.hasRoleError && <FormHelperText style={{ color: 'red' }}>this field is required</FormHelperText>}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseUserCreate} color="primary">
                Cancel
                </Button>
              <Button color="primary" type="submit">
                Create
                </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

      </div>
    );
  }
}


export default withStyles(styles)(UsersList);