import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Paper from '@material-ui/core/Paper';
import { getProjectBoqs, getBoqDetails, getPurchaseOrder, getMaterialTracking, getClientPayments } from '../../../api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import CircularIndeterminate from '../../Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  rootTabPanel: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing(),
  },
  textCenter:{
    textAlign: 'center',
  },
  rootTable: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});


class ProjectDetails extends Component {
  state = {
    value: 0,
    open: false,
    AllBoqs:[],
    expanded: false,
    setExpanded: false,
    lineItems: [],
    boqID:null,
    PurchaseOrders:[],
    MaterialTracking: [],
    ClientPayments:[],
    isLoading: false,
    innerLoader: false
  };

  handleChange2 = (event, value) => {
    (value === 1 && this.purchaseOrder() ) ||
    (value === 2 && this.clientPayments() )
    // (value === 3 && this.materialTracking() ) ||
    
    this.setState({ value })
  };

  handleChangeIndex = index => {
    (index === 1 && this.purchaseOrder() ) ||
    (index === 2 && this.clientPayments() )
    // (index === 3 && this.materialTracking() ) ||

    this.setState({ value: index });
  };

  componentDidMount(){
    this.setState({isLoading:true})
    const { token, project} = this.props;
    getProjectBoqs(token, project.id)
    .then(response => {
      if (response.status === 200){
        this.setState({AllBoqs:response.data, isLoading:false})
      }
      else{
        alert(response.message);
      }
    })
    .catch(error => {
      this.setState({AllBoqs:[], isLoading: false})      
      console.log(error)
    });
  }

  purchaseOrder = () => {
    this.setState({innerLoader: true})
    const { token } = this.props;
    getPurchaseOrder(token, this.state.boqID)
    .then( response => {
      console.log('po',response);
      if(response.status === 200){
        this.setState({PurchaseOrders: response.data, innerLoader:false})
      }else{
       alert(response.message)
      }
    })
    .catch(error => {
      this.setState({PurchaseOrders: [], innerLoader: false})
      console.log(error)
    });
  };


  // materialTracking = () => {
  //   const {token} = this.props;
  //   getMaterialTracking(token, this.state.boqID)
  //   .then( response => {
  //     if (response.status === 200) {
  //       this.setState({MaterialTracking: response.data})
  //     }else{
  //       alert(response.message)
  //     }
  //   })
  //   .catch( error => { 
  //     console.log(error)
  //   });
  // };

  clientPayments = ()=>{
    this.setState({innerLoader: true})
    const {token} = this.props;
    getClientPayments(token, this.state.boqID)
    .then( response => {
      if (response.status === 200) {
        this.setState({ClientPayments: response.data, innerLoader: false})
      }else{
        alert(response.message)
      }
    })
    .catch( error => { 
      this.setState({ClientPayments: [], innerLoader: false});
      console.log(error)
    });
  }
  
  render(){
    const {classes, token} = this.props;
    
    const { expanded,
      value, 
      AllBoqs, 
      lineItems, 
      PurchaseOrders, 
      isLoading,
      ClientPayments, innerLoader } = this.state;

    const handleChange = (panel,boqID) => (event, isExpanded) => {
    if(isExpanded){
      this.setState({innerLoader:true})      
      getBoqDetails(token,boqID)
      .then( response => {
        if (response.status === 200){
          this.setState({ lineItems: response.data, innerLoader: false })
        }
        else{
          alert("Something went wrong, please try again!");
        }
      })
      .catch( error=>{
        this.setState({ lineItems: [], innerLoader: false })
        console.log(error)
      });
    }  
    this.setState({expanded:(isExpanded ? panel : false), value:0, boqID})
    }
      
    var finalBoqs = AllBoqs.filter(function(boq){
    return(boq.wip_status === "10_50_percent")
    });

    var filteredPOs = PurchaseOrders.filter(function(po){
    return(po.status !== "cancelled")
    });

    return(
      <div className={classes.root}>
        <Typography className={classes.heading}>Final BOQs:</Typography>
        { isLoading ?
            <CircularIndeterminate/>
          :  
            finalBoqs.length !== 0 ?
              finalBoqs.map((b)=>{
                return(
                  <ExpansionPanel key={b.id} expanded={expanded === `panel${b.id}`} onChange={handleChange(`panel${b.id}`,b.id)}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${b.id}bh-content`}
                      id={`panel${b.id}bh-header`}
                      className={classes.rootTable}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                          {b.reference_number}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <strong>Date of 40% Payment</strong> <br/>
                          {b.get_parent_quotation.per_50_approved_at !== null ? moment(b.get_parent_quotation.per_50_approved_at).format('ll') : 'NA'}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <strong>Parent BOQ</strong> <br/>
                          {b.get_parent_quotation.parent_quotation}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{padding: '0px'}}>
                      <Paper className={classes.rootTabPanel}>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange2}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab label="BOQ" />
                            <Tab label="Purchase Orders" />
                            {/* <Tab label="material Tracking" /> */}
                            <Tab label="Client Payment Details" />
                          </Tabs>
                        </AppBar>
                        <SwipeableViews
                          index={this.state.value}
                          onChangeIndex={this.handleChangeIndex}
                        >
                      
                          <TabPanel value={value} index={0} className={classes.rootTabPanel}>
                            <Paper></Paper>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Space</TableCell>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="left">Type of Furniture</TableCell>
                                  <TableCell align="left">Description</TableCell>
                                  <TableCell align="left">Unit of Measurement</TableCell>
                                  <TableCell align="left">Quantity</TableCell>
                                  <TableCell align="left">BOQ label</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                { innerLoader?
                                    <CircularIndeterminate/>
                                  :
                                    lineItems !== [] ?
                                      Object.keys(lineItems).map(key =>
                                        lineItems[key].map((l) => {
                                          return (
                                            <TableRow key={l.id}>
                                              <TableCell align="left">{l.space}</TableCell>
                                              <TableCell align="left">{l.product !== null ? l.product : 'NA'}</TableCell>
                                              <TableCell align="left">{l.type}</TableCell>
                                              <TableCell align="left" style={{whiteSpace:'pre-wrap'}}>{l.name}</TableCell>
                                              <TableCell align="left">{l.measurement_unit}</TableCell>
                                              <TableCell align="left">{l.quantity}</TableCell>
                                              <TableCell align="left">{l.label}</TableCell>
                                            </TableRow>)
                                        })
                                      )
                                    : 
                                      <TableRow>
                                        <TableCell colSpan={6} align="center">
                                          <div className={classes.heading}> No Purchase Order Found!</div>
                                        </TableCell>
                                      </TableRow>   
                                }
                              </TableBody>
                            </Table>
                          </TabPanel>

                          <TabPanel value={value} index={1} className={classes.rootTabPanel}>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">PO Number</TableCell>
                                  <TableCell align="left">Shipping Address</TableCell>
                                  <TableCell align="left">Vendor name</TableCell>
                                  <TableCell align="left">PO Release date </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                { innerLoader?
                                    <CircularIndeterminate/>
                                  :
                                    filteredPOs.length !== 0 ?
                                      filteredPOs.map((p) => {
                                        return (
                                          <TableRow key={p.id}>
                                            <TableCell align="left">{p.reference_no}</TableCell>
                                            <TableCell align="left">{p.shipping_address}</TableCell>
                                            <TableCell align="left">{p.vendor&& p.vendor.name}</TableCell>
                                            <TableCell align="left">{p.created_at !== null ? moment(p.created_at).format("ll") : 'NA'}</TableCell>
                                          </TableRow>
                                        )
                                      }) 
                                    :
                                      <TableRow>
                                        <TableCell colSpan={6} align="center">
                                          <div className={classes.heading}> No Purchase Order Found!</div>
                                        </TableCell>
                                      </TableRow>
                                }
                              </TableBody>
                            </Table>
                          </TabPanel>

                          {/* <TabPanel value={value} index={2} className={classes.rootTabPanel}>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">PO Number</TableCell>
                                  <TableCell align="left">Vendor Name</TableCell>
                                  <TableCell align="left">Element Name</TableCell>
                                  <TableCell align="left">Quantity</TableCell>
                                  <TableCell align="left">Delivery Date</TableCell>
                                  <TableCell align="left">Dispatch Date</TableCell>
                                  <TableCell align="left">Dispatch Readiness Date</TableCell>
                                  <TableCell align="left">Current Address</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {MaterialTracking.length !== 0 ?
                                MaterialTracking.map((m)=>{
                                    return (
                                      <TableRow key={m.id}>
                                        <TableCell align="left">{}</TableCell>
                                        <TableCell align="left">{m.vendor_name}</TableCell>
                                        <TableCell align="left">{m.element_name}</TableCell>
                                        <TableCell align="left">{m.quantity === null ? "NA" : m.quantity}</TableCell>
                                        <TableCell align="left">{m.delivery_status === null ? "NA" : m.delivery_status }</TableCell>
                                        <TableCell align="left">{m.dispatch_status  === null ? "NA" : m.dispatch_status}</TableCell>
                                        <TableCell align="left">{m.dispatch_readiness_date  === null ? "NA" : m.dispatch_readiness_date}</TableCell>
                                        <TableCell align="left">{m.current_address}</TableCell>
                                      </TableRow>
                                    )
                                  }
                                  ) :
                                  <TableRow>
                                    <TableCell colSpan={6} align="center">
                                      <div className={classes.heading}>No Tracking Record Found!</div>
                                    </TableCell>
                                  </TableRow>
                                }
                              </TableBody>
                            </Table>
                          </TabPanel> */}
                            
                          <TabPanel value={value} index={2} className={classes.rootTabPanel}>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Ref No.</TableCell>
                                  <TableCell align="left">Pay Stage</TableCell>
                                  <TableCell align="left">Amount(in%)</TableCell>
                                  <TableCell align="left">BOQ Value</TableCell>
                                  <TableCell align="left">Verification</TableCell>
                                  <TableCell align="left">Payment Date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                { innerLoader?
                                    <CircularIndeterminate/>
                                  :
                                    ClientPayments.length !== 0 ?
                                      ClientPayments.map((p)=>{
                                        return (
                                          <TableRow key={p.id}>
                                            <TableCell align="left">{p.boq_references.reference_number}</TableCell>
                                            <TableCell align="left">
                                            { (p.payment_stage === "pre_10_percent" && "10% ") || 
                                              (p.payment_stage === "10_50_percent" && "50% ") ||
                                              (p.payment_stage === "final_payment") || "NA "
                                            } stage
                                            </TableCell>
                                            <TableCell align="left">{`${p.amount} (${(((p.amount)/(p.boq_references.total_amount))*100).toFixed(2)}%)`}</TableCell>
                                            <TableCell align="left">{p.boq_references.total_amount}</TableCell>
                                            <TableCell align="left">{(p.finance_approved_at === null) ? "NA" : "Finance Verified"}</TableCell>
                                            <TableCell align="left">{(p.finance_approved_at !== null) ? moment(p.finance_approved_at).format('ll') : "NA"}</TableCell>
                                          </TableRow>
                                        )
                                      })
                                    :
                                      <TableRow>
                                        <TableCell colSpan={6} align="center">
                                          <div className={classes.heading}> No Payments Found!</div>
                                        </TableCell>
                                      </TableRow>
                                }
                              </TableBody>
                            </Table>
                          </TabPanel>
                        </SwipeableViews>
                      </Paper>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>                
                );
              })
            :
              <div className={classes.heading}> No Final BOQs found!</div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(ProjectDetails);
