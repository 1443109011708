import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Badge, Grid, TextField } from '@material-ui/core';
import FileIcon from '@material-ui/icons/AttachFile';
import { getSiteUpdateList, getSiteUpdatePMFilter, getDynamicSiteUpdateFilterPMDropdowns } from '../../api'
import Checkbox from '@material-ui/core/Checkbox';
import Swal from 'sweetalert2';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const typeList = [{id:0, name:"Project", value: "Project"},{id:1, name:"Task", value: "OfficeTask"},{id:2, name:"Site", value: "OfficeUserSiteMeasurementRequest"}];
const taskTypeList = [{id:0, name:"Services", value: "services"}, {id:1, name:"Installation", value: "installation"}];
const isClientMomList = [{id: 0, name:"YES", value: "true"}, {id: 1, name:"NO", value: "false"}];


const styles = theme => ({
  card: {
    marginTop: '20px'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  sserror: {
    color: 'red',
    fontSize: 'small'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }
});

const img = {
  display: 'block',
  width: '50vh',
  height: '50vh'
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

class SiteUpdate extends Component {
  state = {
    isLoading: true,
    SiteUpdate: [],
    openDialog: false,
    files: [],
    filesDialog: false,
    filesList: [],
    selectedPms: [],
    selectedPmsName: [],
    totalEnteries: 0,
    rowsPerPage: 15,
    totalPages: 0,
    fromDate:"",
    toDate:"",
    typeFilters: [],
    typeFilterArray: [],
    taskTypeFilters: [],
    taskTypeFilterArray: [],
    vendorList: [],
    vendorFilters: [],
    vendorFilterArray: [],
    siteSupervisorList: [],
    siteSupervisors: [],
    siteSupervisorFilterArray: [],
    isClientMomFilters: [],
    isClientMomFilterArray: [],
    searchString:"",
  };

  componentDidMount() {
    getSiteUpdateList(this.props.auth.token)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            SiteUpdate: res.data,
            page:0,
            isLoading: false,
            totalEnteries: res.headers['total-entries'],
            totalPages: res.headers['total-pages']
          })
        }
      })
      .catch(error => console.log(error))

    getDynamicSiteUpdateFilterPMDropdowns(this.props.auth.token,  this.state.selectedPms)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            vendorList: res.data.vendor,
            siteSupervisorList: res.data.site_supervisor
          })
        }
      })
      .catch(error => console.log(error));
  }

  setSearchValHandler = (val) => {
    this.setState({ searchString: val })
  }

  onDateFromChange = (e) => {
    this.setState({
      fromDate: e.target.value
    })
  }

  onDateToChange = (e) => {
    this.setState({
      toDate: e.target.value
    })
  }

  handleTypeAutoComplete = (event, newValue) => {
    this.setState({
      typeFilters: newValue.map(v => v.value) || [],
      typeFilterArray: newValue || []
    })
  }

  handleTaskTypeAutoComplete = (event, newValue) => {
    this.setState({
      taskTypeFilters: newValue.map(v => v.value) || [],
      taskTypeFilterArray: newValue || []
    })
  }

  handleIsClientMomAutoComplete = (event, newValue) => {
    this.setState({
      isClientMomFilters: newValue.map(v => v.value) || [],
      isClientMomFilterArray: newValue || []
    })
  }

  handleVendorAutoComplete = (event, newValue) => {
    this.setState({
      vendorFilters: newValue.map(v => v.id) || [],
      vendorFilterArray: newValue || []
    })
  }

  handleSiteSupervisorAutoComplete = (event, newValue) => {
    this.setState({
      siteSupervisors: newValue.map(v => v.id) || [],
      siteSupervisorFilterArray: newValue || []
    })
  }
  
  resetAllFilter = () => {
    this.setState({
      isLoading: true,
      totalEnteries: 0,
      rowsPerPage: 15,
      totalPages: 0,
      fromDate:"",
      toDate:"",
      typeFilters: [],
      typeFilterArray: [],
      taskTypeFilters: [],
      taskTypeFilterArray: [],
      vendorFilters: [],
      vendorFilterArray: [],
      siteSupervisors: [],
      siteSupervisorFilterArray: [],
      isClientMomFilters: [],
      isClientMomFilterArray: [],
      searchString:"",
    })
    getSiteUpdateList(this.props.auth.token)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            SiteUpdate: res.data,
            page:0,
            isLoading: false,
            totalEnteries: res.headers['total-entries'],
            totalPages: res.headers['total-pages']
          })
        }
      })
      .catch(error => console.log(error))
  }

  handleFilter = (e) => {
    this.setState({ isLoading: true });
    const {
      searchString,
      fromDate,
      toDate,
      typeFilters,
      taskTypeFilters,
      vendorFilters,
      siteSupervisors,
      isClientMomFilters,
      selectedPms } = this.state;
    let page_num = 1
    this.props.history.push(`/pe/site-update/${page_num}`)
    getSiteUpdatePMFilter(this.props.auth.token, selectedPms, page_num, searchString, fromDate, toDate, typeFilters, taskTypeFilters, vendorFilters, siteSupervisors, isClientMomFilters)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            SiteUpdate: res.data,
            isLoading: false,
            page:0,
            totalEnteries: res.headers['total-entries'],
            totalPages: res.headers['total-pages']
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleKeyPress = async(e) => {
    const { searchString,
      fromDate,
      toDate,
      typeFilters,
      taskTypeFilters,
      vendorFilters,
      siteSupervisors,
      isClientMomFilters,
      selectedPms } = this.state;
    let page_num = 1
    if(e.key == "Enter") {
      this.setState({
        isLoading: true,
      })
      this.props.history.push(`/pe/site-update/${page_num}`)
      await getSiteUpdatePMFilter(this.props.auth.token, selectedPms, page_num, searchString, fromDate, toDate, typeFilters, taskTypeFilters, vendorFilters, siteSupervisors, isClientMomFilters)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              SiteUpdate: res.data,
              isLoading: false,
              page:1,
              totalEnteries: res.headers['total-entries'],
              totalPages: res.headers['total-pages']
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  handleChangePage = async(event, page) => {
    this.setState({ page, isLoading: true });
    const {
      searchString,
      fromDate,
      toDate,
      typeFilters,
      taskTypeFilters,
      vendorFilters,
      siteSupervisors,
      isClientMomFilters, 
      selectedPms } = this.state;
    let page_num = page + 1
    
    if((searchString && searchString.length > 0) || fromDate || toDate || typeFilters || taskTypeFilters || vendorFilters || siteSupervisors || isClientMomFilters) {
      this.props.history.push(`/pe/site-update/${page_num}`)
      getSiteUpdatePMFilter(this.props.auth.token, selectedPms, page_num, searchString, fromDate, toDate, typeFilters, taskTypeFilters, vendorFilters, siteSupervisors, isClientMomFilters)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              SiteUpdate: res.data,
              isLoading: false,
              totalEnteries: res.headers['total-entries'],
              totalPages: res.headers['total-pages']
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }else {
      this.props.history.push(`/pe/site-update/${page_num + 1}`)
      getSiteUpdatePMFilter(this.props.auth.token, page_num, searchString, fromDate, toDate, typeFilters, taskTypeFilters, vendorFilters, siteSupervisors, isClientMomFilters)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              SiteUpdate: res.data,
              isLoading: false,
              totalEnteries: res.headers['total-entries'],
              totalPages: res.headers['total-pages']
            })
          } else {
            Swal.fire({ title: res?.data?.message })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  render() {
    const { classes } = this.props;
    const { SiteUpdate, files, filesDialog, filesList, assignedPmToOmListOption, selectedPms, selectedPmsName,
      typeFilterArray, taskTypeFilterArray, isClientMomFilterArray, totalEnteries, page, isLoading,
      rowsPerPage, vendorList, vendorFilterArray, siteSupervisorList, siteSupervisorFilterArray } = this.state;

    let url = new URL(window.location.href)
    let page_number = this.props?.project?.id ? (page + 1) : (parseInt(url.pathname.split('/').reverse()[0]) || (page + 1))

    return (
      <div>
        <MaterialTable
          title="Site Update"
          isLoading={isLoading}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Grid container spacing={2} className="w-auto">
                  <Grid item xs={9}>
                  </Grid>
                  <Grid item xs={3}>
                    {!this.props?.project && 
                      <Grid item xs={6} style={{maxWidth: '100%'}}>
                        <div style={{ paddingRight: "20px", textAlign:"right"}}>
                            <TextField
                              label="Search"
                              value={this.state?.searchString}
                              onChange={(e) => this.setSearchValHandler(e.target.value)}
                              onKeyPress={this.handleKeyPress}
                              autoFocus={true}
                              className={"w-100"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                        </div>
                      </Grid>}
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="w-auto m-2">
                  <Grid item xs={3} >
                    <TextField
                      id="date"
                      label="From"
                      type="date"
                      defaultValue=""
                      className="w-100"
                      value={this.state.fromDate}
                      onChange={(e) => this.onDateFromChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="date"
                      label="To"
                      type="date"
                      defaultValue=""
                      className="w-100"
                      value={this.state.toDate}
                      onChange={(e) => this.onDateToChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={typeFilterArray}
                      options={typeList}
                      getOptionSelected={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Type" />
                      )}
                      onChange={(event, newValue) => this.handleTypeAutoComplete(event, newValue)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={taskTypeFilterArray}
                      options={taskTypeList}
                      getOptionSelected={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Task Type" />
                      )}
                      onChange={(event, newValue) => this.handleTaskTypeAutoComplete(event, newValue)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={vendorFilterArray}
                      options={vendorList}
                      getOptionSelected={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Vendor" />
                      )}
                      onChange={(event, newValue) => this.handleVendorAutoComplete(event, newValue)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={siteSupervisorFilterArray}
                      options={siteSupervisorList}
                      getOptionSelected={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Site Supervisor" />
                      )}
                      onChange={(event, newValue) => this.handleSiteSupervisorAutoComplete(event, newValue)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      value={isClientMomFilterArray}
                      options={isClientMomList}
                      getOptionSelected={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} style={{padding:"16px 0px"}} className="textField" placeholder="Is Client MOM" />
                      )}
                      onChange={(event, newValue) => this.handleIsClientMomAutoComplete(event, newValue)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item style={{padding:"10px"}}>
                        <Button variant="contained" color="primary" onClick={() => this.handleFilter()}>Filter Task List</Button>
                      </Grid>
                      <Grid item style={{padding:"10px"}}>
                        <Button variant="contained" color="primary" onClick={() => this.resetAllFilter()}>Reset All Filters</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ),
          }}
          columns={[
            { title: 'ID', field: 'lead_id' },
            { title: 'Name', field: 'lead_name' },
            {
              title: 'Files',
              field: 'files',
              render: rowData => {
                return (
                  <Tooltip title="Files" aria-label="add">
                    <Badge
                      className={classes.button}
                      color="secondary"
                      badgeContent={rowData.files.length}
                      invisible={rowData.files.length > 0 ? false : true}
                    >
                      <FileIcon color={rowData.files.length > 0 ? "primary" : "disabled"} />
                    </Badge>
                  </Tooltip>
                )
              },
              grouping: false,
              filtering: false,
              editable: 'never'
            },
            {
              title: 'Type', field: 'type',
              lookup: {
                "Project": "Project",
                "OfficeTask": "Task",
                "OfficeUserSiteMeasurementRequest": "Site Measurement Request"
              }
            },
            { title: 'Remark', field: 'remark' },
            {
              title: 'Created on',
              field: 'created_on',
              render: rowData => moment(rowData.created_on).format("ll"),
              type: 'date'
            },
            { title: 'Task name', field: 'task_name' },
            {
              title: 'Task type',
              field: 'task_type',
              lookup: {
                services: "Services",
                installation: "Installation"
              }
            },
            { title: 'Task information', field: 'task_description' },
            { title: 'vendor', field: 'vendor' },
            { title: 'PM Name', field: 'pm_name' },
            { title: 'SS Name', field: 'ss_name' },
            {
              title: 'Is Client MoM',
              field: 'is_client_mom',
              lookup: {
                true: "Yes",
                false: "No"
              }
            },
          ]}
          onRowClick={(event, rowData, toggle) => rowData.files.length > 0 ? this.setState({ filesDialog: true, filesList: rowData.files }) : alert("No files to show!!")}
          data={SiteUpdate}
          options={{
            // filtering: true,
            // exportButton: true,
            search: false,
            paging: false,
          }}
        />
        <div style={{float:"right"}}>
          <TablePagination
            colSpan={8}
            count={totalEnteries}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page_number - 1}
            onChangePage={this.handleChangePage}
            ActionsComponent={TablePaginationWrapper}
          />
        </div>

        <Dialog
          open={filesDialog}
          onClose={() => this.setState({ filesDialog: false })}
          scroll={'paper'}
          aria-labelledby="site-updates"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="site-updates">Files</DialogTitle>
          <DialogContent dividers={true}>
            <aside style={thumbsContainer}>
              {
                filesList.map((file, index) => (
                  <div key={index} style={thumbInner}>
                    <img
                      src={file.file}
                      style={img}
                      alt="Arrivae"
                    />
                  </div>
                ))
              }
            </aside>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ filesDialog: false })} color="primary">
              Cancel
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

export default compose(withStyles(styles),
  connect(mapStateToProps))(SiteUpdate);