import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Paper from '@material-ui/core/Paper';
import { getProjectFiles, getOfficeUpdateFilesList } from '../../../api';
import CircularIndeterminate from '../../Loader';
import MaterialTable from 'material-table';
import moment from 'moment'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width:'100%',
  },
  button: {
    margin: theme.spacing(),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight:'500',
    textAlign:'center',
  },
  headingLeft: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight:'500',
    textAlign:'flexStart',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textCenter:{
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: 1,
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

class ProjectFiles extends Component {
  state = {
    value: 0,
    open: false,
    allFiles:[],
    expanded: false,
    setExpanded: false,
    Folders: ["floorplans","site_gallery","cad_drawings","elevations","reference_images", "three_d_images", "line_markings"],
    isLoading: true,
    siteFiles: []
  };

  componentDidMount() {
    getOfficeUpdateFilesList(this.props.token, this.props.project.id)
    .then(res => {
      if (res.status === 200) {
        this.setState({ siteFiles: res.data })
      }
    })
    .catch(error => {
      this.setState({ siteFiles: [], isLoading:false })
      console.log(error)  
    })
  }

  handleChange2 = (event, value) => {
    this.setState({ value })
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  humanize(str) {
    var frags = str.split('_');
    for (var i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  
  render(){
    const {classes, project, token} = this.props;
    const {
      allFiles,
      Folders,
      expanded,
      value,
      isLoading, siteFiles } = this.state;

    const handleChange = panel => (event, isExpanded) => {
      this.setState({ expanded:(isExpanded ? panel : false), isLoading: true});
      getProjectFiles(token,project.id,panel)
      .then( response => {
        this.setState({allFiles: response.data, isLoading: false})
      })
      .catch( error => {
        this.setState({allFiles: [], isLoading: false})
        console.log(error)
      })
    }

    return(
      <div className={classes.root}>
      	<Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange2}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Files From Designer" />
                  <Tab label="Files From Site" />
                </Tabs>
              </AppBar>
              <SwipeableViews
                index={this.state.value}
                onChangeIndex={this.handleChangeIndex}
              >
                { value === 0 && <TabContainer className={classes.root}>
                  { Folders.map((f)=>{
                      return(
                        <ExpansionPanel key={f} expanded={expanded === f} onChange={handleChange(f)}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${f}bh-content`}
                            id={`${f}bh-header`}
                          >
                            <Grid container key={f} spacing={8}>
                              <Grid item className={classes.textCenter} xs={2}>
                                <FolderIcon color="primary"/>
                              </Grid>
                              <Grid item className={classes.textCenter} xs={4}>{this.humanize(f)}</Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container  spacing={0}>
                            { isLoading ?
                                <CircularIndeterminate/>
                              :
                                allFiles.length !== 0 ?
                                  allFiles.map((file,index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <Grid item xs={8}>{file.name}</Grid>
                                        <Grid item className={classes.textCenter} xs={3}>
                                          <a href={file.url} target="_blank" download={file.name}>
                                            <CloudDownloadIcon color="primary"/>
                                          </a>
                                        </Grid>
                                      </React.Fragment>
                                    );
                                  })
                                :
                                  <div>No files found.</div>
                                }
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      );
                    })
                  }
                  </TabContainer>
                }

                {value === 1 &&
                  <TabContainer>
                    <MaterialTable
                      title=""
                      columns={[
                        { title: 'File Name', field: 'file_file_name' },
                        {
                          title: 'Uploaded on',
                          field: 'created_at',
                          render: rowData => moment(rowData.created_at).format("ll"),
                          type: 'date'
                        },
                      ]}
                      data={siteFiles.filter(file => file.file !== "/images/original/missing.png")}    
                      actions={[
                        {
                          icon: 'cloud_download',
                          tooltip: 'Download',
                          onClick: (event, rowData) => window.open(rowData.file),
                        }]
                      }
                      options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        grouping: true
                      }}
                    />
                  </TabContainer>
                }
              </SwipeableViews>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(ProjectFiles);
