import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core';
import moment from 'moment';
import { updateOfficeProjectHandover } from '../../../../api'

class ProjectHandover extends Component {

  state = {
    office_project_handover_details: this.props.project.office_project_handover_details
  }

  approveNow = () => {
    const { project } = this.props;
    const data = {
      id: project.office_project_handover_details.id,
      is_approved: true
    }
    updateOfficeProjectHandover(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          this.setState({ office_project_handover_details: res.data })
        }
      })
      .catch(error => console.log(error))
  }

  render() {
    const { office_project_handover_details } = this.state;
    return (
      (office_project_handover_details && office_project_handover_details.length !== 0) ?
        <div>
          <Card>
            <CardHeader
              action={
                <>
                  {!office_project_handover_details.is_approved &&
                    <Button variant="contained" color="primary" onClick={this.approveNow}>
                      Approve Now
                  </Button>
                  }
                </>
              }
              title="Project Handover Details"
            />
            <CardContent>
              <Grid container spacing={0}>

                <Grid item xs={12} sm={4}><strong> Approval Status: </strong></Grid>
                <Grid item xs={12} sm={8}>{office_project_handover_details.is_approved ? "Yes" : "No"}</Grid>

                {office_project_handover_details.is_approved &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Approved By(Project Manager): </strong></Grid>
                    <Grid item xs={12} sm={8}>
                      {office_project_handover_details.approved_by_details.name} <br />
                      {office_project_handover_details.approved_by_details.email} <br />
                      {office_project_handover_details.approved_by_details.contact} <br />
                    </Grid>
                  </>
                }
                {office_project_handover_details.raised_by_details !== undefined &&
                  office_project_handover_details.raised_by_details.length !== 0 &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor): </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.name}</Grid>

                    <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor) Details: </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.email}</Grid>

                    <Grid item xs={12} sm={4}><strong></strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.raised_by_details.contact}</Grid>
                  </>
                }

                <Grid item xs={12} sm={4}><strong> Raised on: </strong></Grid>
                <Grid item xs={12} sm={8}>{moment(office_project_handover_details.created_at).format('lll')}</Grid>

                {office_project_handover_details.is_approved &&
                  <>
                    <Grid item xs={12} sm={4}><strong> Approved Date: </strong></Grid>
                    <Grid item xs={12} sm={8}>{office_project_handover_details.approved_date && moment(office_project_handover_details.approved_date).format('lll')}</Grid>
                  </>
                }

                {
                  office_project_handover_details.files.length !== 0 &&
                  office_project_handover_details.files.map((file, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={4}>
                          <img
                            src={file.file}
                            alt={file.file_file_name}
                            height="200"
                            weight="200"
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  })
                }
              </Grid>
            </CardContent>
          </Card>
        </div>
        : null
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default connect(mapStateToProps)(ProjectHandover);
