import React, { Component } from 'react';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../../Loader';
import {
  getSiteSupervisorsOfPm, assignSSToProject
} from '../../../../api'
import { connect } from 'react-redux';
import {
  Button
} from '@material-ui/core';
import Swal from 'sweetalert2';

class AssignProjectManager extends Component {
  state = {
    isLoading: false,
    SSList: [],
    isMapLoading: false,
    showDialog: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })

    await getSiteSupervisorsOfPm(this.props.token, { id: this.props.current_user.id, project_id: this.props.project.id })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            SSList: res.data,
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false })
      })
  }

  assignSS = (office_user_id) => {
    this.setState({ isLoading: true })
    const data = {
      office_user_id: office_user_id,
      project_id: this.props.project.id
    }
    assignSSToProject(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          getSiteSupervisorsOfPm(this.props.token, { id: this.props.current_user.id, project_id: this.props.project.id })
            .then(res => {
              if (res.status === 200) {
                this.setState({
                  SSList: res.data,
                  isLoading: false
                })
                Swal.fire('Success', 'Site Supervisor assignment updated', 'success')
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ isLoading: false })
            })
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        })
      })
  }

  render() {
    const { SSList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Assign Site Supervisor"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects' },
            ]}
            data={SSList}
            actions={[
              {
                icon: 'save',
                tooltip: 'Save User',
                onClick: (event, rowData) => this.assignSS(rowData.id)
              }
            ]}
            components={{
              Action: props => {
                return (
                  props.data.is_ss_assigned ?
                    <Button
                      onClick={(event) => props.action.onClick(event, props.data)}
                      color="secondary"
                      variant="contained"
                      size="small"
                    >
                      Unassign
                </Button>
                    :
                    <Button
                      onClick={(event) => props.action.onClick(event, props.data)}
                      color="primary"
                      variant="contained"
                      size="small"
                    >
                      Assign
                </Button>
                )
              }
            }}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true
            }}
          />
        }
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token,
    current_user: state.auth.current_user
  }
};

export default connect(mapStateToProps)(AssignProjectManager);