import React, { Component } from 'react';
import { getOfficeUserListByRole, getAssignedCMToOMList, mapCMToOM } from '../../../api';
import MaterialTable from 'material-table';
import CircularIndeterminate from '../../Loader';
import { connect } from 'react-redux';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';

class OmCmMapping extends Component {

  state = {
    isLoading: false,
    OperationalManagers: [],
    showDialog: false,
    CMList: [],
    seletedOM: null,
    isMapLoading: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    try {

      let OperationalManagerRes = await getOfficeUserListByRole(this.props.token, 'operational_manager')
      this.setState({
        OperationalManagers: OperationalManagerRes.data,
        isLoading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  AssignCMModel = async (rowData) => {
    await getAssignedCMToOMList(this.props.token, rowData.id)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            CMList: res.data,
            showDialog: true,
            seletedOM: rowData.id
          })
        }
      })
      .catch(error => console.log(error))
  }

  assignCMToOM = (user_id) => {
    this.setState({ isMapLoading: true })
    let data = {
      office_user_id: this.state.seletedOM,
      user_id: user_id
    }
    mapCMToOM(this.props.token, data)
      .then(res => {
        if (res.status === 200) {
          getAssignedCMToOMList(this.props.token, this.state.seletedOM)
            .then(res => {
              if (res.status === 200) {
                this.setState({
                  CMList: res.data,
                  isMapLoading: false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.setState({ isMapLoading: false })
            })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isMapLoading: false })
      })
  }

  render() {
    const { OperationalManagers, isLoading, showDialog, CMList, isMapLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ?
          <CircularIndeterminate />
          :
          <MaterialTable
            title="Operational Manager List"
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Contact', field: 'contact' },
              { title: 'No. Active Projects', field: 'active_projects.count' },
              {
                title: 'Assigned Community Manager',
                field: 'id',
                render: rowData => <AssignmentIndIcon
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.AssignCMModel(rowData)} />,
                grouping: false,
                filtering: false
              }
            ]}
            data={OperationalManagers}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              grouping: true
            }}
          />
        }
        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='lg'
          onClose={() => this.setState({ showDialog: false })}
          scroll='paper'
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Assign Community Manager
            </DialogTitle>
          <DialogContent dividers={true}>
            {isMapLoading ?
              <CircularIndeterminate />
              :
              <MaterialTable
                title=""
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Email', field: 'email' },
                  { title: 'Contact', field: 'contact' },
                  { title: 'No. Active Projects', field: 'active_projects' }
                ]}
                data={CMList}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  grouping: true,
                  pageSize: 20,
                  pageSizeOptions: [20, 50, 100]
                }}
                actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => this.assignCMToOM(rowData.id)
                  }
                ]}
                components={{
                  Action: props => {
                    return (
                      props.data.is_cm_assigned ?
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          Unassign
                    </Button>
                        :
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Assign
                    </Button>
                    )
                  }
                }}

              />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })} color="primary">
              Close
              </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default connect(mapStateToProps)(OmCmMapping);