import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getSiteSupervisorList } from '../../api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationWrapper from '../Shared/TablePaginationWrapper';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  appBar: {
    position: 'relative',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

class SiteSupervisor extends Component {
  state = {
    SiteSupervisor: [],
    page: 0,
    rowsPerPage: 5,
  }

  componentDidMount() {
    getSiteSupervisorList(this.props.token, 'site_supervisor')
    .then(response => {
      if (response.status) {
        this.setState({
          SiteSupervisor: response.data,
        });
      }
    })
  }
 
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  

  render() {
    const { SiteSupervisor } = this.state;
    return ( 
      <div>
        <Typography variant="h4" gutterBottom style={{marginTop: '20px'}}>
          Site Supervisors List
        </Typography>
        <div className="table-container">
          { SiteSupervisor.length !== 0 &&   
            <Paper className="user-list-root">
              <Table className="user-list-table">
                  <TableHead>
                    <TableRow>
                      <TableCell numeric style={{textAlign: 'left'}}>Name</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Email</TableCell>
                      <TableCell numeric style={{textAlign: 'left'}}>Contact</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SiteSupervisor.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(n => {
                      return (
                        <TableRow key={n.id}>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.name}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.email}</TableCell>
                          <TableCell numeric style={{textAlign: 'left'}}>{n.contact}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={4}
                      count={SiteSupervisor.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationWrapper}
                    />
                  </TableRow>
                </TableFooter>
                </Table>
              </Paper>
          }
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),
connect(mapStateToProps))(SiteSupervisor);